import propTypeModels from 'propTypeModels';

import {
  GET_LIST_MEDIA,
  GET_LIST_MEDIA_SUCCESS,
  GET_LIST_MEDIA_FAILED,

  GET_MEDIA_DETAIL,
  GET_MEDIA_DETAIL_SUCCESS,
  GET_MEDIA_DETAIL_FAILED,

  UPLOAD_MEDIA,
  UPLOAD_MEDIA_SUCCESS,
  UPLOAD_MEDIA_FAILED,

  UPDATE_MEDIA,
  UPDATE_MEDIA_SUCCESS,
  UPDATE_MEDIA_FAILED,

  DELETE_MEDIA,
  DELETE_MEDIA_SUCCESS,
  DELETE_MEDIA_FAILED,

  CLEAR_MEDIA_DETAIL,
} from './actionTypes';

const initialState = {
  loading: false,
  loadingDetail: false,
  loadingUpdate: false,
  loadingUpload: false,

  mediaTotal: 0,

  mediaList: [],
  mediaDetail: propTypeModels.media.defaultData,
};

const media = (state = initialState, action) => {
  switch (action.type) {
    // GET LIST MEDIA
    case GET_LIST_MEDIA:
      return {
        ...state,
        loading: true,
      };

    case GET_LIST_MEDIA_SUCCESS:
      return {
        ...state,
        loading: false,
        mediaList: action.payload.list,
        mediaTotal: action.payload.total,
      };

    case GET_LIST_MEDIA_FAILED:
      return {
        ...state,
        loading: false,
      };

    // GET MEDIA DETAIL
    case GET_MEDIA_DETAIL:
      return {
        ...state,
        loadingDetail: true,
      };

    case GET_MEDIA_DETAIL_SUCCESS:
      return {
        ...state,
        loadingDetail: false,
        mediaDetail: action.payload,
      };

    case GET_MEDIA_DETAIL_FAILED:
      return {
        ...state,
        loadingDetail: false,
      };

      // UPLOAD MY AVATAR
    case UPLOAD_MEDIA:
      return {
        ...state,
        loadingUpload: true,
      };

    case UPLOAD_MEDIA_SUCCESS:
      return {
        ...state,
        loadingUpdate: false,
        mediaList: [
          action.payload,
          ...state.mediaList,
        ],
        mediaTotal: state.mediaTotal + 1,
      };

    case UPLOAD_MEDIA_FAILED:
      return {
        ...state,
        loadingUpload: false,
      };

    // UPDATE MEDIA
    case UPDATE_MEDIA:
      return {
        ...state,
        loadingUpdate: true,
      };

    case UPDATE_MEDIA_SUCCESS: {
      const newListMedia = state.mediaList.map((m) => {
        if (m._id === action.payload._id) {
          return action.payload;
        }
        return m;
      });

      return {
        ...state,
        loadingUpdate: false,
        mediaList: newListMedia,
      };
    }

    case UPDATE_MEDIA_FAILED:
      return {
        ...state,
        loadingUpdate: false,
      };

    // DELETE MEDIA
    case DELETE_MEDIA:
      return {
        ...state,
        loading: true,
      };

    case DELETE_MEDIA_SUCCESS: {
      const { _id } = action.payload;

      return {
        ...state,
        loading: false,
        mediaList: state.mediaList.filter((m) => (m._id !== _id)),
      };
    }

    case DELETE_MEDIA_FAILED:
      return {
        ...state,
        loading: false,
      };

      // CLEAR MEDIA DETAIL
    case CLEAR_MEDIA_DETAIL:
      return {
        ...state,
        mediaDetail: propTypeModels.media.defaultData,
      };
    default:
      return state;
  }
};

export default media;
