import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  Col, Modal, ModalBody, Row,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';

import images from 'assets/images';

import AppLoading from 'components/App/Loading';

import './style.scss';

function ModalConfirm(props) {
  const {
    isDelete,
    isOpen,
    isCancelOrder,
    loading,

    notification,

    onCloseClick,
    onAcceptConfirm,
  } = props;

  const { t } = useTranslation();

  return (
    <Modal className="modal-confirm" isOpen={isOpen} toggle={onCloseClick} centered>
      <AppLoading loading={loading} loaderOnPage />

      <ModalBody className="modal-confirm__body position-relative">
        <button
          type="button"
          className="btn btn__close"
          onClick={onCloseClick}
        >
          <div className="image-wrapper">
            <img src={images.modalClose.default} alt="" className="img-fluid img-close" />
          </div>
        </button>

        <Row>
          <Col lg={12}>
            <div className="text-center">
              { !isCancelOrder && (
                isDelete ? (
                  <div className="image-wrapper">
                    <img
                      src={images.modalGarbageCan.default}
                      alt="trash-can"
                      className="img-delete"
                    />
                  </div>
                ) : (
                  <i
                    className="mdi mdi-alert-circle-outline"
                    style={{ fontSize: '9em', color: 'orange' }}
                  />
                )
              )}

              {isCancelOrder && (
              <div className="image-wrapper">
                <img
                  src={images.cancelOrderIcon.default}
                  alt="cancel-order"
                  className="img-delete"
                />
              </div>
              )}
              <h2 className="title">{t(notification.message)}</h2>
              <h4 className="subtitle">{t(notification.description)}</h4>
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            {isCancelOrder
              ? (
                <div className="text-center btn__group">
                  <button
                    type="button"
                    className="btn btn-outline-light-secondary font-size-14 btn-width me-2"
                    onClick={onAcceptConfirm}
                  >
                    Xác nhận
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary btn-width font-size-14 me-2"
                    onClick={onCloseClick}
                  >
                    Hủy
                  </button>
                </div>
              ) : (
                <div className="text-center btn__group">
                  <button
                    type="button"
                    className="btn btn-outline-light-secondary font-size-14 btn-width me-2"
                    onClick={onCloseClick}
                  >
                    Hủy
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary btn-width font-size-14 me-2"
                    onClick={onAcceptConfirm}
                  >
                    Xác nhận
                  </button>
                </div>
              )}
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
}

ModalConfirm.propTypes = {
  isDelete: PropTypes.bool,
  isOpen: PropTypes.bool,
  isCancelOrder: PropTypes.bool,
  loading: PropTypes.bool,

  notification: PropTypes.instanceOf(Object).isRequired,

  onCloseClick: PropTypes.func,
  onAcceptConfirm: PropTypes.func,
};

ModalConfirm.defaultProps = {
  isDelete: false,
  isOpen: false,
  isCancelOrder: false,
  loading: false,

  onCloseClick: () => { },
  onAcceptConfirm: () => { },
};

export default memo(ModalConfirm);
