/* eslint-disable global-require */
export default {
  avatar3: require('./users/avatar-3.jpg'),
  avatar4: require('./users/avatar-4.jpg'),

  // Global
  globalError: require('./error-img.png'),

  // Logo
  logoBookeseFull: require('./logo/logo-bookese-full.png'),
  logoBookeseMini: require('./logo/logo-bookese-mini.png'),

  // Flags
  flagUs: require('./flags/us.jpg'),
  flagVi: require('./flags/vi.jpg'),

  // Media
  mediaImgExcel: require('./excel.png'),
  mediaImgPDF: require('./pdf-image.png'),
  mediaImgWord: require('./word.png'),

  // Auth
  authProfile: require('./profile-img.png'),

  // Modal
  modalGarbageCan: require('./garbage-can.svg'),
  modalClose: require('./close-round-fill.svg'),

  // Arrow
  arrowRight: require('./chevron-right.svg'),

  // Cancel Order:
  cancelOrderIcon: require('./cancel-order-icon-modal.svg'),

  // Locked Modal:
  lockIconSVG: require('./lockIconSVG.svg'),

  // EX: Authenticated Assets
  authBackground: require('./auth/bg.png'),

  // Location: Image Location:
  locationImage: require('./location/location-image-png.png'),
  finalStepImage: require('./location/finalImage.png'),
  masterCardImage: require('./location/payment-master-card-png.png'),
  JCBImage: require('./location/payment-jcb-png.png'),
  visaImage: require('./location/payment-visa-png.png'),
  dinersClubImage: require('./location/payment-diners-club-png.png'),
  napasImage: require('./location/payment-napas-png.png'),
  bookeseImage: require('./location/payment-bookese-png.png'),
};
