import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Container, Row, Col, Card, CardTitle,
  CardBody, Label, Input, FormFeedback, Form, Dropdown, DropdownToggle, DropdownMenu,
} from 'reactstrap';
import * as Yup from 'yup';
import Flatpickr from 'react-flatpickr';
import Select from 'react-select';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';

import {
  allowPermission,
  employeeTypeSelectList,
  mediaFolder,
  userGenderSelectList,
} from 'constants/index';
import {
  checkHadError,
  checkPermission,
  convertIsoDateToString,
  getPlaceholderTrans,
  getUserForFirstName,
  translateOptions,
  validationErrorHandler,
} from 'utils';
import {
  userValidation,
  passwordValidation as pwdValidation,
} from 'utils/validate';
import propTypeModels from 'propTypeModels';

import AppAvatar from 'components/App/Avatar';
import AppButton from 'components/App/Button';
import AppLoading from 'components/App/Loading';
import Breadcrumb from 'components/Breadcrumb';
import Meta from 'components/App/Meta';
import PopupUploadMedia from 'components/PopupFormUploadMedia';

import 'flatpickr/dist/themes/material_blue.css';
import './style.scss';

const Profile = (props) => {
  const {
    actionGetMe,
    me,
    profileError,
    profileLoading,

    actionUpdateMe,
    profileLoadingUpdate,

    actionUpdateMyAvatar,
    profileLoadingAvatar,

    actionDeleteMyAvatar,

    actionUpdateMyPassword,
    profilePasswordError,
    profileLoadingPassword,

  } = props;

  const { t } = useTranslation();

  const [newDate] = useState(new Date());
  const [isShowAvatarDropdown, setIsShowAvatarDropdown] = useState(false);

  const [statePassWord, setStatePassWord] = useState({
    currentPass: true,
    newPass: true,
    confirmPass: true,
  });

  const [isShowFormMedia, setIsShowFormMedia] = useState(false);

  const onToggle = useCallback(() => {
    setIsShowAvatarDropdown(!isShowAvatarDropdown);
  }, [isShowAvatarDropdown]);

  const passwordValidation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      password: '',
      newPassword: '',
      confirmPassword: '',
    },

    validationSchema: Yup.object(pwdValidation),

    onSubmit: (values) => {
      const callBackResetPassword = () => {
        passwordValidation.resetForm();
      };

      actionUpdateMyPassword({ userPwd: values, callback: callBackResetPassword });
    },
  });

  const onToggleShowPassWord = useCallback((name) => {
    setStatePassWord((prevState) => ({
      ...prevState,
      [name]: !prevState[name],
    }));
  }, []);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      firstName: '',
      lastName: '',
      middleName: '',
      cardId: '',
      gender: '',
      birthday: null,
      address: '',
      phoneNumber: '',
      businessEmail: '',
      email: '',
      employeeType: '',
    },

    validationSchema: Yup.object(userValidation),

    onSubmit: (values) => {
      const userData = {
        ...values,
        birthday: convertIsoDateToString(values.birthday),
      };

      actionUpdateMe({ userData });
    },
  });

  const onChangePasswordInput = useCallback((name) => (e) => {
    if (e.currentTarget.value.includes(' ')) {
      e.currentTarget.value = e.currentTarget.value.replace(/\s/g, '');
    }

    passwordValidation.setValues((prevState) => ({
      ...prevState,
      [name]: e.currentTarget.value,
    }));
  }, []);

  const onKeyDownPasswordInput = useCallback((value) => {
    if (value.key === ' ') {
      value.preventDefault();
    }
  }, []);

  useEffect(() => {
    actionGetMe();
  }, []);

  useEffect(() => {
    validation.setValues((prevState) => ({
      ...prevState,
      ...me,
    }));
  }, [me]);

  const onChangePassword = useCallback((e) => {
    e.preventDefault();
    passwordValidation.handleSubmit();
  }, []);

  const onDeleteAvatar = useCallback(() => {
    actionDeleteMyAvatar();
  }, []);

  const onSelectEmployee = useCallback((options) => {
    validation.setValues((prevState) => ({
      ...prevState,
      employeeType: options.value,
    }));
  }, []);

  const onSelectGender = useCallback((gender) => {
    validation.setValues((prevState) => ({
      ...prevState,
      gender: gender.value,
    }));
  }, []);

  const onChangeBirthday = useCallback((date) => {
    validation.setValues((prevState) => ({
      ...prevState,
      birthday: date,
    }));
  }, []);

  useEffect(() => {
    if (profilePasswordError) {
      if (Object.keys(profilePasswordError).length) {
        passwordValidation.setErrors({
          ...profilePasswordError,
        });
      }

      if (Object.keys(profileError).length) {
        validation.setErrors({
          ...profileError,
        });
      }
    }
  }, [profilePasswordError, profileError]);

  useEffect(() => {
    if (profileLoadingAvatar) {
      setIsShowAvatarDropdown(false);
    }
  }, [profileLoadingAvatar]);

  const loadingIcon = <i className="bx bx-loader bx-spin align-middle" />;

  const onTogglePopupMedia = useCallback(() => {
    setIsShowFormMedia((prevState) => (!prevState));
  }, []);

  const onTurnOffPopup = useCallback(() => {
    setIsShowFormMedia(false);
  }, []);

  const onSubmit = useCallback((e) => {
    e.preventDefault();

    validation.submitForm();
  }, []);

  const validationErrors = useMemo(() => validation.errors, [validation.errors]);
  const passwordValidationErrors = useMemo(
    () => passwordValidation.errors, [passwordValidation.errors],
  );

  const onUpdateAvatar = useCallback((image) => {
    actionUpdateMyAvatar({
      avatar: image?.location,
      callback: onTurnOffPopup,
    });
  }, []);

  return (
    <div className="page-content">
      <Meta name="Hồ sơ" />

      <Container fluid>
        <Breadcrumb
          breadcrumbItem="Hồ sơ"
          title={t('global.dashboard')}
        />

        <PopupUploadMedia
          isOpen={isShowFormMedia}
          type={mediaFolder.AVATAR}
          onTurnOffPopup={onTurnOffPopup}
          onToggleModal={onTogglePopupMedia}
          onSelectMedia={onUpdateAvatar}
        />

        <Row className="position-relative">
          <Col lg={8}>
            <Card>
              <CardBody className="positon-relative">
                <AppLoading loading={profileLoadingUpdate} loaderOnPage />

                <div className="d-flex mb-4">
                  <div className="avatar-upload d-flex mb-4">
                    <div className="avatar-edit">
                      <Dropdown
                        isOpen={isShowAvatarDropdown}
                        toggle={onToggle}
                        className="d-inline-block"
                      >
                        <DropdownToggle
                          className="btn btn-dropdown-avatar"
                          id="page-header-user-dropdown"
                          tag="button"
                        >
                          {
                            profileLoadingAvatar ? loadingIcon : <i className="fa fa-fw fa-bars" />
                          }
                        </DropdownToggle>
                        <DropdownMenu
                          className="dropdown-avatar"
                        >
                          <div
                            role="button"
                            onClick={onTogglePopupMedia}
                            onKeyDown={() => {}}
                            tabIndex={0}
                          >
                            <label className="dropdown-item label-dropdown">
                              <i className="mdi mdi-cloud-upload font-size-16 align-middle me-1" />
                              <span>{t('type.UPLOAD')}</span>
                            </label>
                          </div>
                          {
                            me.avatar && (
                              <>
                                <div className="dropdown-divider" />

                                <label
                                  htmlFor=""
                                  className="dropdown-item label-dropdown"
                                >
                                  <div
                                    onClick={onDeleteAvatar}
                                    onKeyDown={() => {}}
                                    role="button"
                                    tabIndex={0}
                                  >
                                    <i className="text-danger mdi mdi-delete font-size-16 align-middle me-1" />
                                    <span>{t('type.REMOVE')}</span>
                                  </div>
                                </label>
                              </>
                            )
                          }
                        </DropdownMenu>
                      </Dropdown>

                    </div>
                    <div className="avatar-preview">
                      <AppAvatar
                        size={180}
                        src={me.avatar}
                        name={getUserForFirstName(me)}
                        alt={`${getUserForFirstName(me)} avatar`}
                      />
                    </div>
                  </div>
                </div>

                <Form
                  className="form-horizontal"
                  onSubmit={onSubmit}
                >
                  <Row>
                    <Col md={4}>
                      <div className="mb-3">
                        <Label className="form-label">
                          {t('profile.last_name')}
                        </Label>

                        <Input
                          id="lastName"
                          name="lastName"
                          className="form-control"
                          placeholder={getPlaceholderTrans({
                            type: 'type.ENTER',
                            name: 'profile.last_name',
                          })}
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.lastName || ''}
                          invalid={checkHadError(validation, 'lastName')}
                        />

                        {checkHadError(validation, 'lastName') && (
                          <FormFeedback type="invalid">
                            {
                              validationErrorHandler(validationErrors.lastName)
                            }
                          </FormFeedback>
                        )}
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="mb-3">
                        <Label className="form-label">{t('profile.middle_name')}</Label>

                        <Input
                          id="middleName"
                          name="middleName"
                          className="form-control"
                          placeholder={getPlaceholderTrans({
                            type: 'type.ENTER',
                            name: 'profile.middle_name',
                          })}
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.middleName || ''}
                          invalid={checkHadError(validation, 'middleName')}
                        />
                        {checkHadError(validation, 'middleName') && (
                          <FormFeedback type="invalid">
                            {
                              validationErrorHandler(validationErrors.middleName)
                            }
                          </FormFeedback>
                        )}
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="mb-3">
                        <Label className="form-label">
                          {t('profile.first_name')}
                          <code>*</code>
                        </Label>

                        <Input
                          id="firstName"
                          name="firstName"
                          className="form-control"
                          placeholder={getPlaceholderTrans({
                            type: 'type.ENTER',
                            name: 'profile.first_name',
                          })}
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.firstName || ''}
                          invalid={checkHadError(validation, 'firstName')}
                        />

                        {checkHadError(validation, 'firstName') && (
                          <FormFeedback type="invalid">
                            {
                              validationErrorHandler(validationErrors.firstName)
                            }
                          </FormFeedback>
                        )}
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={4}>
                      <div className="mb-3">
                        <Label className="form-label">{t('profile.birthday')}</Label>
                        {me._id && (
                          <Flatpickr
                            className={`form-control ${checkHadError(validation, 'birthday') && 'invalid'}`}
                            placeholder={getPlaceholderTrans({
                              type: 'type.ENTER',
                              name: 'profile.birthday',
                            })}
                            selected={newDate}
                            onChange={onChangeBirthday}
                            defaultValue={convertIsoDateToString(me.birthday)}
                            options={{
                              maxDate: newDate,
                            }}
                          />

                        )}
                        {checkHadError(validation, 'birthday') && (
                        <FormFeedback type="invalid" className="d-block">
                          {validationErrors.birthday}
                        </FormFeedback>
                        )}

                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="mb-3">
                        <Label className="form-label">{t('profile.personal_id')}</Label>
                        <Input
                          name="cardId"
                          className="form-control"
                          type="text"
                          value={validation.values.cardId || ''}
                          placeholder={getPlaceholderTrans({
                            type: 'type.ENTER',
                            name: 'profile.personal_id',
                          })}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={checkHadError(validation, 'cardId')}
                        />

                        {checkHadError(validation, 'cardId') && (
                          <FormFeedback type="invalid">
                            {
                              validationErrorHandler(validationErrors.cardId)
                            }
                          </FormFeedback>
                        )}
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="mb-3">
                        <Label className="form-label">{t('profile.gender')}</Label>

                        <Select
                          value={
                            translateOptions('constant.gender', userGenderSelectList).filter(
                              (option) => option.value === validation.values.gender,
                            )
                          }
                          noOptionsMessage={({ option }) => (!option && t('no_options_message'))}
                          placeholder={getPlaceholderTrans({
                            type: 'type.SELECT',
                            name: 'profile.gender',
                          })}
                          onChange={onSelectGender}
                          options={translateOptions('constant.gender', userGenderSelectList)}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>

                    <Col md={6}>
                      <div className="mb-3">
                        <Label className="form-label">{t('profile.address')}</Label>

                        <Input
                          id="address"
                          name="address"
                          className="form-control"
                          type="text"
                          value={validation.values.address || ''}
                          placeholder={getPlaceholderTrans({
                            type: 'type.ENTER',
                            name: 'profile.address',
                          })}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={checkHadError(validation, 'address')}
                        />

                        {checkHadError(validation, 'address') && (
                          <FormFeedback type="invalid">
                            {
                              validationErrorHandler(validationErrors.address)
                            }
                          </FormFeedback>
                        )}
                      </div>
                    </Col>

                    <Col md={6}>
                      <div className="mb-3">
                        <Label className="form-label">{t('profile.phone_number')}</Label>

                        <Input
                          id="phoneNumber"
                          name="phoneNumber"
                          className="form-control"
                          type="phone"
                          value={validation.values.phoneNumber || ''}
                          placeholder={getPlaceholderTrans({
                            type: 'type.ENTER',
                            name: 'profile.phone_number',
                          })}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={checkHadError(validation, 'phoneNumber')}
                        />

                        {checkHadError(validation, 'phoneNumber') && (
                          <FormFeedback type="invalid">
                            {
                              validationErrorHandler(validationErrors.phoneNumber)
                            }
                          </FormFeedback>
                        )}
                      </div>
                    </Col>

                    <Col md={6}>
                      <div className="mb-3">
                        <Label className="form-label">{t('employee.type')}</Label>
                        <code>*</code>
                        <Select
                          value={
                            translateOptions('constant.employee_type', employeeTypeSelectList).filter(
                              (option) => option.value === validation.values.employeeType,
                            )
                          }
                          isDisabled={!checkPermission(me.role, allowPermission.EDIT_EMPLOYEE)}
                          placeholder={getPlaceholderTrans({
                            type: 'type.SELECT',
                            name: 'employee.type',
                          })}
                          onChange={onSelectEmployee}
                          options={translateOptions('constant.employee_type', employeeTypeSelectList)}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>

                  </Row>

                  <Row>
                    <Col md={4}>
                      <div className="mb-3">
                        <Label className="form-label">{t('profile.business_email')}</Label>

                        <Input
                          id="businessEmail"
                          name="businessEmail"
                          className="form-control"
                          type="text"
                          value={validation.values.businessEmail || ''}
                          placeholder={getPlaceholderTrans({
                            type: 'type.ENTER',
                            name: 'profile.business_email',
                          })}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={checkHadError(validation, 'businessEmail')}
                        />

                        {checkHadError(validation, 'businessEmail') && (
                          <FormFeedback type="invalid">
                            {
                              validationErrorHandler(validationErrors.businessEmail)
                            }
                          </FormFeedback>
                        )}
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="mb-3">
                        <Label className="form-label">Email</Label>

                        <Input
                          id="email"
                          name="email"
                          className="form-control"
                          type="text"
                          value={validation.values.email || ''}
                          placeholder={getPlaceholderTrans({
                            type: 'type.ENTER',
                            name: 'profile.email',
                          })}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={checkHadError(validation, 'email')}
                        />

                        {checkHadError(validation, 'email') && (
                          <FormFeedback type="invalid">
                            {
                              validationErrorHandler(validationErrors.email)
                            }
                          </FormFeedback>
                        )}
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="mb-3">
                        <Label className="form-label">{t('profile.username')}</Label>

                        <Input
                          id="username"
                          name="username"
                          className="form-control"
                          type="username"
                          value={validation.values.username || 'username'}
                          disabled
                        />
                      </div>
                    </Col>
                  </Row>

                  <div className="mt-4 text-end">
                    {profileLoadingUpdate ? <AppButton loading={profileLoadingUpdate} /> : (
                      <button
                        disabled={profileLoading}
                        className="btn btn-primary btn-block"
                        type="submit"
                      >
                        {t('type.UPDATE')}
                      </button>
                    )}
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>

          <Col lg={4}>
            <Card>
              <CardBody className="position-relative">
                <AppLoading loading={profileLoadingPassword} loaderOnPage />
                <CardTitle className="mb-4">
                  {t('auth.change_password')}
                </CardTitle>

                <Form
                  className="form-horizontal"
                  onSubmit={onChangePassword}
                >
                  <div className="mb-3 position-relative">
                    <Label className="form-label">
                      {t('auth.current_password')}
                      <code>*</code>
                    </Label>

                    <Input
                      className="password_type"
                      name="password"
                      type={statePassWord.currentPass ? 'password' : 'text'}
                      placeholder={getPlaceholderTrans({
                        type: 'type.ENTER',
                        name: 'auth.current_password',
                      })}
                      onChange={onChangePasswordInput('password')}
                      onKeyDown={onKeyDownPasswordInput}
                      onBlur={passwordValidation.handleBlur}
                      value={passwordValidation.values.password}
                      invalid={checkHadError(passwordValidation, 'password')}
                    />

                    <i
                      className={`font-size-18 show_password fas ${!statePassWord.currentPass ? 'fa-eye-slash' : 'fa-eye'}`}
                      onClick={() => onToggleShowPassWord('currentPass')}
                      aria-hidden="true"
                    />
                    {checkHadError(passwordValidation, 'password')
                      && (
                        <FormFeedback type="invalid">
                          {
                            validationErrorHandler(passwordValidationErrors.password)
                          }
                        </FormFeedback>
                      )}
                  </div>

                  <div className="mb-3 position-relative">
                    <Label className="form-label">
                      {getPlaceholderTrans({
                        type: 'type.ENTER',
                        name: 'auth.new_password',
                      })}
                      <code>*</code>
                    </Label>

                    <Input
                      className="password_type"
                      name="newPassword"
                      type={statePassWord.newPass ? 'password' : 'text'}
                      placeholder={getPlaceholderTrans({
                        type: 'type.ENTER',
                        name: 'auth.new_password',
                      })}
                      onChange={onChangePasswordInput('newPassword')}
                      onKeyDown={onKeyDownPasswordInput}
                      onBlur={passwordValidation.handleBlur}
                      value={passwordValidation.values.newPassword}
                      invalid={checkHadError(passwordValidation, 'newPass')}
                    />

                    <i
                      className={`font-size-18 show_password fas ${!statePassWord.newPass ? 'fa-eye-slash' : 'fa-eye'}`}
                      onClick={() => onToggleShowPassWord('newPass')}
                      aria-hidden="true"
                    />

                    {checkHadError(passwordValidation, 'newPass')
                      && (
                        <FormFeedback type="invalid">
                          {
                            validationErrorHandler(passwordValidationErrors.newPassword)
                          }
                        </FormFeedback>
                      )}
                  </div>

                  <div className="mb-3 position-relative">
                    <Label className="form-label">
                      {t('auth.re_enter_new_password')}
                      <code>*</code>
                    </Label>

                    <Input
                      className="password_type"
                      name="confirmPassword"
                      type={statePassWord.confirmPass ? 'password' : 'text'}
                      placeholder={t('auth.re_enter_new_password')}
                      onChange={onChangePasswordInput('confirmPassword')}
                      onKeyDown={onKeyDownPasswordInput}
                      onBlur={passwordValidation.handleBlur}
                      value={passwordValidation.values.confirmPassword}
                      invalid={checkHadError(passwordValidation, 'confirmPassword')}
                    />

                    <i
                      className={`font-size-18 show_password fas ${!statePassWord.confirmPass ? 'fa-eye-slash' : 'fa-eye'}`}
                      onClick={() => onToggleShowPassWord('confirmPass')}
                      aria-hidden="true"
                    />

                    {checkHadError(passwordValidation, 'confirmPassword')
                      && (
                        <FormFeedback type="invalid">
                          {
                            validationErrorHandler(passwordValidationErrors.confirmPassword)
                          }
                        </FormFeedback>
                      )}
                  </div>

                  <Row className="mb-3">
                    <Col className="text-end">
                      <button
                        disabled={profileLoadingPassword}
                        className="btn btn-primary btn-block"
                        type="submit"
                      >
                        {t('type.UPDATE')}
                      </button>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

Profile.propTypes = {
  actionGetMe: PropTypes.func.isRequired,
  me: propTypeModels.user.userPropTypes.isRequired,
  profileError: PropTypes.instanceOf(Object).isRequired,
  profileLoading: PropTypes.bool.isRequired,

  actionUpdateMe: PropTypes.func.isRequired,
  profileLoadingUpdate: PropTypes.bool.isRequired,

  actionUpdateMyAvatar: PropTypes.func.isRequired,
  profileLoadingAvatar: PropTypes.bool.isRequired,

  actionDeleteMyAvatar: PropTypes.func.isRequired,

  actionUpdateMyPassword: PropTypes.func.isRequired,
  profilePasswordError: PropTypes.instanceOf(Object).isRequired,
  profileLoadingPassword: PropTypes.bool.isRequired,
};

export default Profile;
