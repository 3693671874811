import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  Row,
} from 'reactstrap';

import { checkHadError } from 'utils/index';

import Button from 'components/App/Button';
import FormField from 'components/AddLocation/FormField';
import InputValidation from 'components/App/InputValidation';
import SectionWrapper from 'components/AddLocation/SectionWrapper';

import styles from './StepOne.module.scss';

function StepOne(props) {
  const {
    isShowPassword,

    validation,

    onToggleShowPassword,
    onSubmitStepOne,
  } = props;

  return (
    <div className={styles.Component}>
      <form onSubmit={validation.handleSubmit}>
        <SectionWrapper title="Bắt đầu thêm chỗ nghỉ">
          <Row className="Section">
            <Col md="6">
              <FormField
                label="Email đăng nhập"
                isRequired
              >
                <InputValidation
                  inputClassName="Section__Input Section__Input__Email"
                  name="email"
                  keyName="email"
                  placeholder="Nhập vào email"
                  validation={validation}
                />
              </FormField>
            </Col>

            <Col md="6">
              <FormField
                label="Mật khẩu"
                isRequired
              >
                <InputValidation
                  inputClassName="Section__Input Section__Input__Password"
                  name="password"
                  keyName="password"
                  placeholder="Nhập vào mật khẩu"
                  type={isShowPassword ? 'text' : 'password'}
                  validation={validation}
                />
              </FormField>

              <i
                className={`font-size-16 fas
                ${checkHadError(validation, 'password') ? 'eye-error' : 'eye-default'}
                ${!isShowPassword ? 'fa-eye-slash' : 'fa-eye'} cursor-pointer Section__Input__Password__Show
              `}
                aria-hidden="true"
                onClick={onToggleShowPassword}
              />
            </Col>
          </Row>
        </SectionWrapper>

        <div className="Button__Wrapper">
          <Button
            classes="Button Button__Next"
            onClick={onSubmitStepOne}
          >
            Bước tiếp theo

            <i className="fas fa-arrow-right" />
          </Button>
        </div>
      </form>
    </div>
  );
}

StepOne.propTypes = {
  isShowPassword: PropTypes.bool.isRequired,

  validation: PropTypes.instanceOf(Object).isRequired,

  onToggleShowPassword: PropTypes.func.isRequired,
  onSubmitStepOne: PropTypes.func.isRequired,
};

StepOne.defaultProps = {

};

export default memo(StepOne);
