/* eslint-disable no-useless-escape */

export const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:+\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const PHONE_REGEX = /^(\+?61|\+?84|0)0?[1-9]\d{8}$/g;

export const URL_REGEX = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/g;

export const IS_SPECIAL_CHARACTER = /^[0-9a-zA-Z_ÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹýế,.!;@$? ]+$/;

export const LETTER_WITH_SPACE_REGEX = /^[a-zA-ZÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹýế ]+$/;

export const LETTER_NUMBER_WITH_NO_SPACE_REGEX = /^[0-9a-zA-Z_ÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹýế]+$/;

export const IS_SPECIAL_ACCEPT = /^\S*$/;

export const PICTURE_REGEX = /^([\w\.\- ]+(\.|\/)(jpeg|png|jpg|JPG|JPEG|PNG))$/gm;

export const VIDEO_REGEX = /^([\w\.\- ]+(\.|\/)(mp4|mkv|wmv|m4v|mov|avi|flv|webm|flac|mka|m4a|aac|ogg))$/gm;

export const IS_IDENTITY_CARD_NUMBER = /^[0-9]\d{6,11}$/gm;

export const ACCENTED_REGEX = /^[0-9a-zA-Z_abcdefghijklmnopqrstuvwxyz]+$/;

export const NUMBER_ONLY = /^[0-9]*$/;

export const NUMBER_WITH_PERIOD = /^[0-9\.]*$/;

export const WORDS_AND_NUMBERS_REGEX = /^[a-zA-Z0-9]+$/;

export const CHECK_MORE_THAN_2_WORDS = /^\s*[\S]+(\s+[\S]+)+\s*$/;

export const NUMBER_ONLY_REGEX = /^[0-9]*$/;

export const PASSWORD_IS_HAVE_SPECIAL = /(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*?&]/;

// export const NUMBERS_REGEX = /^[0-9]+$/;

export const textLengthLimit = {
  CATEGORY_LEVEL: 3,
  PHONE_NUMBER: 10,
  VERY_SHORT: 25,
  SHORT: 150,
  MEDIUM: 300,
  LONG: 2500,
  CATEGORY_DESCRIPTION: 3000,
  PASSWORD: 8,
  PASSWORD_MAX: 32,
  REASON: 1000,
  CODE: 6,
};
