import React, { memo } from 'react';
import PropTypes from 'prop-types';

import styles from './FormFieldWithLine.module.scss';

function FormFieldWithLine({ children, label, isRequired }) {
  return (
    <div className={styles.Component}>
      <label className={`${isRequired ? 'require' : ''}`} htmlFor={children.props.id}>
        {label}
      </label>

      { children }
    </div>
  );
}

FormFieldWithLine.propTypes = {
  children: PropTypes.node.isRequired,
  label: PropTypes.string,
  isRequired: PropTypes.bool,
};

FormFieldWithLine.defaultProps = {
  label: '',
  isRequired: false,
};

export default memo(FormFieldWithLine);
