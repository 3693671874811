import { connect } from 'react-redux';

import {
  actionGetListMedia,
  // actionGetProjectPersonalClientDetail,
  actionDeleteMedia,
} from 'store/actions';

import Media from './Media';

const mapStateToProps = (state) => ({
  me: state.Profile.me,

  mediaList: state.Media.mediaList,
  mediaTotal: state.Media.mediaTotal,
  mediaLoading: state.Media.loading,
});

const mapDispatchToProps = (dispatch) => ({
  actionGetListMedia: (payload) => dispatch(actionGetListMedia(payload)),

  actionDeleteMedia: (payload) => dispatch(actionDeleteMedia(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Media);
