import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormFeedback } from 'reactstrap';
import Flatpickr from 'react-flatpickr';
import get from 'lodash/get';

import { validationCheckErrorType } from 'constants/index';
import { checkHadError, validationErrorHandler } from 'utils';

const AppFlatPickInput = (props) => {
  const {
    isShowError,

    checkErrorType,
    keyName,
    name,
    placeholder,

    options,
    validation,

    onChange,
  } = props;

  const keyCheckError = useMemo(() => (keyName || name), [keyName, name]);

  const isHadError = useMemo(() => checkHadError(
    validation, keyCheckError, checkErrorType,
  ), [validation, keyCheckError, checkErrorType]);

  return (
    <>
      <Flatpickr
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        value={get(validation.values, keyCheckError, '')}
        options={options}
      />

      {
        isShowError && isHadError && (
          <FormFeedback type="invalid" className="d-block">
            {validationErrorHandler(get(validation.errors, keyCheckError, ''))}
          </FormFeedback>
        )
      }
    </>
  );
};

AppFlatPickInput.propTypes = {
  isShowError: PropTypes.bool,

  checkErrorType: PropTypes.oneOf([
    '',
    validationCheckErrorType.ERR_ONLY,
    validationCheckErrorType.TOUCHED_ONLY,
  ]),
  keyName: PropTypes.string,
  name: PropTypes.string,

  placeholder: PropTypes.string,

  options: PropTypes.instanceOf(Object),
  validation: PropTypes.instanceOf(Object),

  onChange: PropTypes.func.isRequired,
};

AppFlatPickInput.defaultProps = {
  isShowError: true,

  checkErrorType: '',
  keyName: '',
  name: '',
  placeholder: 'Chọn',

  options: {},
  validation: {},
};

export default memo(AppFlatPickInput);
