import PropTypes from 'prop-types';

const roleSchema = {
  _id: PropTypes.string,
  createdAt: PropTypes.string,
  description: PropTypes.string,
  isRoot: PropTypes.bool,
  name: PropTypes.string,
  organization: PropTypes.string,
  permissions: PropTypes.arrayOf(PropTypes.string),
  totalEmployees: PropTypes.number,
};

export const role = PropTypes.shape(
  roleSchema,
);

export const roleList = PropTypes.arrayOf(PropTypes.shape(
  roleSchema,
));

export const defaultData = {
  _id: '',
  createdAt: '',
  description: '',
  isRoot: false,
  name: '',
  organization: '',
  permissions: [],
  totalEmployees: 0,
};
