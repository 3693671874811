import React, { useState, useCallback, useEffect } from 'react';
import {
  Card,
  CardTitle,
  Col,
  Container,
  Input,
  Row,
} from 'reactstrap';
import * as Yup from 'yup';
import moment from 'moment';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';

import AppBreadcrumb from 'components/App/Breadcrumb';
import AppButton from 'components/App/Button';
import AppFlatPickInput from 'components/App/FlatPickInput';
import AppMeta from 'components/App/Meta';
import FormField from 'components/AddLocation/FormField';

import { LOCATIONS, themeColor } from 'constants/index';
import {
  getDatesRank,
  guidGeneratorID,
  onCheckSameDay,
} from 'utils';

import styles from './OpenCloseRoom.module.scss';

const breadcrumbList = [
  {
    name: 'Giá và tình trạng phòng trống',
    isActive: false,
    link: LOCATIONS.LOCATION_LIST,
  },
  {
    name: 'Mở/Đóng phòng',
    isActive: true,
    link: '',
  },
];

const newDate = new Date();
const aWeekAgo = new Date(moment(newDate).add(6, 'days'));

function OpenCloseRoom() {
  const { t } = useTranslation();

  const [dateInWeek, setDateInWeek] = useState([]);

  const [roomsType, setRoomsType] = useState([
    {
      id: guidGeneratorID(),
      label: 'Suite with Terrace',
      isChecked: true,
    },
    {
      id: guidGeneratorID(),
      label: 'Superior Twin Room',
      isChecked: true,
    },
    {
      id: guidGeneratorID(),
      label: 'Superior Double Room',
      isChecked: true,
    },
    {
      id: guidGeneratorID(),
      label: 'Deluxe Double Room',
      isChecked: true,
    },
    {
      id: guidGeneratorID(),
      label: 'Suite with City View',
      isChecked: true,
    },
  ]);

  const [isPublish, setIsPublish] = useState(true);

  const validation = useFormik({
    initialValues: {
      startDate: newDate,
      endDate: aWeekAgo,
    },

    validationSchema: Yup.object({}),

    onSubmit: () => {

    },
  });

  const onChangeStartDate = useCallback((date) => {
    validation.setValues((prevState) => ({
      ...prevState,
      startDate: date.length ? date[0] : '',
    }));
  }, []);

  const onChangeEndDate = useCallback((date) => {
    const selectedDate = date[0];
    const currentDate = new Date();
    const selectedDateWithCurrentTime = new Date(
      selectedDate.setHours(currentDate.getHours(),
        currentDate.getMinutes(), currentDate.getSeconds()),
    );

    let checkDateStart = false;
    if (onCheckSameDay((validation.values.startDate),
      selectedDateWithCurrentTime)) {
      checkDateStart = false;
    } else {
      checkDateStart = date[0] < (validation.values.startDate);
    }

    if (checkDateStart) {
      validation.setValues((prevState) => ({
        ...prevState,
        startDate: '',
      }));
    }

    validation.setValues((prevState) => ({
      ...prevState,
      endDate: date.length ? date[0] : '',
    }));
  }, []);

  const onToggleCheckedDateInWeek = useCallback((id) => () => {
    const newList = dateInWeek.map((item) => {
      const date = { ...item };
      if (date.id === id) {
        date.isChecked = !date.isChecked;
      }

      return date;
    });

    setDateInWeek(newList);
  }, [dateInWeek]);

  const onClearAllCheckedDateInWeek = useCallback(() => {
    const newList = dateInWeek.map((item) => {
      const date = { ...item };
      date.isChecked = false;

      return date;
    });

    setDateInWeek(newList);
  }, [dateInWeek]);

  const onToggleCheckedRoom = useCallback((id) => () => {
    const newList = roomsType.map((item) => {
      const date = { ...item };
      if (date.id === id) {
        date.isChecked = !date.isChecked;
      }

      return date;
    });

    setRoomsType(newList);
  }, [roomsType]);

  const onCheckedAllRooms = useCallback(() => {
    const newList = roomsType.map((item) => {
      const date = { ...item };
      date.isChecked = true;

      return date;
    });

    setRoomsType(newList);
  }, [roomsType]);

  const onChangeStatus = useCallback((status) => () => {
    setIsPublish(status);
  }, []);

  useEffect(() => {
    const listDate = getDatesRank(validation.values.startDate, validation.values.endDate);
    const newDateArray = listDate.map((item) => ({
      id: guidGeneratorID(),
      isChecked: true,
      label: item,
    }));

    setDateInWeek(newDateArray);
  }, [validation.values.startDate, validation.values.endDate]);

  return (
    <div className={`page-content ${styles.Page}`}>
      <AppMeta
        name="Mở / Đóng phòng"
      />

      <Container fluid>
        <AppBreadcrumb
          pageTitle="Mở / Đóng phòng"
          breadcrumbList={breadcrumbList}
        />

        <div className="CContainer">
          <div className="CContainer__Body">
            <Card className="Section">
              <CardTitle className="Section__Title">
                Quý vị muốn thay đổi ngày nào?
              </CardTitle>

              <Row>
                <Col md="6" className="mb-24">
                  <FormField label="Từ ngày">
                    <AppFlatPickInput
                      name="startDate"
                      validation={validation}
                      onChange={onChangeStartDate}
                      options={{
                        minDate: 'today',
                      }}
                    />
                  </FormField>
                </Col>

                <Col md="6" className="mb-24">
                  <FormField label="Đến ngày">
                    <AppFlatPickInput
                      name="endDate"
                      validation={validation}
                      onChange={onChangeEndDate}
                      options={{
                        minDate: validation.values.startDate || 'today',
                      }}
                    />
                  </FormField>
                </Col>
              </Row>

              <div className="Section__WeekBlock">
                <div className="Section__WeekBlock--CheckBox">
                  {
                    dateInWeek.length > 0 && (
                      dateInWeek.map((item) => (
                        <div className="Checkbox Checkbox--WeekBlock" key={item.id}>
                          <Input
                            id={item.id}
                            type="checkbox"
                            checked={item.isChecked}
                            className="Checkbox__Input"
                            onChange={onToggleCheckedDateInWeek(item.id)}
                          />

                          <label className="Checkbox__Name" htmlFor={item.id}>
                            {t(`global.${item.label.format('dddd')}`)}
                          </label>
                        </div>
                      ))
                    )
                  }
                </div>

                <div className="Section__WeekBlock--Button">
                  <button
                    className="btn-minimal blue-100"
                    type="button"
                    onClick={onClearAllCheckedDateInWeek}
                  >
                    Xoá tất cả lựa chọn
                  </button>
                </div>
              </div>
            </Card>

            <Card className="Section">
              <CardTitle className="Section__Title">
                Quý vị muốn mở hay đóng những phòng nào?
              </CardTitle>

              <div className="Section_RoomBlock">
                <div className="Section__RoomBlock--CheckBox">
                  {
                    roomsType.map((item) => (
                      <div className="Checkbox Checkbox--RoomBlock" key={item.id}>
                        <Input
                          type="checkbox"
                          checked={item.isChecked}
                          className="Checkbox__Input"
                          id={item.id}
                          onChange={onToggleCheckedRoom(item.id)}
                        />

                        <label className="Checkbox__Name" htmlFor={item.id}>
                          {item.label}
                        </label>
                      </div>
                    ))
                  }
                </div>

                <button
                  className="btn-minimal blue-100 mt-24"
                  type="button"
                  onClick={onCheckedAllRooms}
                >
                  Chọn tất cả
                </button>
              </div>
            </Card>

            <Card className="Section mb-0">
              <CardTitle className="Section__Title">
                Chọn tình trạng cho những phòng đã chọn:
              </CardTitle>

              <div className="BtnRadioWrapper">
                <div className="Box">
                  <input className="InputRadio" type="radio" id="smoke-allow" name="smoke" checked={isPublish} onChange={onChangeStatus(true)} />

                  <label htmlFor="smoke-allow">Mở</label>
                </div>
                <div className="Box">
                  <input className="InputRadio" type="radio" id="smoke-deny" name="smoke" checked={!isPublish} onChange={onChangeStatus(false)} />

                  <label htmlFor="smoke-deny">Đóng</label>
                </div>
              </div>
            </Card>
          </div>

          <div className="CContainer__Footer">
            <div className="d-flex justify-content-end">
              <AppButton color={themeColor.SECONDARY} classes="ButtonAction mr-24">
                Hủy
              </AppButton>

              <AppButton classes="ButtonAction">
                Cập nhật
              </AppButton>
            </div>
          </div>

        </div>

      </Container>
    </div>
  );
}

export default OpenCloseRoom;
