import { connect } from 'react-redux';

import { actionUpdateRoomStatusList } from 'store/location/room/actions';

import ModalEditRoomStatus from './ModalEditRoomStatus';

const mapStateToProps = (state) => ({
  loadingUpdateRoomStatusList: state.RoomStatusList.loadingUpdateRoomStatusList,
});

const mapDispatchToProps = (dispatch) => ({
  actionUpdateLocation: (payload) => dispatch(actionUpdateRoomStatusList(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalEditRoomStatus);
