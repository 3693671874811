import React, { memo } from 'react';
import PropTypes from 'prop-types';

import styles from './SectionWrapper.module.scss';

function SectionWrapper({
  children, title, isRequired, className,
}) {
  return (
    <div className={`${styles.Component} ${className}`}>
      {title && (
      <span className={`Title ${isRequired ? 'require' : ''}`}>
        {title}
      </span>
      )}

      {children}
    </div>
  );
}

SectionWrapper.propTypes = {
  isRequired: PropTypes.bool,

  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  className: PropTypes.string,
};

SectionWrapper.defaultProps = {
  isRequired: false,

  title: '',
  className: '',
};
export default memo(SectionWrapper);
