import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardSubtitle,
  CardTitle,
  Col,
  Row,
} from 'reactstrap';

import Button from 'components/App/Button';

import { ReactComponent as StarIconSvg } from 'assets/images/location/star-icon-svg.svg';
import { ReactComponent as LocationMarkOrangeIconSvg } from 'assets/images/location/location-mark-orange-icon-svg.svg';
import { ReactComponent as LocationMarkBlueIconSvg } from 'assets/images/location/location-mark-blue-icon-svg.svg';

import OtpInput from 'components/App/OtpInput';
import styles from './StepThree.module.scss';

function StepThree(props) {
  const {
    otp,

    // validation,

    tabPrevious,

    onChangeOtpInput,
    onClickPrevTab,
  } = props;

  return (
    <>
      <Card className={styles.Component}>
        <div className="Card">
          <CardTitle className="Card__Title">
            Xác nhận liên kết
          </CardTitle>

          <Card className="Card__Section">
            <div className="Card__Section__Title__Wrapper">
              <CardTitle className="Card__Section__Title__Name">
                White Lotus Saigon Hotel
              </CardTitle>

              <CardTitle className="Card__Section__Title__Stars">
                <StarIconSvg className="Card__Section__Title__Star" />
                <StarIconSvg className="Card__Section__Title__Star" />
                <StarIconSvg className="Card__Section__Title__Star" />
              </CardTitle>
            </div>

            <div className="Card__Section__Subtitle__Wrapper">
              <CardSubtitle className="Card__Section__Subtitle">
                Khách sạn
              </CardSubtitle>

              <CardSubtitle className="Card__Section__Subtitle">
                Tổng số phòng: 38
              </CardSubtitle>
            </div>

            <div className="Card__Section__Location">
              <Card className="Card__Section__Location__Container">
                <Row className="Card__Section__Location__Wrapper">
                  <Col md="1">
                    <LocationMarkOrangeIconSvg />
                  </Col>

                  <Col md="11">
                    <CardTitle className="Card__Section__Location__Address">
                      6A Thi Sách, Quận 1, Thành phố Hồ Chí Minh
                    </CardTitle>

                    <CardSubtitle className="Card__Section__Location__Address__Subtitle">
                      Khu vực Quận 1
                    </CardSubtitle>
                  </Col>
                </Row>
              </Card>

              <Card className="Card__Section__Location__Container">
                <Row className="Card__Section__Location__Wrapper">
                  <Col md="1">
                    <LocationMarkBlueIconSvg />
                  </Col>

                  <Col md="11">
                    <CardTitle className="Card__Section__Location__Address">
                      10.778175948350482, 106.70459657907486
                    </CardTitle>

                    <CardSubtitle className="Card__Section__Location__Address__Subtitle">
                      trên
                      {' '}
                      <span className="Card__Section__Location__Link">Google Maps</span>
                    </CardSubtitle>
                  </Col>
                </Row>
              </Card>
            </div>
          </Card>

          <CardSubtitle className="Card__Subtitle">
            Bookese đã gửi mã OTP đến tài khoản liên hệ của chủ khách sạn
            {' '}
            <span className="Card__Subtitle__Span">
              White Lotus Saigon Hotel
            </span>
          </CardSubtitle>

          <div className="Card__OTP">
            <CardSubtitle className="Card__OTP__Title">
              Nhập mã OTP
            </CardSubtitle>

            <OtpInput
              value={otp}
              name="otpCode"
              valueLength={6}
              onChange={onChangeOtpInput}
            />

          </div>
        </div>
      </Card>

      <div className={styles.ButtonWrapper}>
        <div className="Button__Wrapper">
          <Button
            classes="Button Button__Back"
            onClick={onClickPrevTab(tabPrevious)}
          >
            <i className="fas fa-arrow-left" />

            Quay về trước
          </Button>

          <Button
            classes="Button Button__Next"
            type="submit"
          >
            Bước tiếp theo

            <i className="fas fa-arrow-right" />
          </Button>
        </div>
      </div>
    </>
  );
}

StepThree.propTypes = {
  otp: PropTypes.string.isRequired,
  tabPrevious: PropTypes.string.isRequired,

  // validation: PropTypes.instanceOf(Object).isRequired,

  onChangeOtpInput: PropTypes.func.isRequired,
  onClickPrevTab: PropTypes.func.isRequired,
};

StepThree.defaultProps = {

};

export default memo(StepThree);
