import React, { useState, useCallback } from 'react';
import {
  Card,
  CardTitle,
  Col,
  Container,
  Row,
} from 'reactstrap';
import * as Yup from 'yup';
import { useFormik } from 'formik';

import AppBreadcrumb from 'components/App/Breadcrumb';
import AppButton from 'components/App/Button';
// import AppInputValidation from 'components/App/InputValidation';
import AppInputNumberValidation from 'components/App/InputNumberValidation';
import AppMeta from 'components/App/Meta';
import AppSelectValidation from 'components/App/SelectValidation';
import FormField from 'components/AddLocation/FormField';
import ModalEditFacilityService from 'components/Modals/ModalEditFacilityService';

import { LOCATIONS, themeColor } from 'constants/index';

import styles from './ConveniencesServices.module.scss';

const breadcrumbList = [
  {
    name: 'Chỗ nghỉ',
    isActive: false,
    link: LOCATIONS.LOCATION_LIST,
  },
  {
    name: 'Tiện ích và dịch vụ',
    isActive: true,
    link: '',
  },
];

const optionsLanguage = [
  {
    label: 'Tiếng Việt',
    value: 'Vietnamese',
  },
  {
    label: 'Tiếng Anh',
    value: 'English',
  },
  {
    label: 'Tiếng Tây Ban Nha',
    value: 'Spain',
  },
  {
    label: 'Tiếng Trung Quốc',
    value: 'Chinese',
  },
  {
    label: 'Tiếng Hàn Quốc',
    value: 'Korea',
  },
];

const facilitiesServices1 = [
  {
    _id: 1,
    title: 'Lễ tân',
    isActive: true,
    groupServices: [
      {
        _id: 11,
        isActive: true,
        name: 'Lễ tân suốt 24 tiếng',
      },
      {
        _id: 12,
        isActive: true,
        name: 'Nước uống chào đón khách',
      },
      {
        _id: 13,
        isActive: true,
        name: 'Thu đổi ngoại tệ',
      },
      {
        _id: 14,
        isActive: true,
        name: 'Hỗ trợ tiền sảnh',
      },
      {
        _id: 15,
        isActive: true,
        name: 'Đặt tour',
      },
      {
        _id: 16,
        isActive: true,
        name: 'Đặt vé xe/tàu/máy bay',
      },
      {
        _id: 17,
        isActive: true,
        name: 'Giữ hành lý miễn phí',
      },
      {
        _id: 18,
        isActive: true,
        name: 'Giữ hành lý trả phí',
      },
    ],
  },
  {
    _id: 2,
    title: 'Reception',
    isActive: true,
    groupServices: [
      {
        _id: 21,
        isActive: true,
        name: 'Lễ tân suốt 24 tiếng',
      },
      {
        _id: 22,
        isActive: true,
        name: 'Nước uống chào đón khách',
      },
      {
        _id: 23,
        isActive: true,
        name: 'Thu đổi ngoại tệ',
      },
      {
        _id: 24,
        isActive: true,
        name: 'Hỗ trợ tiền sảnh',
      },
      {
        _id: 25,
        isActive: true,
        name: 'Đặt tour',
      },
      {
        _id: 26,
        isActive: true,
        name: 'Đặt vé xe/tàu/máy bay',
      },
      {
        _id: 27,
        isActive: true,
        name: 'Giữ hành lý miễn phí',
      },
      {
        _id: 28,
        isActive: true,
        name: 'Giữ hành lý trả phí',
      },
    ],
  },
  {
    _id: 3,
    title: 'Di chuyển',
    isActive: true,
    groupServices: [
      {
        _id: 31,
        isActive: true,
        name: 'Đưa đón sân bay',
      },
      {
        _id: 32,
        isActive: true,
        name: 'Đưa đón trong thành phố',
      },
      {
        _id: 33,
        isActive: true,
        name: 'Đưa đón ra biển',
      },
      {
        _id: 34,
        isActive: true,
        name: 'Cho thuê xe đạp',
      },
      {
        _id: 35,
        isActive: true,
        name: 'Cho thuê xe máy',
      },
      {
        _id: 36,
        isActive: true,
        name: 'Cho thuê xe hơi',
      },
    ],
  },
  {
    _id: 4,
    title: 'Mạng',
    isActive: false,
    groupServices: [
      {
        _id: 41,
        isActive: true,
        name: 'Wifi miễn phí',
      },
      {
        _id: 42,
        isActive: true,
        name: 'Wifi trả phí',
      },
      {
        _id: 43,
        isActive: true,
        name: 'Cáp miễn phí',
      },
      {
        _id: 44,
        isActive: true,
        name: 'Cáp trả phí',
      },
    ],
  },
  {
    _id: 5,
    title: 'Bãi biển',
    isActive: false,
    groupServices: [
      {
        _id: 51,
        isActive: true,
        name: 'Bãi biển riêng',
      },
      {
        _id: 52,
        isActive: true,
        name: 'Sát biển',
      },
      {
        _id: 53,
        isActive: true,
        name: 'Biển đối diện',
      },
      {
        _id: 55,
        isActive: true,
        name: 'Ghế/dù bãi biển',
      },
      {
        _id: 56,
        isActive: true,
        name: 'Khăn dùng tại bãi biển',
      },
    ],
  },
  {
    _id: 6,
    title: 'Các hoạt động',
    isActive: false,
    groupServices: [
      {
        _id: 61,
        isActive: true,
        name: 'Phòng tập gym',
      },
      {
        _id: 62,
        isActive: true,
        name: 'Yoga',
      },
      {
        _id: 63,
        isActive: true,
        name: 'Sân tennis',
      },
      {
        _id: 65,
        isActive: true,
        name: 'Đạp xe',
      },
      {
        _id: 66,
        isActive: true,
        name: 'Câu cá',
      },
      {
        _id: 67,
        isActive: true,
        name: 'Lặn',
      },
      {
        _id: 68,
        isActive: true,
        name: 'Đi bộ đường dài',
      },
      {
        _id: 69,
        isActive: true,
        name: 'Bowling',
      },
      {
        _id: 611,
        isActive: true,
        name: 'Bi-a',
      },
      {
        _id: 612,
        isActive: true,
        name: 'Công viên giải trí',
      },
      {
        _id: 613,
        isActive: true,
        name: 'Công viên nước',
      },
    ],
  },
];

const facilitiesServices2 = [
  {
    _id: 1,
    title: 'Giải trí và thư giãn',
    isActive: true,
    groupServices: [
      {
        _id: 11,
        isActive: true,
        name: 'Quán bar',
      },
      {
        _id: 12,
        isActive: true,
        name: 'Quán bar sân thượng',
      },
      {
        _id: 13,
        isActive: true,
        name: 'Quán bar bãi biển',
      },
      {
        _id: 14,
        isActive: true,
        name: 'Karaoke',
      },
      {
        _id: 15,
        isActive: true,
        name: 'Vũ trường',
      },
      {
        _id: 16,
        isActive: true,
        name: 'Câu lạc bộ đêm',
      },
      {
        _id: 17,
        isActive: true,
        name: 'Sòng bạc',
      },
      {
        _id: 18,
        isActive: true,
        name: 'Spa',
      },
      {
        _id: 19,
        isActive: true,
        name: 'Spa',
      },
      {
        _id: 111,
        isActive: true,
        name: 'Phòng xông hơi khô',
      },
      {
        _id: 112,
        isActive: true,
        name: 'Phòng xông hơi ướt',
      },
      {
        _id: 113,
        isActive: true,
        name: 'Mát xa toàn thân',
      },
      {
        _id: 114,
        isActive: true,
        name: 'Mát xa chân',
      },
      {
        _id: 115,
        isActive: true,
        name: 'Mát xa mặt',
      },
    ],
  },
  {
    _id: 2,
    title: 'Reception',
    isActive: true,
    groupServices: [
      {
        _id: 21,
        isActive: true,
        name: 'Lễ tân suốt 24 tiếng',
      },
      {
        _id: 22,
        isActive: true,
        name: 'Nước uống chào đón khách',
      },
      {
        _id: 23,
        isActive: true,
        name: 'Thu đổi ngoại tệ',
      },
      {
        _id: 24,
        isActive: true,
        name: 'Hỗ trợ tiền sảnh',
      },
      {
        _id: 25,
        isActive: true,
        name: 'Đặt tour',
      },
      {
        _id: 26,
        isActive: true,
        name: 'Đặt vé xe/tàu/máy bay',
      },
      {
        _id: 27,
        isActive: true,
        name: 'Giữ hành lý miễn phí',
      },
      {
        _id: 28,
        isActive: true,
        name: 'Giữ hành lý trả phí',
      },
    ],
  },
  {
    _id: 3,
    title: 'Language',
    isActive: true,
    groupServices: [
      {
        _id: 31,
        isActive: true,
        name: 'English spoken',
      },
      {
        _id: 32,
        isActive: true,
        name: 'Russian spoken',
      },
      {
        _id: 33,
        isActive: true,
        name: 'Chinese spoken',
      },
      {
        _id: 34,
        isActive: true,
        name: 'Korean spoken',
      },
      {
        _id: 35,
        isActive: true,
        name: 'Japanese spoken',
      },
    ],
  },
  {
    _id: 4,
    title: 'Mạng',
    isActive: false,
    groupServices: [
      {
        _id: 41,
        isActive: true,
        name: 'Wifi miễn phí',
      },
      {
        _id: 42,
        isActive: true,
        name: 'Wifi trả phí',
      },
      {
        _id: 43,
        isActive: true,
        name: 'Cáp miễn phí',
      },
      {
        _id: 44,
        isActive: true,
        name: 'Cáp trả phí',
      },
    ],
  },
];

function ConveniencesServices() {
  const [isOpenModalEditService, setIsOpenModalEditService] = useState(false);
  const [editData, setEditData] = useState({});

  const validation = useFormik({
    initialValues: {
      language: optionsLanguage[0].value,
    },

    validationSchema: Yup.object({}),

    onSubmit: () => {

    },
  });

  const onToggleModalEditService = useCallback((service) => () => {
    setIsOpenModalEditService((prev) => !prev);
    setEditData(service);
  }, []);

  const onChangeLanguage = useCallback((selected) => {
    validation.setFieldValue('language', selected, false);
  }, [validation]);

  const onToggleService = useCallback((id) => () => {
    const newServiceGroup = editData.groupServices.map((item) => {
      const service = { ...item };

      if (service._id === id) {
        service.isActive = !item.isActive;
      }

      return service;
    });

    setEditData({
      ...editData,
      groupServices: newServiceGroup,
    });
  }, [editData]);

  const onToggleAllService = useCallback((status) => () => {
    const newServiceGroup = editData.groupServices.map((item) => ({
      ...item,
      isActive: status,
    }));

    setEditData({
      ...editData,
      groupServices: newServiceGroup,
    });
  }, [editData]);

  const RenderServiceList = useCallback((groupServices) => {
    const activeList = [];
    groupServices.map((item) => item.isActive && activeList.push(item.name));

    return activeList.join(', ');
  });

  return (
    <div className={`page-content ${styles.Page}`}>
      <AppMeta
        name="Tiện ích và dịch vụ"
      />

      <Container fluid>
        <AppBreadcrumb
          pageTitle="Tiện ích và dịch vụ"
          breadcrumbList={breadcrumbList}
        />

        <Card className="Section">
          <CardTitle className="Section__Title">
            Ngôn ngữ được sử dụng
          </CardTitle>

          <AppSelectValidation
            placeholder="Chọn ngôn ngữ"
            name="language"
            key="language"
            options={optionsLanguage}
            value={validation.values.language}
            validation={validation}
            onChange={onChangeLanguage}
            isMulti
            isHiddenClearable
          />
        </Card>

        <Card className="Section">
          <CardTitle className="Section__Title">
            Thông tin chỗ nghỉ
          </CardTitle>

          <FormField label="Tổng số tầng chỗ nghỉ có (trừ tầng hầm)" isRequired>
            <AppInputNumberValidation
              name="numberFloor"
              _id="numberFloor"
              type="number"
              subTitle="Vui lòng nhập số từ 1 đến 200"
              placeholder="Nhập số tầng"
              validation={validation}
            />
          </FormField>

          <FormField label="Tổng số phòng tại chỗ nghỉ" isRequired>
            <AppInputNumberValidation
              name="numberRoom"
              _id="numberRoom"
              type="number"
              subTitle="Vui lòng nhập số từ 1 đến 4294967295"
              placeholder="Nhập số phòng"
              validation={validation}
            />
          </FormField>
        </Card>

        <Row>
          <Col md="6">
            <Card className="Section">
              {
                facilitiesServices1.map((service) => (
                  <FormField label={service.title} key={service._id}>
                    <>
                      <span className="Description">
                        {
                          service.isActive ? (
                            RenderServiceList(service.groupServices)
                          ) : '-'
                        }
                      </span>

                      <AppButton
                        classes="ButtonEdit"
                        onClick={onToggleModalEditService(service)}
                      >
                        Chỉnh sửa
                      </AppButton>
                    </>
                  </FormField>
                ))
              }
            </Card>
          </Col>

          <Col md="6">
            <Card className="Section">
              {
                facilitiesServices2.map((service) => (
                  <FormField label={service.title} key={service._id}>
                    <>
                      <span className="Description">
                        {
                          service.isActive ? (
                            RenderServiceList(service.groupServices)
                          ) : '-'
                        }
                      </span>

                      <AppButton
                        classes="ButtonEdit"
                        onClick={onToggleModalEditService(service)}
                      >
                        Chỉnh sửa
                      </AppButton>
                    </>
                  </FormField>
                ))
              }
            </Card>
          </Col>
        </Row>

        <Card className="Section">
          <div className="d-flex justify-content-end">
            <AppButton color={themeColor.SECONDARY} classes="ButtonAction mr-24">
              Hủy
            </AppButton>

            <AppButton classes="ButtonAction">
              Lưu
            </AppButton>
          </div>
        </Card>
      </Container>

      <ModalEditFacilityService
        isOpen={isOpenModalEditService}
        facilityService={editData}
        onToggleModal={onToggleModalEditService()}
        onToggleService={onToggleService}
        onToggleAllService={onToggleAllService}
      />
    </div>
  );
}

export default ConveniencesServices;
