import {
  GET_ROOM_STATUS_LIST,
  GET_ROOM_STATUS_LIST_FAILED,
  GET_ROOM_STATUS_LIST_SUCCESS,
  UPDATE_ROOM_STATUS_LIST,
  UPDATE_ROOM_STATUS_LIST_FAILED,
  UPDATE_ROOM_STATUS_LIST_SUCCESS,
} from './actionTypes';

const initialState = {
  loadingRoomStatusList: true,
  loadingUpdateRoomStatusList: true,
  roomStatusList: [],
};

export default function roomStatus(state = initialState, action) {
  switch (action.type) {
    case GET_ROOM_STATUS_LIST:
      return {
        ...state,
        loadingRoomStatusList: true,
      };

    case GET_ROOM_STATUS_LIST_SUCCESS:
      return {
        ...state,
        loadingRoomStatusList: false,
        roomStatusList: action.payload,
      };

    case GET_ROOM_STATUS_LIST_FAILED:
      return {
        ...state,
        loadingRoomStatusList: false,
      };

    case UPDATE_ROOM_STATUS_LIST:
      return {
        ...state,
        loadingLocationList: true,
      };

    case UPDATE_ROOM_STATUS_LIST_FAILED:
      return {
        ...state,
        loadingUpdateRoomStatusList: false,
      };

    case UPDATE_ROOM_STATUS_LIST_SUCCESS:
      return {
        ...state,
        loadingUpdateRoomStatusList: false,
      };

    default:
      return {
        ...state,
      };
  }
}
