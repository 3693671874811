import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';

import {
  Container,
  Card,
  Row,
  Col,
  Label,
  Input,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';
import { useHistory } from 'react-router-dom';

import classnames from 'utils/classnames';
import { LOCATIONS, MODAL_ADD_LOCATION_TAB, themeColor } from 'constants/index';

import Breadcrumb from 'components/App/Breadcrumb';
import Button from 'components/App/Button';
import DataTableLocations from 'components/App/DataTableLocations';
import Meta from 'components/App/Meta';
import Selection from 'components/App/Selection';
import Pagination from 'components/Pagination';

import ModalAddLocation from 'components/Modals/ModalAddLocation';

import styles from './LocationList.module.scss';

const breadcrumbList = [
  {
    name: 'Quản lý khách sạn',
    isActive: false,
    link: '', // TODO: ADD ROUTE WHEN HAVE LINK
  },
  {
    name: 'Danh sách khách sạn',
    isActive: true,
    link: '', // TODO: ADD ROUTE WHEN HAVE LINK
  },
];

const TAB = {
  ALL: 'ALL',
  PENDING: 'PENDING',
  DISCOUNT: 'DISCOUNT',
};

const STATUS_PROGRESS_DATA = {
  PENDING: {
    label: 'Chờ duyệt',
    value: 'PENDING',
  },
  REMOVED: {
    label: 'Đã xóa',
    value: 'REMOVED',
  },
};

const NAV_TAB = [
  {
    title: 'Tất cả',
    value: TAB.ALL,
  },
  {
    title: 'Chờ duyệt',
    value: TAB.PENDING,
  },
  {
    title: 'Giảm giá',
    value: TAB.DISCOUNT,
  },
];

const optionsList = [
  {
    label: 'Bộ lọc 1',
    value: 'Filter 1',
  },
  {
    label: 'Bộ lọc 2',
    value: 'Filter 3',
  },
  {
    label: 'Bộ lọc 3',
    value: 'Filter 3',
  },
];

const DEFAULT_LIMIT = 20;

function LocationList(props) {
  const {
    actionGetLocationList,
    // loadingLocationList,
    locationList,
  } = props;

  const history = useHistory();
  const [activeTab, setActiveTab] = useState(TAB.ALL);
  const [pageCount, setPageCount] = useState(0);
  const [isActiveModalAddLocation, setIsActiveModalAddLocation] = useState(false);
  const [tabModalActive, setTabModalActive] = useState(MODAL_ADD_LOCATION_TAB.NEW);

  const onChangeTab = useCallback((tab) => () => {
    setActiveTab(tab);
  }, []);

  const onChangePage = useCallback((selected) => {
    history.push(`?page=${selected.selected + 1}`);
    // tableRef.current.scrollIntoView({ behavior: 'smooth' });
  }, [history]);

  const onToggleAddLocation = useCallback(() => {
    setIsActiveModalAddLocation((prev) => !prev);

    if (isActiveModalAddLocation) {
      setTabModalActive(MODAL_ADD_LOCATION_TAB.NEW);
    }
  }, [isActiveModalAddLocation]);

  const onClickTabModalActive = useCallback((tab) => () => {
    setTabModalActive(tab);
  }, []);

  const onSubmitModal = useCallback((tab) => () => {
    switch (tab) {
      case MODAL_ADD_LOCATION_TAB.ALREADY:
        history.push(LOCATIONS.ADD_ALREADY_LOCATION);
        break;

      case MODAL_ADD_LOCATION_TAB.NEW:
        history.push(LOCATIONS.LOCATION_ADD);
        break;

      default:
        break;
    }
  }, []);

  const urlPage = useMemo(() => Number(new URLSearchParams(history.location.search).get('page') || 1), [history]);

  const totalPage = useMemo(() => (Math.ceil((
    locationList.length || 0) / DEFAULT_LIMIT)), []);

  useEffect(() => {
    setPageCount(totalPage);
  }, [totalPage]);

  useEffect(() => {
    actionGetLocationList();
  }, []);

  return (
    <>
      <div className={`page-content ${styles.Page}`}>
        <Meta name="Danh sách chỗ nghỉ" />

        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb
            pageTitle="Danh sách khách sạn"
            breadcrumbList={breadcrumbList}
          />

          <Card className="Section">
            <Row>
              <Col xxl="6" className="mb-24">
                <Label className="Section__Search__Label">
                  Tìm kiếm
                </Label>

                <div className="Section__Search__Container">
                  <Input
                    placeholder="Tìm theo ID, Email và Số điện thoại của chỗ nghỉ"
                    className={classnames('Section__Search__Input')}
                  />

                  <i className="fas fa-search Section__Search__Icon__Search" />
                </div>
              </Col>

              <Col xxl="6">
                <Label className="Section__Search__Label">
                  Bộ lọc
                </Label>

                <Row>
                  <Col md="4" className="mb-24">
                    <Selection
                      placeholder="Trạng thái"
                      options={optionsList}
                    />
                  </Col>

                  <Col md="4" className="mb-24 p-0">
                    <Selection
                      placeholder="Loại hình chỗ nghỉ"
                      options={optionsList}
                    />
                  </Col>

                  <Col md="4" className="mb-24">
                    <Selection
                      placeholder="Khu vực"
                      options={optionsList}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <div className="d-flex">
              <Button
                outline
                classes="Section__Search__Button__ReType mr-24"
                color={themeColor.LIGHT}
              >
                Nhập lại
              </Button>

              <Button
                classes="Section__Search__Button__Search"
              >
                Tìm kiếm
              </Button>
            </div>
          </Card>

          <Card className="Section">
            <div className="d-flex Table__Header">
              <Nav pills className="col-md-10">
                {
                  NAV_TAB.map((tab) => (
                    <NavItem key={tab.value} className="col-md-2">
                      <NavLink
                        className={activeTab === tab.value ? 'active' : ''}
                        onClick={onChangeTab(tab.value)}
                      >
                        <span className="d-none d-sm-block">{tab.title}</span>
                      </NavLink>
                    </NavItem>
                  ))
                }
              </Nav>

              <Button
                classes="col-md-2 Table__Header__Button"
                fontSize="13px"
                onClick={onToggleAddLocation}
              >
                <i className="fas fa-plus" />

                Thêm chỗ nghỉ
              </Button>
            </div>

            <TabContent
              activeTab={activeTab}
              className="Table__Body"
            >
              <Label className="Table__Body__Total">
                {`${locationList.length} chỗ nghỉ`}
              </Label>

              {NAV_TAB.map((item) => (
                <TabPane
                  tabId={item.value}
                  key={item.value}
                >
                  <DataTableLocations
                    locations={locationList}
                    statusProgressData={STATUS_PROGRESS_DATA}
                  />
                </TabPane>
              ))}
            </TabContent>
          </Card>

          <Pagination
            page={urlPage}
            pageCount={pageCount}
            onChangePage={onChangePage}
          />
        </Container>

        <ModalAddLocation
          isActiveModalAddLocation={isActiveModalAddLocation}
          tabModalActive={tabModalActive}
          onToggleAddLocation={onToggleAddLocation}
          onClickTabModalActive={onClickTabModalActive}
          onSubmitModal={onSubmitModal}
        />
      </div>
    </>
  );
}

LocationList.propTypes = {
  actionGetLocationList: PropTypes.func.isRequired,
  // loadingLocationList: PropTypes.bool.isRequired,
  locationList: PropTypes.instanceOf(Array),
};

LocationList.defaultProps = {
  locationList: [],
};

export default LocationList;
