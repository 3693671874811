import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Avatar from 'react-avatar';

const AppAvatar = (props) => {
  const {
    isRound,
    alt,
    name,
    title,
    src,
    className,

    size,
    maxInitials,

    fontStyle,
  } = props;

  return (
    <Avatar
      name={name}
      title={title || name}
      alt={alt || name}
      src={src}
      size={size}
      className={className}
      maxInitials={maxInitials}
      textSizeRatio={3}
      round={isRound}
      style={{
        fontWeight: 'bold',
        ...fontStyle,
      }}
    />
  );
};

AppAvatar.propTypes = {
  isRound: PropTypes.bool,
  alt: PropTypes.string,
  name: PropTypes.string,
  title: PropTypes.string,
  src: PropTypes.string,
  className: PropTypes.string,

  size: PropTypes.number,
  maxInitials: PropTypes.number,

  fontStyle: PropTypes.instanceOf(Object),
};

AppAvatar.defaultProps = {
  isRound: true,
  alt: '',
  name: '',
  src: '',
  title: '',
  className: '',

  size: 40,
  maxInitials: 2,

  fontStyle: null,
};

export default memo(AppAvatar);
