import {
  GET_ROOM_STATUS_LIST,
  GET_ROOM_STATUS_LIST_FAILED,
  GET_ROOM_STATUS_LIST_SUCCESS,

  UPDATE_ROOM_STATUS_LIST,
  UPDATE_ROOM_STATUS_LIST_FAILED,
  UPDATE_ROOM_STATUS_LIST_SUCCESS,
} from './actionTypes';

export const actionGetRoomStatusList = (payload) => ({
  payload,
  type: GET_ROOM_STATUS_LIST,
});

export const actionGetRoomStatusListSuccess = (payload) => ({
  payload,
  type: GET_ROOM_STATUS_LIST_SUCCESS,
});

export const actionGetRoomStatusListFailed = (payload) => ({
  payload,
  type: GET_ROOM_STATUS_LIST_FAILED,
});

export const actionUpdateRoomStatusList = (payload) => ({
  payload,
  type: UPDATE_ROOM_STATUS_LIST,
});

export const actionUpdateRoomStatusListSuccess = (payload) => ({
  payload,
  type: UPDATE_ROOM_STATUS_LIST_SUCCESS,
});

export const actionUpdateRoomStatusListFailed = (payload) => ({
  payload,
  type: UPDATE_ROOM_STATUS_LIST_FAILED,
});
