import React, {
  useMemo, useEffect, useState, useCallback,
} from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Badge, Card, CardBody } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import Breadcrumb from 'components/Breadcrumb';
import DataTables from 'components/DataTables';
import Meta from 'components/App/Meta';
import ModalConfirm from 'components/Modals/ModalConfirm';

import images from 'assets/images';
import {
  allowPermission,
  LOCATIONS,
  mediaFolder,
  mediaMimeTypeSelectList,
  mediaTypeSelectList,
} from 'constants/index';
import propTypeModels from 'propTypeModels';
import { checkPermission, convertIsoDateToString, getRestoreOrRemoveTrans } from 'utils';

import './style.scss';

const Media = (props) => {
  const {
    me,

    actionGetListMedia,
    mediaList,
    mediaTotal,
    mediaLoading,

    actionDeleteMedia,
  } = props;

  const { t } = useTranslation();

  const [state, setState] = useState({
    isShowDeleteModal: false,
    item: {},
  });

  const onBtnDelete = useCallback((item) => () => {
    setState((preState) => ({
      ...preState,
      isShowDeleteModal: true,
      item,
    }));
  }, []);

  const onToggleModal = useCallback(() => {
    setState((preState) => ({
      ...preState,
      isShowDeleteModal: false,
      item: {},
    }));
  }, []);

  const onDeleteMedia = useCallback(() => {
    actionDeleteMedia(state.item._id);

    setState((preState) => ({
      ...preState,
      isShowDeleteModal: false,
      item: {},
    }));
  }, []);

  useEffect(() => {
    actionGetListMedia();
  }, []);

  const columns = useMemo(() => [
    {
      dataField: '_id',
      sort: false,
      text: 'ID Role',
      hidden: true,
    },
    {
      dataField: 'location',
      text: t('image'),
      sort: false,
      headerClasses: 'col-min-width-avatar',
      formatter: (cellContent, row) => {
        if (row.mimeType === 'application/pdf') {
          return (
            <Link to={{ pathname: row.location }} target="_blank">
              <img className="size-img" src={images.mediaImgPDF.default} alt="" />
            </Link>
          );
        }

        if (row.mimeType === 'application/vnd.ms-excel') {
          return (
            <Link to={{ pathname: row.location }} target="_blank">
              <img className="size-img" src={images.mediaImgExcel.default} alt="" />
            </Link>
          );
        }

        if (row.mimeType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
          return (
            <Link to={{ pathname: row.location }} target="_blank">
              <img className="size-img" src={images.mediaImgExcel.default} alt="" />
            </Link>
          );
        }

        if (row.mimeType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
          return (
            <Link to={{ pathname: row.location }} target="_blank">
              <img className="size-img" src={images.mediaImgWord.default} alt="" />
            </Link>
          );
        }

        return <img src={row.location} className="size-img" alt="" />;
      },
    },
    {
      dataField: 'name',
      text: t('name'),
      sort: false,
      formatter: (cellContent, item) => (
        <Link to={`${LOCATIONS.MEDIA}/${item._id}`}>
          <div className="text-break col-min-width-lg">{item.name}</div>
        </Link>
      ),
    },
    {
      dataField: 'size',
      text: t('media.size'),
      sort: false,
      formatter: (cellContent, item) => (
        <div className="text-break width-content text-description">
          {item.size}
          {' '}
          bytes
        </div>
      ),
    },
    {
      dataField: 'mimeType',
      text: t('media.type'),
      sort: false,
      formatter: (cellContent, item) => (
        mediaMimeTypeSelectList.map((m) => {
          if (item.mimeType === m.value) {
            return (
              <Badge
                key={m.value}
                className="font-size-12 col-min-width-sm badge bg-info-light"
                pill
              >
                {t(`constant.media_mime_types.${m.value}`)}
              </Badge>
            );
          } return '';
        })
      ),
    },
    {
      dataField: 'date_created',
      text: t('media.date_created'),
      sort: false,
      formatter: (cellContent, item) => (
        <div className="width-date">
          {convertIsoDateToString(item.createdAt)}
        </div>
      ),
    },
    {
      dataField: 'action',
      isDummyField: true,
      text: t('media.action'),
      hidden: !checkPermission(me.role, allowPermission.VIEW_DETAIL_MEDIA)
        && !checkPermission(me.role, allowPermission.EDIT_MEDIA)
        && !checkPermission(me.role, allowPermission.DELETE_MEDIA),
      formatter: (cellContent, item) => (
        <div className="d-flex gap-3">
          {checkPermission(me.role, allowPermission.VIEW_DETAIL_MEDIA)
          && (
          <Link to={`${LOCATIONS.MEDIA}/${item._id}`}>
            <i className="text-success mdi mdi-eye font-size-20" aria-hidden="true" />
          </Link>
          )}
          {checkPermission(me.role, allowPermission.EDIT_MEDIA)
            && (
            <Link to={`${LOCATIONS.MEDIA}/${item._id}`}>
              <i className="text-primary mdi mdi-pencil font-size-20" aria-hidden="true" />
            </Link>
            )}
          {checkPermission(me.role, allowPermission.DELETE_MEDIA)
            && <i className="text-danger mdi mdi-delete font-size-20" onClick={onBtnDelete(item)} aria-hidden="true" />}
        </div>
      ),
    },
  ], [t, me.role]);

  return (
    <div className="page-content">
      <Meta name="media.manager_title" />

      <div className="page-content__wrapper">
        <div className="hard-width">
          <Breadcrumb
            breadcrumbItem="media.manager_title"
            title="media.manager_title"
          />

          {/* delete personal client  */}
          <ModalConfirm
            isOpen={state.isShowDeleteModal}
            onCloseClick={onToggleModal}
            onAcceptConfirm={onDeleteMedia}
            notification={{
              message: getRestoreOrRemoveTrans('global.question_delete'),
              description: getRestoreOrRemoveTrans('global.restore_fail'),
            }}
          />

          <Card>
            <CardBody>
              <DataTables
                data={{
                  list: mediaList,
                  total: mediaTotal,
                }}
                columns={columns}
                dataFolderMedia={{
                  visibleMedia: true,
                  selectMedia: mediaFolder,
                }}
                dataTypeMedia={{
                  visibleTypeMedia: true,
                  selectTypeMedia: mediaTypeSelectList,
                }}
                search={{
                  visibleSearch: true,
                  onFilter: actionGetListMedia,
                }}
                loading={mediaLoading}
              />
            </CardBody>
          </Card>
        </div>
      </div>
    </div>
  );
};

Media.propTypes = {
  me: propTypeModels.user.userPropTypes.isRequired,

  actionGetListMedia: PropTypes.func.isRequired,
  mediaList: propTypeModels.media.mediaList.isRequired,
  mediaTotal: PropTypes.number.isRequired,
  mediaLoading: PropTypes.bool.isRequired,

  actionDeleteMedia: PropTypes.func.isRequired,
};

export default Media;
