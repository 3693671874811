import { call, takeLeading } from 'redux-saga/effects';

import { apiErrorHandler } from 'utils';

import {
  CHANGE_SIDEBAR_THEME,
  CHANGE_SIDEBAR_TYPE,
  CHANGE_TOPBAR_THEME,
  SHOW_RIGHT_SIDEBAR,
} from './actionTypes';

function changeBodyAttribute(attribute, value) {
  if (document.body) document.body.setAttribute(attribute, value);
  return true;
}

/**
 * Toggle the class on body
 * @param {*} cssClass
 */
function manageBodyClass(cssClass, action = 'toggle') {
  switch (action) {
    case 'add':
      if (document.body) document.body.classList.add(cssClass);
      break;
    case 'remove':
      if (document.body) document.body.classList.remove(cssClass);
      break;
    default:
      if (document.body) document.body.classList.toggle(cssClass);
      break;
  }

  return true;
}

/**
 * Changes the left sidebar theme
 * @param {*} param0
 */
function* changeLeftSidebarTheme({ payload: theme }) {
  try {
    yield call(changeBodyAttribute, 'data-sidebar', theme);
  } catch (error) {
    apiErrorHandler(error);
  }
}

/**
 * Changes the left sidebar theme Image
 * @param {*} param0
 */
// function* changeLeftSidebarThemeImage({ payload: theme }) {
//   try {
//     yield call(changeBodyAttribute, 'data-sidebar-image', theme);
//   } catch (error) {}
// }

/**
 * Changes the topbar theme
 * @param {*} param0
 */
function* changeTopBarTheme({ payload: theme }) {
  try {
    yield call(changeBodyAttribute, 'data-topbar', theme);
  } catch (error) {
    apiErrorHandler(error);
  }
}

/**
 * Changes the left sidebar type
 * @param {*} param0
 */
function* changeLeftSidebarType({ payload: { sidebarType, isMobile } }) {
  try {
    switch (sidebarType) {
      case 'compact':
        yield call(changeBodyAttribute, 'data-sidebar-size', 'small');
        yield call(manageBodyClass, 'sidebar-enable', 'remove');
        yield call(manageBodyClass, 'vertical-collpsed', 'remove');
        break;
      case 'icon':
        yield call(changeBodyAttribute, 'data-sidebar-size', '');
        yield call(changeBodyAttribute, 'data-keep-enlarged', 'true');
        yield call(manageBodyClass, 'vertical-collpsed', 'add');
        break;
      case 'condensed':
        yield call(manageBodyClass, 'sidebar-enable', 'add');
        if (window.screen.width >= 992) {
          yield call(manageBodyClass, 'vertical-collpsed', 'remove');
          yield call(manageBodyClass, 'sidebar-enable', 'remove');
          yield call(manageBodyClass, 'vertical-collpsed', 'add');
          yield call(manageBodyClass, 'sidebar-enable', 'add');
        } else {
          yield call(manageBodyClass, 'sidebar-enable', 'add');
          yield call(manageBodyClass, 'vertical-collpsed', 'add');
        }
        break;
      default:
        yield call(changeBodyAttribute, 'data-sidebar-size', '');
        yield call(manageBodyClass, 'sidebar-enable', 'remove');
        if (!isMobile) yield call(manageBodyClass, 'vertical-collpsed', 'remove');
        break;
    }
  } catch (error) {
    apiErrorHandler(error);
  }
}

/**
 * Show the rightsidebar
 */
function* showRightSidebar() {
  try {
    yield call(manageBodyClass, 'right-bar-enabled', 'add');
  } catch (error) {
    apiErrorHandler(error);
  }
}

export default function* LayoutSaga() {
  yield takeLeading(CHANGE_SIDEBAR_THEME, changeLeftSidebarTheme);
  yield takeLeading(CHANGE_SIDEBAR_TYPE, changeLeftSidebarType);
  yield takeLeading(CHANGE_TOPBAR_THEME, changeTopBarTheme);
  yield takeLeading(SHOW_RIGHT_SIDEBAR, showRightSidebar);
}
