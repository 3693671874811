import { all, fork } from 'redux-saga/effects';

// public
import RegisterSaga from 'store/auth/register/saga';
import AuthSaga from 'store/auth/login/saga';
import ForgotSaga from 'store/auth/forgotPassword/saga';
import ResetPasswordSaga from 'store/auth/resetPassword/saga';
import ProfileSaga from 'store/auth/profile/saga';
import LayoutSaga from 'store/layout/saga';

import MediaSaga from 'store/media/saga';
import LocationSaga from 'store/location/saga';
import RoomStatusSaga from 'store/location/room/saga';

export default function* rootSaga() {
  yield all([
    // public
    fork(RegisterSaga),
    fork(AuthSaga),
    fork(ForgotSaga),
    fork(ResetPasswordSaga),
    fork(ProfileSaga),
    fork(LayoutSaga),

    fork(MediaSaga),

    fork(LocationSaga),
    fork(RoomStatusSaga),
  ]);
}
