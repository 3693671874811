import React, {
  useMemo, memo, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from 'reactstrap';
import moment from 'moment';
import { useFormik } from 'formik';

import { ReactComponent as CloseButtonSvg } from 'assets/images/location/close-button-svg.svg';

import AppInputValidation from 'components/App/InputValidation';
import AppInputNumberValidation from 'components/App/InputNumberValidation';
import Button from 'components/App/Button';

import { parseCurrency } from 'utils';
import styles from './ModalEditRoomStatus.module.scss';

const AVAILABLE = 'AVAILABLE';
const FULL = 'FULL';
const CLOSE = 'CLOSE';

function ModalEditRoomStatus(props) {
  const {
    isOpen,
    data,
    onToggleModal,
    actionUpdateLocation,
    roomStatusList,
    place,
    dayStartFormat,
    dayEndFormat,
    priceTypes,
  } = props;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      availableRooms: data.availableRooms || 0,
      roomStatus: data.roomStatus || 'CLOSE',
      roomPrice: data?.prices?.[0]?.price || 0,
    },
  });

  const roomName = roomStatusList.find((item) => item._id === data.room)?.name?.toUpperCase();

  const CloseButton = useMemo(() => (
    <button className={styles.ButtonClose} onClick={onToggleModal} type="button">
      <CloseButtonSvg />
    </button>
  ));

  const isCheckedRoomStatus = useCallback((status) => {
    const { roomStatus } = formik.values;

    return status === roomStatus;
  }, [formik.values]);

  const onSelectCheckedRoomStatus = useCallback((status) => () => {
    formik.setFieldValue('roomStatus', status);
  }, [formik]);

  const onSubmitModal = useCallback(
    () => {
      let { roomStatus, roomPrice } = formik.values;

      const { availableRooms } = formik.values;

      if (availableRooms === 0) {
        roomStatus = FULL;
      }

      if (availableRooms > 0 && roomStatus === FULL) {
        roomStatus = CLOSE;
      }

      roomPrice = parseCurrency(roomPrice);

      const dataUpdate = {
        data: {
          place,
          items: [
            {
              room: data.room,
              statusDate: data.statusDate,
              availableRooms,
              prices: [
                {
                  priceType: priceTypes[0]?._id,
                  price: roomPrice,
                },
              ],
              roomStatus,
            },
          ],
        },

        filter: {
          startDate: dayStartFormat,
          endDate: dayEndFormat,
          place,
        },
      };

      actionUpdateLocation(dataUpdate);

      onToggleModal();
    }, [actionUpdateLocation,
      data.room,
      data.statusDate,
      dayEndFormat,
      dayStartFormat,
      formik.values,
      onToggleModal,
      place,
      priceTypes],
  );

  return (
    <div className={styles.Component}>
      <Modal
        fade
        size="lg"
        isOpen={isOpen}
        toggle={onToggleModal}
        className={styles.Modal}
      >
        <ModalHeader
          toggle={onToggleModal}
          className={styles.ModalHeader}
          close={CloseButton}
        />
        <ModalBody className={styles.ModalBody}>

          <div className="ServiceWrapper">
            <h2 className="ServiceWrapper__Title">{`Bạn đang chỉnh sửa phòng ${roomName} vào ngày ${moment(data.statusDate).format('DD-MM-YYYY')}`}</h2>

            <div className="ModalEditRoom__FormField">
              <div className="BtnRadioWrapper">
                <div className="Title">Tình trạng phòng:</div>

                <div className="Box">
                  <input
                    className="InputRadio"
                    type="radio"
                    id="pets-allow"
                    name="pets"
                    checked={isCheckedRoomStatus(AVAILABLE)}
                    onChange={onSelectCheckedRoomStatus(AVAILABLE)}
                  />

                  <label htmlFor="pets-allow">Mở</label>
                </div>

                <div className="Box">
                  <input
                    className="InputRadio"
                    type="radio"
                    id="pets-deny"
                    name="pets"
                    checked={isCheckedRoomStatus(CLOSE)}
                    onChange={onSelectCheckedRoomStatus(CLOSE)}
                  />

                  <label htmlFor="pets-deny">Đóng</label>
                </div>

              </div>
            </div>

            <div className="ModalEditRoom__FormField">
              <span>Phòng để bán: </span>

              <AppInputValidation
                name="availableRooms"
                id="availableRooms"
                type="number"
                validation={formik}
                placeholder="Nhập số phòng"
                wrapperClassName="MaxWidth"
                value={formik.values.availableRooms}
              />
            </div>

            <div className="ModalEditRoom__FormField">
              <span>Giá tiêu chuẩn: </span>

              <AppInputNumberValidation
                name="roomPrice"
                id="roomPrice"
                type="number"
                validation={formik}
                placeholder="Nhập số tiền"
                showCurrency="VNĐ"
                wrapperClassName="MaxWidth"
                value={formik.values.roomPrice}
              />
            </div>
          </div>

          <hr className="Divider" />
        </ModalBody>

        <ModalFooter className={styles.ModalFooter}>
          <div className="FooterButton">
            <div className="d-flex m-0">
              <Button
                classes="Button Button__Cancel"
                onClick={onToggleModal}
              >
                Hủy
              </Button>

              <Button
                classes=" Button Button__Submit"
                onClick={onSubmitModal}
              >
                Xác nhận
              </Button>
            </div>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
}

ModalEditRoomStatus.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  place: PropTypes.string,
  dayStartFormat: PropTypes.string,
  dayEndFormat: PropTypes.string,

  onToggleModal: PropTypes.func.isRequired,
  actionUpdateLocation: PropTypes.func.isRequired,

  roomStatusList: PropTypes.instanceOf(Array),
  data: PropTypes.instanceOf(Object),
  priceTypes: PropTypes.instanceOf(Array),
};

ModalEditRoomStatus.defaultProps = {
  data: {},
  roomStatusList: [],
  place: '',
  dayStartFormat: '',
  dayEndFormat: '',
  priceTypes: [],
};

export default memo(ModalEditRoomStatus);
