import React, { useCallback, useState } from 'react';
import {
  Col,
  Container,
} from 'reactstrap';
import { Link } from 'react-router-dom';

import AppButton from 'components/App/Button';
import Breadcrumb from 'components/App/Breadcrumb';
import Meta from 'components/App/Meta';

import ImageHotel from 'assets/images/avatarHotel.png';
import { ReactComponent as StarSVG } from 'assets/images/star_purple500.svg';
import { ReactComponent as LocationYellow } from 'assets/images/LocationYellow.svg';
import { ReactComponent as LocationBlue } from 'assets/images/LocationBlue.svg';

import ModalEditLocation from 'components/Modals/ModalEditLocation';

import styles from './Location.module.scss';

const breadcrumbList = [
  {
    name: 'Chỗ nghỉ',
    isActive: false,
    link: '',
  },
  {
    name: 'Tổng quát',
    isActive: true,
    link: '',
  },
];

function Location() {
  const [isOpenModalEditLocation, setIsOpModalEditLocation] = useState(false);

  const onToggleModal = useCallback(() => {
    setIsOpModalEditLocation((prev) => !prev);
  }, []);

  return (
    <>
      <div className={`page-content ${styles.CustomPage}`}>
        <Meta name="Chi tiết chỗ nghỉ" />

        <Container fluid>
          <Breadcrumb
            pageTitle="Tổng quát"
            breadcrumbList={breadcrumbList}
          />

          <div className={styles.Page}>
            <div className="row FixSpacingRow">
              <Col>
                <div className="Information">
                  <div className="InformationContent">
                    <div className="InformationContentTitle">
                      <p className="InformationContentTitle__Name">
                        White Lotus Saigon Hotel
                      </p>

                      <div className="InformationContentTitle__Star">
                        <StarSVG />

                        <StarSVG />

                        <StarSVG />
                      </div>
                    </div>

                    <div className="InformationContentSubTitle">
                      <span className="Text">Khách sạn</span>
                      <span className="Text">Tổng số phòng: 38</span>
                    </div>

                    <div className="InformationContentLocation">
                      <LocationYellow />

                      <div className="InformationContentLocation_Description">
                        <div className="Title">
                          6A Thi Sách, Quận 1, Thành phố Hồ Chí Minh
                        </div>

                        <div className="SubTitle">
                          Khu vực Quận 1
                        </div>
                      </div>
                    </div>

                    <div className="InformationContentLocation">
                      <LocationBlue />

                      <div className="InformationContentLocation_Description">
                        <div className="Title">
                          10.778175948350482, 106.70459657907486
                        </div>

                        <div className="SubTitle">
                          trên
                          {' '}
                          <Link to="/">
                            <span className="Hyperlink">
                              Google Maps
                            </span>
                          </Link>
                        </div>
                      </div>
                    </div>

                    <hr />

                    <div className="InformationContentDescription">
                      <p className="Title">
                        Miêu tả khách sạn
                      </p>

                      <p>
                        Located within a 4-minute stroll from Opera House and Dong Khoi
                        Shopping Street,
                        White Lotus Saigon Hotel offers contemporary rooms with free WiFi
                        in Ho Chi Minh
                        City. Guests can enjoy a meal at the on-site restaurant or grab a
                        drink at the

                        Featuring brown and white hues, air-conditioned rooms have wooden
                        furnishings
                        and tile/marble floor.
                        All ample rooms come with large windows, a satellite TV and minibar
                        for your
                        convenience.

                        Located within a 4-minute stroll from Opera House and Dong Khoi
                        Shopping Street,
                        White Lotus Saigon Hotel offers contemporary rooms with free WiFi
                        in Ho Chi Minh
                        City. Guests can enjoy a meal at the on-site restaurant or grab a
                        drink at the

                        Featuring brown and white hues, air-conditioned rooms have wooden
                        furnishings
                        and tile/marble floor.
                        All ample rooms come with large windows, a satellite TV and minibar for your
                        convenience.
                      </p>
                    </div>

                  </div>
                </div>
              </Col>

              <Col>
                <div className="ImageWrapper">
                  <img className="Image" src={ImageHotel} alt="avatar" />
                </div>
              </Col>
            </div>
          </div>

          <div className={styles.FooterButton}>
            <AppButton
              onClick={onToggleModal}
            >
              Yêu cầu chỉnh sửa
            </AppButton>

            <ModalEditLocation
              isOpenModalEditLocation={isOpenModalEditLocation}
              onToggleModal={onToggleModal}
            />
          </div>
        </Container>
      </div>
    </>
  );
}

export default Location;
