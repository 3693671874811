import React, {
  useMemo,
  memo,
} from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  ModalBody,
  ModalHeader,
  Label,
  Col,
  Row,
  Input,
} from 'reactstrap';

import { ReactComponent as CloseButtonSvg } from 'assets/images/location/close-button-svg.svg';

import Button from 'components/App/Button';

import styles from './ModalEditFacilityService.module.scss';

function ModalEditFacilityService(props) {
  const {
    isOpen,

    facilityService,

    onToggleModal,
    onToggleService,
    onToggleAllService,
  } = props;

  const CloseButton = useMemo(() => (
    <button className={styles.ButtonClose} onClick={onToggleModal} type="button">
      <CloseButtonSvg />
    </button>
  ));

  return (
    <div className={styles.Component}>
      <Modal
        fade
        size="lg"
        isOpen={isOpen}
        toggle={onToggleModal}
        className={styles.Modal}
      >
        <ModalHeader
          toggle={onToggleModal}
          className={styles.ModalHeader}
          close={CloseButton}
        />

        <ModalBody
          className={styles.ModalBody}
        >
          <Label className="Label">
            {facilityService.title}
          </Label>

          <hr className="Divider" />

          <Row className="">
            {
              facilityService?.groupServices?.map((item) => (
                <Col md="4" key={item._id}>
                  <div className="Checkbox">
                    <Input
                      type="checkbox"
                      checked={item.isActive}
                      className="Checkbox__Input"
                      onChange={onToggleService(item._id)}
                    />

                    <div className="Checkbox__Name">
                      {item.name}
                    </div>
                  </div>
                </Col>
              ))
            }
          </Row>

          <hr className="Divider" />

          <div className="Button__Wrapper">
            <div className="Button__Wrapper--Left">
              <button
                className="btn-minimal blue-100 mr-24"
                type="button"
                onClick={onToggleAllService(true)}
              >
                Chọn tất cả
              </button>

              <button
                className="btn-minimal red-100"
                type="button"
                onClick={onToggleAllService(false)}
              >
                Xóa tất cả lựa chọn
              </button>
            </div>

            <div>
              <Button
                classes="Button Button__Cancel mr-24"
                onClick={onToggleModal}
              >
                Hủy
              </Button>

              <Button classes="Button Button__Save">
                Lưu
              </Button>

            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}

ModalEditFacilityService.propTypes = {
  isOpen: PropTypes.bool.isRequired,

  facilityService: PropTypes.instanceOf(Object),

  onToggleModal: PropTypes.func.isRequired,
  onToggleService: PropTypes.func.isRequired,
  onToggleAllService: PropTypes.func.isRequired,
};

ModalEditFacilityService.defaultProps = {
  facilityService: {},
};

export default memo(ModalEditFacilityService);
