import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
} from 'reactstrap';

import AppAvatar from 'components/App/Avatar';

import { ReactComponent as At } from 'assets/images/At.svg';
import { ReactComponent as Phone } from 'assets/images/Phone.svg';
import { ReactComponent as Language } from 'assets/images/Language.svg';

function CardLocationContactInfo(props) {
  const {
    list,
  } = props;
  return (
    <Card className="Section__Card Border">
      <div className="BoxAvatar">
        <AppAvatar
          className="avatar-select"
                  // src={src}
          name={list.name}
          alt={list.name}
        />

        <div className="Text">
          <div className="Text__Title">
            {list.name}
          </div>

          <div className="Text__Subtitle">
            {list.position}
          </div>
        </div>
      </div>

      <hr className="Line" />

      <div className="SocialGroup">
        <At />

        <p className="Text">{list.mail}</p>
      </div>

      <div className="SocialGroup">
        <Phone />

        <p className="Text">{list.phoneNumber}</p>
      </div>

      <div className="SocialGroup">
        <Language />

        <p className="Text">{list.language}</p>
      </div>
    </Card>
  );
}

CardLocationContactInfo.propTypes = {
  list: PropTypes.instanceOf(Object),
};

CardLocationContactInfo.defaultProps = {
  list: {},
};

export default memo(CardLocationContactInfo);
