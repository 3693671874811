import React, { useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';

import { MODAL_ADD_LOCATION_TAB } from 'constants/index';

import { ReactComponent as CloseButtonSvg } from 'assets/images/location/close-button-svg.svg';
import { ReactComponent as AddNewLocationSvg } from 'assets/images/location/add-new-location-icon-svg.svg';
import { ReactComponent as AddAlreadyLocationSvg } from 'assets/images/location/add-already-location-icon-svg.svg';
import { ReactComponent as CheckMarkIconSvg } from 'assets/images/location/check-mark-icon-svg.svg';

import Button from 'components/App/Button';

import styles from './ModalAddLocation.module.scss';

function ModalAddLocation(props) {
  const {
    isActiveModalAddLocation,
    tabModalActive,

    onToggleAddLocation,
    onClickTabModalActive,
    onSubmitModal,
  } = props;

  const CloseButton = useMemo(() => (
    <button className={styles.ButtonClose} onClick={onToggleAddLocation} type="button">
      <CloseButtonSvg />
    </button>
  ));

  return (
    <div className={styles.Component}>
      <Modal
        isOpen={isActiveModalAddLocation}
        toggle={onToggleAddLocation}
        size="lg"
        fade
      >
        <ModalHeader
          className={styles.ModalHeader}
          toggle={onToggleAddLocation}
          close={CloseButton}
        />

        <ModalBody className={styles.ModalBody}>
          <div className="Container">
            <Col
              md="6"
              className={`Section ${tabModalActive === MODAL_ADD_LOCATION_TAB.NEW ? 'Active' : ''}`}
              onClick={onClickTabModalActive(MODAL_ADD_LOCATION_TAB.NEW)}
            >
              <Row className="Section__Container">
                <AddNewLocationSvg className="Section__Icon" />

                <Label className="Section__Label">Thêm chỗ nghỉ mới</Label>

                <div className="Section__Subtitle">
                  Quý vị sẽ được dẫn đến trình tạo chỗ nghỉ mới
                  và được tự động thêm vào danh sách sau khi hoàn thành
                </div>
              </Row>

              {tabModalActive === MODAL_ADD_LOCATION_TAB.NEW && (
                <CheckMarkIconSvg className="Section__CheckMark" />
              )}
            </Col>

            <Col
              md="6"
              className={`Section ${tabModalActive === MODAL_ADD_LOCATION_TAB.ALREADY ? 'Active' : ''}`}
              onClick={onClickTabModalActive(MODAL_ADD_LOCATION_TAB.ALREADY)}
            >
              <Row className="Section__Container">
                <AddAlreadyLocationSvg className="Section__Icon" />

                <Label className="Section__Label">Thêm chỗ nghỉ đã có</Label>

                <div className="Section__Subtitle">
                  Quý vị sẽ nhập ID khách sạn đã có sẵn trên hệ thống của Bookese
                  để thực hiện liên kết với tài khoản
                </div>
              </Row>

              {tabModalActive === MODAL_ADD_LOCATION_TAB.ALREADY && (
                <CheckMarkIconSvg className="Section__CheckMark" />
              )}
            </Col>
          </div>
        </ModalBody>

        <ModalFooter className={styles.ModalFooter}>
          <div className="Container">
            <Button
              classes="Button Button__Cancel"
              onClick={onToggleAddLocation}
            >
              Hủy
            </Button>

            <Button
              classes="Button Button__Submit"
              onClick={onSubmitModal(tabModalActive)}
            >
              Xác nhận
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
}

ModalAddLocation.propTypes = {
  isActiveModalAddLocation: PropTypes.bool.isRequired,
  tabModalActive: PropTypes.string,

  onToggleAddLocation: PropTypes.func.isRequired,
  onClickTabModalActive: PropTypes.func,
  onSubmitModal: PropTypes.func,
};

ModalAddLocation.defaultProps = {
  tabModalActive: '',

  onClickTabModalActive: () => null,
  onSubmitModal: () => null,
};

export default memo(ModalAddLocation);
