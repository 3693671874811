import { connect } from 'react-redux';
import { actionRegister } from 'store/actions';

import Register from './Register';

const mapStateToProps = (state) => ({
  me: state.Profile.me,

  registerLoading: state.Register.loading,
  registerError: state.Register.error,
});

const mapDispatchToProps = (dispatch) => ({
  actionRegister: (payload) => dispatch(actionRegister(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Register);
