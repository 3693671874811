import React, { memo, useCallback } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Table } from 'reactstrap';

import images from 'assets/images';
import { ReactComponent as SortIconSvg } from 'assets/images/location/sort-icon-svg.svg';
import { LOCATIONS } from 'constants/index';

import Button from 'components/App/Button';

import styles from './DataTableLocations.module.scss';

function DataTableLocations(props) {
  const {
    locations,
    statusProgressData,
  } = props;

  const ButtonType = useCallback((progress, locationId) => {
    if (progress) {
      return (
        <div className="d-flex">
          <Button
            outline
            classes="Action__Button Action__Button__Danger"
          >
            Xóa
          </Button>

          <Button
            outline
            classes="Action__Button Action__Button__Link"
          >
            Duyệt
          </Button>

          <Link to={`${LOCATIONS.LOCATION_DETAIL}?place=${locationId}`}>
            <Button
              outline
              classes="Action__Button Action__Button__Link"
            >
              Xem chi tiết
            </Button>
          </Link>
        </div>
      );
    }

    return (
      <div className="d-flex">
        <Button
          outline
          classes="Action__Button Action__Button__Danger"
        >
          Xóa
        </Button>

        <Link to={`${LOCATIONS.LOCATION_DETAIL}?place=${locationId}`}>
          <Button
            outline
            classes="Action__Button Action__Button__Link"
          >
            Xem chi tiết
          </Button>
        </Link>
      </div>
    );
  }, []);

  return (
    <div className={styles.Component}>
      <Table
        responsive
        className="Table"
      >
        <thead className="Table__Header">
          <tr className="Table__Header__Row">
            <th className="col-md-2 Table__Header__Row__Content">
              Id
              <SortIconSvg />
            </th>

            <th className="col-md-3 Table__Header__Row__Content">Tên chỗ nghỉ</th>

            <th className="col-md-2 Table__Header__Row__Content">Vị trí</th>

            <th className="col-md-2 Table__Header__Row__Content">Trạng thái</th>

            <th className="col-md-3 Table__Header__Row__Content">Khách đến hôm nay</th>
          </tr>
        </thead>

        <tbody className="Table__Body">
          {locations.map((location, index) => (
            <tr className="Table__Body__Row" key={location.id}>
              <td className="Table__Body__Row__Content">
                {index + 1}

                <div className="Action">
                  {ButtonType(location.statusProgress, location._id)}
                </div>
              </td>

              <td className="Table__Body__Row__Content">
                <div className="d-flex ">
                  <img
                    src={images.locationImage.default}
                    alt="location-img"
                    className="Table__Body__Row__Content__Image"
                  />

                  <div>
                    <Link to={`${LOCATIONS.LOCATION_DETAIL}?place=${location._id}`} className="mb-5">
                      {location.name}
                    </Link>

                    {location.statusProgress && (
                      <span className={`Progress
                        ${statusProgressData.PENDING.value === location.statusProgress
                        ? 'Progress__Pending'
                        : 'Progress__Removed'
                        }`}
                      >
                        {statusProgressData.PENDING.value === location.statusProgress
                          ? (
                            statusProgressData.PENDING.label
                          ) : (
                            statusProgressData.REMOVED.label
                          )}
                      </span>
                    )}
                  </div>
                </div>
              </td>

              <td className="Table__Body__Row__Content">
                {location.address}
              </td>

              <td className="Table__Body__Row__Content">
                Mở/Có thể đặt phòng
              </td>

              <td className="Table__Body__Row__Content">
                {location.totalRooms}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}

DataTableLocations.propTypes = {
  locations: PropTypes.instanceOf(Array),
  statusProgressData: PropTypes.instanceOf(Object),
};

DataTableLocations.defaultProps = {
  locations: [],
  statusProgressData: {},
};

export default memo(DataTableLocations);
