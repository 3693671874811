import { connect } from 'react-redux';

import {
  actionGetMediaDetail,
  actionUpdateMedia,
  actionDeleteMedia,
  actionClearMediaDetail,
} from 'store/actions';

import MediaDetail from './MediaDetail';

const mapStateToProps = (state) => ({
  me: state.Profile.me,

  mediaDetail: state.Media.mediaDetail,
  mediaLoadingDetail: state.Media.loadingDetail,
  mediaLoadingUpdate: state.Media.loadingUpdate,
});

const mapDispatchToProps = (dispatch) => ({
  actionGetMediaDetail: (payload) => dispatch(actionGetMediaDetail(payload)),

  actionUpdateMedia: (payload) => dispatch(actionUpdateMedia(payload)),
  actionDeleteMedia: (payload) => dispatch(actionDeleteMedia(payload)),
  actionClearMediaDetail: () => dispatch(actionClearMediaDetail()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MediaDetail);
