import React, {
  useState, useCallback, useMemo, useEffect,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Container } from 'reactstrap';

import { LOCATIONS } from 'constants/index';

import AppMeta from 'components/App/Meta';
import AppBreadcrumb from 'components/App/Breadcrumb';
import RoomCard from 'components/DashboardPage/Location/RoomCard';
import ModalSuccess from 'components/Modals/ModalSuccess';

import { ReactComponent as AddRoomButtonSVG } from 'assets/images/queue.svg';

import styles from './TypeRoom.module.scss';

const breadcrumbList = [
  {
    name: 'Chỗ nghỉ',
    isActive: false,
    link: LOCATIONS.LOCATION_DETAIL,
  },

  {
    name: 'Loại phòng',
    isActive: true,
    link: LOCATIONS.LOCATION_ROOM_TYPE,
  },
];

function TypeRoom() {
  const [isOpenModalSuccess, setIsOpenModalSuccess] = useState(false);

  const history = useHistory();
  const location = useLocation();

  const createStatus = useMemo(() => new URLSearchParams(location.search).get('createStatus') || '', [location]);

  const onClickCreateNewRoom = useCallback(() => {
    history.push(LOCATIONS.LOCATION_CREATE_ROOM);
  }, []);

  const onToggleModalSuccess = useCallback(() => {
    setIsOpenModalSuccess((prev) => !prev);
  }, []);

  useEffect(() => {
    if (createStatus === 'success') {
      setIsOpenModalSuccess(true);

      // setTimeout(() => {
      //   setIsOpenModalSuccess(false);
      // }, 5000);
    }
    return null;
  }, [createStatus, setIsOpenModalSuccess]);

  return (
    <div className="page-content">
      <div className={styles.Page}>
        <AppMeta name="Loại phòng" />

        <Container fluid>
          <div className="Breadcrumb">
            <AppBreadcrumb
              pageTitle="Loại phòng"
              breadcrumbList={breadcrumbList}
            />
          </div>

          <div className="row">

            <div className="col-6 col-lg-3">
              <RoomCard />
            </div>

            <div className="col-6 col-lg-3">
              <RoomCard />
            </div>

            <div className="col-6 col-lg-3">
              <RoomCard />
            </div>

            <div className="col-6 col-lg-3">
              <RoomCard />
            </div>

            <div className="col-6 col-lg-3">
              <RoomCard />
            </div>

            <div className="col-6 col-lg-3">
              <div className="CardAdd">
                <button type="button" onClick={onClickCreateNewRoom}>
                  <div>
                    <AddRoomButtonSVG />
                  </div>

                  <span> TẠO PHÒNG MỚI </span>
                </button>
              </div>
            </div>
          </div>

        </Container>

        <ModalSuccess
          isOpen={isOpenModalSuccess}
          onToggleModal={onToggleModalSuccess}
        />
      </div>
    </div>
  );
}

export default TypeRoom;
