import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Label,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';

import images from 'assets/images';

import styles from './Authentication.module.scss';

function Authentication(props) {
  const {
    title,
    description,

    children,
  } = props;
  const { t } = useTranslation();

  return (
    <div className={styles.Component}>

      <div className="account-pages row">
        <div className="Background">
          <img
            src={images.authBackground.default}
            alt="background-admin-login"
            className="Background__Image"
          />
        </div>

        <div className="Auth-Form-Container">
          <div className="Auth-Form-Container__Logo">
            <img
              src={images.logoBookeseFull.default}
              alt="logo-admin-login"
            />
          </div>

          <div className="Auth-Form">
            <div className="Auth-Form__Header">
              <p className="Auth-Form__Header__Title">
                {t(title)}
              </p>

              <p className="Auth-Form__Header__Description">
                {t(description)}
              </p>
            </div>

            {children}

          </div>

          <div className="Auth-Form__Footer">
            <Label className="Auth-Form__Footer__Terms">
              Qua việc đăng nhập, bạn đồng ý với các
              {' '}
              <Link to="/temp">Điều khoản và Điều kiện</Link>
              {' '}
              cũng như
              {' '}
              <Link to="/temp">Chính sách An toàn và Bảo mật</Link>
              {' '}
              của chúng tôi
            </Label>

            <Label className="Auth-Form__Footer__Created">
              © 2022 Bookese. Design and Develop by Wiicamp
            </Label>
          </div>
        </div>
      </div>
    </div>
  );
}

Authentication.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,

  children: PropTypes.node.isRequired,
};

export default Authentication;
