import {
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILED,
} from './actionTypes';

// FORGOT PASSWORD
export const actionForgotPassword = (payload) => ({
  type: FORGOT_PASSWORD,
  payload,
});

export const actionForgotPasswordSuccess = () => ({
  type: FORGOT_PASSWORD_SUCCESS,
});

export const actionForgotPasswordFailed = () => ({
  type: FORGOT_PASSWORD_FAILED,
});
