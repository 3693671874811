import React, { memo, useCallback, useState } from 'react';
// import PropTypes from 'prop-types';
import {
  Card,
  CardBody,
  Row,
  CardSubtitle,
  CardTitle,
} from 'reactstrap';
import * as Yup from 'yup';
import { useFormik } from 'formik';

import AppButton from 'components/App/Button';
import AppSelectValidation from 'components/App/SelectValidation';
import FormField from 'components/AddLocation/FormField';
// import ModalEditServiceLocation from 'components/Modals/ModalEditServiceLocation';
import SectionWrapper from 'components/AddLocation/SectionWrapper';
import ModalEditFacilityService from 'components/Modals/ModalEditFacilityService';

import { stepThreeValidation } from 'utils/validate';
import { FACILITIES_SERVICES } from 'constants/fakeRooms';

import styles from './StepThree.module.scss';

const optionsLanguage = [
  {
    label: 'Tiếng Việt',
    value: 'Vietnamese',
  },
  {
    label: 'Tiếng Anh',
    value: 'English',
  },
  {
    label: 'Tiếng Tây Ban Nha',
    value: 'Spain',
  },
  {
    label: 'Tiếng Trung Quốc',
    value: 'Chinese',
  },
  {
    label: 'Tiếng Hàn Quốc',
    value: 'Korea',
  },
];

function StepThree() {
  const [isOpenModalStep3, setIsOpenModalStep3] = useState(false);
  const [editData, setEditData] = useState({});

  const validation = useFormik({
    initialValues: {
      language: optionsLanguage[0].value,
    },

    validationSchema: Yup.object({
      language: stepThreeValidation.language,
    }),

    onSubmit: () => {

    },
  });

  const RenderServiceList = useCallback((groupServices) => {
    const activeList = [];
    groupServices.map((item) => item.isActive && activeList.push(item.name));

    return activeList.join(', ');
  }, []);

  const onChangeLanguage = useCallback((selected) => {
    validation.setFieldValue('language', selected, false);
  }, [validation]);

  const onToggleModalStep3 = useCallback((service) => () => {
    setIsOpenModalStep3((prevState) => !prevState);
    setEditData(service);
  }, []);

  const onToggleService = useCallback((id) => () => {
    const newServiceGroup = editData.groupServices.map((item) => {
      const service = { ...item };

      if (service._id === id) {
        service.isActive = !item.isActive;
      }

      return service;
    });

    setEditData({
      ...editData,
      groupServices: newServiceGroup,
    });
  }, [editData]);

  const onToggleAllService = useCallback((status) => () => {
    const newServiceGroup = editData.groupServices.map((item) => ({
      ...item,
      isActive: status,
    }));

    setEditData({
      ...editData,
      groupServices: newServiceGroup,
    });
  }, [editData]);

  return (
    <div className={styles.Component}>
      <SectionWrapper
        isRequired
        title="Ngôn ngữ được sử dụng"
      >
        <FormField isHiddenLabel>
          <AppSelectValidation
            placeholder="Chọn ngôn ngữ"
            name="language"
            key="language"
            options={optionsLanguage}
            value={validation.values.language}
            validation={validation}
            onChange={onChangeLanguage}
            isMulti
            isHiddenClearable
          />
        </FormField>
      </SectionWrapper>

      <Card className="Section">
        <CardBody className="Section__Information">
          {FACILITIES_SERVICES.map((service) => (
            <Row className="Section__Information__Field" key={service.title}>
              <CardTitle className="Section__Information__Field__Title">
                {service.title}
              </CardTitle>

              <CardSubtitle className="Section__Information__Field__Subtitle">
                {
                  service.isActive ? (
                    RenderServiceList(service.groupServices)
                  ) : '-'
                }
              </CardSubtitle>

              <AppButton
                outline
                classes="Section__Information__Field__Button__Edit"
                onClick={onToggleModalStep3(service)}
              >
                Chỉnh sửa
              </AppButton>
            </Row>
          ))}
        </CardBody>
      </Card>

      <ModalEditFacilityService
        isOpen={isOpenModalStep3}
        facilityService={editData}
        onToggleModal={onToggleModalStep3()}
        onToggleService={onToggleService}
        onToggleAllService={onToggleAllService}
      />
    </div>
  );
}

StepThree.propTypes = {

};

StepThree.defaultProps = {

};

export default memo(StepThree);
