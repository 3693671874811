import React, {
  useCallback,
  useMemo,
} from 'react';
import {
  Row,
  Col,
  Input,
  InputGroup,
  Button,
} from 'reactstrap';
import { useFormik } from 'formik';

import AppInputValidation from 'components/App/InputValidation';
import AppInputNumberValidation from 'components/App/InputNumberValidation';
import AppSelectValidation from 'components/App/SelectValidation';
import FormField from 'components/AddLocation/FormField';
import FormFieldCheckbox from 'components/AddLocation/FormFieldCheckbox';
import FormFieldWithLine from 'components/AddLocation/FormFieldWithLine';
import SectionWrapper from 'components/AddLocation/SectionWrapper';

import styles from './StepTwo.module.scss';

const value = [
  { value: 'giang', label: 'giang' },
  { value: 'mango', label: 'mango' },
  { value: 'mango', label: 'mango' },
  { value: 'mango', label: 'mango' },
];

const value2 = [
  { value: 1, label: 1 },
  { value: 2, label: 2 },
  { value: 3, label: 3 },
  { value: 4, label: 4 },
];

const CHANGE_QUANTITY_STATUS = {
  INCREASE: 'INCREASE',
  REDUCE: 'REDUCE',
};

function StepTwo() {
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      typeRoom: '',
      type: '',
      amountRoom: 1,
      amountBedRoom: 1,
      amountLivingRoom: 1,
      amountBathRoom: 1,
      amountBed: value2[0].value,
      maxAdult: 1,
      maxChild: 1,
    },
  });

  const onChangeOption = useCallback((name) => (e) => {
    validation.setFieldValue(name, e.value, true);
  }, []);

  const totalGuestValue = useMemo(() => {
    const totalGuest = validation.values.maxAdult + validation.values.maxChild;

    return totalGuest;
  }, [validation.values]);

  const updateAmount = useCallback((status, name, valueAmount) => () => {
    if (status === CHANGE_QUANTITY_STATUS.INCREASE) {
      if (totalGuestValue < 20) {
        validation.setFieldValue(name, valueAmount + 1, false);
      }
    } else if (status === CHANGE_QUANTITY_STATUS.REDUCE) {
      if (valueAmount > 0) {
        validation.setFieldValue(name, valueAmount - 1, false);
      }
    }
  }, [totalGuestValue]);

  return (
    <div className={styles.Component}>
      <SectionWrapper title="Quý vị cần ít nhất một phòng để đăng tải chỗ nghỉ">
        {/* <FormField label="Loại phòng" isRequired>

          <AppSelectValidation placeholder="Phòng giường đôi" options={value} />
        </FormField> */}

        <FormField label="Tên phòng" isRequired>
          <AppSelectValidation placeholder="Vui lòng chọn" options={value} subTitle="Đây là tên mà khách sẽ thấy trên trang web Bookesecom." />
        </FormField>

        <FormField label="Tên tùy chọn (không bắt buộc)">
          <AppInputValidation
            name="nameLocation"
            id="nameLocation"
            type="text"
            subTitle="Tạo tên tùy chọn cho riêng Quý vị tham khảo (không bắt buộc)."
            placeholder="Nhập tên tuỳ chọn"
            validation={validation}
            isShowCounter
          />
        </FormField>

        <Row>
          <Col md="6">
            <FormField label="Số phòng (loại này)" isRequired>
              <AppInputNumberValidation
                subTitle="Trong số 38 phòng tại chỗ nghỉ của Quý vị"
                name="amountRoom"
                keyName="amountRoom"
                validation={validation}
              />
            </FormField>
          </Col>

          <Col md="6">
            <FormField label="Số phòng ngủ">
              <AppInputNumberValidation
                name="amountBedRoom"
                keyName="amountBedRoom"
                validation={validation}
              />
            </FormField>
          </Col>

          <Col md="6" className="mt-24">
            <FormField label="Số phòng khách">
              <AppInputNumberValidation
                name="amountLivingRoom"
                keyName="amountLivingRoom"
                validation={validation}
              />
            </FormField>
          </Col>

          <Col md="6" className="mt-24">
            <FormField label="Số phòng tắm">
              <AppInputNumberValidation
                name="amountBathRoom"
                keyName="amountBathRoom"
                validation={validation}
              />
            </FormField>
          </Col>

        </Row>
      </SectionWrapper>

      <SectionWrapper title="Tùy chọn giường">
        <FormFieldCheckbox label="Chọn loại giường" isRequired>
          <Row>
            <Col md="4" className="d-flex align-items-center mb-12 ">
              <Input type="checkbox" id="checkbox1" onChange={() => { }} />

              <label className="CheckBoxLabel" htmlFor="checkbox1">Giường đơn (1m2 x 2m)</label>
            </Col>

            <Col md="4" className="d-flex align-items-center mb-12">
              <Input type="checkbox" id="checkbox2" onChange={() => { }} />

              <label className="CheckBoxLabel" htmlFor="checkbox2">Giường đôi (1m4 x 2m)</label>
            </Col>

            <Col md="4" className="d-flex align-items-center mb-12">
              <Input type="checkbox" id="checkbox3" onChange={() => { }} />

              <label className="CheckBoxLabel" htmlFor="checkbox3">Giường Queen (1m6 x 2m)</label>
            </Col>

            <Col md="4" className="d-flex align-items-center mb-12">
              <Input type="checkbox" id="checkbox4" onChange={() => { }} />

              <label className="CheckBoxLabel" htmlFor="checkbox4">Giường King (1m8 x 2m)</label>
            </Col>

            <Col md="4" className="d-flex align-items-center mb-12">
              <Input type="checkbox" id="checkbox5" onChange={() => { }} />

              <label className="CheckBoxLabel" htmlFor="checkbox5">Giường Super King (2m x 2m2)</label>
            </Col>

          </Row>
        </FormFieldCheckbox>

        <FormField label="Số lượng giường" isRequired>
          <AppSelectValidation
            options={value2}
            name="amountBed"
            keyName="amountBed"
            onChange={onChangeOption('amountBed')}
            validation={validation}
            value={validation.values.amountBed}
          />
        </FormField>
      </SectionWrapper>

      <SectionWrapper title="Tổng sức chứa">
        <FormFieldWithLine label="Số người lớn tối đa" isRequired>
          <InputGroup className="InputGroup">
            <Button
              type="button"
              color="primary"
              className="InputGroup__Btn InputGroup__Btn--Decrease"
              onClick={updateAmount(CHANGE_QUANTITY_STATUS.REDUCE, 'maxAdult', validation.values.maxAdult)}
            >
              <i className="mdi mdi-minus" />
            </Button>

            <input
              type="number"
              name="parent"
              className="form-control InputGroup__Input"
              value={validation.values.maxAdult}
              placeholder="number"
              readOnly
            />

            <Button
              type="button"
              color="primary"
              className="InputGroup__Btn InputGroup__Btn--Increase"
              onClick={updateAmount(CHANGE_QUANTITY_STATUS.INCREASE, 'maxAdult', validation.values.maxAdult)}
            >
              <i className="mdi mdi-plus" />
            </Button>
          </InputGroup>
        </FormFieldWithLine>

        <FormFieldWithLine label="Số trẻ em tối đa" isRequired>
          <InputGroup className="InputGroup">
            <Button
              type="button"
              color="primary"
              className="InputGroup__Btn InputGroup__Btn--Decrease"
              onClick={updateAmount(CHANGE_QUANTITY_STATUS.REDUCE, 'maxChild', validation.values.maxChild)}
            >
              <i className="mdi mdi-minus" />
            </Button>

            <input
              type="number"
              name="child"
              className="form-control InputGroup__Input"
              value={validation.values.maxChild}
              placeholder="number"
              readOnly
            />

            <Button
              type="button"
              color="primary"
              className="InputGroup__Btn InputGroup__Btn--Increase"
              onClick={updateAmount(CHANGE_QUANTITY_STATUS.INCREASE, 'maxChild', validation.values.maxChild)}
            >
              <i className="mdi mdi-plus" />
            </Button>
          </InputGroup>
        </FormFieldWithLine>

        <FormFieldWithLine label="Tổng số khách tối đa" isRequired subTitle="Tối đa 20 khách">
          <input type="text" value={totalGuestValue} disabled className="text-center" />
        </FormFieldWithLine>
      </SectionWrapper>

      <SectionWrapper title="Giá cơ bản mỗi đêm" isRequired>
        <FormField isHiddenLabel>
          <AppInputNumberValidation
            name="nameLocation"
            id="nameLocation"
            type="text"
            placeholder="Nhập số tiền"
            validation={validation}
            showCurrency="VNĐ"
          />
        </FormField>

        <div className="Description">
          Giá đã bao gồm 5% hoa hồng từ hệ thống
        </div>
      </SectionWrapper>
    </div>
  );
}

export default StepTwo;
