import {
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILED,
} from './actionTypes';

// RESET PASSWORD
export const actionResetPassword = (payload) => ({
  type: RESET_PASSWORD,
  payload,
});

export const actionResetPasswordSuccess = () => ({
  type: RESET_PASSWORD_SUCCESS,
});

export const actionResetPasswordFailed = () => ({
  type: RESET_PASSWORD_FAILED,
});
