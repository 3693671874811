import React, {
  useMemo, memo, useCallback, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  ModalBody,
  ModalHeader,
  Label,
  Row,
  Col,
  Input,
  ModalFooter,
} from 'reactstrap';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { get } from 'lodash';

import FormField from 'components/AddLocation/FormField';

import { ReactComponent as CloseButtonSvg } from 'assets/images/location/close-button-svg.svg';
import { editInformationLocateValidation } from 'utils/validate';

import AppInputValidation from 'components/App/InputValidation';
import Button from 'components/App/Button';
import SelectValidation from 'components/App/SelectValidation';

import styles from './ModalEditLocation.module.scss';

const optionList = [
  {
    label: 'Lựa chọn 1',
    value: 'option1',
  },
  {
    label: 'Lựa chọn 2',
    value: 'option2',
  },
  {
    label: 'Lựa chọn 3',
    value: 'option3',
  },
];

function ModalEditLocation(props) {
  const {
    isOpenModalEditLocation,

    onToggleModal,
  } = props;

  const validation = useFormik({
    initialValues: {
      name: '',
      type: '',
      rankStar: '',
      city: '',
      district: '',
      ward: '',
      address: '',
      gps: '',
      description: '',
    },

    validationSchema: Yup.object({
      name: editInformationLocateValidation.name,
      type: editInformationLocateValidation.type,
      rankStar: editInformationLocateValidation.rankStar,
      city: editInformationLocateValidation.city,
      district: editInformationLocateValidation.district,
      ward: editInformationLocateValidation.ward,
      address: editInformationLocateValidation.address,
      gps: editInformationLocateValidation.gps,
      description: editInformationLocateValidation.description,
    }),

    onSubmit: () => {

    },
  });

  const onChangeOption = useCallback((name) => (e) => {
    validation.setFieldValue(name, e.value, false);
  }, []);

  const CloseButton = useMemo(() => (
    <button className={styles.ButtonClose} onClick={onToggleModal} type="button">
      <CloseButtonSvg />
    </button>
  ));

  useEffect(() => {
    if (!isOpenModalEditLocation) {
      validation.resetForm();
    }
  }, [isOpenModalEditLocation]);

  return (
    <div className={styles.Component}>
      <Modal
        fade
        size="lg"
        isOpen={isOpenModalEditLocation}
        toggle={onToggleModal}
        className={styles.Modal}
      >
        <ModalHeader
          toggle={onToggleModal}
          className={styles.ModalHeader}
          close={CloseButton}
        />

        <ModalBody className={styles.ModalBody}>
          <Label className="Label">
            Điền thông tin Chỗ nghỉ của Quý vị
          </Label>

          <hr className="Divider" />

          <FormField
            isHiddenLabel
          >

            {/* Name */}
            <Row className="FormField">
              <Col
                md="4"
                className="FormField__Title"
              >
                Tên chỗ nghỉ
              </Col>

              <Col
                md="8"
                className="FormField__Input__Wrapper"
              >
                <AppInputValidation
                  isShowCounter
                  name="name"
                  id="name"
                  type="text"
                  placeholder="Nhập vào chỗ nghỉ"
                  validation={validation}
                />
              </Col>
            </Row>

            {/* Type */}
            <Row className="FormField">
              <Col
                md="4"
                className="FormField__Title"
              >
                Loại hình
              </Col>

              <Col
                md="8"
                className="FormField__Input__Wrapper"
              >
                <SelectValidation
                  name="type"
                  keyName="type"
                  placeholder="Chọn loại hình"
                  options={optionList}
                  validation={validation}
                  onChange={onChangeOption('type')}
                  value={validation.values.type}
                  fontSize="13px"
                />
              </Col>
            </Row>

            {/* Rank Star */}
            <Row className="FormField">
              <Col
                md="4"
                className="FormField__Title"
              >
                Hạng sao
              </Col>

              <Col
                md="8"
                className="FormField__Input__Wrapper"
              >
                <SelectValidation
                  name="rankStar"
                  keyName="rankStar"
                  placeholder="Chọn hạng sao"
                  options={optionList}
                  validation={validation}
                  onChange={onChangeOption('rankStart')}
                  value={validation.values.rankStart}
                  fontSize="13px"
                />
              </Col>
            </Row>

            {/* City  */}
            <Row className="FormField">
              <Col
                md="4"
                className="FormField__Title"
              >
                Tỉnh/ Thành phố
              </Col>

              <Col
                md="8"
                className="FormField__Input__Wrapper"
              >
                <SelectValidation
                  name="city"
                  keyName="city"
                  placeholder="Chọn thành phố"
                  options={optionList}
                  validation={validation}
                  onChange={onChangeOption('city')}
                  value={validation.values.city}
                  fontSize="13px"

                />
              </Col>
            </Row>

            {/* District  */}
            <Row className="FormField">
              <Col
                md="4"
                className="FormField__Title"
              >
                Quận/ Huyện
              </Col>

              <Col
                md="8"
                className="FormField__Input__Wrapper"
              >
                <SelectValidation
                  name="district"
                  keyName="district"
                  placeholder="Chọn Quận/ Huyện"
                  options={optionList}
                  validation={validation}
                  onChange={onChangeOption('district')}
                  value={validation.values.district}
                  fontSize="13px"
                />
              </Col>
            </Row>

            {/* Ward  */}
            <Row className="FormField">
              <Col
                md="4"
                className="FormField__Title"
              >
                Phường/ Xã
              </Col>

              <Col
                md="8"
                className="FormField__Input__Wrapper"
              >
                <SelectValidation
                  name="ward"
                  keyName="ward"
                  placeholder="Chọn Phường/ Xã"
                  options={optionList}
                  validation={validation}
                  onChange={onChangeOption('ward')}
                  value={validation.values.ward}
                  fontSize="13px"
                />
              </Col>
            </Row>

            {/* Address  */}
            <Row className="FormField">
              <Col
                md="4"
                className="FormField__Title"
              >
                Địa chỉ
              </Col>

              <Col
                md="8"
                className="FormField__Input__Wrapper"
              >
                <AppInputValidation
                  isShowCounter
                  name="address"
                  id="address"
                  type="text"
                  placeholder="Nhập vào địa chỉ"
                  validation={validation}
                />
              </Col>
            </Row>

            {/* GPS  */}
            <Row className="FormField">
              <Col
                md="4"
                className="FormField__Title"
              >
                Định vị
              </Col>

              <Col
                md="8"
                className="FormField__Input__Wrapper"
              >
                <Input
                  className="FormField__Input"
                  name="gps"
                  onBlur={validation.handleBlur}
                  onChange={validation.handleChange}
                  placeholder="Nhập vào định vị"
                  value={get(validation.values, 'gps', '')}
                />

                <span className="FormField__Pick">
                  Chọn trên
                  {' '}
                  <span className="FormField__Pick__Blue">Google Map</span>
                </span>
              </Col>
            </Row>

            {/* Description  */}
            <Row className="FormField">
              <Col
                md="4"
                className="FormField__Title"
              >
                Miêu tả chỗ nghỉ
              </Col>

              <Col
                md="8"
                className="FormField__Input__Wrapper"
              >
                <Input
                  className="FormField__Input FormField__Textarea"
                  name="description"
                  type="textarea"
                  onBlur={validation.handleBlur}
                  onChange={validation.handleChange}
                  placeholder="Nhập vào mô tả"
                  value={get(validation.values, 'description', '')}
                  maxLength={1000}
                />
              </Col>

              <div className="FormField__Textarea__Counter">
                {`${validation.values.description.length}/1000`}
              </div>
            </Row>
          </FormField>
        </ModalBody>

        <ModalFooter className={styles.ModalFooter}>
          <div className="d-flex m-0">
            <Button
              classes="Button Button__Cancel"
              onClick={onToggleModal}
            >
              Hủy
            </Button>

            <Button
              classes=" Button Button__Submit"
              type="submit"
            >
              Gửi
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
}

ModalEditLocation.propTypes = {
  isOpenModalEditLocation: PropTypes.bool.isRequired,

  onToggleModal: PropTypes.func.isRequired,
};

ModalEditLocation.defaultProps = {

};

export default memo(ModalEditLocation);
