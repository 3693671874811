import React, { memo, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Modal, ModalBody, ModalFooter, ModalHeader,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';

import AppButton from 'components/App/Button';
import AppLoading from 'components/App/Loading';

const ModalGeneral = (props) => {
  const {
    centered,
    isEdit,
    isEditSubmit,
    isOpen,
    loading,
    visibleBtnAccept,
    visibleDetail,
    visibleFooter,
    visibleTitle,

    backdrop,
    btnAcceptText,
    btnEditText,
    btnCancelText,
    className,
    size,
    title,
    id,

    onToggleModal,
    onBtnAccept,
    onBtnEdit,

    children,
  } = props;

  const { t } = useTranslation();

  const onClickButton = useCallback(() => onToggleModal(), []);

  const BtnAccept = useMemo(() => (
    loading ? <AppButton loading={loading} />
      : (
        <Button
          type="submit"
          className="btn"
          color="primary"
          onClick={onBtnAccept}
          disabled={isEditSubmit}
        >
          {t(btnAcceptText)}
        </Button>
      )
  ), [loading, onBtnAccept, isEditSubmit, btnAcceptText]);

  const BtnEdit = (
    loading ? <AppButton loading={loading} />
      : (
        <Button
          type="submit"
          className="btn"
          color="primary"
          onClick={onBtnEdit}
          disabled={isEditSubmit}
        >
          <i className="mdi mdi-pencil mr-2" />
          {' '}
          {t(btnEditText)}
        </Button>
      )
  );

  return (
    <div>
      <Modal
        size={size}
        isOpen={isOpen}
        toggle={onToggleModal}
        backdrop={backdrop}
        centered={centered}
        scrollable
        id={id}
        className={`position-relative ${className}`}
      >
        <AppLoading loading={loading} loaderOnPage />

        {
          visibleTitle
            && (<ModalHeader toggle={!loading ? onClickButton : () => { }}>{t(title)}</ModalHeader>)
        }

        <ModalBody className={`position-relative modal-body ${isEdit ? 'disable-event' : ''}`}>
          {children}
        </ModalBody>

        {
          visibleFooter && (
            <ModalFooter className="modal-footer">
              <Button
                disabled={loading}
                type="button"
                className="btn"
                color="light"
                onClick={onClickButton}
              >
                {btnCancelText}
              </Button>

              {
                visibleBtnAccept && BtnAccept
              }
            </ModalFooter>
          )
        }

        {visibleDetail && (
          <ModalFooter className="modal-footer">
            {visibleBtnAccept && BtnEdit}
          </ModalFooter>
        )}
      </Modal>
    </div>
  );
};

ModalGeneral.propTypes = {
  size: PropTypes.string,
  className: PropTypes.string,
  btnAcceptText: PropTypes.string,
  btnEditText: PropTypes.string,
  btnCancelText: PropTypes.string,
  backdrop: PropTypes.string,
  title: PropTypes.string,
  id: PropTypes.string,

  isOpen: PropTypes.bool,
  isEdit: PropTypes.bool,
  isEditSubmit: PropTypes.bool,
  loading: PropTypes.bool,
  centered: PropTypes.bool,
  visibleTitle: PropTypes.bool,
  visibleFooter: PropTypes.bool,
  visibleDetail: PropTypes.bool,
  visibleBtnAccept: PropTypes.bool,

  onToggleModal: PropTypes.func,
  onBtnAccept: PropTypes.func,
  onBtnEdit: PropTypes.func,

  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any,
};

ModalGeneral.defaultProps = {
  centered: false,
  isEdit: false,
  isEditSubmit: false,
  isOpen: false,
  loading: false,
  visibleBtnAccept: true,
  visibleDetail: false,
  visibleFooter: true,
  visibleTitle: true,

  backdrop: 'static',
  btnAcceptText: 'Xác nhận',
  btnEditText: 'button.update',
  btnCancelText: 'Hủy',
  children: '',
  className: '',
  size: 'md',
  title: 'Title header',
  id: 'staticBackdrop',

  onToggleModal: () => { },
  onBtnAccept: () => { },
  onBtnEdit: () => { },
};

export default memo(ModalGeneral);
