import locationApi from 'api/locationApi';
import { put, takeLeading } from 'redux-saga/effects';

import { apiErrorHandler } from 'utils';

import {
  actionGetRoomStatusList,
  actionGetRoomStatusListFailed,
  actionGetRoomStatusListSuccess,
  actionUpdateRoomStatusListFailed,
  actionUpdateRoomStatusListSuccess,
} from './actions';
import { GET_ROOM_STATUS_LIST, UPDATE_ROOM_STATUS_LIST } from './actionTypes';

function* getRoomStatusList({ payload }) {
  try {
    const response = yield locationApi.getRoomStatusList(payload);

    yield put(actionGetRoomStatusListSuccess(response.payload));
  } catch (error) {
    apiErrorHandler(error);

    yield put(actionGetRoomStatusListFailed());
  }
}

function* updateRoomStatusList({ payload }) {
  const { data, filter } = payload;

  try {
    const response = yield locationApi.updateRoomStatus(data);

    yield put(actionUpdateRoomStatusListSuccess(response.payload));

    yield put(actionGetRoomStatusList(filter));
  } catch (error) {
    apiErrorHandler(error);

    yield put(actionUpdateRoomStatusListFailed());
  }
}

export default function* roomSaga() {
  yield takeLeading(GET_ROOM_STATUS_LIST, getRoomStatusList);
  yield takeLeading(UPDATE_ROOM_STATUS_LIST, updateRoomStatusList);
}
