import { connect } from 'react-redux';
import { actionGetRoomStatusList } from 'store/location/room/actions';

import Calendar from './Calendar';

const mapStateToProps = (state) => ({
  loadingRoomStatusList: state.RoomStatusList.loadingRoomStatusList,
  roomStatusList: state.RoomStatusList.roomStatusList.rooms,
  priceTypes: state.RoomStatusList.roomStatusList.priceTypes,
});

const mapDispatchToProps = (dispatch) => ({
  actionGetRoomStatusList: (payload) => dispatch(actionGetRoomStatusList(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Calendar);
