import React, { useCallback } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { stepOneValidation } from 'utils/validate';

import AppInputValidation from 'components/App/InputValidation';
import AppSelectValidation from 'components/App/SelectValidation';
import FormField from 'components/AddLocation/FormField';
import SectionWrapper from 'components/AddLocation/SectionWrapper';

import styles from './StepOne.module.scss';

const tempOptions = [
  { value: 'giang', label: 'giang' },
  { value: 'option1', label: 'option1' },
  { value: 'option2', label: 'option2' },
  { value: 'option3', label: 'option3' },
];

function StepOne() {
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      type: '',
      nameLocation: '',
      star: '',
      userName: '',
      phoneNumber: '',
      otherPhoneNumber: '',
      city: '',
      district: '',
      village: '',
      arena: '',
      locate: '',
      address: '',
    },

    validationSchema: Yup.object({
      type: stepOneValidation.type,
      nameLocation: stepOneValidation.nameLocation,
      star: stepOneValidation.star,
      userName: stepOneValidation.userName,
      phoneNumber: stepOneValidation.phoneNumber,
      otherPhoneNumber: stepOneValidation.otherPhoneNumber,
      city: stepOneValidation.city,
      district: stepOneValidation.district,
      village: stepOneValidation.village,
      arena: stepOneValidation.arena,
      locate: stepOneValidation.locate,
      address: stepOneValidation.address,
    }),

    // To Test Submit Form Data

    // onSubmit: (values) => {
    //   const {
    //     type,
    //     nameLocation,
    //     star,
    //     userName,
    //     phoneNumber,
    //     otherPhoneNumber,
    //     city,
    //     district,
    //     village,
    //     arena,
    //     locate,
    //     address,
    //   } = values;

    //   alert(`You submit with data:
    //   Loại hình: ${type},
    //   Tên của chỗ nghỉ: ${nameLocation},
    //   Xếp hạng sao: ${star},
    //   Tên người liên hệ: ${userName},
    //   Số điện thoại liên lạc: ${phoneNumber},
    //   Số điện thoại khác: ${otherPhoneNumber},
    //   Thành phố: ${city},
    //   Quận/ Huyện: ${district},
    //   Phường/ Xã: ${village},
    //   Khu vực: ${arena},
    //   Địa chỉ: ${locate},
    //   Định vị: ${address},
    //   `);
    // },
  });

  const onChangeOption = useCallback((name) => (e) => {
    validation.setFieldValue(name, e.value, true);
  }, []);

  return (
    <div className={styles.Component}>
      <form>
        <SectionWrapper title="Xin chào Account Name!">
          <FormField label="Loại hình" isRequired>
            <AppSelectValidation
              placeholder="Khách sạn"
              name="type"
              key="type"
              options={tempOptions}
              validation={validation}
              onChange={onChangeOption('type')}
              value={validation.values.type}
            />
          </FormField>

          <FormField label="Tên chỗ nghỉ" isRequired>
            <AppInputValidation
              name="nameLocation"
              id="nameLocation"
              type="text"
              subTitle="Tên này sẽ được hiển thị tới khách khi họ tìm kiếm chỗ nghỉ."
              placeholder="Nhập tên tuỳ chọn"
              validation={validation}
              isShowCounter
              limitCharacter={100}
            />
          </FormField>

          <FormField label="Hạng sao" isRequired>
            <AppSelectValidation
              placeholder="Vui lòng chọn hạng sao"
              name="star"
              options={tempOptions}
              validation={validation}
              onChange={onChangeOption('star')}
              value={validation.values.star}
            />
          </FormField>
        </SectionWrapper>

        <SectionWrapper title="Chi tiết liên hệ của chỗ nghỉ là gì?">
          <FormField label="Tên người liên hệ" isRequired>
            <AppInputValidation
              type="text"
              id="userName"
              name="userName"
              placeholder="Nhập Tên người liên hệ chính của chỗ nghỉ"
              validation={validation}
              isShowCounter
              limitCharacter={100}
            />
          </FormField>

          <FormField label="Số điện thoại liên lạc" isRequired>
            <AppInputValidation
              type="text"
              id="phoneNumber"
              name="phoneNumber"
              placeholder="Nhập số điện thoại người liên hệ"
              validation={validation}
            />
          </FormField>

          <FormField label="Số điện thoại khác">
            <AppInputValidation
              type="text"
              id="otherPhoneNumber"
              name="otherPhoneNumber"
              placeholder="Không bắt buộc"
              validation={validation}
            />
          </FormField>
        </SectionWrapper>

        <SectionWrapper title="Chỗ nghỉ tọa lạc ở đâu?">
          <FormField label="Tỉnh/ Thành phố" isRequired>
            <AppSelectValidation
              options={tempOptions}
              placeholder="Vui lòng chọn thành phố"
              value={validation.values.city}
              name="city"
              validation={validation}
              onChange={onChangeOption('city')}
            />
          </FormField>

          <FormField label="Quận/ Huyện" isRequired>
            <AppSelectValidation
              placeholder="Vui lòng chọn quận huyện"
              options={tempOptions}
              value={validation.values.district}
              name="district"
              validation={validation}
              onChange={onChangeOption('district')}
            />
          </FormField>

          <FormField label="Phường/ Xã" isRequired>
            <AppSelectValidation
              placeholder="Vui lòng chọn phường xã"
              options={tempOptions}
              value={validation.values.village}
              name="village"
              validation={validation}
              onChange={onChangeOption('village')}
            />
          </FormField>

          <FormField label="Địa chỉ">
            <AppInputValidation
              type="text"
              id="address"
              name="address"
              validation={validation}
              isShowCounter
              limitCharacter={100}
            />
          </FormField>

          <FormField label="Khu vực">
            <AppSelectValidation
              placeholder="Vui lòng chọn khu vực"
              options={tempOptions}
              value={validation.values.arena}
              name="arena"
              validation={validation}
              onChange={onChangeOption('arena')}
            />
          </FormField>

          <FormField label="Định vị" isRequired>
            <>
              <AppInputValidation
                type="text"
                id="locate"
                name="locate"
                validation={validation}
                isShowCounter
                limitCharacter={100}
              />

              <span className="SubContent">
                Chọn trên
                <a href="##"> Google Map</a>
              </span>
            </>
          </FormField>
        </SectionWrapper>

        {/* To Test Submit Form Data */}
        {/* <button
            type="button"
            onClick={(e) => { e.preventDefault(); formik.handleSubmit(); }}
          >
            Test Submit
          </button> */}
      </form>
    </div>
  );
}

export default StepOne;
