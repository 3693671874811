import { put, takeLeading } from 'redux-saga/effects';

import locationApi from 'api/locationApi';
import { apiErrorHandler } from 'utils';

import { GET_LOCATION_LIST } from './actionTypes';
import { actionGetLocationListFailed, actionGetLocationListSuccess } from './actions';

function* getLocationList() {
  try {
    const response = yield locationApi.getLocationList();

    yield put(
      actionGetLocationListSuccess(response.payload),
    );
  } catch (error) {
    apiErrorHandler(error);

    yield put(actionGetLocationListFailed());
  }
}

export default function* locationSaga() {
  yield takeLeading(GET_LOCATION_LIST, getLocationList);
}
