import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { Container } from 'reactstrap';

import AppBreadcrumb from 'components/App/Breadcrumb';
import AppMeta from 'components/App/Meta';
import AppSelectValidation from 'components/App/SelectValidation';
import CalendarComponent from 'components/DashboardPage/PriceAndRoomState/Calendar';
import FlatPickInput from 'components/App/FlatPickInput';

import { LOCATIONS } from 'constants/index';

import styles from './Calendar.module.scss';

const today = new Date();

const breadcrumbList = [
  {
    name: 'Giá và tình trạng phòng trống',
    isActive: false,
    link: LOCATIONS.LOCATION_LIST,
  },
  {
    name: 'Lịch',
    isActive: true,
    link: '',
  },
];

const optionFilter = [
  { label: 'Phòng 1', value: 'Phòng 1' },
  { label: 'Phòng 2', value: 'Phòng 2' },
];

function Calendar(props) {
  const endDay = new Date(today.setDate(today.getDate() + 30));

  const [dayStart, setDayStart] = useState(new Date());
  const [dayEnd, setDayEnd] = useState(endDay);
  const [isHasFilter, setIsHasFilter] = useState(false);

  const {
    // loadingRoomStatusList,
    roomStatusList,
    actionGetRoomStatusList,
    priceTypes,
  } = props;

  const handleAddEndDate = useCallback(() => {
    setDayEnd((prev) => {
      const newEndDate = new Date(prev.setDate(today.getDate() + 30));
      return newEndDate;
    });
  }, []);

  // const param = useParams();

  const location = useLocation();

  const query = new URLSearchParams(location.search).get('place');

  const dayStartFormat = useMemo(() => moment(dayStart).format('YYYY-MM-DD'), [dayStart]);
  const dayEndFormat = useMemo(() => moment(dayEnd).format('YYYY-MM-DD'), [dayEnd]);

  useEffect(() => {
    if (JSON.stringify(dayEnd) !== '{}') {
      actionGetRoomStatusList({
        place: query,
        startDate: dayStartFormat,
        endDate: dayEndFormat,
      });
    }
  }, [actionGetRoomStatusList, dayStartFormat, dayEndFormat, query, dayEnd]);

  const onChangeStartDate = (e) => {
    if (e.length === 1) {
      setDayStart(e[0]);
      setIsHasFilter(true);
    }

    if (e.length === 2) {
      setDayStart(e[0]);
      setDayEnd(e[1]);
      setIsHasFilter(true);
    }
  };

  return (
    <div className={`page-content ${styles.Calendar}`}>
      <AppMeta
        name="Lịch"
      />

      <Container fluid>
        <AppBreadcrumb
          pageTitle="LỊCH"
          breadcrumbList={breadcrumbList}
        />

        <div className="FilterBar">
          <div className="FilterBar__Input">
            <span>Tìm theo Phòng</span>

            <AppSelectValidation
              placeholder="Tất cả phòng"
              name="roomNameRequired"
              key="roomNameRequired"
              options={optionFilter}
            />
          </div>

          <div className="FilterBar__Input">
            <span>Chọn thời gian xem</span>

            <FlatPickInput
              name="startDate"
              onChange={onChangeStartDate}
              options={{
                minDate: 'today',
                mode: 'range',
              }}
            />
          </div>
        </div>

        <CalendarComponent
          dayStart={dayStart}
          dayEnd={dayEnd}
          roomStatusList={roomStatusList}
          isHasFilter={isHasFilter}
          handleAddEndDate={handleAddEndDate}
          place={query}
          dayStartFormat={dayStartFormat}
          dayEndFormat={dayEndFormat}
          priceTypes={priceTypes}
        />
      </Container>
    </div>
  );
}

Calendar.propTypes = {
  roomStatusList: PropTypes.instanceOf(Array),
  priceTypes: PropTypes.instanceOf(Array),
  actionGetRoomStatusList: PropTypes.func.isRequired,
};

Calendar.defaultProps = {
  roomStatusList: [],
  priceTypes: [],
};

export default Calendar;
