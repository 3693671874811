import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormFeedback } from 'reactstrap';
import CurrencyFormat from 'react-currency-format';
import { get } from 'lodash';

import { validationCheckErrorType } from 'constants/index';
import { checkFirstNumber, checkHadError } from 'utils';
import classnames from 'utils/classnames';

import styles from './InputNumberValidation.module.scss';

const AppInputNumberValidation = (props) => {
  const {
    allowNegative,
    isCustomOnChange,
    isShowErrorMessage,
    isShowErrorStyle,
    isDisable,

    checkErrorType,
    inputClassName,
    keyName,
    name,
    placeholder,
    showCurrency,
    subTitle,
    value,
    wrapperClassName,
    thousandSeparator,
    decimalSeparator,

    validation,
    formatOptions,

    onBlur,
    onChange,
  } = props;

  const keyCheckError = useMemo(() => (keyName || name), [keyName, name]);

  const isHadError = useMemo(() => checkHadError(
    validation, keyCheckError, checkErrorType,
  ), [validation, keyCheckError, checkErrorType]);

  return (
    <div className={`${wrapperClassName} ${styles.Component}`}>
      <div className="position-relative">
        <CurrencyFormat
          thousandSeparator={thousandSeparator}
          decimalSeparator={decimalSeparator}
          displayType="input"
          allowNegative={allowNegative}
          className={classnames(
            `form-control ${inputClassName}`,
            showCurrency && 'Input__Currency',
            isHadError && isShowErrorStyle && 'is-invalid',
          )}
          name={name}
          onBlur={validation.handleBlur || onBlur}
          onChange={isCustomOnChange ? onChange : validation.handleChange}
          placeholder={placeholder}
          value={checkFirstNumber(value || get(validation.values, keyCheckError, ''))}
          {...formatOptions}
          disabled={isDisable}
        />

        {
        showCurrency && <span className="Currency">{showCurrency}</span>
        }
      </div>

      {
        subTitle && <div className="Selection-SubTitle">{subTitle}</div>
      }

      {
        isShowErrorMessage && isHadError && (
          <FormFeedback type="invalid" className="d-block font-size-12">
            {get(validation.errors, keyCheckError, '')}
          </FormFeedback>
        )
      }
    </div>
  );
};

AppInputNumberValidation.propTypes = {
  allowNegative: PropTypes.bool,
  isCustomOnChange: PropTypes.bool,
  isShowErrorMessage: PropTypes.bool,
  isShowErrorStyle: PropTypes.bool,
  isDisable: PropTypes.bool,

  checkErrorType: PropTypes.oneOf([
    '',
    validationCheckErrorType.ERR_ONLY,
    validationCheckErrorType.TOUCHED_ONLY,
  ]),
  inputClassName: PropTypes.string,
  keyName: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  showCurrency: PropTypes.string,
  subTitle: PropTypes.string,
  thousandSeparator: PropTypes.string,
  decimalSeparator: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),

  wrapperClassName: PropTypes.string,

  validation: PropTypes.instanceOf(Object).isRequired,
  formatOptions: PropTypes.instanceOf(Object),

  onBlur: PropTypes.func,
  onChange: PropTypes.func,
};

AppInputNumberValidation.defaultProps = {
  allowNegative: false,
  isCustomOnChange: false,
  isDisable: false,
  isShowErrorMessage: true,
  isShowErrorStyle: true,

  checkErrorType: '',
  inputClassName: '',
  keyName: '',
  name: '',
  placeholder: 'Nhập',
  showCurrency: '',
  subTitle: '',
  value: '',
  wrapperClassName: '',
  thousandSeparator: '.',
  decimalSeparator: ',',

  formatOptions: {},

  onBlur: () => { },
  onChange: () => { },
};

export default memo(AppInputNumberValidation);
