import React, {
  useMemo,
  memo,
  useCallback,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  ModalBody,
  ModalHeader,
  Label,
  Col,
  Row,
} from 'reactstrap';
import { useFormik } from 'formik';
import { v4 as uuidv4 } from 'uuid';

import { ReactComponent as CloseButtonSvg } from 'assets/images/location/close-button-svg.svg';
import { ReactComponent as Add } from 'assets/images/Add.svg';

import AppButton from 'components/App/Button';
import AppInputValidation from 'components/App/InputValidation';
import AppSelectValidation from 'components/App/SelectValidation';

import styles from './ModalAddContactInfo.module.scss';

const gender = [
  { value: 'Nam', label: 'Nam' },
  { value: 'Nữ', label: 'Nữ' },
];

const language = [
  { value: 'Tiếng Việt', label: 'Tiếng Việt' },
  { value: 'English', label: 'English' },
];

const position = [
  { value: 'Giám đốc', label: 'Giám đốc' },
  { value: 'Nhân viên', label: 'Nhân viên' },
];
const DEFAULT_NUMBER_CONTACT = ['1'];

function ModalAddContactInfo(props) {
  const {
    isOpen,

    onToggleModal,
  } = props;

  const [phoneNumber, setPhoneNumber] = React.useState(DEFAULT_NUMBER_CONTACT);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      type: '',
    },
  });

  const CloseButton = useMemo(() => (
    <button className={styles.ButtonClose} onClick={onToggleModal} type="button">
      <CloseButtonSvg />
    </button>
  ), []);

  const onAddNewPhoneNumber = useCallback((id) => () => {
    setPhoneNumber((prev) => [...prev, id]);
  }, []);

  const onRemovePhoneNumber = useCallback((id) => () => {
    setPhoneNumber((prev) => prev.filter((item) => item !== id));
  }, []);

  useEffect(() => {
    if (!isOpen) {
      setPhoneNumber(DEFAULT_NUMBER_CONTACT);
    }
  }, [
    isOpen,
  ]);

  return (
    <div className={styles.Component}>
      <Modal
        fade
        size="lg"
        isOpen={isOpen}
        toggle={onToggleModal}
        className={styles.Modal}
      >
        <ModalHeader
          toggle={onToggleModal}
          className={styles.ModalHeader}
          close={CloseButton}
        />

        <ModalBody
          className={styles.ModalBody}
        >
          <Label className="Label">
            Thêm thông tin liên hệ
          </Label>

          <hr className="Divider" />

          <Row className="Block">
            <Col md="5" className="Block__Title">
              Tên người liên hệ
              <code>*</code>
            </Col>

            <Col md="7" className="Block__Content__Wrapper">
              <AppInputValidation
                inputClassName={styles.Input}
                name="nameContact"
                id="nameContact"
                type="text"
                placeholder="Nhập tên người liên hệ"
                validation={formik}
                isShowCounter
              />
            </Col>
          </Row>

          <Row className="Block">
            <Col md="5" className="Block__Title">
              Giới tính
            </Col>

            <Col md="7" className="Block__Content__Wrapper">
              <AppSelectValidation
                placeholder="Vui lòng chọn giới tính"
                options={gender}
              />
            </Col>
          </Row>

          <Row className="Block">
            <Col md="5" className="Block__Title">
              Ngôn ngữ ưu tiên
              <code>*</code>
            </Col>

            <Col md="7" className="Block__Content__Wrapper">
              <AppSelectValidation
                placeholder="Vui lòng chọn ngôn ngữ"
                options={language}
              />
            </Col>
          </Row>

          <Row className="Block">
            <Col md="5" className="Block__Title">
              Chức vụ
              <code>*</code>
            </Col>

            <Col md="7" className="Block__Content__Wrapper">
              <AppSelectValidation
                placeholder="Vui lòng chọn chức vụ"
                options={position}
              />
            </Col>
          </Row>

          <Row className="Block">
            <Col md="5" className="Block__Title">
              Địa chỉ Email
              <code>*</code>
            </Col>

            <Col md="7" className="Block__Content__Wrapper">
              <AppInputValidation
                name="email"
                id="email"
                type="text"
                placeholder="Nhập địa chỉ email"
                validation={formik}
                isShowCounter
                limitCharacter={50}
              />
            </Col>
          </Row>

          <Row className="Block BlockPhone">
            <Col md="5" className="Block__Title">
              Số điện thoại
              <code>*</code>
            </Col>

            <Col md="7" className="Block__Content__Wrapper">
              {phoneNumber.map((item) => (
                <div className="position-relative" key={item}>
                  <AppInputValidation
                    inputClassName="InputPhoneNumber"
                    name={item}
                    id="phoneNumber"
                    type="text"
                    placeholder="Số điện thoại"
                    validation={formik}
                  />

                  {phoneNumber.length > 1 && (
                    <div
                      className="BtnRemovePhoneNumber"
                      onClick={onRemovePhoneNumber(item)}
                      role="presentation"
                    >
                      x
                    </div>
                  )}
                </div>
              ))}

              <AppButton
                color="secondary"
                classes="BtnAddRepresentative"
                onClick={onAddNewPhoneNumber(uuidv4())}
              >
                <Add />

                <p className="TextButton">Thêm số điện thoại liên hệ</p>
              </AppButton>
            </Col>
          </Row>

          <div className="Button__Wrapper">
            <AppButton
              // outline
              classes="Button__Danger"
            >
              Xóa
            </AppButton>

            <AppButton
              classes="Button Button__Cancel"
              onClick={onToggleModal}
            >
              Hủy
            </AppButton>

            <AppButton classes="Button Button__Save">
              Lưu
            </AppButton>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}

ModalAddContactInfo.propTypes = {
  isOpen: PropTypes.bool.isRequired,

  onToggleModal: PropTypes.func.isRequired,
};

ModalAddContactInfo.defaultProps = {

};

export default memo(ModalAddContactInfo);
