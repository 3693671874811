import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  memo,
} from 'react';
import PropTypes from 'prop-types';
import {
  Col, Input, Row, Card,
} from 'reactstrap';
import Dropzone from 'react-dropzone';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';

import images from 'assets/images';
import {
  DEBOUNCE_SEARCH_DELAY,
  LIMIT_FILE_SIZE,

  imageType,
  typeMediaUpload,
} from 'constants/index';
import { getPathNameImage, uploadSingleFile, uploadLogoFormatFile } from 'utils';
import propTypeModels from 'propTypeModels';

import AppButton from 'components/App/Button';
import ModalGeneral from 'components/Modals/ModalGeneral';
import Pagination from 'components/Pagination';

import './style.scss';

function PopupUploadMedia(props) {
  const {
    isOpen,
    fileSizeLimit,

    mediaTotal,
    type,
    typeUpload,

    mediaList,

    actionGetListMedia,
    actionUploadMedia,
    onSelectMedia,
    onToggleModal,
  } = props;

  const { t } = useTranslation();
  const [state, setState] = useState({
    folderType: type,
    limit: 10,
    page: 1,
    q: '',
  });

  const [image, setImage] = useState(null);
  const [currentId, setCurrentId] = useState(null);

  const [selectedImg, setSelectedImg] = useState({ activeId: null });
  const [isShowDropdownImage, setIsShowDropdownImage] = useState(false);
  const [pageCount, setPageCount] = useState(0);

  const totalPage = useMemo(
    () => Math.ceil(mediaTotal / state.limit) || 1, [mediaTotal, state.limit],
  );

  const pageNumbers = [];

  const emptyDataMessage = useMemo(() => (<p className="w-100 mb-0">{t('search_not_found')}</p>), [t]);

  let i = 1;

  for (i; i <= Math.ceil(mediaTotal / state.limit); i += 1) {
    pageNumbers.push(i);
  }

  const onUploadImage = useCallback((item) => {
    setImage({
      ...item,
      formattedSize: uploadLogoFormatFile(item.size),
    });
  }, []);

  const onAcceptedFiles = useCallback((files) => {
    const newFile = uploadSingleFile(files[0], typeUpload, fileSizeLimit);
    if (newFile) {
      setCurrentId(0);
      setSelectedImg({
        activeId: null,
      });
      actionUploadMedia({
        file: newFile,
        typeImg: type,
        onUploadImage,
        fileSizeLimit,
      });
    }
  }, [image]);

  const onSelectImg = useCallback((item) => () => {
    setIsShowDropdownImage(true);
    setSelectedImg({ activeId: item._id });
    setCurrentId(null);
    onUploadImage(item);
  }, []);

  const searchDebounced = useCallback(
    debounce((params) => setState(params), DEBOUNCE_SEARCH_DELAY), [],
  );

  const onSearch = useCallback((e) => {
    setState((prevState) => ({
      ...prevState,
      q: e.target.value,
      page: 1,
    }));

    searchDebounced({
      ...state,
      q: e.target.value,
      page: 1,
    });
  }, []);

  const onDeleteImage = useCallback(() => {
    setImage(null);
    setCurrentId(null);
    setSelectedImg((prevState) => ({
      ...prevState,
      activeId: null,
    }));
  }, []);

  const onShowDropDownImage = useCallback(() => {
    setIsShowDropdownImage((prevState) => !prevState);
  }, []);

  const onSubmitData = useCallback(() => {
    onSelectMedia(image);
  }, [image]);

  useEffect(() => {
    actionGetListMedia(state);
  }, [isOpen, state.page, state.q]);

  useEffect(() => {
    if (isOpen === false) {
      setImage(null);
      setCurrentId(null);
      setSelectedImg((prevState) => ({
        ...prevState,
        activeId: null,
      }));
    }
  }, [isOpen]);

  useEffect(() => {
    setPageCount(totalPage);
  }, [totalPage]);

  const onChangePage = useCallback((event) => {
    setState((prevState) => ({
      ...prevState,
      page: event.selected + 1,
    }));

    window.scrollTo(0, 0);
  }, [state]);

  return (
    <ModalGeneral
      isOpen={isOpen}
      size="lg"
      onToggleModal={onToggleModal}
      btnAcceptText="type.UPDATE"
      title={t('media.choses_image')}
      onBtnAccept={onSubmitData}
      centered
    >
      <Card className="cardMediaUpload">
        <Col sm={12}>
          <Row className="space-media">
            <Col sm={6}>
              <AppButton
                classes="p-2"
                onClick={onShowDropDownImage}
              >
                {t('media.upload_image')}
                {' '}
                <i className="bx bx-cloud-upload" />
              </AppButton>
            </Col>
            <Col sm={6}>
              <div className="search-media search-box me-2 mb-2 d-inline-block">
                <div className="position-relative">
                  <Input
                    onChange={onSearch}
                    placeholder={`${t('search')}...`}
                    name="search"
                  />
                  <i className="bx bx-search-alt search-icon" />
                </div>
              </div>
            </Col>
          </Row>
        </Col>
        <div className="line-media" />
        <Col className="space-media" sm={12}>
          {isShowDropdownImage
            ? (
              <div className="dropzone">
                {
            image ? (
              <div className="dropzone-previews" id="file-previews">
                <Card
                  className="mt-1 mb-0 shadow-none dz-processing dz-image-preview dz-success dz-complete"
                >
                  <div className="p-2">
                    <Row>
                      <Col className="col-auto">
                        <div
                          className="delete-avatar"
                          role="button"
                          onClick={onDeleteImage}
                          onKeyDown={() => {}}
                          tabIndex={0}
                        >
                          <i className="bx bx-x" />

                        </div>
                        <img
                          data-dz-thumbnail=""
                          className="avatar-md rounded bg-light"
                          alt={image.name}
                          src={
                            image.location?.slice(-3) === imageType.PDF
                              ? images.mediaImgPDF.default : image.location
                          }
                        />
                      </Col>
                      <Col>
                        <span
                          className="text-muted font-weight-bold text-break"
                        >
                          {getPathNameImage(image.name)}
                        </span>
                        <p className="mb-0">
                          <strong>{image.formattedSize}</strong>
                        </p>
                      </Col>
                    </Row>
                  </div>
                </Card>
              </div>
            ) : (
              <Dropzone
                onDrop={(acceptedFiles) => onAcceptedFiles(acceptedFiles)}
                multiple={false}
                className="dropzone-height"
              >
                {({ getRootProps, getInputProps }) => (
                  <div>
                    <div
                      className="dz-message needsclick"
                      {...getRootProps()}
                    >
                      <input {...getInputProps()} />

                      <div className="dz-message needsclick">
                        <div className="mb-3">
                          <i className="display-4 text-muted bx bxs-cloud-upload" />
                        </div>

                        <h6>{t('global.drop_image')}</h6>
                      </div>
                    </div>
                  </div>
                )}
              </Dropzone>
            )
          }
              </div>
            ) : null}
        </Col>
        <Col sm={12}>
          <div className="container-img space-media">
            {mediaList.length === 0 && emptyDataMessage}
            {mediaList.map((item, index) => (
              <div
                key={item._id}
                className="space-img"
                onClick={onSelectImg(item)}
                onKeyDown={() => {}}
                onKeyPress={() => {}}
                tabIndex="0"
                role="button"
              >
                <div className="column-img">
                  <img
                    className={`item-media-add ${(selectedImg.activeId === item._id || currentId === index) && 'img-selected'}`}
                    src={item?.location.slice(-3) === imageType.PDF
                      ? images.mediaImgPDF.default : item.location}
                    alt={item.location}
                  />
                </div>
              </div>
            ))}
          </div>
        </Col>
        <Col sm={12}>
          <div className="mt-3 pagination-media d-flex justify-content-end">
            <Pagination
              className="text-md-right ms-auto"
              pageCount={pageCount}
              onChangePage={onChangePage}
            />
          </div>
        </Col>
      </Card>
    </ModalGeneral>
  );
}

PopupUploadMedia.propTypes = {
  isOpen: PropTypes.bool,

  fileSizeLimit: PropTypes.number,
  mediaTotal: PropTypes.number,

  type: PropTypes.string,
  typeUpload: PropTypes.string,

  validation: PropTypes.instanceOf(Object),
  mediaList: propTypeModels.media.mediaList.isRequired,

  actionGetListMedia: PropTypes.func,
  actionUploadMedia: PropTypes.func,
  onSelectMedia: PropTypes.func,
  onToggleModal: PropTypes.func,
};

PopupUploadMedia.defaultProps = {
  isOpen: false,

  fileSizeLimit: LIMIT_FILE_SIZE.BIG,
  mediaTotal: 0,
  type: '',
  typeUpload: typeMediaUpload.DEFAULT,
  validation: {},
  actionGetListMedia: () => { },
  actionUploadMedia: () => { },
  onSelectMedia: () => { },
  onToggleModal: () => { },
};

export default memo(PopupUploadMedia);
