import { getParamsFormObject } from 'utils';
import axiosClient from 'utils/axios';

const endPoint = '/api/v1.0/employee-media';

export default {
  async getListMedia(payload) {
    const { limit, mimeType, page } = payload;

    const search = getParamsFormObject({ limit, mimeType, page });

    const path = `${endPoint}/${search}`;

    const response = await axiosClient.get(path);

    return response.data;
  },

  async getMediaDetail(payload) {
    const { _id } = payload;

    const path = `${endPoint}/${_id}`;

    const response = await axiosClient.get(path);

    return response.data;
  },

  async updateMedia(payload) {
    const { _id, data } = payload;

    const path = `${endPoint}/${_id}`;

    const response = await axiosClient.put(path, data);

    return response.data;
  },

  async uploadMedia(payload) {
    const { file, typeImg } = payload;

    const path = `/api/v1.0/employee-media?folderType=${typeImg}`;

    const formData = new FormData();
    formData.set('file', file);

    const response = await axiosClient.post(path, formData);
    return response.data;
  },

  async deleteMedia(payload) {
    const { _id } = payload;
    const path = `${endPoint}/${_id}`;

    const response = await axiosClient.delete(path);

    return response.data;
  },
};
