const {
  GET_LOCATION_LIST,
  GET_LOCATION_LIST_FAILED,
  GET_LOCATION_LIST_SUCCESS,
} = require('./actionTypes');

const initialState = {
  loadingLocationList: true,
  locationList: [],
};

const location = (state = initialState, action) => {
  switch (action.type) {
    case GET_LOCATION_LIST:
      return {
        ...state,
        loadingLocationList: true,
      };

    case GET_LOCATION_LIST_SUCCESS:
      return {
        ...state,
        locationList: action.payload,
        loadingLocationList: false,
      };

    case GET_LOCATION_LIST_FAILED:
      return {
        ...state,
        loadingLocationList: false,
      };

    default:
      return state;
  }
};

export default location;
