import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  CardBody,
  Col,
  FormFeedback,
  Input,
  Label,
  Row,
} from 'reactstrap';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useTranslation, withTranslation } from 'react-i18next';

import AppButton from 'components/App/Button';
import AppLoading from 'components/App/Loading';
import Breadcrumb from 'components/Breadcrumb';
import Meta from 'components/App/Meta';
import ModalConfirm from 'components/Modals/ModalConfirm';

import images from 'assets/images';
import {
  allowPermission,
  LOCATIONS,
  mediaMimeType,
} from 'constants/index';
import propTypeModels from 'propTypeModels';
import {
  checkPermission,
  convertIsoDateToString,
  getPlaceholderTrans,
  getRestoreOrRemoveTrans,
} from 'utils';
import { mediaValidation } from 'utils/validate';

import './style.scss';

const EmployeeProfile = (props) => {
  const {
    match,

    me,

    // GET MEDIA
    actionGetMediaDetail,
    mediaDetail,
    mediaLoadingDetail,

    // UPDATE
    actionUpdateMedia,
    mediaLoadingUpdate,

    // DELETE
    actionDeleteMedia,

    // CLEAR
    actionClearMediaDetail,
  } = props;

  const { t } = useTranslation();

  const history = useHistory();

  const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: '',
    },

    validationSchema: Yup.object(mediaValidation),

    onSubmit: (values) => {
      const newValues = {
        _id: match.params.id,
        data: values,
      };
      actionUpdateMedia(newValues);
    },
  });

  const onSubmit = useCallback((e) => {
    e.preventDefault();
    validation.handleSubmit();
  }, []);

  const onBtnDelete = useCallback(() => {
    setIsShowDeleteModal(true);
  }, [isShowDeleteModal]);

  const onToggleModal = useCallback(() => {
    setIsShowDeleteModal(false);
  }, [isShowDeleteModal]);

  const onDeleteMedia = useCallback(() => {
    actionDeleteMedia(match.params.id);

    setIsShowDeleteModal(false);

    actionClearMediaDetail();

    history.push(LOCATIONS.MEDIA);
  }, [isShowDeleteModal]);

  const renderPreview = useMemo(() => {
    if (mediaDetail.mimeType === mediaMimeType.PDF) {
      return (
        <img className="img-pdf" src={images.mediaImgPDF.default} alt="" />
      );
    }

    if (mediaDetail.mimeType === mediaMimeType.EXCEL
      || mediaDetail.mimeType === mediaMimeType.SHEET) {
      return (
        <img className="img-pdf" src={images.mediaImgExcel.default} alt="" />
      );
    }

    if (mediaDetail.mimeType === mediaMimeType.WORD
      || mediaDetail.mimeType === mediaMimeType.DOCUMENT) {
      return (
        <img className="img-pdf" src={images.mediaImgWord.default} alt="" />
      );
    }

    return (
      <img
        className="preview-avatar-track"
        src={mediaDetail.location || ''}
        alt=""
      />
    );
  }, [mediaDetail]);

  useEffect(() => {
    actionClearMediaDetail();

    actionGetMediaDetail({ _id: match.params.id });
  }, [match.params.id]);

  useEffect(() => {
    validation.setValues({
      name: mediaDetail.name,
    });
  }, [mediaDetail]);

  useEffect(() => function cleanup() {
  }, []);

  return (
    <div className="page-content">
      <Meta name={t('media.media_detail')} />

      <div className="container-fluid">
        <Breadcrumb
          breadcrumbItem="media.media_detail"
          historyBack={LOCATIONS.MEDIA}
          title="media.title"
        />

        <AppLoading loading={mediaLoadingDetail} />

        {/* delete personal client  */}
        <ModalConfirm
          isOpen={isShowDeleteModal}
          onCloseClick={onToggleModal}
          onAcceptConfirm={onDeleteMedia}
          notification={{
            message: getRestoreOrRemoveTrans('global.question_delete'),
            description: getRestoreOrRemoveTrans('global.restore_fail'),
          }}
        />

        <Row>
          <Col xl="8">
            <Card>
              <CardBody>
                <Row>
                  <Col xl="8">
                    <div className="mb-3">
                      <Label className="form-label">
                        {t('name')}
                      </Label>
                      <Input
                        id="name"
                        name="name"
                        className="form-control"
                        type="text"
                        placeholder={getPlaceholderTrans({
                          type: 'type.ENTER',
                          name: 'name',
                        })}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.name || ''}
                        invalid={!!(validation.touched.name
                  && validation.errors.name)}
                        disabled={!checkPermission(me.role, allowPermission.EDIT_MEDIA)}
                      />

                      {validation.touched.name && validation.errors.name ? (
                        <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                      ) : null}
                    </div>
                  </Col>

                  <Col xl="4">
                    <div className="mb-3">
                      <Label className="form-label">
                        {t('media.size')}
                      </Label>
                      <Input
                        id="size"
                        name="size"
                        className="form-control"
                        type="text"
                        defaultValue={mediaDetail.size || ''}
                        disabled
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xl="6">
                    <div className="mb-3">
                      <Label className="form-label">
                        Key
                      </Label>
                      <Input
                        id="keyName"
                        name="keyName"
                        className="form-control"
                        type="text"
                        defaultValue={mediaDetail.keyName || ''}
                        disabled
                      />
                    </div>
                  </Col>

                  <Col xl="3">
                    <div className="mb-3">
                      <Label className="form-label">
                        {t('media.upload_date')}
                      </Label>
                      <Input
                        id="createdAt"
                        name="createdAt"
                        className="form-control"
                        type="text"
                        defaultValue={convertIsoDateToString(mediaDetail.createdAt) || ''}
                        disabled
                      />
                    </div>
                  </Col>

                  <Col xl="3">
                    <div className="mb-3">
                      <Label className="form-label">
                        {t('media.type')}
                      </Label>
                      <Input
                        id="mimeType"
                        name="mimeType"
                        className="form-control"
                        type="text"
                        defaultValue={mediaDetail.mimeType || ''}
                        disabled
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xl="12">
                    <div className="mb-3">
                      <Label className="form-label">
                        {t('media.uploader')}
                      </Label>
                      <Input
                        id="fullName"
                        name="fullName"
                        className="form-control"
                        type="text"
                        defaultValue={mediaDetail.createdByEmployee?.username || ''}
                        disabled
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xl="12">
                    <div className="mb-3">
                      <Label className="form-label">
                        {t('media.path')}
                      </Label>
                      <Input
                        id="location"
                        name="location"
                        className="form-control"
                        type="text"
                        defaultValue={mediaDetail.location || ''}
                        disabled
                      />
                    </div>
                  </Col>
                </Row>
                <div className="d-flex justify-content-end">
                  {
                    checkPermission(me.role, allowPermission.DELETE_MEDIA) && (
                      <Button
                        type="submit"
                        className="btn me-2"
                        color="danger"
                        onClick={onBtnDelete}
                        disabled={mediaLoadingDetail || mediaLoadingUpdate}
                      >
                        <i className="mdi mdi-delete mr-2" />
                        {' '}
                        {t('button.delete')}
                      </Button>
                    )
                  }

                  {checkPermission(me.role, allowPermission.EDIT_MEDIA)
                  && (mediaLoadingUpdate ? <AppButton loading={mediaLoadingUpdate} /> : (
                    <Button
                      type="submit"
                      className="btn"
                      color="primary"
                      onClick={onSubmit}
                      disabled={mediaLoadingDetail || mediaLoadingUpdate}
                    >
                      <i className="mdi mdi-pencil mr-2" />
                      {' '}
                      {t('button.update')}
                    </Button>
                  ))}
                </div>
              </CardBody>
            </Card>
          </Col>

          <Col xl="4">
            <Card>
              <CardBody>
                <div className="preview-avatar">
                  {renderPreview}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>

      </div>
    </div>
  );
};

EmployeeProfile.propTypes = {
  match: PropTypes.instanceOf(Object).isRequired,

  me: propTypeModels.user.userPropTypes.isRequired,

  actionGetMediaDetail: PropTypes.func.isRequired,
  mediaDetail: propTypeModels.media.mediaPropTypes.isRequired,
  mediaLoadingDetail: PropTypes.bool.isRequired,

  actionUpdateMedia: PropTypes.func.isRequired,
  mediaLoadingUpdate: PropTypes.bool.isRequired,

  actionDeleteMedia: PropTypes.func.isRequired,

  actionClearMediaDetail: PropTypes.func.isRequired,
};

export default withTranslation()(EmployeeProfile);
