import React from 'react';
import PropTypes from 'prop-types';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';

import './style.scss';

const SelectedPageSize = (props) => {
  const {
    isShowDropDown,
    limit,
    limitList,
    onChangeOptionLimit,
  } = props;

  return (
    <Dropdown
      isOpen={isShowDropDown}
      toggle={onChangeOptionLimit}
    >
      <DropdownToggle
        className="btn btn-secondary"
        caret
      >
        {limit}
        {' '}
        <i className="mdi mdi-chevron-down" />
      </DropdownToggle>
      <DropdownMenu>
        {limitList.map((value) => (
          <DropdownItem
            key={value}
            value={value}
          >
            {value}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>

  );
};

SelectedPageSize.propTypes = {
  isShowDropDown: PropTypes.bool.isRequired,
  limit: PropTypes.number.isRequired,
  limitList: PropTypes.arrayOf(PropTypes.number),
  onChangeOptionLimit: PropTypes.func.isRequired,
};

SelectedPageSize.defaultProps = {
  limitList: [20, 30, 50],
};

export default SelectedPageSize;
