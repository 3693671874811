import React, { memo } from 'react';
import { Link } from 'react-router-dom';

import AppButton from 'components/App/Button';

import ImageTest1 from 'assets/images/imageTest.jpg';
import { LOCATIONS } from 'constants/index';

import styles from './RoomCard.module.scss';

function RoomCard() {
  return (
    <div className={styles.Component}>
      <div className="RoomCard__Container">
        <img src={ImageTest1} className="RoomCard__Image" alt="imageRoom" />

        <div className="RoomCard__Content">
          <div className="RoomCardContent__Body">
            <h5>Suite Có Sân Hiên</h5>

            <p>
              Số khách tối đa:
              <strong> 2 Khách</strong>
            </p>

            <p>
              Số người lớn tối đa:
              <strong> 2 Người lớn</strong>
            </p>

            <p>
              Số trẻ em tối đa:
              <strong> 1 Trẻ em</strong>
            </p>

            <p>
              Số lượng loại này:
              <strong> 1 </strong>
            </p>

            <div className="RoomCardContent__Line" />
          </div>

          <div className="RoomCardContent__Footer">
            <button className="ButtonDelete" type="button"> Xoá </button>

            {/* FIXME: Need to change fake id to slug or real id */}
            <Link to={`${LOCATIONS.LOCATION_ROOM_TYPE}/1`}>
              <AppButton>Chỉnh sửa</AppButton>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(RoomCard);
