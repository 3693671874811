import React, {
  useState,
  memo,
  useCallback,
  useRef,
  useEffect,
} from 'react';
import {
  Link, useLocation,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ArrowHeaderIcon } from 'assets/images/headerNavigation/arrow.svg';

import { getPathname } from 'utils';

const MenuDropdown = (props) => {
  const {
    isDisableEffect,
    isBreakTitle,

    classnames,

    children,

    list,
  } = props;

  const ref = useRef(null);
  const location = useLocation();
  const { t } = useTranslation();

  const [menu, setMenu] = useState(false);
  const [hasClass, setHasClass] = useState(false);

  const query = new URLSearchParams(location.search).get('place');

  const checkActiveDropdown = useCallback(() => {
    const path = location.pathname.slice(1);
    const chillNode = ref.current.menuRef.current.childNodes;

    Object.keys(chillNode).forEach((key) => {
      if (chillNode[key].href && path === getPathname(chillNode[key].href)) {
        setHasClass(true);
      }
    });
  }, []);

  const onToggle = useCallback(() => {
    setMenu((prevState) => !prevState);

    checkActiveDropdown();
  }, []);

  useEffect(() => {
    checkActiveDropdown();
  }, [menu]);

  const RenderNavLink = useCallback((nav) => (
    <Link to={`${nav.link}?place=${query}`} className="align-middle Navigation__Item ms-0" key={nav.name}>
      <DropdownItem>
        <span className={`Navigation__Item__ChildrenTitle ${nav.link === location.pathname ? 'mm-show' : ''}`}>{t(nav.name)}</span>
      </DropdownItem>
    </Link>
  ), []);

  return (
    <Dropdown isOpen={menu} toggle={onToggle} className={`${classnames} ${!isDisableEffect && hasClass ? 'mm-show' : ''}`} ref={ref}>
      <DropdownToggle
        className={`d-flex align-items-center border-0 bg-none ps-0 ${isBreakTitle ? 'flex-column' : ''}`}
        type="button"
        tag="button"
      >
        {children}
      </DropdownToggle>

      <DropdownMenu className="dropdown-menu-end">
        {
          list.map((item) => {
            if (item?.children?.length > 0) {
              return (
                <div className="ml-25 mb-1 me-3" key={item.name}>
                  <a className="Navigation__Item" data-toggle="collapse" href={`#${item.name}`} role="button">
                    <span className="Navigation__Item__SubChildTitle">{t(item.name)}</span>

                    <span className="ms-3 mt-1"><ArrowHeaderIcon /></span>
                  </a>

                  <div className="collapse" id={item.name}>
                    {
                      item.children.map((navItem) => RenderNavLink(navItem))
                    }
                  </div>
                </div>
              );
            }

            return RenderNavLink(item);
          })
        }
      </DropdownMenu>
    </Dropdown>
  );
};

MenuDropdown.propTypes = {
  isDisableEffect: PropTypes.bool,
  isBreakTitle: PropTypes.bool,

  classnames: PropTypes.string,

  children: PropTypes.node.isRequired,

  list: PropTypes.instanceOf(Array).isRequired,
};

MenuDropdown.defaultProps = {
  isDisableEffect: false,
  isBreakTitle: false,

  classnames: '',
};

export default memo(MenuDropdown);
