import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import images from 'assets/images';
import { LOCATIONS } from 'constants/index';

import SidebarContent from './SidebarContent';

const Sidebar = (props) => {
  const {
    type,
  } = props;

  return (
    <div className="vertical-menu position-fixed">
      <div className="navbar-brand-box">
        <Link to={LOCATIONS.DASHBOARD} className="logo logo-dark">
          <span className="logo-sm">
            <img src={images.logoBookeseMini.default} alt="" height="30" />
          </span>

          <span className="logo-lg">
            <img src={images.logoBookeseFull.default} alt="" width="120" height="30" />
          </span>
        </Link>

        <Link to={LOCATIONS.DASHBOARD} className="logo logo-light">
          <span className="logo-sm">
            <img src={images.logoBookeseMini.default} alt="" height="30" />
          </span>

          <span className="logo-lg">
            <img src={images.logoBookeseFull.default} alt="" width="120" height="30" />
          </span>
        </Link>
      </div>

      <div data-simplebar className="h-100">
        {type !== 'condensed' ? <SidebarContent /> : <SidebarContent />}
      </div>
      <div className="sidebar-background" />
    </div>
  );
};

Sidebar.propTypes = {
  type: PropTypes.string.isRequired,
};

export default memo(Sidebar);
