/* eslint-disable no-return-assign */
import React, {
  useCallback,
  useEffect, useMemo, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import ModalEditRoomStatus from 'components/Modals/ModalEditRoomStatus';

import { currencyFormat } from 'utils';

import styles from './CalendarComponent.module.scss';

const compareDate = (date, dateCompare) => (moment(date).format('MM/DD/YYYY') === moment(dateCompare).format('MM/DD/YYYY'));

const LIMIT_RANK = 30;
const endDate = new Date();

function detectRoomStatus(data) {
  const classes = {
    AVAILABLE: 'DateContentColum--Available',
    FULL: 'DateContentColum--Full',
    CLOSE: 'DateContentColum--Closed',
  };

  const status = {
    AVAILABLE: 'Đặt được',
    FULL: 'Đã bán hết',
    CLOSE: 'Đã đóng',
  };

  return {
    class: classes[data],
    status: status[data],
  };
}

function CalendarComponent(props) {
  const { t } = useTranslation();

  const {
    roomStatusList,
    dayStart,
    dayEnd,
    isHasFilter,
    handleAddEndDate,
    place,
    dayStartFormat,
    dayEndFormat,
    priceTypes,
  } = props;

  const scrollRef = useRef(null);

  const followRef = useRef([]);

  const [dates, setDates] = useState([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [dataItemClick, setDataItemClick] = useState({});

  const newData = useMemo(() => roomStatusList.map((room) => ({
    ...room,
    roomStatus: (dates.map((item) => {
      const dateCompare = room?.roomStatus?.find((data) => (
        compareDate(item, data.statusDate)));
      if (dateCompare) {
        return dateCompare;
      }

      return {
        roomStatus: 'CLOSE',
        prices: [],
        statusDate: moment(item).format('YYYY-MM-DD'),
        room: room._id,
      };
    })),
  })), [dates, roomStatusList]);

  useEffect(() => {
    if (isHasFilter) {
      const startDay = new Date(dayStart);
      const endDay = new Date(dayEnd);
      const newDates = [];
      const currentDate = new Date(startDay);
      endDay.setDate(endDay.getDate());

      while (currentDate <= endDay) {
        newDates.push(moment(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
      }

      setDates(newDates);
    } else {
      const today = new Date();
      const newDates = [];
      const currentDate = today;

      endDate.setDate(today.getDate() + LIMIT_RANK);

      while (currentDate <= endDate) {
        newDates.push(moment(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
      }

      setDates(newDates);
    }
  }, [dayEnd, dayStart, isHasFilter]);

  useEffect(() => {
    function handleScrollDate() {
      followRef.current.forEach((ref) => {
        // eslint-disable-next-line no-param-reassign
        ref.scrollLeft = scrollRef.current.scrollLeft;
      });
    }

    scrollRef.current.addEventListener('scroll', handleScrollDate);

    return () => {
      if (scrollRef.current) {
        scrollRef.current.removeEventListener('scroll', handleScrollDate);
      }
    };
  }, [followRef]);

  const handleScroll = useCallback((e) => {
    if (Math.ceil(e.target.scrollLeft) + e.target.clientWidth >= e.target.scrollWidth
    && LIMIT_RANK <= 360 && !isHasFilter) {
      handleAddEndDate();
    }
  }, [handleAddEndDate, isHasFilter]);

  const onToggleModal = useCallback((data) => () => {
    setIsOpenModal((prev) => !prev);

    setDataItemClick(data);
  }, []);

  return (
    <div className={styles.Component}>
      <div className="Header">
        <div className="ContentColum" />

        <div
          className="Header__Wrapper"
          ref={scrollRef}
          onScroll={handleScroll}
        >
          <table>
            <tbody>
              <tr>
                {
                  dates.map((item) => (
                    <th
                      key={crypto.randomUUID()}
                      className={`${item.format('dddd') === 'Saturday'
                      || item.format('dddd') === 'Sunday' ? 'DateContentColum--Strong' : ''} DateContentColum`}
                    >
                      {item && t(`global.${item.format('dddd')}`)}

                      <br />

                      <span>
                        {item.format('MM/DD')}
                      </span>

                      <br />

                      <span>
                        {item.format('YYYY')}
                      </span>
                    </th>
                  ))
                }
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      {newData.map((room, i) => (
        <div key={room._id} className="RoomSection">
          <div className="TitleRoom">
            <h2>{room.name}</h2>

            <span>{`(Số ID phòng: ${room._id})`}</span>
          </div>

          <div className="BodyColumContent">
            <div className="ContentColum">
              <p>
                Trạng thái phòng
              </p>

              <p>
                Phòng để bán
              </p>

              <p>
                Giá bán tiêu chuẩn
              </p>
            </div>

            <div ref={(el) => followRef.current[i] = el} style={{ overflow: 'hidden', flex: 1 }}>
              <table>
                <tbody>
                  <tr>
                    {
                    room.roomStatus.map((item) => (
                      <th
                        key={crypto.randomUUID()}
                        className={`DateContentColum ${detectRoomStatus(item?.roomStatus).class}`}
                      >
                        <span>{detectRoomStatus(item?.roomStatus).status}</span>
                      </th>
                    ))
                  }
                  </tr>

                  <tr>
                    {
                    room.roomStatus.map((item) => (
                      <td
                        key={crypto.randomUUID()}
                        className={`DateContentColum ${detectRoomStatus(item?.roomStatus).class}`}
                        data={item}
                        onClick={onToggleModal(item, room._id)}
                        role="presentation"
                        onKeyDown={null}
                      >
                        {item?.availableRooms}
                      </td>
                    ))
                  }
                  </tr>

                  <tr>
                    {
                    room.roomStatus.map((item) => (
                      <td
                        key={crypto.randomUUID()}
                        className={`DateContentColum ${detectRoomStatus(item?.roomStatus).class}`}
                        role="presentation"
                        data={item}
                        onClick={onToggleModal(item, room._id)}
                        onKeyDown={null}
                      >
                        {item?.prices?.[0]?.price > 0 ? currencyFormat(item?.prices?.[0]?.price, true).slice(0, -2) : ''}
                      </td>
                    ))
                  }
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      ))}

      {
        isOpenModal
          ? (
            <ModalEditRoomStatus
              isOpen={isOpenModal}
              onToggleModal={onToggleModal()}
              data={dataItemClick}
              place={place}
              dayStartFormat={dayStartFormat}
              dayEndFormat={dayEndFormat}
              priceTypes={priceTypes}
              roomStatusList={roomStatusList}
            />
          )
          : null
      }
    </div>
  );
}

CalendarComponent.propTypes = {
  roomStatusList: PropTypes.instanceOf(Array),
  dayStart: PropTypes.instanceOf(Date).isRequired,
  dayEnd: PropTypes.instanceOf(Date).isRequired,
  isHasFilter: PropTypes.bool.isRequired,
  handleAddEndDate: PropTypes.func.isRequired,
  place: PropTypes.string.isRequired,
  dayStartFormat: PropTypes.string,
  dayEndFormat: PropTypes.string,
  priceTypes: PropTypes.instanceOf(Array),
};

CalendarComponent.defaultProps = {
  roomStatusList: [],
  dayStartFormat: '',
  dayEndFormat: '',
  priceTypes: [],
};

export default CalendarComponent;
