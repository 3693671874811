import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import Home from 'components/DashboardPage/Home';
// import { useTranslation } from 'react-i18next';
// import {
//   Container,
//   Button,
// } from 'reactstrap';

import Meta from 'components/App/Meta';

// const initialState = {
//   limit: DEFAULT_LIMIT,
//   page: 1,
// };

const Dashboard = (props) => {
  const { actionLogout } = props;

  const history = useHistory();
  const location = useLocation();
  // const { t } = useTranslation();

  // const [paging, setPaging] = useState(initialState);

  // const onChangePagination = useCallback((newFilter) => {
  //   actionGetAPI({
  //     ...newFilter,
  //   });

  //   setPaging((prevState) => ({
  //     ...prevState,
  //     ...newFilter,
  //   }));
  // }, []);

  // const columns = useMemo(() => [
  //   {
  //     dataField: '_id',
  //     hidden: true,
  //   },
  //   {
  //     dataField: 'name',
  //     text: 'Tên',
  //   },
  //   {
  //     dataField: 'joinedOn',
  //     text: 'Ngày tạo',
  //     formatter: (cellContent, item) => convertIsoDateToString(item.joinedOn),
  //   },
  // ], []);

  // useEffect(() => {
  //   const filterData = {
  //     ...paging,
  //   };

  //   actionGetAPI(filterData);
  // }, []);

  useEffect(() => {
    if ((location.pathname === '/dashboard') && (location.search === '?client')) {
      actionLogout({ history });
    }
  }, []);

  return (
    <>
      <div className="page-content">
        <Meta name="Trang tổng quan" />

        {/* <Container fluid>
          {t('global.dashboard')}
        </Container>

        <Button>{t('button.button')}</Button> */}

        <Home />

        {/* Table Section */}
        {/* <AppDataTables
          loading={loading}
          columns={columns}
          data={DataList}
        /> */}

        {/* Paging Section */}
        {/* <AppPagination
          total={total}
          paging={paging}
          onChangePagination={onChangePagination}
        /> */}
      </div>
    </>
  );
};

Dashboard.propTypes = {
  actionLogout: PropTypes.func,
};

Dashboard.defaultProps = {
  actionLogout: () => {},
};

export default Dashboard;
