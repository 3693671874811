import React, {
  useCallback, useEffect, useRef,
} from 'react';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import MetisMenu from 'metismenujs';
import SimpleBar from 'simplebar-react';

import { LOCATIONS } from 'constants/index';

import { ReactComponent as BriefCaseIconSVG } from 'assets/images/sideBar/far_fa-briefcase.svg';

import './styles.scss';

const SidebarContent = (props) => {
  const { locationList } = props;

  const ref = useRef();
  const location = useLocation();
  const locationId = new URLSearchParams(location.search).get('place');
  const curentLocation = locationList.find((item) => item._id === locationId);

  const onScrollElement = useCallback((item) => {
    if (item) {
      const currentPosition = item.offsetTop;

      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }, []);

  const onActivateParentDropdown = useCallback((item) => {
    item.classList.add('active');

    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];

    if (parent2El && parent2El.id !== 'side-menu') {
      parent2El.classList.add('mm-show');
    }

    if (parent) {
      parent.classList.add('mm-active');
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add('mm-show'); // ul tag
        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add('mm-active'); // li
          parent3.childNodes[0].classList.add('mm-active'); // a
          const parent4 = parent3.parentElement; // ul

          if (parent4) {
            parent4.classList.add('mm-show'); // ul
            const parent5 = parent4.parentElement;

            if (parent5) {
              parent5.classList.add('mm-show'); // li
              parent5.childNodes[0].classList.add('mm-active'); // a tag
            }
          }
        }
      }

      onScrollElement(item);

      return false;
    }

    onScrollElement(item);

    return false;
  }, []);

  const initMenu = useCallback(() => {
    // eslint-disable-next-line no-new
    new MetisMenu('#side-menu');
    let matchingMenuItem = null;

    const ul = document.getElementById('side-menu');
    const items = ul.getElementsByTagName('a');
    const pathName = location.pathname;
    const route = `/${pathName.split('/')[1]}`;

    for (let i = 0; i < items.length; i += 1) {
      if (pathName === items[i].pathname || route === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }

    if (matchingMenuItem) {
      onActivateParentDropdown(matchingMenuItem);
    }
  }, []);

  useEffect(() => {
    initMenu();
  }, []);

  useEffect(() => {
    ref.current.recalculate();
  }, [ref]);

  return (
    <SimpleBar className="h-100" ref={ref}>
      <div id="sidebar-menu">
        <ul className="metismenu list-unstyled" id="side-menu">
          <li>
            <Link to={LOCATIONS.DASHBOARD} className="">
              <i className="bx bx-home-circle" />

              <span className="badge rounded-pill bg-success float-end">Mới</span>

              <span>Trang tổng quan</span>
            </Link>
          </li>

          {/* <li>
            <Link to={LOCATIONS.ORDER_MANAGER}>
              <div className="wrapper-icon">
                <OrderIconSVG className="order-icon-svg" />
              </div>

              <span>Đơn hàng</span>
            </Link>
          </li> */}

          <li>
            <Link to={LOCATIONS.HOME_PAGE} className="has-arrow">
              <BriefCaseIconSVG className="sidebar-icon" />

              <span>Quản lý chỗ nghỉ</span>
            </Link>

            <ul className="sub-menu">
              <li><Link to={LOCATIONS.LOCATION_ADD}>Thêm chỗ nghỉ</Link></li>

              <li><Link to={LOCATIONS.LOCATION_LIST}>Danh sách chỗ nghỉ</Link></li>
              {curentLocation && (
              <li key={curentLocation?._id}>
                <Link to={`${LOCATIONS.LOCATION_DETAIL}?place=${curentLocation?._id}`} className="Location">
                  {/* FIXME: Name and image need to be obtained from the reducer  => */}
                  <img
                    src={curentLocation.previewImage}
                    alt="location-img"
                    className="img-thumbnail rounded-circle Location__Image"
                  />

                  <span className="Location__Title">{curentLocation.name}</span>
                </Link>
              </li>
              )}
            </ul>
          </li>
        </ul>
      </div>
    </SimpleBar>
  );
};

SidebarContent.propTypes = {
  locationList: PropTypes.instanceOf(Array),
};

SidebarContent.defaultProps = {
  locationList: [],
};

export default SidebarContent;
