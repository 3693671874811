import React, { memo } from 'react';
import PropTypes from 'prop-types';

import styles from './FormField.module.scss';

function FormField({
  children, label, isRequired, isHiddenLabel,
}) {
  return (
    <div className={styles.Component}>
      {
        !isHiddenLabel && (
          <label className={`${isRequired ? 'require' : ''}`} htmlFor={children.props?.id}>
            {label}
          </label>
        )
      }

      { children }
    </div>
  );
}

FormField.propTypes = {
  isHiddenLabel: PropTypes.bool,
  isRequired: PropTypes.bool,

  children: PropTypes.node.isRequired,
  label: PropTypes.string,
};

FormField.defaultProps = {
  isHiddenLabel: false,
  isRequired: false,

  label: '',
};

export default memo(FormField);
