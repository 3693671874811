import images from 'assets/images';

const languages = {
  vi: {
    label: 'Tiếng Việt',
    flag: images.flagVi.default,
  },
  en: {
    label: 'English',
    flag: images.flagUs.default,
  },
};

export default languages;
