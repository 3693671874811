import React, { useCallback, useState } from 'react';
import { useFormik } from 'formik';
import { Card, CardBody } from 'reactstrap';
// import * as Yup from 'yup';

import images from 'assets/images';

import AppInputValidation from 'components/App/InputValidation';
import AppSelectValidation from 'components/App/SelectValidation';
import SectionWrapper from '../SectionWrapper';
import FormField from '../FormField';

import styles from './StepSix.module.scss';

const FAKE_OPTIONS = [
  { value: 'value1', label: 'Giá trị 1' },
  { value: 'value2', label: 'Giá trị 2' },
  { value: 'value3', label: 'Giá trị 3' },
  { value: 'value4', label: 'Giá trị 4' },
  { value: 'value5', label: 'Giá trị 5' },
];

const INVOICE_TYPE = {
  FULL_NAME: 'FULL_NAME',
  LOCATION: 'LOCATION',
  COMPANY_NAME: 'COMPANY_NAME',
};

const INVOICE_TYPE_LIST = [
  { value: INVOICE_TYPE.FULL_NAME, label: 'Họ và Tên đăng ký ở bước 1' },
  { value: INVOICE_TYPE.LOCATION, label: 'Tên chỗ lưu trú' },
  { value: INVOICE_TYPE.COMPANY_NAME, label: 'Tên pháp lý của công ty (Vui lòng nêu rõ)' },
];

function StepSix() {
  const [isShowInformation, setIsShowInformation] = useState('allow');

  const onChangeRadio = useCallback(
    (e) => {
      setIsShowInformation(e.target.value);
    },
    [isShowInformation],
  );

  const validation = useFormik({
    initialValues: {
      invoiceType: INVOICE_TYPE_LIST[0].value,
      companyName: '',
      nation: FAKE_OPTIONS[0].value,
      city: FAKE_OPTIONS[0].value,
      district: FAKE_OPTIONS[0].value,
      address: '',
    },

    onSubmit: () => {

    },
  });

  const onChangeOption = useCallback((name) => (e) => {
    validation.setFieldValue(name, e.value, true);
  }, []);

  return (
    <div className={styles.Component}>
      <SectionWrapper title="Thanh toán" isRequired className="mb-24">
        <Card className="ContentWrapper">
          <div className="Input">
            <span className="Input__Text">Thẻ quốc tế</span>

            <div className="Input__InputContainer">
              <div className="InputWrapper">
                <input id="masterCard" type="checkbox" />

                <img
                  className="ml-8"
                  src={images.masterCardImage.default}
                  alt="payment"
                />

                <label className="cursor--pointer" htmlFor="masterCard">
                  Euro/Mastercard
                </label>
              </div>

              <div className="InputWrapper">
                <input id="jcb" type="checkbox" />

                <img
                  className="ml-8"
                  src={images.JCBImage.default}
                  alt="payment"
                />

                <label className="cursor--pointer" htmlFor="jcb">
                  JCB
                </label>
              </div>

              <div className="InputWrapper">
                <input id="visa" type="checkbox" />

                <img
                  className="ml-8"
                  src={images.visaImage.default}
                  alt="payment"
                />

                <label className="cursor--pointer" htmlFor="visa">
                  Visa
                </label>
              </div>

              <div className="InputWrapper">
                <input id="diners" type="checkbox" />

                <img
                  className="ml-8"
                  src={images.dinersClubImage.default}
                  alt="payment"
                />

                <label className="cursor--pointer" htmlFor="diners">
                  Diners Club
                </label>
              </div>
            </div>
          </div>

          <div className="Input">
            <span className="Input__Text">Thẻ nội địa</span>

            <div className="Input__InputContainer">
              <div className="InputWrapper">
                <input id="napas" type="checkbox" />

                <img
                  className="ml-8"
                  src={images.napasImage.default}
                  alt="payment"
                />

                <label htmlFor="napas" className="cursor--pointer">
                  Napas
                </label>
              </div>
            </div>
          </div>
        </Card>
      </SectionWrapper>

      <SectionWrapper title="Thanh toán hoa hồng" className="mb-24">
        <Card className="Card__Container">
          <CardBody className="Card__Content">
            Phần trăm hoa hồng:
            {' '}

            <span className="Card__Content__Percent">
              ??? %
            </span>
          </CardBody>
        </Card>

        <FormField label="Chúng tôi nên viết tên nào trên hóa đơn (ví dụ: tên công ty/pháp lý)?">
          <AppSelectValidation
            placeholder="Tên pháp lý của công ty (Vui lòng nêu rõ)"
            name="invoiceType"
            options={INVOICE_TYPE_LIST}
            value={validation.values.invoiceType}
            onChange={onChangeOption('invoiceType')}
          />
        </FormField>

        {validation.values.invoiceType === INVOICE_TYPE.COMPANY_NAME && (
          <FormField label="Tên pháp lý của công ty">
            <AppInputValidation
              type="text"
              name="companyName"
              placeholder="Vui lòng nhập tên pháp lý của công ty"
              validation={validation}
              isShowCounter
            />
          </FormField>
        )}

        <FormField label="Người nhận này có cùng địa chỉ với chỗ nghỉ của Quý vị không?">
          <div className="BtnRadioWrapper">
            <div className="Box">
              <input
                className="InputRadio"
                value="allow"
                type="radio"
                id="radio-allow"
                name="radio"
                onChange={onChangeRadio}
                checked={isShowInformation === 'allow'}
              />

              <label htmlFor="radio-allow">Có</label>
            </div>
            <div className="Box">
              <input
                className="InputRadio"
                value="deny"
                type="radio"
                id="radio-deny"
                name="radio"
                onChange={onChangeRadio}
                checked={isShowInformation === 'deny'}
              />

              <label htmlFor="radio-deny">Không</label>
            </div>
          </div>
        </FormField>

        {isShowInformation === 'deny' && (
          <>
            <FormField label="Chọn vùng/quốc gia của người nhận hóa đơn" isRequired>
              <AppSelectValidation
                name="nation"
                options={FAKE_OPTIONS}
                validation={validation}
                value={validation.values.nation}
                onChange={onChangeOption('nation')}
              />
            </FormField>

            <FormField label="Thành phố" isRequired>
              <AppSelectValidation
                name="city"
                options={FAKE_OPTIONS}
                validation={validation}
                value={validation.values.city}
                onChange={onChangeOption('city')}
              />
            </FormField>

            <FormField label="Quận/Huyện" isRequired>
              <AppSelectValidation
                name="province"
                options={FAKE_OPTIONS}
                validation={validation}
                value={validation.values.province}
                onChange={onChangeOption('province')}
              />
            </FormField>

            <FormField label="Địa chỉ" isRequired>
              <AppInputValidation
                type="text"
                name="address"
                placeholder="66/1 Thành Thái"
                validation={validation}
                isShowCounter
              />
            </FormField>
          </>
        )}

      </SectionWrapper>

      <SectionWrapper title="Quý vị sắp hoàn tất rồi – chỉ còn vài điều lưu ý cuối cùng" className="mb-24">
        <span className="Policy">
          Để giúp Quý vị bắt đầu kiếm doanh thu,
          chúng tôi sẽ tự động mở chỗ nghỉ của Quý vị cho khá
          ch đặt trong 18 tháng tới. Nếu muốn thay đổi tình trạng phòn
          g trống trước khi mở, Quý vị có thể chọn &ldquo;hoàn tất đăng
          ký và mở sau&ldquo; . Tình trạng phòng trống cũng
          có thể được điều chỉnh sau khi Quý vị mở phòng cho khách đặt.
        </span>
      </SectionWrapper>

      <SectionWrapper title="Để hoàn tất đăng ký, vui lòng đánh dấu vào những ô dưới đây:" className="mb-24 Policy">
        <div className="InputWrapper">
          <div className="Input">
            <input id="policy1" type="checkbox" />
          </div>

          <label htmlFor="policy1" className="Policy">
            Tôi chứng nhận đây là hoạt động kinh doanh lưu
            trú hợp pháp được cấp đầy đủ bằng và giấy phép cần thiết, có sẵn để trình
            khi có yêu cầu. Bookese.com B.V. có quyền
            kiểm chứng và điều tra bất kỳ thông tin bên Quý vị cung cấp khi đăng ký.
          </label>
        </div>

        <div className="InputWrapper">
          <div className="Input">
            <input id="policy2" type="checkbox" />
          </div>

          <label htmlFor="policy2" className="Policy">
            Tôi đã đọc, chấp nhận và đồng ý với
            {' '}
            <span className="Policy__Link">Điều khoản chung</span>
            {' '}
            và
            {' '}
            <span className="Policy__Link">Chính sách An toàn và Bảo mật.</span>
          </label>
        </div>
      </SectionWrapper>
    </div>
  );
}

export default StepSix;
