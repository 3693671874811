import PropTypes from 'prop-types';

const userSchema = {
  _id: PropTypes.string.isRequired,
  gender: PropTypes.string,
  isBlocked: PropTypes.bool,
  numOfTimeSendPhoneNumber: PropTypes.number,
  numOfTimeSendMail: PropTypes.number,
  name: PropTypes.string,
  displayName: PropTypes.string,
  email: PropTypes.string,
  phoneNumber: PropTypes.string,
  avatar: PropTypes.string,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
  birthday: PropTypes.string,
  shop: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    displayName: PropTypes.string,
  }).isRequired,

  firstName: PropTypes.string,
  lastName: PropTypes.string,
  middleName: PropTypes.string,
  username: PropTypes.string,
  address: PropTypes.string,
};

export const userPropTypes = PropTypes.shape(userSchema);

// default data
export const defaultData = {
  _id: '',
  name: '',
  displayName: '',
  isBlocked: false,
  numOfTimeSendPhoneNumber: 0,
  numOfTimeSendMail: 0,
  firstName: '',
  lastName: '',
  middleName: '',
  avatar: '',
  username: '',
  address: '',
  birthday: '',
  createdAt: '',
  email: '',
  updatedAt: '',
  phoneNumber: '',
  gender: '',

  shop: {
    _id: '',
    name: '',
    displayName: '',
  },
};
