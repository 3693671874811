import { connect } from 'react-redux';

import { actionResetPassword } from 'store/actions';

import ResetPassword from './ResetPassword';

const mapStateToProps = (state) => ({
  resetPasswordLoading: state.ResetPassword.loading,
});

const mapDispatchToProps = (dispatch) => ({
  actionResetPassword: (payload) => dispatch(actionResetPassword(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
