import React, {
  useState, useCallback, useMemo,
} from 'react';
import {
  Card,
  CardTitle,
  Container,
  Input,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';

import AppBreadcrumb from 'components/App/Breadcrumb';
import AppButton from 'components/App/Button';
import AppMeta from 'components/App/Meta';

import { LOCATIONS, priceType, themeColor } from 'constants/index';
import {
  guidGeneratorID,
} from 'utils';

import styles from './EditPriceType.module.scss';

const breadcrumbList = [
  {
    name: 'Giá và tình trạng phòng trống',
    isActive: false,
    link: LOCATIONS.LOCATION_LIST,
  },
  {
    name: 'Loại giá',
    isActive: false,
    link: LOCATIONS.LIST_PRICE_TYPE,
  },
  {
    name: 'Thêm loại giá hoàn toàn linh động',
    isActive: true,
    link: '',
  },
];

const dateType = {
  DYNAMIC_ONE: 'DYNAMIC_ONE',
  DYNAMIC_TWO: 'DYNAMIC_TWO',
};

const meal = {
  BREAKFAST: 'BREAKFAST',
  LUNCH: 'LUNCH',
  DINNER: 'DINNER',
};

function EditPriceType() {
  const { t } = useTranslation();

  const [dateTypeSelected, setDateTypeSelected] = useState('');
  const [isHaveMeal, setIsHaveMeal] = useState(false);
  const [mealType, setMealType] = useState('');
  const [roomsType, setRoomsType] = useState([
    {
      id: guidGeneratorID(),
      label: 'Suite with Terrace',
      isChecked: true,
      amount: 0,
    },
    {
      id: guidGeneratorID(),
      label: 'Superior Twin Room',
      isChecked: true,
      amount: 0,
    },
    {
      id: guidGeneratorID(),
      label: 'Superior Double Room',
      isChecked: true,
      amount: 0,
    },
    {
      id: guidGeneratorID(),
      label: 'Deluxe Double Room',
      isChecked: true,
      amount: 0,
    },
    {
      id: guidGeneratorID(),
      label: 'Suite with City View',
      isChecked: true,
      amount: 0,
    },
  ]);

  const onToggleCheckedRoom = useCallback((event) => {
    const newList = roomsType.map((item) => {
      const date = { ...item };
      if (date.id === event.target.value) {
        date.isChecked = !date.isChecked;
      }

      return date;
    });

    setRoomsType(newList);
  }, [roomsType]);

  const onCheckedAllRooms = useCallback(() => {
    const newList = roomsType.map((item) => {
      const date = { ...item };
      date.isChecked = true;

      return date;
    });

    setRoomsType(newList);
  }, [roomsType]);

  const onChangeDateType = useCallback((event) => {
    setDateTypeSelected(event.target.value);
  }, []);

  const onChangeMealIncluded = useCallback((event) => {
    setMealType(event.target.value);
  }, []);

  const onChangeHaveMeal = useCallback((event) => {
    const value = Boolean(Number(event.target.value));
    setIsHaveMeal(value);

    if (!value) {
      setMealType('');
    }
  }, []);

  const RenderDateType = useMemo(() => Object.keys(dateType).map((key) => (
    <div className="Box" key={key}>
      <input
        className="InputRadio"
        type="radio"
        id={key}
        name="dateType"
        checked={dateTypeSelected === key}
        value={key}
        onChange={onChangeDateType}
      />

      <label htmlFor={key}>{t(`global.date_type.${key}`)}</label>
    </div>
  )), [dateType, dateTypeSelected, onChangeDateType]);

  const RenderMealType = useMemo(() => Object.keys(meal).map((key) => (
    <div className="Checkbox Checkbox--RoomBlock" key={key}>
      <Input
        type="checkbox"
        checked={mealType === key}
        disabled={!isHaveMeal}
        className="Checkbox__Input"
        value={key}
        id={key}
        onChange={onChangeMealIncluded}
      />

      <label htmlFor={key} className="Checkbox__Name">
        {t(`global.meal.${key}`)}
      </label>
    </div>
  )), [meal, mealType, isHaveMeal, onChangeMealIncluded]);

  return (
    <div className={`page-content ${styles.Page}`}>
      <AppMeta
        name="Thêm loại giá hoàn toàn linh động"
      />

      <Container fluid>
        <AppBreadcrumb
          pageTitle="Thêm loại giá hoàn toàn linh động"
          breadcrumbList={breadcrumbList}
        />

        <div className="CContainer">
          <div className="CContainer__Body">
            <Card className="Section">
              <CardTitle className="Section__Header">
                <div className="Section__Header__Number">1</div>

                <span className="Section__Header__Title">Quý vị muốn sử dụng chính sách hủy phòng nào cho loại giá này?</span>
              </CardTitle>

              <div className="Section__Body">
                <div className="BtnRadioWrapper">
                  {RenderDateType}
                </div>
              </div>
            </Card>

            <Card className="Section">
              <CardTitle className="Section__Header">
                <div className="Section__Header__Number">2</div>

                <span className="Section__Header__Title">Quý vị có muốn bao gồm bữa ăn trong loại giá này?</span>
              </CardTitle>

              <div className="Section__Body">
                <div className="BtnRadioWrapper">
                  <div className="Box">
                    <input
                      className="InputRadio"
                      type="radio"
                      id="NotIncluded"
                      name="meal"
                      checked={!isHaveMeal}
                      value={0}
                      onChange={onChangeHaveMeal}
                    />

                    <label htmlFor="NotIncluded">Không</label>
                  </div>

                  <div className="Box">
                    <input
                      className="InputRadio"
                      type="radio"
                      id="included"
                      name="meal"
                      checked={isHaveMeal}
                      value={1}
                      onChange={onChangeHaveMeal}
                    />

                    <label htmlFor="included">Có, thêm lựa chọn bữa ăn</label>
                  </div>
                </div>

                <div className="Section__Body--SubChoose">
                  <div className="Section_RoomBlock">
                    <div className="Section__RoomBlock--CheckBox">
                      {RenderMealType}
                    </div>
                  </div>
                </div>
              </div>
            </Card>

            <Card className="Section">
              <CardTitle className="Section__Header">
                <div className="Section__Header__Number">3</div>

                <span className="Section__Header__Title">Quý vị có muốn đặt tên cho loại giá này là gì?</span>
              </CardTitle>

              <div className="Section__Body">
                <div className="Section_RoomBlock">
                  <div className="Section__RoomBlock--CheckBox">
                    {
                  roomsType.map((item) => (
                    <div className="Checkbox Checkbox--RoomBlock" key={item.id}>
                      <Input
                        type="checkbox"
                        checked={item.isChecked}
                        className="Checkbox__Input"
                        value={item.id}
                        id={item.id}
                        onChange={onToggleCheckedRoom}
                      />

                      <label htmlFor={item.id} className="Checkbox__Name">
                        {item.label}
                      </label>
                    </div>
                  ))
                }
                  </div>

                  <button
                    className="btn-minimal blue-100 mt-24"
                    type="button"
                    onClick={onCheckedAllRooms}
                  >
                    Chọn tất cả
                  </button>
                </div>
              </div>
            </Card>

            <Card className="Section mb-0">
              <CardTitle className="Section__Header">
                <div className="Section__Header__Number">4</div>

                <span className="Section__Header__Title">Quý vị có muốn đặt tên cho loại giá này là gì?</span>
              </CardTitle>

              <div className="Section__Body">
                <Input
                  name="priceType"
                  type="text"
                  defaultValue={t(`global.price_type.${priceType.NON_REFUNDABLE_PRICE}`)}
                />
              </div>
            </Card>
          </div>

          <div className="CContainer__Footer">
            <div className="d-flex justify-content-end">
              <AppButton color={themeColor.SECONDARY} classes="ButtonAction mr-24">
                Hủy
              </AppButton>

              <AppButton classes="ButtonAction">
                Cập nhật
              </AppButton>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default EditPriceType;
