import React, { memo } from 'react';
import { Carousel as CarouselReact } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Col } from 'reactstrap';

import { useTranslation } from 'react-i18next';

import { SITE_NAME } from 'constants/index';

const Carousel = () => {
  const { t } = useTranslation();
  return (
    <Col xl={9}>
      <div className="auth-full-bg pt-lg-5 p-4">
        <div className="w-100">
          <div className="bg-overlay" />

          <div className="d-flex h-100 flex-column">
            <div className="p-4 mt-auto">
              <div className="row justify-content-center">
                <div className="col-lg-7">
                  <div className="text-center">
                    <h4 className="mb-3">
                      <i className="bx bxs-quote-alt-left text-primary h1 align-middle me-3" />
                      <span className="text-primary">{SITE_NAME}</span>
                    </h4>

                    <div dir="ltr">
                      <CarouselReact showThumbs={false} className="slider_css">
                        <div>
                          <div className="item">
                            <div className="py-3">
                              <p className="font-size-16 mb-4" />

                              <div>
                                <h4 className="font-size-16 text-primary">
                                  -
                                  {' '}
                                  {t('auth.by')}
                                  {' '}
                                  Wiicamp
                                </h4>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div>
                          <div className="item">
                            <div className="py-3">
                              <p className="font-size-16 mb-4" />

                              <div>
                                <h4 className="font-size-16 text-primary">
                                  -
                                  {' '}
                                  {t('auth.by')}
                                  {' '}
                                  Wiicamp
                                </h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </CarouselReact>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Col>
  );
};

export default memo(Carousel);
