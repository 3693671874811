import { put, takeLeading } from 'redux-saga/effects';

import mediaApi from 'api/mediaApi';
import mediaApiMini from 'api/uploadMiniApi';

import {
  LIMIT_FILE_SIZE,

  mediaFolder,
} from 'constants/index';
import {
  apiErrorHandler, showSuccessTrans,
} from 'utils';

import {
  GET_LIST_MEDIA,
  GET_MEDIA_DETAIL,
  UPLOAD_MEDIA,
  UPDATE_MEDIA,
  DELETE_MEDIA,
} from './actionTypes';
import {
  actionGetListMediaFailed,
  actionGetListMediaSuccess,

  actionGetMediaDetailSuccess,
  actionGetMediaDetailFailed,

  actionUploadMediaSuccess,
  actionUploadMediaFailed,

  actionUpdateMediaSuccess,
  actionUpdateMediaFailed,

  actionDeleteMediaSuccess,
  actionDeleteMediaFailed,
} from './actions';

// GET LIST MEDIA
function* getListMedia({ payload }) {
  try {
    const response = yield mediaApi.getListMedia(payload);

    yield put(actionGetListMediaSuccess(response.payload));
  } catch (error) {
    apiErrorHandler(error);
    yield put(actionGetListMediaFailed());
  }
}

// GET MEDIA DETAIL
function* getMediaDetail({ payload }) {
  try {
    const response = yield mediaApi.getMediaDetail(payload);

    yield put(actionGetMediaDetailSuccess(response.payload));
  } catch (error) {
    apiErrorHandler(error);
    yield put(actionGetMediaDetailFailed());
  }
}

// UPLOAD MEDIA
function* uploadMedia({ payload }) {
  const {
    file,
    typeImg,
    onUploadImage,
    fileSizeLimit,
  } = payload;
  let response;

  try {
    if (fileSizeLimit <= LIMIT_FILE_SIZE.BIG || typeImg === mediaFolder.INVOICE) {
      response = yield mediaApiMini.uploadSingleMiniFile({ file, typeImg });
    } else {
      response = yield mediaApi.uploadMedia({ file, typeImg });
    }
    onUploadImage(response.payload);

    yield put(actionUploadMediaSuccess(response.payload));
  } catch (error) {
    apiErrorHandler(error);
    yield put(actionUploadMediaFailed());
  }
}

// UPDATE MEDIA
function* updateMedia({ payload }) {
  try {
    const response = yield mediaApi.updateMedia(payload);

    yield put(actionUpdateMediaSuccess(response.payload));

    showSuccessTrans({ type: 'type.UPDATE' });
  } catch (error) {
    apiErrorHandler(error);
    yield put(actionUpdateMediaFailed());
  }
}

// DELETE MEDIA
function* deleteMedia({ payload }) {
  try {
    yield mediaApi.deleteMedia({ _id: payload });

    yield put(actionDeleteMediaSuccess({ _id: payload }));

    showSuccessTrans({ type: 'type.DELETE' });
  } catch (error) {
    apiErrorHandler(error);
    yield put(actionDeleteMediaFailed());
  }
}

export default function* mediaSaga() {
  yield takeLeading(GET_LIST_MEDIA, getListMedia);
  yield takeLeading(GET_MEDIA_DETAIL, getMediaDetail);
  yield takeLeading(UPLOAD_MEDIA, uploadMedia);
  yield takeLeading(UPDATE_MEDIA, updateMedia);
  yield takeLeading(DELETE_MEDIA, deleteMedia);
}
