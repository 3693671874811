import { connect } from 'react-redux';
import {
  actionGetListMedia,
  actionUploadMedia,
} from 'store/actions';

import PopupFormUploadMedia from './PopupFormUploadMedia';

const mapStateToProps = (state) => ({
  mediaTotal: state.Media.mediaTotal,

  mediaList: state.Media.mediaList,
});

const mapDispatchToProps = (dispatch) => ({
  actionGetListMedia: (payload) => dispatch(actionGetListMedia(payload)),

  actionUploadMedia: (payload) => dispatch(actionUploadMedia(payload)),

});

export default connect(mapStateToProps, mapDispatchToProps)(PopupFormUploadMedia);
