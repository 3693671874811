import React, { useMemo, useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  FormFeedback, Input, Label, Form,
  Row, Col, Card, CardBody, Container,
} from 'reactstrap';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';

import images from 'assets/images';
import { checkHadError, getButtonNameTrans, validationErrorHandler } from 'utils';
import { accountValidation } from 'utils/validate';

import AppButton from 'components/App/Button';
import Meta from 'components/App/Meta';

const ResetPassword = (props) => {
  const {
    actionResetPassword,
    resetPasswordLoading,
  } = props;

  const history = useHistory();
  const { t } = useTranslation();

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      password: '',
      confirmPassword: '',
    },

    validationSchema: Yup.object({
      password: accountValidation.password,

      confirmPassword: accountValidation.confirmPassword,
    }),

    onSubmit: (values) => {
      actionResetPassword({ user: values, history });
    },
  });

  const onSubmit = useCallback((e) => {
    e.preventDefault();
    validation.handleSubmit();
  }, []);

  const validationErrors = useMemo(() => validation.errors, [validation.errors]);

  return (
    <>
      <Meta name="change_pass" />

      <div className="account-pages">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">{t('request_success')}</h5>
                        <p>{t('change_pass_success_description')}</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={images.authProfile.default} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={images.logoMini.default}
                            alt=""
                            className=""
                            height="42"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={onSubmit}
                    >

                      <div className="mb-3">
                        <Label className="form-label">
                          {t('password')}
                          <code>*</code>
                        </Label>

                        <Input
                          name="password"
                          type="password"
                          placeholder={getButtonNameTrans({
                            type: 'type.ENTER',
                            name: 'password',
                          })}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.password || ''}
                          invalid={checkHadError(validation, 'password')}
                        />

                        {
                          checkHadError(validation, 'password') && (
                            <FormFeedback type="invalid">
                              {validationErrorHandler(validationErrors.password)}
                            </FormFeedback>
                          )
                        }
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">
                          {t('re_enter_password')}
                          <code>*</code>
                        </Label>

                        <Input
                          name="confirmPassword"
                          type="password"
                          placeholder={getButtonNameTrans({
                            type: '',
                            name: 're_enter_password',
                          })}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.confirmPassword || ''}
                          invalid={checkHadError(validation, 'confirmPassword')}
                        />

                        {
                          checkHadError(validation, 'confirmPassword') && (
                            <FormFeedback type="invalid">
                              {validationErrorHandler(validationErrors.confirmPassword)}
                            </FormFeedback>
                          )
                        }
                      </div>
                      <Row className="mb-3">
                        <Col className="text-end">
                          {resetPasswordLoading ? <AppButton loading={resetPasswordLoading} /> : (
                            <button
                              disabled={resetPasswordLoading}
                              className="btn btn-primary btn-block "
                              type="submit"
                            >
                              {t('reset_password')}
                            </button>
                          )}
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  {t('back')}
                  {' '}
                  <Link to="login" className="font-weight-medium text-primary">
                    {t('login.title')}
                  </Link>
                  {' '}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

ResetPassword.propTypes = {
  actionResetPassword: PropTypes.func.isRequired,
  resetPasswordLoading: PropTypes.bool.isRequired,
};

export default ResetPassword;
