import React, { useCallback, useRef, useState } from 'react';
import {
  Container,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Card,
  CardBody,
  // CardText,
} from 'reactstrap';
import classnames from 'classnames';

// Import Breadcrumb
import AppButton from 'components/App/Button';
import Breadcrumb from 'components/App/Breadcrumb';
import Meta from 'components/App/Meta';
import StepFive from 'components/AddLocation/StepFive';
import StepFour from 'components/AddLocation/StepFour';
import StepOne from 'components/AddLocation/StepOne';
import StepThree from 'components/AddLocation/StepThree';
import StepTwo from 'components/AddLocation/StepTwo';
import StepSix from 'components/AddLocation/StepSix';

import images from 'assets/images';
import { ReactComponent as UserIconSVG } from 'assets/images/location/person-pin.svg';
import { ReactComponent as TagIconSVG } from 'assets/images/location/local-offer.svg';
import { ReactComponent as ServicesIconSVG } from 'assets/images/location/restaurant-menu.svg';
import { ReactComponent as ImageIconSVG } from 'assets/images/location/add-photo_alternate.svg';
import { ReactComponent as PolicyIconSVG } from 'assets/images/location/event-note.svg';
import { ReactComponent as MoneyIconSVG } from 'assets/images/location/bx-money.svg';
import { ReactComponent as PhoneIconSVG } from 'assets/images/location/phone-icon.svg';
import { ReactComponent as NextIconSVG } from 'assets/images/location/next-icon.svg';
import { ReactComponent as PrevIconSVG } from 'assets/images/location/prev-icon.svg';

import styles from './EditLocation.module.scss';

const TAB = {
  BASIC: 1,
  ROOM_PRICE: 2,
  AMENITIES_SERVICES: 3,
  IMAGE: 4,
  POLICY: 5,
  PAYMENT: 6,
};

const NAV_TAB = [
  {
    icon: UserIconSVG,
    title: 'Thông tin cơ bản',
    value: TAB.BASIC,
  },
  {
    icon: TagIconSVG,
    title: 'Phòng và Giá',
    value: TAB.ROOM_PRICE,
  },
  {
    icon: ServicesIconSVG,
    title: 'Tiện nghi và dịch vụ',
    value: TAB.AMENITIES_SERVICES,
  },
  {
    icon: ImageIconSVG,
    title: 'Hình ảnh',
    value: TAB.IMAGE,
  },
  {
    icon: PolicyIconSVG,
    title: 'Chính sách',
    value: TAB.POLICY,
  },
  {
    icon: MoneyIconSVG,
    title: 'Thanh toán',
    value: TAB.PAYMENT,
  },
];

const breadcrumbList = [
  {
    name: 'Quản lý chỗ nghỉ',
    isActive: false,
    link: '', // TODO: ADD ROUTE WHEN HAVE LINK
  },
  {
    name: 'Thêm chỗ nghỉ',
    isActive: true,
    link: '', // TODO: ADD ROUTE WHEN HAVE LINK
  },
];

const EditLocation = () => {
  const topRef = useRef();

  const [activeTab, setActiveTab] = useState(TAB.BASIC);
  const [isShowFinalStep, setIsShowFinalStep] = useState(false);

  const onChangeTab = useCallback((tab) => () => {
    setActiveTab(tab);
  }, []);

  const onPrevStep = useCallback(() => {
    setActiveTab((prevState) => prevState - 1);

    topRef.current.scrollIntoView({ behavior: 'smooth' });
  }, [activeTab]);

  const onNextStep = useCallback(() => {
    if (activeTab === TAB.PAYMENT) {
      setIsShowFinalStep(true);
    } else {
      setActiveTab((prevState) => prevState + 1);

      topRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [activeTab]);

  return (
    <>
      <div className="page-content" ref={topRef}>
        <Meta name="Thêm chỗ nghỉ" />

        <Container fluid>
          {
            isShowFinalStep ? (
              <div className={styles.PageFinal}>
                <div className="Modal">
                  <h3 className="Modal__Title">Chúng tôi đang tạo tài khoản Chỗ nghỉ cho Quý vị</h3>

                  <div className="Modal__Description">
                    <p>
                      Hãy thư giãn nhé vì bây giờ là lúc chúng tôi thiết lập tài khoản
                      chỗ nghỉ cho Quý vị và chuẩn bị sẵn sàng tài khoản Connect (extranet).
                    </p>

                    <p>
                      Nhằm giúp Quý vị lưu giữ hồ sơ, chúng tôi cũng đã gửi cho
                      Quý vị một email xác nhận đăng ký cùng với một email khác
                      có đính kèm bản sao thỏa thuận của Quý vị.
                    </p>

                    <p>
                      Chúng tôi sẽ gửi email cho Quý vị ngay khi mọi việc đã sẵn sàng.
                    </p>
                  </div>

                  <img
                    src={images.finalStepImage.default}
                    alt="final step"
                  />
                </div>
              </div>
            ) : (
              <>
                <Breadcrumb
                  pageTitle="Thêm chỗ nghỉ"
                  breadcrumbList={breadcrumbList}
                />

                <div className={`checkout-tabs ${styles.Page}`}>
                  <Row>
                    <Col className="NavMenu" sm="12">
                      <Nav className="flex-column" pills>
                        {
                    NAV_TAB.map((tab) => (
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === tab.value })}
                          onClick={onChangeTab(tab.value)}
                          key={tab.value}
                        >
                          <tab.icon className="d-block check-nav-icon mt-4 mb-2" />
                          <p className="font-weight-bold mb-4">{tab.title}</p>
                        </NavLink>
                      </NavItem>
                    ))
                  }
                      </Nav>
                    </Col>

                    <Col className="NavBody" sm="12">
                      <TabContent activeTab={activeTab}>
                        <TabPane tabId={TAB.BASIC}>
                          <StepOne />
                        </TabPane>

                        <TabPane tabId={TAB.ROOM_PRICE}>
                          <StepTwo />
                        </TabPane>

                        <TabPane tabId={TAB.AMENITIES_SERVICES}>
                          <StepThree />
                        </TabPane>

                        <TabPane
                          tabId={TAB.IMAGE}
                        >
                          <StepFour />
                        </TabPane>

                        <TabPane tabId={TAB.POLICY}>
                          <StepFive />
                        </TabPane>

                        <TabPane tabId={TAB.PAYMENT}>
                          <StepSix />
                        </TabPane>
                      </TabContent>

                      <Row className="mt-4 mb-24">
                        <Col sm="6">
                          {
                      activeTab !== TAB.BASIC && (
                        <AppButton
                          classes="Btn Btn__PrevStep"
                          outline
                          onClick={onPrevStep}
                        >
                          <PrevIconSVG className="Icon__PrevStep" />
                          Quay về bước trước
                        </AppButton>
                      )
                    }
                        </Col>

                        <Col sm="6">
                          <div className="text-sm-end">
                            <AppButton
                              classes="Btn Btn__NextStep"
                              onClick={onNextStep}
                            >
                              Bước tiếp theo
                              <NextIconSVG className="Icon__NextStep" />
                            </AppButton>
                          </div>
                        </Col>
                      </Row>
                    </Col>

                    <Col className="NavMenu" sm="12">
                      <Card>
                        <CardBody>
                          <sub className="SubTitle">Sau khi hoàn tất đăng ký, Quý vị vẫn sẽ có thể thực hiện thay đổi tại Extranet của Bookese</sub>
                        </CardBody>
                      </Card>

                      <Card>
                        <CardBody>
                          <div className="Hotline">
                            <PhoneIconSVG className="Hotline__Icon" />
                            <div className="Hotline__Number">
                              078 222 1131
                            </div>
                          </div>
                          <sub className="SubTitle text-center">Nếu cần hỗ trợ trong quá trình tạo chỗ nghỉ, hãy liên hệ với Bookese!</sub>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </div>
              </>
            )
          }
        </Container>
      </div>
    </>
  );
};

export default EditLocation;
