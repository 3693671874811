/* eslint-disable max-len */
import { ReactComponent as HomeHeaderIcon } from 'assets/images/headerNavigation/bx-home-circle.svg';
import { ReactComponent as PriceAvailabilityHeaderIcon } from 'assets/images/headerNavigation/bx-calendar.svg';
// import { ReactComponent as PromotionHeaderIcon }from 'assets/images/headerNavigation/promotion-logo.svg';
// import { ReactComponent as BookingHeaderIcon } from 'assets/images/headerNavigation/bx-file.svg';
import { ReactComponent as LocationHeaderIcon } from 'assets/images/headerNavigation/bed-logo.svg';
// import { ReactComponent as ReviewHeaderIcon } from 'assets/images/headerNavigation/bx-chat.svg';
// import { ReactComponent as FinanceHeaderIcon } from 'assets/images/headerNavigation/money-logo.svg';
// import { ReactComponent as AnalysisHeaderIcon } from 'assets/images/headerNavigation/bxs-bar-chart-alt-2.svg';

export const LOCATIONS = {
  HOME_PAGE: '/',
  DASHBOARD: '/dashboard',

  NOT_FOUND: '/404-NOT-FOUND',

  LOGIN: '/login',
  REGISTER: '/register',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',

  PROFILE: '/profile',

  MEDIA: '/media',
  MEDIA_VIEW: '/media/:id',

  ANALYSIS: '/analysis',
  BOOKING: '/booking',
  FINANCE: '/finance',

  LOCATION_LIST: '/locations',
  LOCATION_ADD: '/locations/add',
  ADD_ALREADY_LOCATION: '/locations/already-location',
  LOCATION_CONTACT_INFO: '/locations/detail/contact-info',
  LOCATION_POLICY: '/locations/detail/policy',
  LOCATION_CONVENIENCE_SERVICE: '/locations/detail/conveniences-services',
  LOCATION_ROOM_TYPE: '/location/room-type',
  LOCATION_CREATE_ROOM: '/locations/room-type/create-new-room',
  LOCATION_EDIT_ROOM: '/locations/room-type',
  LOCATION_CONVENIENCE_ROOM: '/locations/convenience-room',
  LOCATION_DETAIL: '/locations/detail',

  PRICE_AVAILABILITY: '/price-availability',
  CALENDAR: '/locations/detail/calendar/',
  OPEN_CLOSE_ROOM: '/locations/detail/open-close-rooms',
  UPDATE_AMOUNT_ROOM: '/locations/detail/update-amount-rooms',
  UPDATE_PRICE: '/locations/detail/update-price',
  LIST_PRICE_TYPE: '/locations/detail/list-price-type',
  UPDATE_PRICE_TYPE: '/locations/detail/update-price-type',
  PROMOTION: '/promotion',
  REVIEW: '/review',
};

export const headerNavigation = [
  {
    link: LOCATIONS.DASHBOARD,
    name: 'header.navigation.home',
    icon: HomeHeaderIcon,
  },
  {
    name: 'header.navigation.price_room_status',
    icon: PriceAvailabilityHeaderIcon,
    children: [
      {
        name: 'header.navigation.review',
        link: LOCATIONS.PRICE_AVAILABILITY,
      },
      {
        name: 'header.navigation.calendar',
        link: LOCATIONS.CALENDAR,
      },
      {
        name: 'header.navigation.open_close_room',
        link: LOCATIONS.OPEN_CLOSE_ROOM,
      },
      {
        name: 'header.navigation.update_sales_room',
        link: LOCATIONS.UPDATE_AMOUNT_ROOM,
      },
      {
        name: 'header.navigation.update_selling_price',
        link: LOCATIONS.UPDATE_PRICE,
      },
      {
        name: 'header.navigation.price_type',
        link: LOCATIONS.LIST_PRICE_TYPE,
      },
    ],
  },
  // {
  //   link: LOCATIONS.PROMOTION,
  //   name: 'header.navigation.promotion',
  //   icon: PromotionHeaderIcon,
  // },
  // {
  //   link: LOCATIONS.BOOKING,
  //   name: 'header.navigation.booking',
  //   icon: BookingHeaderIcon,
  // },
  {
    name: 'header.navigation.location',
    icon: LocationHeaderIcon,
    children: [
      {
        name: 'header.navigation.general',
        link: LOCATIONS.LOCATION_DETAIL,
      },
      {
        name: 'header.navigation.policy',
        link: LOCATIONS.LOCATION_POLICY,
      },
      {
        name: 'header.navigation.conveniences_services',
        link: LOCATIONS.LOCATION_CONVENIENCE_SERVICE,
      },
      {
        name: 'header.navigation.room_type',
        link: LOCATIONS.LOCATION_ROOM_TYPE,
      },
      {
        name: 'header.navigation.room_amenities',
        link: LOCATIONS.LOCATION_CONVENIENCE_ROOM,
      },
      {
        name: 'header.navigation.picture',
        link: LOCATIONS.PRICE_AVAILABILITY,
      },
      {
        name: 'header.navigation.contact',
        link: LOCATIONS.LOCATION_CONTACT_INFO,
      },
    ],
  },
  // {
  //   link: LOCATIONS.REVIEW,
  //   name: 'header.navigation.review',
  //   icon: ReviewHeaderIcon,
  // },
  // {
  //   link: LOCATIONS.FINANCE,
  //   name: 'header.navigation.finance',
  //   icon: FinanceHeaderIcon,
  // },
  // {
  //   link: LOCATIONS.ANALYSIS,
  //   name: 'header.navigation.analysis',
  //   icon: AnalysisHeaderIcon,
  // },
];

export const SITE_NAME = 'Bookese';
export const COMPANY = 'Wiicamp';

// Storage key
export const storageKey = {
  AUTHENTICATE_TOKEN: 'AUTHENTICATE_TOKEN',
  AUTHENTICATE_REFRESH_TOKEN: 'AUTHENTICATE_REFRESH_TOKEN',
};

export const DEBOUNCE_SEARCH_DELAY = 300;

export const LIMIT_FILE_SIZE = {
  NORMAL: 5 * 1024 * 1024,
  BIG: 30 * 1024 * 1024,
};

export const themeColor = {
  DANGER: 'danger',
  INFO: 'info',
  LIGHT: 'light',
  LINK: 'link',
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  SUCCESS: 'success',
  WARNING: 'warning',
};

// sẽ xóa sau khi có data mới
export const employeeType = {
  FULLTIME: 'FULLTIME',
  PARTTIME: 'PARTTIME',
  INTERN: 'INTERN',
};

export const employeeTypeSelectList = [
  {
    value: employeeType.FULLTIME,
    color: themeColor.SUCCESS,
  },
  {
    value: employeeType.PARTTIME,
    color: themeColor.INFO,
  },
  {
    value: employeeType.INTERN,
    color: 'warning',
  },
];

export const userGender = {
  MALE: 'MALE',
  FEMALE: 'FEMALE',
  OTHER: 'OTHER',
};

export const userGenderSelectList = [
  { value: userGender.MALE, label: 'Nam' },
  { value: userGender.FEMALE, label: 'Nữ' },
  { value: userGender.OTHER, label: 'Khác' },
];

export const mediaTypeSelectList = [
  {
    value: 0,
  },
  {
    value: 1,
  },
  {
    value: 2,
  },
  {
    value: 3,
  },
  {
    value: 4,
  },
];

export const mediaFolder = {
  AVATAR: 'AVATAR',
  ORGANIZATION: 'ORGANIZATION',
  INVOICE: 'INVOICE',
  SKILL: 'SKILL',
  PROJECT: 'PROJECT',
};

export const mediaFolderSelectList = [
  {
    value: mediaFolder.AVATAR,
  },
  {
    value: mediaFolder.ORGANIZATION,
  },
  {
    value: mediaFolder.INVOICE,
  },
  {
    value: mediaFolder.SKILL,
  },
  {
    value: mediaFolder.PROJECT,
  },
];

export const mediaMimeType = {
  JPEG: 'image/jpeg',
  PNG: 'image/png',
  PDF: 'application/pdf',
  EXCEL: 'application/vnd.ms-excel',
  SHEET: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  WORD: 'application/msword',
  DOCUMENT: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
};

export const mediaMimeTypeSelectList = [
  {
    value: mediaMimeType.JPEG,
  },
  {
    value: mediaMimeType.PNG,
  },
  {
    value: mediaMimeType.PDF,
  },
  {
    value: mediaMimeType.EXCEL,
  },
  {
    value: mediaMimeType.SHEET,
  },
  {
    value: mediaMimeType.WORD,
  },
  {
    value: mediaMimeType.DOCUMENT,
  },
];

export const imageType = {
  PDF: 'pdf',
};

export const searchType = {
  STATUS: 'status',
  REQUEST: 'request',
  CASHFLOW_TYPE: 'cashflow-type',
  PERSONAL_CLIENT: 'personal-client',
  BUSINESS_CLIENT: 'business-client',
  TRANSACTION_CLASSIFICATION: 'transaction-classification',
  STATUS_PROJECT: 'status-project',
  MEDIA_FOLDER: 'media-folder',
  MEDIA_TYPE: 'media-type',
  STATUS_SLIDE: 'status-slide',
};

export const typeMediaUpload = {
  TRANSACTION: 'TRANSACTION',
  DEFAULT: 'DEFAULT',
};

export const allowPermission = {
  // PUBLISH PERMISSIONS
  HOME: 'HOME',
  PROFILE: 'PROFILE',
  MY_REQUEST: 'MY_REQUEST',
  LEAVE_REQUEST: 'LEAVE_REQUEST',
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT',

  // MEDIA
  VIEW_ALL_MEDIA: 'VIEW_ALL_MEDIA',
  VIEW_LIST_MEDIA: 'VIEW_LIST_MEDIA',
  VIEW_DETAIL_MEDIA: 'VIEW_DETAIL_MEDIA',
  EDIT_MEDIA: 'EDIT_MEDIA',
  DELETE_MEDIA: 'DELETE_MEDIA',
};

export const statusSlide = {
  PUBLIC: 'PUBLIC',
  PENDING: 'PENDING',
};

export const validationCheckErrorType = {
  ERR_ONLY: 'ERR_ONLY',
  TOUCHED_ONLY: 'TOUCHED_ONLY',
};

export const UPLOAD_FILE_TYPE = {
  PICTURE_ONLY: 'PICTURE_ONLY',
  VIDEO_ONLY: 'VIDEO_ONLY',
};

export const ORDER_STATUS = {
  ALL: '',
  ACCEPTED: 'ACCEPTED',
  CANCELLED: 'CANCELLED',
  DELIVERING: 'DELIVERING',
  DELIVERED: 'DELIVERED',
  FAILED: 'FAILED',
  PAID: 'PAID',
  PENDING: 'PENDING',
  PREPARING: 'PREPARING',
  RECEIVED: 'RECEIVED',
  REJECTED: 'REJECTED',
  WAITING_PAYMENT: 'WAITING_PAYMENT',
};

export const VOUCHER_DISCOUNT_TYPES = {
  PRICE: 'PRICE',
  PERCENT: 'PERCENT',
};

export const VOUCHER_STATUSES = {
  ALL: {
    text: 'Tất cả',
    label: 'ALL',
  },
  NEW: {
    code: 0,
  },
  UPCOMING: {
    text: 'Sắp diễn ra',
    value: 'UPCOMING',
    label: 'UPCOMING',
    code: 1,
    class: 'badge-danger-custom',
  },
  ONGOING: {
    text: 'Đang diễn ra',
    value: 'ONGOING',
    label: 'ONGOING',
    code: 2,
    class: 'badge-success-custom',
  },
  ENDED: {
    text: 'Đã kết thúc',
    value: 'ENDED',
    label: 'ENDED',
    code: 3,
    class: 'badge-info-custom',
  },
};

export const VOUCHER_MAXIMUM_DISCOUNT_TYPE = {
  LIMITED: 'LIMITED',
  UNLIMITED: 'UNLIMITED',
};

export const MODAL_ADD_LOCATION_TAB = {
  NEW: 'NEW',
  ALREADY: 'ALREADY',
};

export const ARRAY_OF_PHONE_LINES = [1, 2, 3, 4, 5, 6];

export const BEST_CONVENIENCE = [
  {
    label: 'Máy lạnh',
    name: 'air_condition',
  },
  {
    label: 'Máy sưởi',
    name: 'heater',
  },
  {
    label: 'Quạt máy',
    name: 'electric_fan',
  },
];

export const OTHER_CONVENIENCE = [
  {
    label: 'TV màn hình phẳng',
    name: 'flat_screen_tv',
  },
  {
    label: 'Truyền hình cáp/vệ tinh',
    name: 'cable_satellite_tv',
  },
  {
    label: 'Tủ quần áo',
    name: 'wardrobe',
  },
  {
    label: 'Móc quần áo',
    name: 'clothes_hangers',
  },
  {
    label: 'Dép mang trong phòng',
    name: 'slippers',
  },
  {
    label: 'Minibar',
    name: 'minibar',
  },
  {
    label: 'Tủ lạnh',
    name: 'refrigerator',
  },
  {
    label: 'Ấm đun nước điện',
    name: 'electric_kettle',
  },
  {
    label: 'Bàn làm việc',
    name: 'desk',
  },
  {
    label: 'Két sắt',
    name: 'safety_box',
  },
  {
    label: 'Sofa',
    name: 'sofa',
  },
  {
    label: 'Bàn ủi',
    name: 'iron',
  },
  {
    label: 'Bàn trải đồ ủi',
    name: 'iron_board',
  },
  {
    label: 'Điện thoại',
    name: 'telephone',
  },
  {
    label: 'Máy giặt',
    name: 'washing_machine',
  },
  {
    label: 'Máy sấy',
    name: 'dryer',
  },
  {
    label: 'Kê được giường phụ',
    name: 'extra_bed_slot',
  },
  {
    label: 'Sàn gạch',
    name: 'tile_marble_floor',
  },
  {
    label: 'Sàn gỗ',
    name: 'hard_wood_floor',
  },
  {
    label: 'Sàn trải thảm',
    name: 'carpeted_floor',
  },
  {
    label: 'Hồ bơi riêng',
    name: 'private_pool',
  },
  {
    label: 'Ban công',
    name: 'balcony',
  },
  {
    label: 'Sân hiên/Sân thượng',
    name: 'patio_terrance',
  },
  {
    label: 'Bếp nhỏ',
    name: 'kitchenette',
  },
  {
    label: 'Đồ dùng bếp',
    name: 'kitchenware',
  },
  {
    label: 'Bàn ăn',
    name: 'dining_table',
  },
  {
    label: 'Lò vi sóng',
    name: 'microwave',
  },
  {
    label: 'Lò nướng BBQ',
    name: 'barbecue',
  },
];

export const VIEW_ROOM = [
  {
    label: 'Nhìn ra biển',
    name: 'sea_view',
  },
  {
    label: 'Nhìn ra núi',
    name: 'mountain_view',
  },
  {
    label: 'Nhìn ra sông',
    name: 'river_view',
  },
  {
    label: 'Nhìn ra hồ',
    name: 'lake_view',
  },
  {
    label: 'Nhìn ra hồ bơi',
    name: 'pool_view',
  },
  {
    label: 'Nhìn ra thành phố',
    name: 'city_view',
  },
  {
    label: 'Nhìn ra đồi',
    name: 'hill_view',
  },
  {
    label: 'Nhìn ra thung lũng',
    name: 'valley_view',
  },
  {
    label: 'Nhìn ra vườn',
    name: 'garden_view',
  },
  {
    label: 'Nhìn ra phố',
    name: 'small_city_view',
  },
  {
    label: 'Không tầm nhìn',
    name: 'no_view',
  },
  {
    label: 'Không cửa sổ',
    name: 'no_window',
  },
];

export const BATHROOM_CONVENIENCE = [
  {
    label: 'Khăn tắm',
    name: 'bath__towels',
  },
  {
    label: 'Khăn mặt',
    name: 'face_towels',
  },
  {
    label: 'Khăn tay',
    name: 'hand_towels',
  },
  {
    label: 'Đồ dùng vệ sinh cá nhân miễn phí',
    name: 'free_toiletries',
  },
  {
    label: 'Mũ chùm tóc',
    name: 'shower_cap',
  },
  {
    label: 'Máy sấy tóc',
    name: 'hair_dryer',
  },
  {
    label: 'Áo choàng tắm',
    name: 'bathrobe',
  },
  {
    label: 'Vòi sen',
    name: 'shower',
  },
  {
    label: 'Vòi xịt',
    name: 'bidet',
  },
  {
    label: 'Buồng tắm đứng',
    name: 'stand_shower',
  },
  {
    label: 'Bồn tắm',
    name: 'bathtup',
  },
  {
    label: 'Buồng tắm đứng hoặc Bồn tắm',
    name: 'stand_shower_or_bathtup',
  },
];

export const priceType = {
  STANDARD_PRICE: 'STANDARD_PRICE',
  NON_REFUNDABLE_PRICE: 'NON_REFUNDABLE_PRICE',
  CUSTOM_PRICE: 'CUSTOM_PRICE',
};
