import React, { memo, useCallback } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Table } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { LOCATIONS, priceType } from 'constants/index';
import { currencyFormat } from 'utils';

import Button from 'components/App/Button';

import styles from './DataTableLocationDetailPriceType.module.scss';

function DataTableLocationDetailPriceType(props) {
  const {
    list,
  } = props;

  const { t } = useTranslation();

  const ButtonType = useCallback((progress, name) => {
    if (progress) {
      return (
        <div className="d-flex">
          {
            name === priceType.CUSTOM_PRICE && (
              <Button
                outline
                classes="Action__Button Action__Button__Danger"
              >
                Xóa
              </Button>
            )
          }

          <Link to={LOCATIONS.UPDATE_PRICE_TYPE}>
            <Button
              outline
              classes="Action__Button Action__Button__Link"
            >
              Chỉnh sửa
            </Button>
          </Link>
        </div>
      );
    }

    return (
      <div className="d-flex">
        {
            name === priceType.CUSTOM_PRICE && (
              <Button
                outline
                classes="Action__Button Action__Button__Danger"
              >
                Xóa
              </Button>
            )
          }

        <Link to={LOCATIONS.UPDATE_PRICE_TYPE}>
          <Button
            outline
            classes="Action__Button Action__Button__Link"
          >
            Chỉnh sửa
          </Button>
        </Link>
      </div>
    );
  }, []);

  return (
    <div className={styles.Component}>
      <Table
        responsive
        className="Table"
      >
        <thead className="Table__Header">
          <tr className="Table__Header__Row">
            <th className="col-md-2 Table__Header__Row__Content">
              Tên loại giá
            </th>

            <th className="col-md-3 Table__Header__Row__Content">Chính sách hủy phòng</th>

            <th className="col-md-3 Table__Header__Row__Content">Loại phòng</th>

            <th className="col-md-2 Table__Header__Row__Content">Bữa ăn bao gồm</th>

            <th className="col-md-2 Table__Header__Row__Content">Doanh thu ròng</th>
          </tr>
        </thead>

        <tbody className="Table__Body">
          {list.map((room) => (
            <tr className="Table__Body__Row" key={room.id}>
              <td className="Table__Body__Row__Content">
                <p className="TextPrimary">
                  {t(`global.price_type.${room.name}`)}
                </p>

                <p className="TextSecondary">
                  ID&nbsp;
                  {room.id}
                </p>

                <div className="Action">
                  {ButtonType(room.statusProgress, room.name)}
                </div>
              </td>

              <td className="Table__Body__Row__Content">
                {room.policy}
              </td>

              <td className="Table__Body__Row__Content">
                {room.typeOfRoom.map((type) => (
                  <div key={type._id} className="TypeRoom">
                    {type.type}
                  </div>
                ))}
              </td>

              <td className="Table__Body__Row__Content">
                {room.meal}
              </td>

              <td className="Table__Body__Row__Content">
                {currencyFormat(room.revenue, { isNotType: true })}
                &nbsp; VNĐ
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}

DataTableLocationDetailPriceType.propTypes = {
  list: PropTypes.instanceOf(Array),
};

DataTableLocationDetailPriceType.defaultProps = {
  list: [],
};

export default memo(DataTableLocationDetailPriceType);
