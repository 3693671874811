import { put, takeLeading } from 'redux-saga/effects';

import authApi from 'api/authApi';

import {
  LOCATIONS,
  storageKey,
} from 'constants/index';
import {
  apiErrorHandler,
  showSuccess,
} from 'utils';
import axiosClient from 'utils/axios';

import {
  LOGIN,
  LOGIN_FAILED,
  LOGIN_SUCCESS,
} from './actionTypes';
import { GET_ME } from '../profile/actionTypes';

function* login({ payload }) {
  try {
    const {
      email,
      password,
      history,
    } = payload;

    const response = yield authApi.login({ email, password });

    localStorage.setItem(storageKey.AUTHENTICATE_TOKEN, response.payload.token);

    yield put({
      type: LOGIN_SUCCESS,
    });

    showSuccess(response.message);
    // showSuccess('Login successful');

    axiosClient.defaults.headers.Authorization = `Bearer ${response.payload.token}`;
    yield put({
      type: GET_ME,
      payload,
    });

    history.push(LOCATIONS.LOCATION_LIST);
  } catch (error) {
    let errors;

    if (error.response?.data?.errors?.length) {
      errors = error.response.data.errors;
    } else {
      apiErrorHandler(error);
    }
    yield put({
      type: LOGIN_FAILED,
      payload: errors,
    });
  }
}

export default function* authSaga() {
  yield takeLeading(LOGIN, login);
}
