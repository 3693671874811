import {
  GET_LOCATION_LIST,
  GET_LOCATION_LIST_FAILED,
  GET_LOCATION_LIST_SUCCESS,
} from './actionTypes';

export const actionGetLocationList = (payload) => ({
  payload,
  type: GET_LOCATION_LIST,
});

export const actionGetLocationListSuccess = (payload) => ({
  payload,
  type: GET_LOCATION_LIST_SUCCESS,
});

export const actionGetLocationListFailed = (payload) => ({
  payload,
  type: GET_LOCATION_LIST_FAILED,
});
