export const STATUS = {
  CLOSE: 'Đã đóng',
  OPEN: 'Được đặt',
  SOLD: 'Đã bán',
};

export const ROOMS = [
  {
    _id: 1,
    code: '33053106',
    name: 'Suite có sân hiên',
    TimeRanges: [
      {
        time: '2023-02-01T02:46:48.610Z',
        status: STATUS.OPEN,
        rooms: 1,
        price: 200.000,
      },
      {
        time: '2023-02-02T02:46:48.610Z',
        status: STATUS.CLOSE,
        rooms: 0,
        price: 400.000,
      },
      {
        time: '2023-02-03T02:46:48.610Z',
        status: STATUS.OPEN,
        rooms: 5,
        price: 300.000,
      },
      {
        time: '2023-02-04T02:46:48.610Z',
        status: STATUS.OPEN,
        rooms: 6,
        price: 200.000,
      },
      {
        time: '2023-02-05T02:46:48.610Z',
        status: STATUS.OPEN,
        rooms: 9,
        price: 450.000,
      },
      {
        time: '2023-02-06T02:46:48.610Z',
        status: STATUS.CLOSE,
        rooms: 0,
        price: 400.000,
      },
      {
        time: '2023-02-07T02:46:48.610Z',
        status: STATUS.CLOSE,
        rooms: 0,
        price: 400.000,
      },
      {
        time: '2023-02-08T02:46:48.610Z',
        status: STATUS.OPEN,
        rooms: 1,
        price: 200.000,
      },
      {
        time: '2023-02-09T02:46:48.610Z',
        status: STATUS.OPEN,
        rooms: 1,
        price: 400.000,
      },
      {
        time: '2023-02-10T02:46:48.610Z',
        status: STATUS.OPEN,
        rooms: 1,
        price: 200.000,
      },
      {
        time: '2023-02-11T02:46:48.610Z',
        status: STATUS.OPEN,
        rooms: 8,
        price: 200.000,
      },
      {
        time: '2023-02-12T02:46:48.610Z',
        status: STATUS.OPEN,
        rooms: 5,
        price: 400.000,
      },
      {
        time: '2023-02-13T02:46:48.610Z',
        status: STATUS.CLOSE,
        rooms: 0,
        price: 400.000,
      },
      {
        time: '2023-02-14T02:46:48.610Z',
        status: STATUS.CLOSE,
        rooms: 0,
        price: 400.000,
      },
      {
        time: '2023-02-15T02:46:48.610Z',
        status: STATUS.CLOSE,
        rooms: 0,
        price: 400.000,
      },
      {
        time: '2023-02-16T02:46:48.610Z',
        status: STATUS.CLOSE,
        rooms: 0,
        price: 400.000,
      },
      {
        time: '2023-02-17T02:46:48.610Z',
        status: STATUS.OPEN,
        rooms: 1,
        price: 300.000,
      },
      {
        time: '2023-02-18T02:46:48.610Z',
        status: STATUS.OPEN,
        rooms: 4,
        price: 200.000,
      },
      {
        time: '2023-02-19T02:46:48.610Z',
        status: STATUS.OPEN,
        rooms: 3,
        price: 200.000,
      },
      {
        time: '2023-02-20T02:46:48.610Z',
        status: STATUS.CLOSE,
        rooms: 0,
        price: 400.000,
      },
      {
        time: '2023-02-21T02:46:48.610Z',
        status: STATUS.OPEN,
        rooms: 1,
        price: 540.000,
      },
      {
        time: '2023-02-22T02:46:48.610Z',
        status: STATUS.OPEN,
        rooms: 4,
        price: 200.000,
      },
      {
        time: '2023-02-23T02:46:48.610Z',
        status: STATUS.OPEN,
        rooms: 3,
        price: 200.000,
      },
      {
        time: '2023-02-24T02:46:48.610Z',
        status: STATUS.SOLD,
        rooms: 0,
        price: 400.000,
      },
      {
        time: '2023-02-25T02:46:48.610Z',
        status: STATUS.SOLD,
        rooms: 0,
        price: 200.000,
      },
      {
        time: '2023-02-26T02:46:48.610Z',
        status: STATUS.SOLD,
        rooms: 0,
        price: 300.000,
      },
      {
        time: '2023-02-27T02:46:48.610Z',
        status: STATUS.SOLD,
        rooms: 0,
        price: 300.000,
      },
      {
        time: '2023-02-28T02:46:48.610Z',
        status: STATUS.SOLD,
        rooms: 0,
        price: 330.000,
      },
      {
        time: '2023-02-29T02:46:48.610Z',
        status: STATUS.SOLD,
        rooms: 0,
        price: 100.000,
      },
      {
        time: '2023-02-30T02:46:48.610Z',
        status: STATUS.SOLD,
        rooms: 0,
        price: 500.000,
      },
    ],
  },
  {
    _id: 2,
    code: '33053106',
    name: 'Superior Twin Room',
    TimeRanges: [
      {
        time: '2023-02-01T02:46:48.610Z',
        status: STATUS.OPEN,
        rooms: 1,
        price: 200.000,
      },
      {
        time: '2023-02-02T02:46:48.610Z',
        status: STATUS.CLOSE,
        rooms: 0,
        price: 400.000,
      },
      {
        time: '2023-02-03T02:46:48.610Z',
        status: STATUS.OPEN,
        rooms: 5,
        price: 300.000,
      },
      {
        time: '2023-02-04T02:46:48.610Z',
        status: STATUS.OPEN,
        rooms: 6,
        price: 200.000,
      },
      {
        time: '2023-02-05T02:46:48.610Z',
        status: STATUS.OPEN,
        rooms: 9,
        price: 450.000,
      },
      {
        time: '2023-02-06T02:46:48.610Z',
        status: STATUS.CLOSE,
        rooms: 0,
        price: 400.000,
      },
      {
        time: '2023-02-07T02:46:48.610Z',
        status: STATUS.CLOSE,
        rooms: 0,
        price: 400.000,
      },
      {
        time: '2023-02-08T02:46:48.610Z',
        status: STATUS.OPEN,
        rooms: 1,
        price: 200.000,
      },
      {
        time: '2023-02-09T02:46:48.610Z',
        status: STATUS.OPEN,
        rooms: 1,
        price: 400.000,
      },
      {
        time: '2023-02-10T02:46:48.610Z',
        status: STATUS.OPEN,
        rooms: 1,
        price: 200.000,
      },
      {
        time: '2023-02-11T02:46:48.610Z',
        status: STATUS.OPEN,
        rooms: 8,
        price: 200.000,
      },
      {
        time: '2023-02-12T02:46:48.610Z',
        status: STATUS.OPEN,
        rooms: 5,
        price: 400.000,
      },
      {
        time: '2023-02-13T02:46:48.610Z',
        status: STATUS.CLOSE,
        rooms: 0,
        price: 400.000,
      },
      {
        time: '2023-02-14T02:46:48.610Z',
        status: STATUS.CLOSE,
        rooms: 0,
        price: 400.000,
      },
      {
        time: '2023-02-15T02:46:48.610Z',
        status: STATUS.CLOSE,
        rooms: 0,
        price: 400.000,
      },
      {
        time: '2023-02-16T02:46:48.610Z',
        status: STATUS.CLOSE,
        rooms: 0,
        price: 400.000,
      },
      {
        time: '2023-02-17T02:46:48.610Z',
        status: STATUS.OPEN,
        rooms: 1,
        price: 300.000,
      },
      {
        time: '2023-02-18T02:46:48.610Z',
        status: STATUS.OPEN,
        rooms: 4,
        price: 200.000,
      },
      {
        time: '2023-02-19T02:46:48.610Z',
        status: STATUS.OPEN,
        rooms: 3,
        price: 200.000,
      },
      {
        time: '2023-02-20T02:46:48.610Z',
        status: STATUS.CLOSE,
        rooms: 0,
        price: 400.000,
      },
      {
        time: '2023-02-21T02:46:48.610Z',
        status: STATUS.OPEN,
        rooms: 1,
        price: 540.000,
      },
      {
        time: '2023-02-22T02:46:48.610Z',
        status: STATUS.OPEN,
        rooms: 4,
        price: 200.000,
      },
      {
        time: '2023-02-23T02:46:48.610Z',
        status: STATUS.OPEN,
        rooms: 3,
        price: 200.000,
      },
      {
        time: '2023-02-24T02:46:48.610Z',
        status: STATUS.SOLD,
        rooms: 0,
        price: 400.000,
      },
      {
        time: '2023-02-25T02:46:48.610Z',
        status: STATUS.SOLD,
        rooms: 0,
        price: 200.000,
      },
      {
        time: '2023-02-26T02:46:48.610Z',
        status: STATUS.SOLD,
        rooms: 0,
        price: 300.000,
      },
      {
        time: '2023-02-27T02:46:48.610Z',
        status: STATUS.SOLD,
        rooms: 0,
        price: 300.000,
      },
      {
        time: '2023-02-28T02:46:48.610Z',
        status: STATUS.SOLD,
        rooms: 0,
        price: 330.000,
      },
      {
        time: '2023-02-29T02:46:48.610Z',
        status: STATUS.SOLD,
        rooms: 0,
        price: 100.000,
      },
      {
        time: '2023-02-30T02:46:48.610Z',
        status: STATUS.SOLD,
        rooms: 0,
        price: 500.000,
      },
    ],
  },
  {
    _id: 3,
    code: '33053106',
    name: 'Deluxe Double Room',
    TimeRanges: [
      {
        time: '2023-02-01T02:46:48.610Z',
        status: STATUS.OPEN,
        rooms: 1,
        price: 200.000,
      },
      {
        time: '2023-02-02T02:46:48.610Z',
        status: STATUS.CLOSE,
        rooms: 0,
        price: 400.000,
      },
      {
        time: '2023-02-03T02:46:48.610Z',
        status: STATUS.OPEN,
        rooms: 5,
        price: 300.000,
      },
      {
        time: '2023-02-04T02:46:48.610Z',
        status: STATUS.OPEN,
        rooms: 6,
        price: 200.000,
      },
      {
        time: '2023-02-05T02:46:48.610Z',
        status: STATUS.OPEN,
        rooms: 9,
        price: 450.000,
      },
      {
        time: '2023-02-06T02:46:48.610Z',
        status: STATUS.CLOSE,
        rooms: 0,
        price: 400.000,
      },
      {
        time: '2023-02-07T02:46:48.610Z',
        status: STATUS.CLOSE,
        rooms: 0,
        price: 400.000,
      },
      {
        time: '2023-02-08T02:46:48.610Z',
        status: STATUS.OPEN,
        rooms: 1,
        price: 200.000,
      },
      {
        time: '2023-02-09T02:46:48.610Z',
        status: STATUS.OPEN,
        rooms: 1,
        price: 400.000,
      },
      {
        time: '2023-02-10T02:46:48.610Z',
        status: STATUS.OPEN,
        rooms: 1,
        price: 200.000,
      },
      {
        time: '2023-02-11T02:46:48.610Z',
        status: STATUS.OPEN,
        rooms: 8,
        price: 200.000,
      },
      {
        time: '2023-02-12T02:46:48.610Z',
        status: STATUS.OPEN,
        rooms: 5,
        price: 400.000,
      },
      {
        time: '2023-02-13T02:46:48.610Z',
        status: STATUS.CLOSE,
        rooms: 0,
        price: 400.000,
      },
      {
        time: '2023-02-14T02:46:48.610Z',
        status: STATUS.CLOSE,
        rooms: 0,
        price: 400.000,
      },
      {
        time: '2023-02-15T02:46:48.610Z',
        status: STATUS.CLOSE,
        rooms: 0,
        price: 400.000,
      },
      {
        time: '2023-02-16T02:46:48.610Z',
        status: STATUS.CLOSE,
        rooms: 0,
        price: 400.000,
      },
      {
        time: '2023-02-17T02:46:48.610Z',
        status: STATUS.OPEN,
        rooms: 1,
        price: 300.000,
      },
      {
        time: '2023-02-18T02:46:48.610Z',
        status: STATUS.OPEN,
        rooms: 4,
        price: 200.000,
      },
      {
        time: '2023-02-19T02:46:48.610Z',
        status: STATUS.OPEN,
        rooms: 3,
        price: 200.000,
      },
      {
        time: '2023-02-20T02:46:48.610Z',
        status: STATUS.CLOSE,
        rooms: 0,
        price: 400.000,
      },
      {
        time: '2023-02-21T02:46:48.610Z',
        status: STATUS.OPEN,
        rooms: 1,
        price: 540.000,
      },
      {
        time: '2023-02-22T02:46:48.610Z',
        status: STATUS.OPEN,
        rooms: 4,
        price: 200.000,
      },
      {
        time: '2023-02-23T02:46:48.610Z',
        status: STATUS.OPEN,
        rooms: 3,
        price: 200.000,
      },
      {
        time: '2023-02-24T02:46:48.610Z',
        status: STATUS.SOLD,
        rooms: 0,
        price: 400.000,
      },
      {
        time: '2023-02-25T02:46:48.610Z',
        status: STATUS.SOLD,
        rooms: 0,
        price: 200.000,
      },
      {
        time: '2023-02-26T02:46:48.610Z',
        status: STATUS.SOLD,
        rooms: 0,
        price: 300.000,
      },
      {
        time: '2023-02-27T02:46:48.610Z',
        status: STATUS.SOLD,
        rooms: 0,
        price: 300.000,
      },
      {
        time: '2023-02-28T02:46:48.610Z',
        status: STATUS.SOLD,
        rooms: 0,
        price: 330.000,
      },
      {
        time: '2023-02-29T02:46:48.610Z',
        status: STATUS.SOLD,
        rooms: 0,
        price: 100.000,
      },
      {
        time: '2023-02-30T02:46:48.610Z',
        status: STATUS.SOLD,
        rooms: 0,
        price: 500.000,
      },
    ],
  },
];

export const FACILITIES_SERVICES = [
  {
    _id: 1,
    title: 'Lễ tân',
    isActive: true,
    groupServices: [
      {
        _id: 11,
        isActive: true,
        name: 'Lễ tân suốt 24 tiếng',
      },
      {
        _id: 12,
        isActive: true,
        name: 'Nước uống chào đón khách',
      },
      {
        _id: 13,
        isActive: true,
        name: 'Thu đổi ngoại tệ',
      },
      {
        _id: 14,
        isActive: true,
        name: 'Hỗ trợ tiền sảnh',
      },
      {
        _id: 15,
        isActive: true,
        name: 'Đặt tour',
      },
      {
        _id: 16,
        isActive: true,
        name: 'Đặt vé xe/tàu/máy bay',
      },
      {
        _id: 17,
        isActive: true,
        name: 'Giữ hành lý miễn phí',
      },
      {
        _id: 18,
        isActive: true,
        name: 'Giữ hành lý trả phí',
      },
    ],
  },
  {
    _id: 4,
    title: 'Mạng',
    isActive: false,
    groupServices: [
      {
        _id: 41,
        isActive: true,
        name: 'Wifi miễn phí',
      },
      {
        _id: 42,
        isActive: true,
        name: 'Wifi trả phí',
      },
      {
        _id: 43,
        isActive: true,
        name: 'Cáp miễn phí',
      },
      {
        _id: 44,
        isActive: true,
        name: 'Cáp trả phí',
      },
    ],
  },
  {
    _id: 3,
    title: 'Di chuyển',
    isActive: true,
    groupServices: [
      {
        _id: 31,
        isActive: true,
        name: 'Đưa đón sân bay',
      },
      {
        _id: 32,
        isActive: true,
        name: 'Đưa đón trong thành phố',
      },
      {
        _id: 33,
        isActive: true,
        name: 'Đưa đón ra biển',
      },
      {
        _id: 34,
        isActive: true,
        name: 'Cho thuê xe đạp',
      },
      {
        _id: 35,
        isActive: true,
        name: 'Cho thuê xe máy',
      },
      {
        _id: 36,
        isActive: true,
        name: 'Cho thuê xe hơi',
      },
    ],
  },
  {
    _id: 5,
    title: 'Bãi biển',
    isActive: false,
    groupServices: [
      {
        _id: 51,
        isActive: true,
        name: 'Bãi biển riêng',
      },
      {
        _id: 52,
        isActive: true,
        name: 'Sát biển',
      },
      {
        _id: 53,
        isActive: true,
        name: 'Biển đối diện',
      },
      {
        _id: 55,
        isActive: true,
        name: 'Ghế/dù bãi biển',
      },
      {
        _id: 56,
        isActive: true,
        name: 'Khăn dùng tại bãi biển',
      },
    ],
  },
  {
    _id: 6,
    title: 'Các hoạt động',
    isActive: false,
    groupServices: [
      {
        _id: 61,
        isActive: true,
        name: 'Phòng tập gym',
      },
      {
        _id: 62,
        isActive: true,
        name: 'Yoga',
      },
      {
        _id: 63,
        isActive: true,
        name: 'Sân tennis',
      },
      {
        _id: 65,
        isActive: true,
        name: 'Đạp xe',
      },
      {
        _id: 66,
        isActive: true,
        name: 'Câu cá',
      },
      {
        _id: 67,
        isActive: true,
        name: 'Lặn',
      },
      {
        _id: 68,
        isActive: true,
        name: 'Đi bộ đường dài',
      },
      {
        _id: 69,
        isActive: true,
        name: 'Bowling',
      },
      {
        _id: 611,
        isActive: true,
        name: 'Bi-a',
      },
      {
        _id: 612,
        isActive: true,
        name: 'Công viên giải trí',
      },
      {
        _id: 613,
        isActive: true,
        name: 'Công viên nước',
      },
    ],
  },
  {
    _id: 7,
    title: 'Giải trí và thư giãn',
    isActive: true,
    groupServices: [
      {
        _id: 11,
        isActive: true,
        name: 'Quán bar',
      },
      {
        _id: 12,
        isActive: true,
        name: 'Quán bar sân thượng',
      },
      {
        _id: 13,
        isActive: true,
        name: 'Quán bar bãi biển',
      },
      {
        _id: 14,
        isActive: true,
        name: 'Karaoke',
      },
      {
        _id: 15,
        isActive: true,
        name: 'Vũ trường',
      },
      {
        _id: 16,
        isActive: true,
        name: 'Câu lạc bộ đêm',
      },
      {
        _id: 17,
        isActive: true,
        name: 'Sòng bạc',
      },
      {
        _id: 18,
        isActive: true,
        name: 'Spa',
      },
      {
        _id: 19,
        isActive: true,
        name: 'Spa',
      },
      {
        _id: 111,
        isActive: true,
        name: 'Phòng xông hơi khô',
      },
      {
        _id: 112,
        isActive: true,
        name: 'Phòng xông hơi ướt',
      },
      {
        _id: 113,
        isActive: true,
        name: 'Mát xa toàn thân',
      },
      {
        _id: 114,
        isActive: true,
        name: 'Mát xa chân',
      },
      {
        _id: 115,
        isActive: true,
        name: 'Mát xa mặt',
      },
    ],
  },
  {
    _id: 8,
    title: 'Reception',
    isActive: true,
    groupServices: [
      {
        _id: 21,
        isActive: true,
        name: 'Lễ tân suốt 24 tiếng',
      },
      {
        _id: 22,
        isActive: true,
        name: 'Nước uống chào đón khách',
      },
      {
        _id: 23,
        isActive: true,
        name: 'Thu đổi ngoại tệ',
      },
      {
        _id: 24,
        isActive: true,
        name: 'Hỗ trợ tiền sảnh',
      },
      {
        _id: 25,
        isActive: true,
        name: 'Đặt tour',
      },
      {
        _id: 26,
        isActive: true,
        name: 'Đặt vé xe/tàu/máy bay',
      },
      {
        _id: 27,
        isActive: true,
        name: 'Giữ hành lý miễn phí',
      },
      {
        _id: 28,
        isActive: true,
        name: 'Giữ hành lý trả phí',
      },
    ],
  },
  {
    _id: 9,
    title: 'Language',
    isActive: true,
    groupServices: [
      {
        _id: 31,
        isActive: true,
        name: 'English spoken',
      },
      {
        _id: 32,
        isActive: true,
        name: 'Russian spoken',
      },
      {
        _id: 33,
        isActive: true,
        name: 'Chinese spoken',
      },
      {
        _id: 34,
        isActive: true,
        name: 'Korean spoken',
      },
      {
        _id: 35,
        isActive: true,
        name: 'Japanese spoken',
      },
    ],
  },
  {
    _id: 10,
    title: 'Mạng',
    isActive: false,
    groupServices: [
      {
        _id: 41,
        isActive: true,
        name: 'Wifi miễn phí',
      },
      {
        _id: 42,
        isActive: true,
        name: 'Wifi trả phí',
      },
      {
        _id: 43,
        isActive: true,
        name: 'Cáp miễn phí',
      },
      {
        _id: 44,
        isActive: true,
        name: 'Cáp trả phí',
      },
    ],
  },
];
