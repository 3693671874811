import React, { useEffect, useCallback, memo } from 'react';
import PropTypes from 'prop-types';

import { leftSidebarTypes } from 'constants/layout';

import AppLoading from 'components/App/Loading';

import Header from './Header';
import Sidebar from './Sidebar';
import Footer from './Footer';
import RightSidebar from './RightSidebar';

const Layout = (props) => {
  const {
    loadingDetailProject,
    loadingDetailEmp,
    leftSideBarType,
    topBarTheme,
    showRightSidebar,
    leftSideBarTheme,
    children,

    actionChangeSidebarType,
    actionChangeSidebarTheme,
    actionChangeTopBarTheme,
    actionShowRightSidebar,
  } = props;

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const onToggleMenuCallback = useCallback(() => {
    if (leftSideBarType === leftSidebarTypes.DEFAULT) {
      actionChangeSidebarType(leftSidebarTypes.CONDENSED, isMobile);
    } else if (leftSideBarType === leftSidebarTypes.CONDENSED) {
      actionChangeSidebarType(leftSidebarTypes.DEFAULT, isMobile);
    }
  }, [leftSideBarType]);

  const onHideRightBar = useCallback((event) => {
    const rightBar = document.getElementById('right-bar');
    if (!rightBar || !rightBar.contains(event.target)) {
      actionShowRightSidebar(false);
    }
  }, []);

  useEffect(() => {
    document.body.addEventListener('click', onHideRightBar, true);
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (leftSideBarTheme) {
      actionChangeSidebarTheme(leftSideBarTheme);
    }
  }, [leftSideBarTheme]);

  useEffect(() => {
    if (leftSideBarType) {
      actionChangeSidebarType(leftSideBarType);
    }
  }, [leftSideBarType]);

  useEffect(() => {
    if (topBarTheme) {
      actionChangeTopBarTheme(topBarTheme);
    }
  }, [topBarTheme]);

  return (
    <>
      <div id="layout-wrapper">
        <AppLoading loading={loadingDetailEmp || loadingDetailProject} />

        <Header onToggleMenuCallback={onToggleMenuCallback} />
        <Sidebar
          theme={leftSideBarTheme}
          type={leftSideBarType}
          isMobile={isMobile}
        />

        <div className="main-content overflow-inherit">
          {children}
        </div>

        <Footer />
      </div>

      {showRightSidebar && <RightSidebar />}
    </>
  );
};

Layout.propTypes = {
  loadingDetailProject: PropTypes.bool,
  loadingDetailEmp: PropTypes.bool,

  actionChangeSidebarTheme: PropTypes.func.isRequired,
  actionChangeSidebarType: PropTypes.func.isRequired,
  actionChangeTopBarTheme: PropTypes.func.isRequired,
  actionShowRightSidebar: PropTypes.func.isRequired,
  children: PropTypes.instanceOf(Object).isRequired,
  leftSideBarTheme: PropTypes.string.isRequired,
  leftSideBarType: PropTypes.string.isRequired,
  showRightSidebar: PropTypes.bool.isRequired,
  topBarTheme: PropTypes.string.isRequired,
};

Layout.defaultProps = {
  loadingDetailProject: false,
  loadingDetailEmp: false,
};

export default memo(Layout);
