import React, {
  useMemo,
  memo,
} from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  ModalBody,
  ModalHeader,
  Label,
  Col,
  Row,
  Input,
} from 'reactstrap';

import images from 'assets/images';

import { ReactComponent as CloseButtonSvg } from 'assets/images/location/close-button-svg.svg';

import Button from 'components/App/Button';

import styles from './ModalEditPolicyDeposit.module.scss';

function ModalEditPolicyDeposit(props) {
  const {
    isOpen,

    onToggleModal,
  } = props;

  const CloseButton = useMemo(() => (
    <button className={styles.ButtonClose} onClick={onToggleModal} type="button">
      <CloseButtonSvg />
    </button>
  ));

  return (
    <div className={styles.Component}>
      <Modal
        fade
        size="lg"
        isOpen={isOpen}
        toggle={onToggleModal}
        className={styles.Modal}
      >
        <ModalHeader
          toggle={onToggleModal}
          className={styles.ModalHeader}
          close={CloseButton}
        />

        <ModalBody
          className={styles.ModalBody}
        >
          <Label className="Label">
            Lựa chọn thanh toán của khách
          </Label>

          <hr className="Divider" />

          <Row className="Block__Container">
            <Col md="5" className="Block__Title">
              Thẻ quốc tế
            </Col>

            <Col md="7" className="Block__Content__Wrapper">
              <Row>
                <Col md="6">
                  <div className="Block__Checkbox__Wrapper">
                    <Input
                      type="checkbox"
                      className="Block__Checkbox"
                    />

                    <img
                      className="Block__Checkbox__Image"
                      src={images.masterCardImage.default}
                      alt="payment-master-card"
                    />

                    <div className="Block__Checkbox__Name">
                      Euro/Mastercard
                    </div>
                  </div>
                </Col>

                <Col md="6">
                  <div className="Block__Checkbox__Wrapper">
                    <Input
                      type="checkbox"
                      className="Block__Checkbox"
                    />

                    <img
                      className="Block__Checkbox__Image"
                      src={images.JCBImage.default}
                      alt="payment-master-card"
                    />

                    <div className="Block__Checkbox__Name">
                      JCB
                    </div>
                  </div>
                </Col>
              </Row>

              <Row className="mt-24">
                <Col md="6">
                  <div className="Block__Checkbox__Wrapper">
                    <Input
                      type="checkbox"
                      className="Block__Checkbox"
                    />

                    <img
                      className="Block__Checkbox__Image"
                      src={images.visaImage.default}
                      alt="payment-master-card"
                    />

                    <div className="Block__Checkbox__Name">
                      Visa
                    </div>
                  </div>
                </Col>

                <Col md="6">
                  <div className="Block__Checkbox__Wrapper">
                    <Input
                      type="checkbox"
                      className="Block__Checkbox"
                    />

                    <img
                      className="Block__Checkbox__Image"
                      src={images.dinersClubImage.default}
                      alt="payment-master-card"
                    />

                    <div className="Block__Checkbox__Name">
                      Diners Club
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

          <hr className="Divider" />

          <Row className="Block__Container">
            <Col md="5" className="Block__Title">
              Thẻ nội địa
            </Col>

            <Col md="7" className="Block__Content__Wrapper">
              <div className="Block__Checkbox__Wrapper">
                <Input
                  type="checkbox"
                  className="Block__Checkbox"
                />

                <img
                  className="Block__Checkbox__Image"
                  src={images.napasImage.default}
                  alt="payment-master-card"
                />

                <div className="Block__Checkbox__Name">
                  Napas
                </div>
              </div>
            </Col>
          </Row>

          <hr className="Divider" />

          <Row className="Block__Container mb-30">
            <Col md="5" className="Block__Title">
              Cho phép thanh toán Online
            </Col>

            <Col md="7" className="Block__Content__Wrapper">
              <div className="Block__Checkbox__Wrapper">
                <Input
                  type="checkbox"
                  className="Block__Checkbox"
                />

                <img
                  className="Block__Checkbox__Image"
                  src={images.bookeseImage.default}
                  alt="payment-master-card"
                />

                <div className="Block__Checkbox__Name">
                  Bookese.com
                </div>
              </div>
            </Col>
          </Row>

          <hr className="Divider" />

          <div className="Block__Policy__Wrapper">
            <Col md="5" className="Block__Title font-weight-600 mb-8">
              Tóm tắt chính sách
            </Col>

            <div className="Block__Policy__Information__Wrapper">
              <div className="Block__Policy__Information__Point">•</div>

              <div className="Block__Policy__Information__Content">
                Thanh toán được chấp thuận: Euro/Matercard, Napas
              </div>
            </div>
          </div>

          <hr className="Divider" />

          <div className="Button__Wrapper">
            <Button
              classes="Button Button__Cancel"
              onClick={onToggleModal}
            >
              Hủy
            </Button>

            <Button classes="Button Button__Save">
              Lưu
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}

ModalEditPolicyDeposit.propTypes = {
  isOpen: PropTypes.bool.isRequired,

  onToggleModal: PropTypes.func.isRequired,
};

ModalEditPolicyDeposit.defaultProps = {

};

export default memo(ModalEditPolicyDeposit);
