import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { BreadcrumbItem } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import styles from './Breadcrumb.module.scss';

const AppBreadcrumb = (props) => {
  const {
    className,
    pageTitle,

    breadcrumbList,
  } = props;

  const { t } = useTranslation();
  const isEnglish = localStorage.getItem('i18nextLng') === 'en';

  return (
    <div className={styles.Component}>
      <div className={`page-title-box d-sm-flex align-items-center justify-content-between ${className}`}>
        {
          pageTitle && <h3 className="mb-0 Title">{t(pageTitle.toUpperCase())}</h3>
        }
        <div className={`page-title-right ${isEnglish ? 'en' : ''}`}>
          <ol className="breadcrumb m-0">
            {
              breadcrumbList.map((b) => (
                <BreadcrumbItem key={b.name} active={b.isActive}>
                  <Link className={b.isActive ? 'Text Text__Link__Active' : 'Text'} to={b.link}>{b.name}</Link>
                </BreadcrumbItem>
              ))
            }
          </ol>
        </div>
      </div>
    </div>
  );
};

AppBreadcrumb.propTypes = {
  className: PropTypes.string,
  pageTitle: PropTypes.string,

  breadcrumbList: PropTypes.instanceOf(Array),
};

AppBreadcrumb.defaultProps = {
  className: '',
  pageTitle: '',

  breadcrumbList: [{
    name: '',
    link: '#',
  }],
};

export default memo(AppBreadcrumb);
