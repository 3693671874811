import axiosClient from 'utils/axios';

const endpoint = '/profile';

export default {
  async getMe() {
    const path = `${endpoint}`;

    const response = await axiosClient.get(path);

    return response.data;
  },

  async updateMe(payload) {
    const { userData } = payload;

    const path = `${endpoint}`;

    const response = await axiosClient.put(`${path}`, userData);

    return response.data;
  },

  async uploadMyAvatar(payload) {
    const { avatar } = payload;

    const path = `${endpoint}/avatar`;

    const response = await axiosClient.put(`${path}`, { avatar });

    return response.data;
  },

  async deleteMyAvatar() {
    const path = `${endpoint}/avatar`;

    const response = await axiosClient.delete(`${path}`);

    return response.data;
  },

  async updateMyPassword(payload) {
    const { userPwd } = payload;

    const path = `${endpoint}/password`;

    const response = await axiosClient.put(`${path}`, userPwd);

    return response.data;
  },
};
