import React, {
  useCallback, memo, useRef, useLayoutEffect, useState, useMemo, useEffect,
} from 'react';
import { Link, useLocation } from 'react-router-dom';
import MetisMenu from 'metismenujs';
import { useTranslation } from 'react-i18next';

import LanguageDropdown from 'Layout/AuthLayout/HeaderDropdown/LanguageDropdown';
import MenuDropdown from 'Layout/AuthLayout/HeaderDropdown/MenuDropdown';
// import NotificationDropdown from 'Layout/AuthLayout/HeaderDropdown/NotificationDropdown';
import ProfileMenu from 'Layout/AuthLayout/HeaderDropdown/ProfileMenu';

import { ReactComponent as MoreHeaderIcon } from 'assets/images/headerNavigation/more-icon.svg';
import { ReactComponent as ArrowHeaderIcon } from 'assets/images/headerNavigation/arrow.svg';

import images from 'assets/images';
import { headerNavigation, LOCATIONS } from 'constants/index';

import styles from './Header.module.scss';

const Header = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const headerRef = useRef();
  const buttonToggleMenuRef = useRef();
  const navigationRef = useRef();
  const rightHeaderRef = useRef();

  const [isShowHeader, setIsShowHeader] = useState(true);
  const [isShowTitle, setIsShowTitle] = useState(true);
  const [isBreakTitle, setIsBreakTitle] = useState(false);

  const onToggleFullscreen = useCallback(() => {
    if (
      !document.fullscreenElement
      /* alternative standard method */ && !document.mozFullScreenElement
      && !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT,
        );
      }
    } else if (document.cancelFullScreen) {
      document.cancelFullScreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitCancelFullScreen) {
      document.webkitCancelFullScreen();
    }
  }, []);

  const updateDimensions = useCallback(() => {
    if (navigationRef.current) {
      const headerWidth = headerRef.current.offsetWidth;
      const buttonWidth = buttonToggleMenuRef.current.offsetWidth;
      const navigationWidth = navigationRef.current.offsetWidth;
      const rightHeaderWidth = rightHeaderRef.current.offsetWidth;

      setIsBreakTitle(!((headerWidth - (buttonWidth + rightHeaderWidth)) >= navigationWidth
      && headerWidth > 1390));

      setIsShowTitle(((headerWidth - (buttonWidth + rightHeaderWidth)) >= navigationWidth)
      && headerWidth > 1100);

      setIsShowHeader(headerWidth > 810);
    }
  }, []);

  const onToggleSidebar = useCallback(() => {
    const { body } = document;
    if (window.screen.width <= 998) {
      body.classList.toggle('sidebar-enable');
    } else {
      body.classList.toggle('vertical-collpsed');
      body.classList.toggle('sidebar-enable');
    }

    updateDimensions();
  }, []);

  const checkShowMenu = useMemo(() => {
    if (location.pathname.includes(LOCATIONS.LOCATION_DETAIL)) {
      return true;
    }

    return false;
  }, [location.pathname]);

  const onScrollElement = useCallback((item) => {
    if (item) {
      const currentPosition = item.offsetTop;

      if (currentPosition > window.innerHeight) {
        headerRef.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }, []);

  const onActivateParentDropdown = useCallback((item) => {
    item.classList.add('active');

    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];

    if (parent.childNodes[0].childNodes[0]) {
      parent.childNodes[0].childNodes[0].classList.add('mm-show');
    }

    if (parent2El && parent2El.id !== 'horizontal-menu' && parent.id !== 'horizontal-menu') {
      parent2El.classList.add('active');
    }

    if (parent) {
      // parent.classList.add('mm-active');
      const parent2 = parent.parentElement;

      if (parent2 && parent2.id !== 'navbar-header-menu') {
        parent2.classList.add('active');
      }

      onScrollElement(item);

      return false;
    }

    onScrollElement(item);

    return false;
  }, []);

  const initMenu = useCallback(() => {
    // eslint-disable-next-line no-new
    new MetisMenu('#horizontal-menu');
    let matchingMenuItem = null;

    const ul = document.querySelector('#horizontal-menu');
    const items = ul.getElementsByTagName('a');

    const pathName = location.pathname;
    const route = `/${pathName.split('/')[1]}`;

    for (let i = 0; i < items.length; i += 1) {
      if (pathName === items[i].pathname || route === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }

    if (matchingMenuItem) {
      onActivateParentDropdown(matchingMenuItem);
    }
  }, []);

  useLayoutEffect(() => {
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  useLayoutEffect(() => {
    updateDimensions();
  }, []);

  useEffect(() => {
    if (checkShowMenu) initMenu();
  }, [location.pathname]);

  return (
    <header id="page-topbar" className={styles.Component}>
      <div className="navbar-header" ref={headerRef}>
        <div className="d-flex" id="navbar-header-menu">
          <div className="navbar-brand-box d-lg-none d-md-block">
            <Link to={LOCATIONS.HOME_PAGE} className="logo logo-dark">
              <span className="logo-sm">
                <img
                  src={images.logoBookeseFull.default}
                  alt=""
                  width={40}
                  height={40}
                />
              </span>
            </Link>

            <Link to={LOCATIONS.HOME_PAGE} className="logo logo-light">
              <span className="logo-sm">
                <img src={images.logoBookeseMini.default} alt="" height="40" />
              </span>
            </Link>
          </div>

          <button
            type="button"
            onClick={onToggleSidebar}
            className="btn btn-sm px-3 font-size-16 header-item "
            id="vertical-menu-btn"
            ref={buttonToggleMenuRef}
          >
            <i className="fa fa-fw fa-bars" />
          </button>

          {
            checkShowMenu && (
              <div className="Navigation" ref={navigationRef} id="horizontal-menu">
                {
                  isShowHeader ? headerNavigation.map((item) => {
                    const IconComponent = item.icon;

                    if (item.children?.length > 0) {
                      return (
                        <MenuDropdown list={item.children} key={item.name} classnames="Navigation__Item" isBreakTitle={isBreakTitle}>
                          <span
                            className="Navigation__Item__Icon pointer-events-none"
                          >
                            <IconComponent />
                          </span>

                          {
                            isShowTitle && <span className="Navigation__Item__Title pointer-events-none">{t(item.name)}</span>
                          }

                          {
                            !isBreakTitle && (
                              <span className="ms-3 mt-1 pointer-events-none"><ArrowHeaderIcon /></span>
                            )
                          }
                        </MenuDropdown>
                      );
                    }

                    return (
                      <Link
                        key={item.name}
                        to={item.link}
                        className={`Navigation__Item ${item.link === location.pathname ? 'active' : ''} ${isBreakTitle ? 'flex-column' : ''}`}
                      >
                        <span className="Navigation__Item__Icon">
                          <IconComponent />
                        </span>

                        {
                          isShowTitle && (
                            <span className="Navigation__Item__Title">{t(item.name)}</span>
                          )
                        }
                      </Link>
                    );
                  }) : (
                    <MenuDropdown list={headerNavigation} classnames="" isDisableEffect>
                      <span className="Navigation__Item__Title">{t('header.navigation.show_more')}</span>

                      <span className="Navigation__Item__Icon ms-3">
                        <MoreHeaderIcon />
                      </span>
                    </MenuDropdown>
                  )
                }
              </div>
            )
          }
        </div>

        {/* Search Mobile */}
        <div className="d-flex" ref={rightHeaderRef}>
          <LanguageDropdown />

          {/* Fullscreen */}
          <div className="dropdown d-none d-lg-inline-block ms-1">
            <button
              type="button"
              onClick={onToggleFullscreen}
              className="btn header-item noti-icon "
              data-toggle="fullscreen"
            >
              <i className="bx bx-fullscreen" />
            </button>
          </div>

          {/* AppToast */}

          {/* Profile */}
          <ProfileMenu />

        </div>
      </div>
    </header>
  );
};

export default memo(Header);
