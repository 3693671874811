import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';

import Meta from 'components/App/Meta';

import images from 'assets/images';
import { LOCATIONS } from 'constants/index';

const Pages404 = () => (
  <div className="account-pages my-5 pt-5">
    <Meta name="404" />

    <Container>
      <Row>
        <Col lg="12">
          <div className="text-center mb-5">
            <h1 className="display-2 font-weight-medium">
              4
              <i className="bx bx-buoy bx-spin text-primary display-3" />
              4
            </h1>

            <h4 className="text-uppercase">Sorry, page not found</h4>

            <div className="mt-5 text-center">
              <Link
                className="btn btn-primary "
                to={LOCATIONS.DASHBOARD}
              >
                Back to Dashboard
              </Link>
            </div>
          </div>
        </Col>
      </Row>

      <Row className="justify-content-center">
        <Col md="8" xl="6">
          <div>
            <img src={images.globalError.default} alt="" className="img-fluid" />
          </div>
        </Col>
      </Row>
    </Container>
  </div>
);

export default Pages404;
