import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import classnames from 'utils/classnames';
import images from 'assets/images';

import './styles.scss';

const Breadcrumb = (props) => {
  const {
    isBreadcrumbHaveChild,

    breadcrumbItem,
    breadcrumbChild,
    className,
    historyBack,

    label,
  } = props;

  const isEnglish = localStorage.getItem('i18nextLng') === 'en';

  return (
    <div className={`page-title-box d-sm-flex align-items-center justify-content-between ${className}`}>
      <h3 className="mb-0 font-size-20 text-breadcrumb-title">
        {isBreadcrumbHaveChild ? breadcrumbChild : breadcrumbItem}
      </h3>

      {!!label?.text && (
        <div className="flex-1 d-flex align-items-center ml-30">
          <div className={classnames('badge-custom d-inline-flex px-4', label.class)}>
            {label.text}
          </div>
        </div>
      )}

      <div className={`page-title-right ${isEnglish ? 'en' : ''}`}>
        <ol className="breadcrumb m-0">
          {/* <BreadcrumbItem>
            <Link to={historyBack}>{t(title)}</Link>
          </BreadcrumbItem> */}

          {/* <BreadcrumbItem active>
            <Link to={breadcrumbLink} className="text-info">{t(breadcrumbItem)}</Link>
          </BreadcrumbItem> */}

          {historyBack ? (
            <Link to={historyBack}>
              <div className={`font-size-14 text__breadcrumb${isBreadcrumbHaveChild ? '__child' : ''} text-breadcrumb`}>
                {`Trang ${breadcrumbItem}`}
              </div>
            </Link>
          ) : (
            <div className={`font-size-14 text__breadcrumb${isBreadcrumbHaveChild ? '__child' : ''} text-breadcrumb`}>
              {`Trang ${breadcrumbItem}`}
            </div>
          )}

          {isBreadcrumbHaveChild && (
          <>
            <img src={images.arrowRight.default} alt="arrow" className="arrow" />
            <div className="font-size-14 color-gray-600">
              {breadcrumbChild}
            </div>
          </>
          )}
        </ol>
      </div>
    </div>
  );
};

Breadcrumb.propTypes = {
  isBreadcrumbHaveChild: PropTypes.bool,

  breadcrumbItem: PropTypes.string,
  breadcrumbChild: PropTypes.string,
  className: PropTypes.string,
  historyBack: PropTypes.string,

  label: PropTypes.instanceOf(Object),
};

Breadcrumb.defaultProps = {
  isBreadcrumbHaveChild: false,

  breadcrumbItem: '',
  breadcrumbChild: '',
  className: '',
  historyBack: '',

  label: {},
};

export default memo(Breadcrumb);
