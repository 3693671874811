import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormFeedback } from 'reactstrap';
import Select from 'react-select';
import { get } from 'lodash';

import { validationCheckErrorType } from 'constants/index';
import { checkHadError } from 'utils';

const AppSelectValidation = (props) => {
  const {
    isHiddenClearable,
    isHiddenSearchable,
    isMulti,
    isShowError,
    isDisabled,

    checkErrorType,
    keyName,
    name,
    placeholder,
    selectedClassName,
    subTitle,
    value,
    fontSize,

    validation,
    options,

    onBlur,
    onChange,
  } = props;

  const keyCheckError = useMemo(() => (keyName || name), [keyName, name]);

  const isHadError = useMemo(() => checkHadError(
    validation, keyCheckError, checkErrorType,
  ), [validation, keyCheckError, checkErrorType]);

  const selectInvalid = useMemo(() => ({
    control: (provided) => ({
      ...provided,
      border: '1px solid var(--bookese-red-100) !important',
      boxShadow: 'var(--bookese-red-100) !important',
    }),
  }), []);

  const stylesCustom = useMemo(() => ({
    control: (provided, state) => ({
      ...provided,
      boxShadow: state.isFocused ? 'var(--bookese-blue-100)' : '',
      borderColor: state.isFocused ? 'var(--bookese-blue-100) !important' : 'var(--bookese-gray-200)',
      fontSize: `${fontSize} !important`,
      minHeight: '36px',
      height: '36px',
    }),

    singleValue: (provided) => ({
      ...provided,
      fontSize: `${fontSize} !important`,
    }),

    placeholder: (provided) => ({
      ...provided,
      fontSize: `${fontSize} !important`,
    }),

    input: (provided) => ({
      ...provided,
      fontSize: `${fontSize} !important`,
    }),

    // option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    //   const color = chroma(data.color);
    //   return {
    //     ...styles,
    //     backgroundColor: isDisabled ? 'red' : blue,
    //     color: '#FFF',
    //     cursor: isDisabled ? 'not-allowed' : 'default',
    //     ...
    //   },
    // },
  }), []);

  return (
    <>
      <Select
        options={options}
        value={isMulti ? value : options.filter((item) => item.value === value)}
        name={name}
        inputId={name}
        isMulti={isMulti}
        classNamePrefix="select2-selection"
        className={`basic-multi-select ${selectedClassName}`}
        isSearchable={isHiddenSearchable}
        isClearable={isHiddenClearable}
        placeholder={placeholder}
        onBlur={validation.handleBlur || onBlur}
        onChange={onChange}
        noOptionsMessage={({ option }) => (!option && 'Không có lựa chọn')}
        styles={isShowError && isHadError ? selectInvalid : stylesCustom}
        invalid={isShowError && isHadError}
        isDisabled={isDisabled}
      />

      {
        subTitle && <div className="Selection-SubTitle">{subTitle}</div>
      }

      {
        isShowError && isHadError && (
          <FormFeedback type="invalid" className="d-block font-size-12">
            {get(validation.errors, keyCheckError, '')}
          </FormFeedback>
        )
      }
    </>
  );
};

AppSelectValidation.propTypes = {
  isHiddenClearable: PropTypes.bool,
  isHiddenSearchable: PropTypes.bool,
  isMulti: PropTypes.bool,
  isShowError: PropTypes.bool,
  isDisabled: PropTypes.bool,

  checkErrorType: PropTypes.oneOf([
    '',
    validationCheckErrorType.ERR_ONLY,
    validationCheckErrorType.TOUCHED_ONLY,
  ]),
  selectedClassName: PropTypes.string,
  keyName: PropTypes.string,
  name: PropTypes.string,
  subTitle: PropTypes.string,
  fontSize: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.number,
    PropTypes.instanceOf(Object),
  ]),

  placeholder: PropTypes.string,

  validation: PropTypes.instanceOf(Object),
  options: PropTypes.instanceOf(Array).isRequired,

  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
};

AppSelectValidation.defaultProps = {
  isHiddenClearable: false,
  isHiddenSearchable: false,
  isMulti: false,
  isShowError: true,
  isDisabled: false,

  checkErrorType: '',
  keyName: '',
  name: '',
  fontSize: '13px',
  placeholder: 'Chọn',
  selectedClassName: '',
  subTitle: '',
  value: '',

  validation: {},

  onBlur: () => { },
};

export default memo(AppSelectValidation);
