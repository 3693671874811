import { connect } from 'react-redux';
import { actionGetLocationList } from 'store/location/actions';

import LocationList from './LocationList';

const mapStateToProps = (state) => ({
  loadingLocationList: state.Location.loadingLocationList,
  locationList: state.Location.locationList,

});

const mapDispatchToProps = (dispatch) => ({
  actionGetLocationList: (payload) => dispatch(actionGetLocationList(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LocationList);
