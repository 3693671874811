import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import translationENG from './locales/eng/index';
import translationVI from './locales/vi/index';

// the translations
const resources = {
  vi: {
    translation: translationVI,
  },
  en: {
    translation: translationENG,
  },
};

const language = localStorage.getItem('I18N_LANGUAGE');
if (!language) {
  localStorage.setItem('I18N_LANGUAGE', 'vi');
}

i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    interpolation: { escapeValue: true },
    resources,
    lng: localStorage.getItem('I18N_LANGUAGE') || 'vi',
    fallbackLng: 'vi', // use en if detected lng is not available
  });

export default i18n;
