import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'reactstrap';
import { get } from 'lodash';

import { validationCheckErrorType } from 'constants/index';
import { checkHadError } from 'utils';
import classNames from 'utils/classnames';

import styles from './Input.module.scss';

const AppInputValidation = (props) => {
  const {
    isCustomOnChange,
    isDisabled,
    isShowCounter,
    isShowErrorMessage,
    isShowErrorStyle,
    isCounterOnInput,
    multiple,

    limitCharacter,
    checkErrorType,
    id,
    inputClassName,
    keyName,
    name,
    placeholder,
    showCurrency,
    subTitle,
    type,
    value,
    wrapperClassName,

    validation,

    onBlur,
    onChange,
  } = props;

  const keyCheckError = useMemo(() => (keyName || name), [keyName, name]);

  const isHadError = useMemo(() => checkHadError(
    validation, keyCheckError, checkErrorType,
  ), [validation, keyCheckError, checkErrorType]);

  const inputValue = useMemo(() => value || get(validation.values, keyCheckError, ''), [value, validation.values]);

  return (
    <div className={`${wrapperClassName} ${styles.Component}`}>
      <div className="position-relative">
        <Input
          className={classNames(
            `form-control ${inputClassName}`,
            showCurrency && 'Input__Currency',
            isCounterOnInput && 'Input__CounterOn',
          )}
          invalid={isShowErrorStyle && isHadError}
          name={name}
          type={type}
          id={id}
          multiple={multiple}
          onBlur={isCustomOnChange ? onBlur : validation.handleBlur}
          onChange={isCustomOnChange ? onChange : validation.handleChange}
          placeholder={placeholder}
          value={inputValue}
          disabled={isDisabled}
          maxLength={limitCharacter}
        />

        {isShowCounter && (
        <span className={isCounterOnInput ? 'CounterOnInput' : 'Counter'}>
          {inputValue.length}
          /
          {limitCharacter}
        </span>
        )}

        {
        showCurrency && <span className="Currency">{showCurrency}</span>
        }
      </div>

      {
        subTitle && <div className="Selection-SubTitle">{subTitle}</div>
      }

      {
        isShowErrorMessage && isHadError && (
          <div className="InvalidFeedback">
            {get(validation.errors, keyCheckError, '')}
          </div>
        )
      }
    </div>
  );
};

AppInputValidation.propTypes = {
  isCustomOnChange: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isShowCounter: PropTypes.bool,
  isShowErrorMessage: PropTypes.bool,
  isShowErrorStyle: PropTypes.bool,
  multiple: PropTypes.bool,
  isCounterOnInput: PropTypes.bool,

  limitCharacter: PropTypes.number,
  checkErrorType: PropTypes.oneOf([
    '',
    validationCheckErrorType.ERR_ONLY,
    validationCheckErrorType.TOUCHED_ONLY,
  ]),
  id: PropTypes.string,
  inputClassName: PropTypes.string,
  keyName: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  showCurrency: PropTypes.string,
  subTitle: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  wrapperClassName: PropTypes.string,

  validation: PropTypes.instanceOf(Object).isRequired,

  onBlur: PropTypes.func,
  onChange: PropTypes.func,
};

AppInputValidation.defaultProps = {
  isCustomOnChange: false,
  isDisabled: false,
  isShowCounter: false,
  isShowErrorMessage: true,
  isShowErrorStyle: true,
  multiple: false,
  isCounterOnInput: false,

  limitCharacter: 100,
  checkErrorType: '',
  id: '',
  inputClassName: '',
  keyName: '',
  name: '',
  placeholder: 'Nhập',
  showCurrency: '',
  subTitle: '',
  type: 'text',
  value: '',
  wrapperClassName: '',

  onBlur: () => {},
  onChange: () => {},
};

export default memo(AppInputValidation);
