// @flow
import {
  CHANGE_SIDEBAR_THEME,
  CHANGE_SIDEBAR_TYPE,
  CHANGE_TOPBAR_THEME,
  SHOW_RIGHT_SIDEBAR,
  TOGGLE_LEFT_MENU,
  SHOW_SIDEBAR,
} from './actionTypes';

// constants
import {
  topBarThemeTypes,
  leftSidebarTypes,
  leftSideBarThemeTypes,
} from '../../constants/layout';

const INIT_STATE = {
  isShowSidebar: true,
  isMobile: false,
  leftMenu: false,
  showRightSidebar: false,

  leftSideBarTheme: leftSideBarThemeTypes.DARK,
  leftSideBarType: leftSidebarTypes.DEFAULT,
  topBarTheme: topBarThemeTypes.LIGHT,
};

const Layout = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHANGE_SIDEBAR_THEME:
      return {
        ...state,
        leftSideBarTheme: action.payload,
      };
    case CHANGE_SIDEBAR_TYPE:
      return {
        ...state,
        leftSideBarType: action.payload.sidebarType,
      };
    case CHANGE_TOPBAR_THEME:
      return {
        ...state,
        topBarTheme: action.payload,
      };
    case SHOW_RIGHT_SIDEBAR:
      return {
        ...state,
        showRightSidebar: action.payload,
      };
    case SHOW_SIDEBAR:
      return {
        ...state,
        isShowSidebar: action.payload,
      };
    case TOGGLE_LEFT_MENU:
      return {
        ...state,
        leftMenu: action.payload,
      };

    default:
      return state;
  }
};

export default Layout;
