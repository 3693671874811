import React, {
  useMemo,
  memo,
} from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  ModalBody,
  ModalHeader,
  Label,
  Col,
  Row,
  Input,
} from 'reactstrap';
import Flatpickr from 'react-flatpickr';
import * as Yup from 'yup';
import { useFormik } from 'formik';

import { ReactComponent as CloseButtonSvg } from 'assets/images/location/close-button-svg.svg';

import AppInputNumberValidation from 'components/App/InputNumberValidation';
import Button from 'components/App/Button';

import { NUMBER_WITH_PERIOD } from 'constants/validationHelper';
import { parseCurrency } from 'utils';

import styles from './ModalEditPolicyReceiveRoom.module.scss';

function ModalEditPolicyReceiveRoom(props) {
  const {
    isOpen,

    startCheckInDate,
    endCheckInDate,
    startCheckOutDate,
    endCheckOutDate,

    onToggleModal,
  } = props;

  const validation = useFormik({
    initialValues: {},

    validationSchema: Yup.object({
      price: Yup.string()
        .matches(NUMBER_WITH_PERIOD, {
          message: 'Vui lòng nhập vào số giá trị tối thiếu 100.000 VNĐ',
          excludeEmptyString: true,
        })
        .test({
          message: 'Vui lòng nhập vào số giá trị tối thiếu 100.000 VNĐ',
          test(value) {
            const number = parseCurrency(value);

            if (number < 100000) return false;
            return true;
          },
        }),
    }),

    onSubmit: () => {

    },
  });

  const CloseButton = useMemo(() => (
    <button className={styles.ButtonClose} onClick={onToggleModal} type="button">
      <CloseButtonSvg />
    </button>
  ), [onToggleModal]);

  return (
    <div className={styles.Component}>
      <Modal
        fade
        size="lg"
        isOpen={isOpen}
        toggle={onToggleModal}
        className={styles.Modal}
      >
        <ModalHeader
          toggle={onToggleModal}
          className={styles.ModalHeader}
          close={CloseButton}
        />

        <ModalBody
          className={styles.ModalBody}
        >
          <Label className="Label">
            Chỉnh sửa giờ Nhận - Trả phòng của Quý vị
          </Label>

          <hr className="Divider" />

          <div className="Section">
            <div className="Section__Title">
              Giờ nhận phòng
            </div>

            <Row className="Section__Content__Wrapper">
              <Col md="6">
                <div className="Section__Content__Title">
                  Từ
                </div>

                <div className="position-relative">
                  <Flatpickr
                    className="Section__Content__TimePicker"
                    defaultValue={startCheckInDate}
                    options={{
                      enableTime: true,
                      noCalendar: true,
                      dateFormat: 'H:i',
                      minuteIncrement: 1,
                      time_24hr: true,
                    }}
                  />

                  <i className="far fa-clock Section__Content__ClockIcon" />
                </div>
              </Col>

              <Col md="6">
                <div className="Section__Content__Title">
                  Đến
                </div>

                <div className="position-relative">
                  <Flatpickr
                    className="Section__Content__TimePicker"
                    defaultValue={endCheckInDate}
                    options={{
                      enableTime: true,
                      noCalendar: true,
                      dateFormat: 'H:i',
                      minuteIncrement: 1,
                      time_24hr: true,
                    }}
                  />

                  <i className="far fa-clock Section__Content__ClockIcon" />
                </div>
              </Col>
            </Row>
          </div>

          <hr className="Divider" />

          <div className="Section">
            <div className="Section__Title">
              Giờ trả phòng
            </div>

            <Row className="Section__Content__Wrapper">
              <Col md="6">
                <div className="Section__Content__Title">
                  Từ
                </div>

                <div className="position-relative">
                  <Flatpickr
                    className="Section__Content__TimePicker"
                    defaultValue={startCheckOutDate}
                    options={{
                      enableTime: true,
                      noCalendar: true,
                      dateFormat: 'H:i',
                      minuteIncrement: 1,
                      time_24hr: true,
                    }}
                  />

                  <i className="far fa-clock Section__Content__ClockIcon" />
                </div>
              </Col>

              <Col md="6">
                <div className="Section__Content__Title">
                  Đến
                </div>

                <div className="position-relative">
                  <Flatpickr
                    className="Section__Content__TimePicker"
                    defaultValue={endCheckOutDate}
                    options={{
                      enableTime: true,
                      noCalendar: true,
                      dateFormat: 'H:i',
                      minuteIncrement: 1,
                      time_24hr: true,
                    }}
                  />

                  <i className="far fa-clock Section__Content__ClockIcon" />
                </div>
              </Col>
            </Row>
          </div>

          <hr className="Divider" />

          <Row className="Section__Container mb-10">
            <Col md="4" className="Section__Title">
              Yêu cầu khách đặt cọc?
            </Col>

            <Col md="8" className="Section__Content__Wrapper">
              <div className="Section__Content__CheckBox">
                <div className="Section__Checkbox__Wrapper">
                  <Input
                    type="radio"
                    className="Section__Checkbox"
                    name="deposit"
                    value="true"
                  />

                  <div className="Section__Checkbox__Name">
                    Có
                  </div>
                </div>

                <div className="Section__Checkbox__Wrapper">
                  <Input
                    type="radio"
                    className="Section__Checkbox"
                    name="deposit"
                    value="false"
                  />

                  <div className="Section__Checkbox__Name">
                    Không
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          <Row className="Section">
            <Col md="12" className="Section__Title Section__TitleAmount">
              Số tiền yêu cầu đặt cọc
            </Col>

            <Col md="12" className="Section__ContentAmount">
              <div className="Amount">
                <AppInputNumberValidation
                  name="price"
                  _id="price"
                  type="number"
                  placeholder="Nhập giá bán"
                  validation={validation}
                  showCurrency="VNĐ"
                />
              </div>

              <div className="Note">
                Tối thiểu 100.000 VNĐ
              </div>
            </Col>
          </Row>

          <hr className="Divider" />

          <div className="Section__Policy">
            <div className="Section__Policy__Title">
              Tóm tắt chính sách
            </div>

            <div className="Section__Policy__Information__Wrapper">
              <div className="Section__Policy__Information__Point">•</div>

              <div className="Section__Policy__Information__Content">
                Giờ nhận phòng: 14:00
              </div>
            </div>

            <div className="Section__Policy__Information__Wrapper">
              <div className="Section__Policy__Information__Point">•</div>

              <div className="Section__Policy__Information__Content">
                Giờ trả phòng phòng: 12:00
              </div>
            </div>

            <div className="Section__Policy__Information__Wrapper">
              <div className="Section__Policy__Information__Point">•</div>

              <div className="Section__Policy__Information__Content">
                Chính sách đặt cọc: Quý khách sẽ được yêu cầu đặt cọc 500.000 VNĐ
                cho những dịch vụ phát sinh và hoàn trả khi làm thủ tục trả phòng
                sau khi đã cần trừ hoàn tất
              </div>
            </div>
          </div>

          <hr className="Divider" />

          <div className="Button__Wrapper">
            <Button
              classes="Button Button__Cancel"
              onClick={onToggleModal}
            >
              Hủy
            </Button>

            <Button classes="Button Button__Save">
              Lưu
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}

ModalEditPolicyReceiveRoom.propTypes = {
  isOpen: PropTypes.bool.isRequired,

  startCheckInDate: PropTypes.instanceOf(Date).isRequired,
  endCheckInDate: PropTypes.instanceOf(Date).isRequired,
  startCheckOutDate: PropTypes.instanceOf(Date).isRequired,
  endCheckOutDate: PropTypes.instanceOf(Date).isRequired,

  onToggleModal: PropTypes.func.isRequired,
};

ModalEditPolicyReceiveRoom.defaultProps = {
};

export default memo(ModalEditPolicyReceiveRoom);
