import { connect } from 'react-redux';
import { actionLogout } from 'store/actions';

import ProfileMenu from './ProfileMenu';

const mapStateToProps = (state) => ({
  loading: state.Profile.loading,
  me: state.Profile.me,
});

const mapDispatchToProps = (dispatch) => ({
  actionLogout: (payload) => dispatch(actionLogout(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileMenu);
