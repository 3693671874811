/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable no-param-reassign */
import i18n from 'i18n';
import moment from 'moment';
import { get } from 'lodash';
import { toast } from 'react-toastify';

import {
  UPLOAD_FILE_TYPE,
  validationCheckErrorType,
} from 'constants/index';
import {
  PICTURE_REGEX,
  VIDEO_REGEX,
} from 'constants/validationHelper';

const toastMessageLengthLimit = {
  SHORT: 30,
  MEDIUM: 80,
  LONG: 150,
};

const toastTimeClose = {
  SHORT: 1500,
  MEDIUM: 2000,
  LONG: 3000,
};

export const showToastError = (message) => {
  const mesLength = message?.length;
  // eslint-disable-next-line no-nested-ternary
  const closeTime = (mesLength < toastMessageLengthLimit.SHORT) ? toastTimeClose.SHORT
    : (mesLength < toastMessageLengthLimit.MEDIUM) ? toastTimeClose.MEDIUM : toastTimeClose.LONG;

  toast.error((message), { autoClose: closeTime });
};

export const validationErrorsArray = (errors = []) => {
  for (let i = 0; i < errors.length; i += 1) {
    showToastError(errors[i].msg);
  }
};

export const apiErrorHandler = (error, showToast = true) => {
  let message = 'default';

  if (error.response?.data?.errors?.length) {
    validationErrorsArray(error.response?.data?.errors);
  } else {
    if (error.response) {
      message = error.response.data.message;
    } else if (error.request?._response) {
      message = error.request._response;
    } else if (error.message) {
      message = error.message;
    }

    if (showToast) {
      showToastError(message);
    }
  }
};

export const validationErrors = (errors = []) => {
  const newObj = {};

  for (let i = 0; i < errors.length; i += 1) {
    newObj[errors[i].param] = errors[i].msg;
  }

  return newObj;
};

export const convertCapitalString = (str) => {
  const mess = str.trim();
  return mess.charAt(0).toUpperCase() + mess.slice(1).toLowerCase();
};

export const showSuccessTrans = ({ type, name }) => {
  toast.success(convertCapitalString(i18n.t('success',
    {
      type: i18n.t(type),
      name: i18n.t(name),
    })));
};

export const showSuccess = (name) => {
  toast.success(convertCapitalString(name));
};

export const translateOptions = (name, options) => {
  const newOptions = [];

  for (let i = 0; i < options.length; i += 1) {
    newOptions.push({
      label: convertCapitalString(i18n.t(`${name}.${options[i].value}`)),
      value: options[i].value,
      ...options[i],
    });
  }

  return newOptions;
};

export const translateOptionsWithCondition = (
  variable, options, name, role, condition = 0, textStyle = 0,
) => {
  const newOptions = [];

  for (let i = 0; i < options.length; i += 1) {
    const option = {
      label: convertCapitalString(i18n.t(`${variable}.${options[i].value}`), textStyle),
      value: options[i].value,
      ...options[i],
    };

    switch (condition) {
      case 1:
        if (options[i][name] === role) option.isDisabled = true;
        break;

      default:
        if (options[i][name] !== role) option.isDisabled = true;
    }

    newOptions.push(option);
  }

  return newOptions;
};

export const getPlaceholderTrans = (placeholder) => {
  const { type, name } = placeholder;
  return convertCapitalString(i18n.t('global.placeholder',
    {
      type: i18n.t(type),
      name: i18n.t(name),
    }));
};

export const getRestoreOrRemoveTrans = (key, name) => convertCapitalString(i18n.t(key,
  {
    name: i18n.t(name),
  }));

export const getButtonNameTrans = (button) => {
  const { type, name } = button;

  return convertCapitalString(i18n.t('button_name',
    {
      type: i18n.t(type),
      name: i18n.t(name),
    }));
};

export const validationErrorHandler = (error) => {
  const { type, name, number } = error;
  return convertCapitalString(i18n.t(`validation.${type}`,
    {
      name: i18n.t(name),
      number: i18n.t(number),
    }));
};

export const checkHadError = (validation, key, type) => {
  if (type === validationCheckErrorType.ERR_ONLY) {
    return !!get(validation.errors, key, false);
  }

  if (type === validationCheckErrorType.TOUCHED_ONLY) {
    return !!get(validation.touched, key, false);
  }

  return !!(get(validation.touched, key, false) && get(validation.errors, key, false));
};

export const getParamsFormObject = (filters) => {
  for (const key in filters) {
    const value = String(filters[key]);
    if (!value) {
      delete filters[key];
    }
  }

  const searchParams = new URLSearchParams(filters);

  return `?${searchParams.toString()}`;
};

export const uploadFormatFile = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  // eslint-disable-next-line no-restricted-properties
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

const checkFileType = (file, type) => {
  if (type === UPLOAD_FILE_TYPE.PICTURE_ONLY) {
    const allowedFile = new RegExp(PICTURE_REGEX);

    if (!allowedFile.test(file.type)) return { result: false, fileName: file.name };
  }

  if (type === UPLOAD_FILE_TYPE.VIDEO_ONLY) {
    const allowedFile = new RegExp(VIDEO_REGEX);

    if (!allowedFile.test(file.type)) return { result: false, fileName: file.name };
  }

  return { result: true };
};

export const uploadSingleFile = (file, type, fileSizeLimit) => {
  const currentFile = checkFileType(file, type);

  if (!currentFile.result) {
    if (type === UPLOAD_FILE_TYPE.PICTURE_ONLY) {
      toast.error('Hình ảnh định dạng JPG, JPEG, PNG');
    } else if (type === UPLOAD_FILE_TYPE.VIDEO_ONLY) {
      toast.error('Video định dạng MP4');
    }
    return null;
  }

  if (file.size < fileSizeLimit) {
    return Object.assign(file, {
      preview: URL.createObjectURL(file),
      formattedSize: uploadFormatFile(file.size),
    });
  }

  if (type === UPLOAD_FILE_TYPE.PICTURE_ONLY) {
    toast.error(`Dung lượng ảnh không được vượt quá ${fileSizeLimit / 1024 / 1024}MB`);
  } else if (type === UPLOAD_FILE_TYPE.VIDEO_ONLY) {
    toast.error(`Dung lượng video không được vượt quá ${fileSizeLimit / 1024 / 1024}MB`);
  }

  return null;
};

export const removeSpacesWithTrim = (data) => {
  const newData = {};

  for (const [key, value] of Object.entries(data)) {
    if (typeof value === 'string' && value !== '') {
      newData[key] = value.trim();
    } else {
      newData[key] = value;
    }
  }

  return newData;
};

export const getUserForFirstName = (user) => {
  const fullName = `${user?.firstName || ''} ${user?.lastName || ''} ${user?.middleName || ''}`;

  return fullName.trim();
};

export const convertArrayToOptions = (arr = []) => {
  const newOptions = [];

  if (!arr) return '';

  if (typeof arr === 'object' && !Array.isArray(arr)) {
    return {
      label: arr?.name || getUserForFirstName(arr) || arr.username || arr?.label || arr?.title,
      value: arr?._id || arr?.value || arr?.id,
      avatar: arr.image || arr.avatar,
    };
  }

  for (let i = 0; i < arr.length; i += 1) {
    if ((arr[i].name !== undefined
      || arr[i].firstName !== undefined
      || arr[i].username) !== undefined && !arr[i].deletedAt) {
      newOptions.push({
        label: arr[i].name
        || getUserForFirstName(arr[i])
        || arr[i].username
        || arr?.label
        || arr[i].title,
        value: arr[i]._id || arr?.value || arr[i].id,
        avatar: arr[i].image || arr[i].avatar,
        reason: arr[i].reason || arr[i].reason,
        suggestion: arr[i].suggestion || arr[i].suggestion,
        type: arr[i].type || arr[i].type,
      });
    }
  }

  return newOptions;
};

export const selectInvalid = () => ({ control: (provided) => ({ ...provided, border: '1px solid #f46a6a !important' }) });

export const checkPermission = (role, permission = '') => {
  if (role?.isRoot || role?.permissions?.includes(permission)) {
    return true;
  }

  return false;
};

export const getUserFullName = (user) => {
  const fullName = `${user?.lastName || ''} ${user?.middleName || ''} ${user?.firstName || ''} `;

  return fullName.trim();
};

export const convertIsoDateToString = (date, format = 'DD/MM/YYYY') => {
  if (date === '' || date === undefined || date === null) return '';

  return moment(new Date(date)).format(format);
};

export const sortArrayOptions = (a, b) => {
  if (a.firstName) {
    return 1 * a.firstName?.localeCompare(b?.firstName, 'es', { sensitivity: 'base' });
  }
  return 1 * a.label?.localeCompare(b?.label, 'es', { sensitivity: 'base' });
};

export const getPathNameImage = (img = '') => {
  const pathName = img.split('/')[img.split('/').length - 1];

  return pathName;
};

export const uploadLogoFormatFile = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  // eslint-disable-next-line no-restricted-properties
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const currencyFormat = (number = 0, { type = 'VND', isNotType = false }) => {
  const amount = new Intl.NumberFormat('vi-VN', isNotType ? { maximumSignificantDigits: 3 } : { style: 'currency', currency: type })?.format(number);

  return amount;
};

export const guidGeneratorID = () => {
  // eslint-disable-next-line no-bitwise
  const S4 = () => (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  return (`${S4() + S4()}-${S4()}-${S4()}-${S4()}-${S4()}${S4()}${S4()}`);
};

export const formatPhoneNumberToServer = (phoneNumber) => {
  let phone = '';
  if (phoneNumber) {
    phone = phoneNumber.replace('0', '+84');
  }
  return phone;
};

export const parseCurrency = (currency = 0, notRound) => {
  let newCurrency = 0;

  const currencyLength = currency.length;

  newCurrency = parseFloat(currencyLength > 3 ? (currency).split('.').join('').replace(',', '.') : currency, 10);

  return notRound ? newCurrency : Math.round(newCurrency);
};

export const invalidNumberArea = (number, min = 0, max = 0) => number < min || number > max;

export const checkFirstNumber = (value) => {
  if (typeof value === 'string' && !value) {
    value = '';
  } else if (value[0] === '0' || value[0] === 0) {
    if (value.length === 1) {
      value = 0;
    } else {
      value = value.substring(1);
    }
  }

  return value;
};

export const cleanVariantData = (draffVariants) => {
  const variants = draffVariants.map((item) => {
    const variant = {};

    variant.name = item.name;

    variant.values = item.values.filter(
      (v) => v.variantName,
    ).map((v) => v.variantName);

    variant.images = item.values.filter(
      (v) => v.variantName,
    ).map((v) => v.image && v.image._id).filter((i) => i);

    if (variant.images.length === 0) delete variant.images;

    return variant;
  });

  return variants;
};

export const getDatesRank = (start, stop) => {
  const listDate = [];
  let currentDate = new Date(start);
  const stopDate = new Date(stop);

  while (currentDate <= stopDate) {
    listDate.push(moment(currentDate));
    currentDate = moment(currentDate).add(1, 'days');
  }

  return listDate;
};

export const onCheckSameDay = (d1, d2) => d1.getFullYear() === d2.getFullYear()
&& d1.getMonth() === d2.getMonth()
&& d1.getDate() === d2.getDate();

export const getPathname = (url) => url.split('/').slice(3).join('/');
