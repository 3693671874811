import axiosClient from 'utils/axios';

const endPoint = '/api/v1.0/employee-media/mini-file';

export default {
  async uploadSingleMiniFile(payload) {
    const { file, typeImg } = payload;

    const path = `${endPoint}?folderType=${typeImg}`;

    const formData = new FormData();
    formData.set('file', file);
    const response = await axiosClient.post(path, formData);

    return response.data;
  },
};
