import {
  REGISTER,
  REGISTER_SUCCESS,
  REGISTER_FAILED,
} from './actionTypes';

// REGISTER
export const actionRegister = (payload) => ({
  type: REGISTER,
  payload,
});

export const actionRegisterSuccess = () => ({
  type: REGISTER_SUCCESS,
});

export const actionRegisterFailed = (payload) => ({
  type: REGISTER_FAILED,
  payload,
});
