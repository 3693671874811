import React, {
  useEffect,
  useState,
  memo,
  useCallback,
} from 'react';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import i18n from 'i18n';
import { get, map } from 'lodash';

import { SITE_NAME } from 'constants/index';
import languages from 'utils/languages';

const LanguageDropdown = () => {
  const [selectedLang, setSelectedLang] = useState('');
  const [menu, setMenu] = useState(false);

  useEffect(() => {
    const currentLanguage = localStorage.getItem('I18N_LANGUAGE');
    setSelectedLang(currentLanguage);
  }, []);

  const onChangeLanguageAction = useCallback((lang) => {
    i18n.changeLanguage(lang);
    localStorage.setItem('I18N_LANGUAGE', lang);
    setSelectedLang(lang);
  }, []);

  const onToggle = useCallback(() => {
    setMenu((prevState) => !prevState);
  }, []);

  return (
    <Dropdown isOpen={menu} toggle={onToggle} className="d-inline-block">
      <DropdownToggle className="btn header-item " type="button" tag="button">
        <img
          src={get(languages, `${selectedLang}.flag`)}
          alt={SITE_NAME}
          height="16"
          className="me-1 pointer-events-none"
        />
      </DropdownToggle>

      <DropdownMenu className="language-switch dropdown-menu-end">
        {map(Object.keys(languages), (key) => (
          <DropdownItem
            key={key}
            onClick={() => onChangeLanguageAction(key)}
            className={`notify-item ${selectedLang === key ? 'active' : 'none'
            }`}
          >
            <img
              src={get(languages, `${key}.flag`)}
              alt={SITE_NAME}
              className="me-1"
              height="12"
            />

            <span className="align-middle">
              {get(languages, `${key}.label`)}
            </span>
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

export default memo(LanguageDropdown);
