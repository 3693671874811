/* eslint-disable react/no-array-index-key */
import React, { useCallback, useMemo, memo } from 'react';
import PropTypes from 'prop-types';

import styles from './OtpInput.module.scss';

export const RE_DIGIT = new RegExp(/^\d+$/);

function OtpInput(props) {
  const {
    value,

    valueLength,

    onChange,
  } = props;

  const valueItems = useMemo(() => {
    const valueArray = value.split('');
    const items = [];

    for (let i = 0; i < valueLength; i += 1) {
      const char = valueArray[i];

      if (RE_DIGIT.test(char)) {
        items.push(char);
      } else {
        items.push('');
      }
    }

    return items;
  }, [value, valueLength]);

  const focusToNextInput = useCallback((target) => {
    const { nextElementSibling } = target;

    if (nextElementSibling) {
      nextElementSibling.focus();
    }
  }, []);

  const focusToPrevInput = useCallback((target) => {
    const { previousElementSibling } = target;

    if (previousElementSibling) {
      previousElementSibling.focus();
    }
  }, []);

  const inputOnChange = useCallback((e, idx) => {
    const { target } = e;
    let targetValue = target.value.trim();
    const isTargetValueDigit = RE_DIGIT.test(targetValue);

    if (!isTargetValueDigit && targetValue !== '') {
      return;
    }

    const nextInputEl = target.nextElementSibling;

    // only delete digit if next input element has no value
    if (!isTargetValueDigit && nextInputEl && nextInputEl.value !== '') {
      return;
    }

    targetValue = isTargetValueDigit ? targetValue : ' ';

    const targetValueLength = targetValue.length;

    if (targetValueLength === 1) {
      const newValue = value.substring(0, idx) + targetValue + value.substring(idx + 1);

      onChange(newValue);

      if (!isTargetValueDigit) {
        return;
      }

      focusToNextInput(target);
    } else if (targetValueLength === valueLength) {
      onChange(targetValue);

      target.blur();
    }

    if (idx === (valueLength - 1)) {
      target.blur();
    }
  }, [onChange]);

  const inputOnKeyDown = useCallback((e) => {
    const { key, target } = e;

    if (key === 'ArrowRight' || key === 'ArrowDown') {
      e.preventDefault();
      return focusToNextInput(target);
    }

    if (key === 'ArrowLeft' || key === 'ArrowUp') {
      e.preventDefault();
      return focusToPrevInput(target);
    }

    const targetValue = target.value;

    // keep the selection range position
    // if the same digit was typed
    target.setSelectionRange(0, targetValue.length);

    if (e.key !== 'Backspace' || targetValue !== '') {
      return null;
    }

    focusToPrevInput(target);

    return null;
  }, [focusToNextInput, focusToPrevInput]);

  const inputOnFocus = useCallback((e) => {
    const { target } = e;

    const prevInputEl = target.previousElementSibling;

    if (prevInputEl && prevInputEl.value === '') {
      return prevInputEl.focus();
    }

    target.setSelectionRange(0, target.value.length);

    return null;
  }, []);

  return (
    <div className={styles.Component}>
      <div className="Otp__Wrapper">
        {valueItems.map((digit, index) => (
          <input
            key={index}
            type="text"
            inputMode="numeric"
            autoComplete="one-time-code"
            pattern="\d{1}"
            maxLength={valueLength}
            className="Otp__Input"
            value={digit}
            onChange={(e) => inputOnChange(e, index)}
            onKeyDown={inputOnKeyDown}
            onFocus={inputOnFocus}
          />
        ))}
      </div>
    </div>
  );
}

OtpInput.propTypes = {
  value: PropTypes.string.isRequired,

  valueLength: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

OtpInput.defaultProps = {

};

export default memo(OtpInput);
