import { connect } from 'react-redux';

import {
  actionChangeSidebarTheme,
  actionChangeSidebarType,
  actionChangeTopBarTheme,
  actionShowRightSidebar,
} from 'store/actions';

import RightSidebar from './RightSidebar';

const mapStateToProps = (state) => ({ ...state.Layout });

const mapDispatchToProps = (dispatch) => ({
  actionChangeSidebarTheme: (payload) => dispatch(actionChangeSidebarTheme(payload)),
  actionChangeSidebarType: (payload) => dispatch(actionChangeSidebarType(payload)),
  actionChangeTopBarTheme: (payload) => dispatch(actionChangeTopBarTheme(payload)),
  actionShowRightSidebar: (payload) => dispatch(actionShowRightSidebar(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RightSidebar);
