import { combineReducers } from 'redux';

// Front
import Layout from 'store/layout/reducer';

// Authentication
import Login from 'store/auth/login/reducer';
import Register from 'store/auth/register/reducer';
import ForgotPassword from 'store/auth/forgotPassword/reducer';
import ResetPassword from 'store/auth/resetPassword/reducer';
import Profile from 'store/auth/profile/reducer';

import Media from 'store/media/reducer';

import Location from 'store/location/reducer';
import RoomStatusList from 'store/location/room/reducer';

const rootReducer = combineReducers({
  // public
  Layout,

  Login,
  Register,
  ForgotPassword,
  ResetPassword,
  Profile,

  Media,

  Location,
  RoomStatusList,

});

export default rootReducer;
