import { connect } from 'react-redux';
import {
  actionGetMe,
  actionUpdateMe,
  actionUpdateMyAvatar,
  actionDeleteMyAvatar,
  actionUpdateMyPassword,
} from 'store/actions';

import Profile from './Profile';

const mapStateToProps = (state) => ({
  me: state.Profile.me,
  profileError: state.Profile.error,
  profileLoading: state.Profile.loading,
  profileLoadingUpdate: state.Profile.loadingUpdate,

  profileLoadingAvatar: state.Profile.loadingAvatar,

  profilePasswordError: state.Profile.passwordError,
  profileLoadingPassword: state.Profile.loadingPassword,
});

const mapDispatchToProps = (dispatch) => ({
  actionGetMe: () => dispatch(actionGetMe()),

  actionUpdateMe: (payload) => dispatch(actionUpdateMe(payload)),

  actionUpdateMyAvatar: (payload) => dispatch(actionUpdateMyAvatar(payload)),

  actionDeleteMyAvatar: () => dispatch(actionDeleteMyAvatar()),

  actionUpdateMyPassword: (payload) => dispatch(actionUpdateMyPassword(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
