import { connect } from 'react-redux';
import { LOGIN } from 'store/auth/login/actionTypes';

import Login from './Login';

const mapStateToProps = (state) => ({
  loadingStep1: state.Login.loading,
  loadingStep2: state.Login.loading,

  me: state.Profile.me,
  loginError: state.Login.error,
});

const mapDispatchToProps = (dispatch) => ({
  actionLoginStep1: (payload) => dispatch(({
    type: LOGIN,
    payload,
  })),

  actionLoginStep2: (payload) => dispatch(({
    type: LOGIN,
    payload,
  })),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
