import React, { memo } from 'react';
import { Link } from 'react-router-dom';

import { LOCATIONS } from 'constants/index';

import Information from './Information';
import Overview from './Overview';
import ReviewList from './ReviewList';

import styles from './Home.module.scss';

function Home() {
  return (
    <div className={styles.Component}>
      <div className="d-flex justify-content-between flex-wrap mb-24">
        <div className="TitlePage">
          <span className="TitlePage__Id">
            1234567 &nbsp; - &nbsp; WHITE LOTUS SAI GON HOTEL
          </span>

          <div className="TitlePage__State">
            <span className="TitlePage__State--Text">Mở/ Có thể đặt phòng</span>
          </div>
        </div>

        <Link to={LOCATIONS.HOME_PAGE} className="Breadcrumb">Trang chủ</Link>
      </div>

      <div className="Home">
        <div className="LeftColum">
          <div className="OverViewContainer">
            <Overview />
          </div>

          <div className="ReviewListContainer">
            <ReviewList />
          </div>
        </div>

        <div className="InformationContainer">
          <Information />
        </div>
      </div>
    </div>
  );
}

export default memo(Home);
