import { connect } from 'react-redux';
import { LOGOUT } from 'store/auth/profile/actionTypes';

import Dashboard from './Dashboard';

const mapDispatchToProps = (dispatch) => ({
  actionLogout: (payload) => dispatch({
    type: LOGOUT,
    payload,
  }),
});

export default connect(null, mapDispatchToProps)(Dashboard);
