import PropTypes from 'prop-types';

import {
  mediaFolder,
  mediaMimeType,
} from 'constants/index';

const mediaSchema = {
  _id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  keyName: PropTypes.string.isRequired,
  mimeType: PropTypes.oneOf([
    mediaMimeType.DOCUMENT,
    mediaMimeType.EXCEL,
    mediaMimeType.JPEG,
    mediaMimeType.PDF,
    mediaMimeType.PNG,
    mediaMimeType.SHEET,
    mediaMimeType.WORD,
  ]).isRequired,
  size: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  folder: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    type: PropTypes.oneOf([
      mediaFolder.AVATAR,
      mediaFolder.INVOICE,
      mediaFolder.ORGANIZATION,
      mediaFolder.PROJECT,
      mediaFolder.SKILL,
    ]).isRequired,
    name: PropTypes.oneOf([
      mediaFolder.AVATAR,
      mediaFolder.INVOICE,
      mediaFolder.ORGANIZATION,
      mediaFolder.PROJECT,
      mediaFolder.SKILL,
    ]).isRequired,
  }),
  createdByEmployee: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      _id: PropTypes.string,
      username: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      middleName: PropTypes.string,
    }),
  ]).isRequired,
  organization: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired,
};

export const mediaPropTypes = PropTypes.shape(mediaSchema);

export const mediaList = PropTypes.arrayOf(
  PropTypes.shape(mediaSchema),
);

export const defaultData = {
  _id: '',
  name: '',
  keyName: '',
  mimeType: 'image/jpeg',
  size: '',
  location: '',
  folder: null,
  createdByEmployee: {
    _id: '',
    username: '',
    firstName: '',
    lastName: '',
    middleName: '',
  },
  organization: '',
  createdAt: '',
  updatedAt: '',
};
