import React, {
  memo,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Link, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
} from 'reactstrap';

import { LOCATIONS } from 'constants/index';
import { getUserFullName, getUserForFirstName } from 'utils';
import propTypeModels from 'propTypeModels';

import AppAvatar from 'components/App/Avatar';
import AppButton from 'components/App/Button';

import './styles.scss';

const ProfileMenu = (props) => {
  const {
    loading,
    me,

    actionLogout,
  } = props;

  const history = useHistory();

  const [menu, setMenu] = useState(false);
  const [fullName, setFullName] = useState('Admin');

  const onToggle = useCallback(() => {
    setMenu((prevState) => !prevState);
  });

  useEffect(() => {
    setFullName(getUserFullName(me));
  }, [me]);

  const onLogout = useCallback(() => {
    actionLogout({ history });
  }, []);

  return (
    loading ? <AppButton loading={loading} color="light" /> : (
      <Dropdown
        isOpen={menu}
        toggle={onToggle}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item d-flex align-items-center"
          id="page-header-user-dropdown"
          tag="button"
        >
          <AppAvatar
            size={36}
            src={me.avatar}
            name={getUserForFirstName(me)}
            alt={`${getUserForFirstName(me)} avatar`}
            className="pointer-events-none"
          />

          <span className="d-none d-xl-inline-block ms-2 me-1 user-name">
            {fullName}
          </span>

          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu-end">

          <Link to={LOCATIONS.PROFILE} className="dropdown-item">
            <i className="bx bx-user font-size-16 align-middle me-1" />

            <span>Hồ sơ</span>
          </Link>

          <div className="dropdown-divider" />

          <button type="button" onClick={onLogout} className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />

            <span>Đăng xuất</span>
          </button>

        </DropdownMenu>
      </Dropdown>
    )
  );
};

ProfileMenu.propTypes = {
  loading: PropTypes.bool.isRequired,

  me: propTypeModels.user.userPropTypes.isRequired,
  actionLogout: PropTypes.func,
};

ProfileMenu.defaultProps = {
  actionLogout: () => {},
};

export default memo(ProfileMenu);
