/* eslint-disable no-unused-vars */
import React, {
  useState, useCallback, useEffect, useMemo,
} from 'react';
import {
  Card,
  CardTitle,
  Col,
  Container,
  Input,
  Row,
  Table,
} from 'reactstrap';
import * as Yup from 'yup';
import moment from 'moment';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';

import AppBreadcrumb from 'components/App/Breadcrumb';
import AppButton from 'components/App/Button';
import AppFlatPickInput from 'components/App/FlatPickInput';
import AppMeta from 'components/App/Meta';
import AppSelectValidation from 'components/App/SelectValidation';
import FormField from 'components/AddLocation/FormField';

import { LOCATIONS, themeColor } from 'constants/index';
import {
  getDatesRank,
  guidGeneratorID,
  onCheckSameDay,
} from 'utils';

import styles from './AmountRoom.module.scss';

const breadcrumbList = [
  {
    name: 'Giá và tình trạng phòng trống',
    isActive: false,
    link: LOCATIONS.LOCATION_LIST,
  },
  {
    name: 'Cập nhật số lượng phòng',
    isActive: true,
    link: '',
  },
];

const value2 = [
  { value: 1, label: 1 },
  { value: 2, label: 2 },
  { value: 3, label: 3 },
  { value: 4, label: 4 },
];

const newDate = new Date();
const aWeekAgo = new Date(moment(newDate).add(6, 'days'));

function AmountRoom() {
  const { t } = useTranslation();

  const [dateInWeek, setDateInWeek] = useState([]);

  // eslint-disable-next-line no-unused-vars
  const [roomsType, setRoomsType] = useState([
    {
      id: guidGeneratorID(),
      label: 'Suite with Terrace',
      isChecked: true,
      amount: 0,
    },
    {
      id: guidGeneratorID(),
      label: 'Superior Twin Room',
      isChecked: true,
      amount: 0,
    },
    {
      id: guidGeneratorID(),
      label: 'Superior Double Room',
      isChecked: true,
      amount: 0,
    },
    {
      id: guidGeneratorID(),
      label: 'Deluxe Double Room',
      isChecked: true,
      amount: 0,
    },
    {
      id: guidGeneratorID(),
      label: 'Suite with City View',
      isChecked: true,
      amount: 0,
    },
  ]);

  const validation = useFormik({
    initialValues: {
      startDate: newDate,
      endDate: aWeekAgo,
    },

    validationSchema: Yup.object({}),

    onSubmit: () => {

    },
  });

  const onChangeStartDate = useCallback((date) => {
    validation.setValues((prevState) => ({
      ...prevState,
      startDate: date.length ? date[0] : '',
    }));
  }, []);

  const onChangeEndDate = useCallback((date) => {
    const selectedDate = date[0];
    const currentDate = new Date();
    const selectedDateWithCurrentTime = new Date(
      selectedDate.setHours(currentDate.getHours(),
        currentDate.getMinutes(), currentDate.getSeconds()),
    );

    let checkDateStart = false;
    if (onCheckSameDay((validation.values.startDate),
      selectedDateWithCurrentTime)) {
      checkDateStart = false;
    } else {
      checkDateStart = date[0] < (validation.values.startDate);
    }

    if (checkDateStart) {
      validation.setValues((prevState) => ({
        ...prevState,
        startDate: '',
      }));
    }

    validation.setValues((prevState) => ({
      ...prevState,
      endDate: date.length ? date[0] : '',
    }));
  }, []);

  const onToggleCheckedDateInWeek = useCallback((event) => {
    const newList = dateInWeek.map((item) => {
      const date = { ...item };
      if (date.id === event.target.value) {
        date.isChecked = !date.isChecked;
      }

      return date;
    });

    setDateInWeek(newList);
  }, [dateInWeek]);

  const onClearAllCheckedDateInWeek = useCallback(() => {
    const newList = dateInWeek.map((item) => {
      const date = { ...item };
      date.isChecked = false;

      return date;
    });

    setDateInWeek(newList);
  }, [dateInWeek]);

  const onToggleCheckedRoom = useCallback((event) => {
    const newList = roomsType.map((item) => {
      const object = { ...item };
      if (object.id === event.target.value) {
        object.isChecked = !object.isChecked;
      }

      return object;
    });

    setRoomsType(newList);
  }, [roomsType]);

  const isCheckAll = useMemo(() => {
    if (roomsType.length > 0 && roomsType.every((item) => item.isChecked)) {
      return true;
    }

    return false;
  }, [roomsType]);

  const onToggleCheckedAllRooms = useCallback(() => {
    const newList = roomsType.map((item) => {
      const object = { ...item };
      object.isChecked = !isCheckAll;

      return object;
    });

    setRoomsType(newList);
  }, [roomsType]);

  const onChangeRoomAmount = useCallback((id) => (selected) => {
    const newList = roomsType.map((item) => {
      const object = { ...item };
      if (object.id === id) {
        object.amount = selected.value;
      }

      return object;
    });

    setRoomsType(newList);
  }, [roomsType]);

  useEffect(() => {
    const listDate = getDatesRank(validation.values.startDate, validation.values.endDate);
    const newDateArray = listDate.map((item) => ({
      id: guidGeneratorID(),
      isChecked: true,
      label: item,
    }));

    setDateInWeek(newDateArray);
  }, [validation.values.startDate, validation.values.endDate]);

  return (
    <div className={`page-content ${styles.Page}`}>
      <AppMeta
        name="Cập nhật số lượng phòng"
      />

      <Container fluid>
        <AppBreadcrumb
          pageTitle="Cập nhật số lượng phòng"
          breadcrumbList={breadcrumbList}
        />

        <div className="CContainer">
          <div className="CContainer__Body">
            <Card className="Section">
              <CardTitle className="Section__Title">
                Quý vị muốn thay đổi ngày nào?
              </CardTitle>

              <Row>
                <Col md="6" className="mb-24">
                  <FormField label="Từ ngày">
                    <AppFlatPickInput
                      name="startDate"
                      validation={validation}
                      onChange={onChangeStartDate}
                      options={{
                        minDate: 'today',
                      }}
                    />
                  </FormField>
                </Col>

                <Col md="6" className="mb-24">
                  <FormField label="Đến ngày">
                    <AppFlatPickInput
                      name="endDate"
                      validation={validation}
                      onChange={onChangeEndDate}
                      options={{
                        minDate: validation.values.startDate || 'today',
                      }}
                    />
                  </FormField>
                </Col>
              </Row>

              <div className="Section__WeekBlock">
                <div className="Section__WeekBlock--CheckBox">
                  {
                dateInWeek.length > 0 && (
                  dateInWeek.map((item) => (
                    <div className="Checkbox Checkbox--WeekBlock" key={item.id}>
                      <Input
                        id={item.id}
                        type="checkbox"
                        checked={item.isChecked}
                        className="Checkbox__Input"
                        value={item.id}
                        onChange={onToggleCheckedDateInWeek}
                      />

                      <label className="Checkbox__Name" htmlFor={item.id}>
                        {t(`global.${item.label.format('dddd')}`)}
                      </label>
                    </div>
                  ))
                )
              }
                </div>

                <div className="Section__WeekBlock--Button">
                  <button
                    className="btn-minimal blue-100"
                    type="button"
                    onClick={onClearAllCheckedDateInWeek}
                  >
                    Xoá tất cả lựa chọn
                  </button>
                </div>
              </div>
            </Card>

            <Card className="Section mb-0">
              <CardTitle className="Section__Title">
                Quý vị muốn thay đổi số lượng phòng nào?
              </CardTitle>

              <Table className="table table-striped mb-0">
                <thead>
                  <tr className="TableHeader">
                    <th>
                      <Input
                        type="checkbox"
                        checked={isCheckAll}
                        className="Checkbox__Input"
                        onChange={onToggleCheckedAllRooms}
                      />
                    </th>
                    <th>Phòng</th>
                    <th>Số lượng phòng</th>
                  </tr>
                </thead>
                <tbody>
                  {
                roomsType.map((item) => (
                  <tr key={item.id}>
                    <th className="align-middle">
                      <Input
                        type="checkbox"
                        checked={item.isChecked}
                        className="Checkbox__Input"
                        value={item.id}
                        onChange={onToggleCheckedRoom}
                      />
                    </th>

                    <td className="align-middle">{item.label}</td>

                    <td>
                      <AppSelectValidation
                        selectedClassName="selected--small"
                        placeholder="1"
                        options={value2}
                        value={item.amount}
                        onChange={onChangeRoomAmount(item.id)}
                      />
                    </td>
                  </tr>
                ))
              }
                </tbody>
              </Table>
            </Card>
          </div>

          <div className="CContainer__Footer">
            <div className="d-flex justify-content-end">
              <AppButton color={themeColor.SECONDARY} classes="ButtonAction mr-24">
                Hủy
              </AppButton>

              <AppButton classes="ButtonAction">
                Cập nhật
              </AppButton>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default AmountRoom;
