export const GET_ROOM_STATUS_LIST = 'GET_ROOM_STATUS_LIST';

export const GET_ROOM_STATUS_LIST_SUCCESS = 'GET_ROOM_STATUS_LIST_SUCCESS';

export const GET_ROOM_STATUS_LIST_FAILED = 'GET_ROOM_STATUS_LIST_FAILED';

export const UPDATE_ROOM_STATUS_LIST = 'UPDATE_ROOM_STATUS_LIST';

export const UPDATE_ROOM_STATUS_LIST_SUCCESS = 'UPDATE_ROOM_STATUS_LIST_SUCCESS';

export const UPDATE_ROOM_STATUS_LIST_FAILED = 'UPDATE_ROOM_STATUS_LIST_FAILED';
