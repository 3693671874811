/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { memo, useCallback } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import SimpleBar from 'simplebar-react';
import { useTranslation } from 'react-i18next';

import {
  topBarThemeTypes,
  leftSidebarTypes,
  leftSideBarThemeTypes,
} from '../../../constants/layout';

import './style.scss';

const RightSidebar = (props) => {
  const {
    topBarTheme,
    leftSideBarType,
    leftSideBarTheme,

    actionChangeSidebarTheme,
    actionChangeSidebarType,
    actionChangeTopBarTheme,
    actionShowRightSidebar,
  } = props;

  const { t } = useTranslation();

  const onShowRightSidebar = useCallback((e) => {
    e.preventDefault();
    actionShowRightSidebar(false);
  }, []);

  const onChangeTopBarTheme = useCallback((e) => {
    if (e.target.checked) {
      actionChangeTopBarTheme(e.target.value);
    }
  }, []);

  const onChangeSidebarType = useCallback((e) => {
    if (e.target.checked) {
      actionChangeSidebarType(e.target.value);
    }
  }, []);

  const onChangeSidebarTheme = useCallback((e) => {
    if (e.target.checked) {
      actionChangeSidebarTheme(e.target.value);
    }
  }, []);

  return (
    <>
      <div className="right-bar" id="right-bar">
        <SimpleBar style={{ height: '900px' }}>
          <div data-simplebar className="h-100">
            <div className="rightbar-title px-3 py-4">
              <Link
                to="#"
                onClick={onShowRightSidebar}
                className="right-bar-toggle float-end"
              >
                <i className="mdi mdi-close noti-icon" />
              </Link>

              <h5 className="m-0">
                {t('theme.settings')}
              </h5>
            </div>

            <hr className="my-0" />

            <div className="p-4">
              <div className="radio-toolbar">
                <span className="mb-2 d-block" id="radio-title">
                  {t('theme.top_bar_theme')}
                </span>

                <input
                  type="radio"
                  id="radioThemeLight"
                  name="radioTheme"
                  value={topBarThemeTypes.LIGHT}
                  checked={topBarTheme === topBarThemeTypes.LIGHT}
                  onChange={onChangeTopBarTheme}
                />

                <label className="me-1" htmlFor="radioThemeLight">
                  {t('theme.light')}
                </label>

                <input
                  type="radio"
                  id="radioThemeDark"
                  name="radioTheme"
                  value={topBarThemeTypes.DARK}
                  checked={topBarTheme === topBarThemeTypes.DARK}
                  onChange={onChangeTopBarTheme}
                />

                <label className="me-1" htmlFor="radioThemeDark">
                  {t('theme.dark')}
                </label>
              </div>

              <>
                <hr className="mt-1" />
                <div className="radio-toolbar">
                  <span className="mb-2 d-block" id="radio-title">
                    {t('theme.left_sidebar_type')}
                    {' '}
                  </span>

                  <input
                    type="radio"
                    id="sidebarDefault"
                    name="sidebarType"
                    value={leftSidebarTypes.DEFAULT}
                    checked={leftSideBarType === leftSidebarTypes.DEFAULT}
                    onChange={onChangeSidebarType}
                  />

                  <label className="me-1" htmlFor="sidebarDefault">
                    {t('theme.default')}
                  </label>

                  <input
                    type="radio"
                    id="sidebarCompact"
                    name="sidebarType"
                    value={leftSidebarTypes.COMPACT}
                    checked={leftSideBarType === leftSidebarTypes.COMPACT}
                    onChange={onChangeSidebarType}
                  />

                  <label className="me-1" htmlFor="sidebarCompact">
                    {t('theme.compact')}
                  </label>

                  <input
                    type="radio"
                    id="sidebarIcon"
                    name="sidebarType"
                    value={leftSidebarTypes.ICON}
                    checked={leftSideBarType === leftSidebarTypes.ICON}
                    onChange={onChangeSidebarType}
                  />

                  <label className="me-1" htmlFor="sidebarIcon">
                    {t('theme.icon')}
                  </label>
                </div>

                <hr className="mt-1" />

                <div className="radio-toolbar coloropt-radio">
                  <span className="mb-2 d-block" id="radio-title">
                    {t('theme.left_sidebar_color_options')}
                  </span>

                  <Row>
                    <Col>
                      <input
                        type="radio"
                        id="leftsidebarThemelight"
                        name="leftsidebarTheme"
                        value={leftSideBarThemeTypes.LIGHT}
                        checked={leftSideBarTheme === leftSideBarThemeTypes.LIGHT}
                        onChange={onChangeSidebarTheme}
                      />

                      <label
                        htmlFor="leftsidebarThemelight"
                        className="bg-light rounded-circle wh-30 me-1"
                      />

                      <input
                        type="radio"
                        id="leftsidebarThemedark"
                        name="leftsidebarTheme"
                        value={leftSideBarThemeTypes.DARK}
                        checked={leftSideBarTheme === leftSideBarThemeTypes.DARK}
                        onChange={onChangeSidebarTheme}
                      />

                      <label
                        htmlFor="leftsidebarThemedark"
                        className="bg-dark rounded-circle wh-30 me-1"
                      />

                      <input
                        type="radio"
                        id="leftsidebarThemecolored"
                        name="leftsidebarTheme"
                        value={leftSideBarThemeTypes.COLORED}
                        checked={leftSideBarTheme === leftSideBarThemeTypes.COLORED}
                        onChange={onChangeSidebarTheme}
                      />

                      <label
                        htmlFor="leftsidebarThemecolored"
                        className="bg-colored rounded-circle wh-30 me-1"
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <input
                        type="radio"
                        id="leftsidebarThemewinter"
                        name="leftsidebarTheme"
                        value={leftSideBarThemeTypes.WINTER}
                        checked={leftSideBarTheme === leftSideBarThemeTypes.WINTER}
                        onChange={onChangeSidebarTheme}
                      />

                      <label
                        htmlFor="leftsidebarThemewinter"
                        className="gradient-winter rounded-circle wh-30 me-1"
                      />

                      <input
                        type="radio"
                        id="leftsidebarThemeladylip"
                        name="leftsidebarTheme"
                        value={leftSideBarThemeTypes.LADYLIP}
                        checked={leftSideBarTheme === leftSideBarThemeTypes.LADYLIP}
                        onChange={onChangeSidebarTheme}
                      />

                      <label
                        htmlFor="leftsidebarThemeladylip"
                        className="gradient-lady-lip rounded-circle wh-30 me-1"
                      />

                      <input
                        type="radio"
                        id="leftsidebarThemeplumplate"
                        name="leftsidebarTheme"
                        value={leftSideBarThemeTypes.PLUMPLATE}
                        checked={leftSideBarTheme === leftSideBarThemeTypes.PLUMPLATE}
                        onChange={onChangeSidebarTheme}
                      />

                      <label
                        htmlFor="leftsidebarThemeplumplate"
                        className="gradient-plum-plate rounded-circle wh-30 me-1"
                      />

                      <input
                        type="radio"
                        id="leftsidebarThemestrongbliss"
                        name="leftsidebarTheme"
                        value={leftSideBarThemeTypes.STRONGBLISS}
                        checked={leftSideBarTheme === leftSideBarThemeTypes.STRONGBLISS}
                        onChange={onChangeSidebarTheme}
                      />

                      <label
                        htmlFor="leftsidebarThemestrongbliss"
                        className="gradient-strong-bliss rounded-circle wh-30 me-1"
                      />

                      <input
                        type="radio"
                        id="leftsidebarThemesgreatwhale"
                        name="leftsidebarTheme"
                        value={leftSideBarThemeTypes.GREATWHALE}
                        checked={leftSideBarTheme === leftSideBarThemeTypes.GREATWHALE}
                        onChange={onChangeSidebarTheme}
                      />

                      <label
                        htmlFor="leftsidebarThemesgreatwhale"
                        className="gradient-strong-great-whale rounded-circle wh-30 me-1"
                      />
                    </Col>
                  </Row>
                </div>
              </>
            </div>
          </div>
        </SimpleBar>
      </div>

      <div className="rightbar-overlay" />
    </>
  );
};

RightSidebar.propTypes = {
  actionChangeSidebarTheme: PropTypes.func.isRequired,
  actionChangeSidebarType: PropTypes.func.isRequired,
  actionChangeTopBarTheme: PropTypes.func.isRequired,
  leftSideBarTheme: PropTypes.string.isRequired,
  leftSideBarType: PropTypes.string.isRequired,
  actionShowRightSidebar: PropTypes.func.isRequired,
  topBarTheme: PropTypes.string.isRequired,
};

export default memo(RightSidebar);
