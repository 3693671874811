import React, { memo } from 'react';
import { Row, Col } from 'reactstrap';

import { COMPANY, SITE_NAME } from 'constants/index';

const Footer = () => (
  <footer className="footer">
    <Row>
      <Col md={6}>
        {`${new Date().getFullYear()} © ${SITE_NAME}.`}
      </Col>

      <Col md={6}>
        <div className="text-sm-end d-none d-sm-block">
          {`Design & Develop by ${COMPANY}`}
        </div>
      </Col>
    </Row>
  </footer>
);

export default memo(Footer);
