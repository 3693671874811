/* LAYOUT */
export const CHANGE_SIDEBAR_THEME = 'CHANGE_SIDEBAR_THEME';
// export const CHANGE_SIDEBAR_THEME_IMAGE = "CHANGE_SIDEBAR_THEME_IMAGE"
export const CHANGE_SIDEBAR_TYPE = 'CHANGE_SIDEBAR_TYPE';

// topbar
export const CHANGE_TOPBAR_THEME = 'CHANGE_TOPBAR_THEME';

// show sidebar
export const SHOW_SIDEBAR = 'SHOW_SIDEBAR';
export const TOGGLE_LEFT_MENU = 'TOGGLE_LEFT_MENU';

/* RIGHT SIDEBAR */
export const SHOW_RIGHT_SIDEBAR = 'SHOW_RIGHT_SIDEBAR';

// Preloader
// export const CHANGE_PRELOADER = "CHANGE_PRELOADER"
