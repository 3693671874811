import React, { memo } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import PropTypes from 'prop-types';

import { ReactComponent as CheckedMarkSuccess } from 'assets/images/location/checked_mark_success-svg.svg';

import styles from './ModalSuccess.module.scss';

function ModalSuccess(props) {
  const {
    isOpen,

    onToggleModal,
  } = props;
  return (
    <div className={styles.Component}>
      <Modal
        fade
        size="md"
        isOpen={isOpen}
        toggle={onToggleModal}
        className={styles.Modal}
      >
        <ModalBody className={styles.ModalBody}>
          <CheckedMarkSuccess className="mb-24 Checkmark" />

          <div className="Content">
            Bạn đã tạo phòng thành công
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}

ModalSuccess.propTypes = {
  isOpen: PropTypes.bool.isRequired,

  onToggleModal: PropTypes.func.isRequired,
};

ModalSuccess.defaultProps = {
};

export default memo(ModalSuccess);
