import React from 'react';
import { Card, Container } from 'reactstrap';
import { Link } from 'react-router-dom';

import AppBreadcrumb from 'components/App/Breadcrumb';
import AppMeta from 'components/App/Meta';
import DataTableLocationDetailPriceType from 'components/App/DataTableLocationDetailPriceType';

import { LOCATIONS, priceType } from 'constants/index';

import styles from './PriceType.module.scss';

const breadcrumbList = [
  {
    name: 'Giá và tình trạng phòng trống',
    isActive: false,
    link: LOCATIONS.LOCATION_LIST,
  },
  {
    name: 'Loại giá',
    isActive: true,
    link: '',
  },
];

const STATUS_PROGRESS_DATA = {
  PENDING: {
    label: 'Chờ duyệt',
    value: 'PENDING',
  },
  REMOVED: {
    label: 'Đã xóa',
    value: 'REMOVED',
  },
};

const fakeDataTableList = [
  {
    id: 123456789,
    name: priceType.STANDARD_PRICE,
    policy: 'Linh động - 2 ngày',
    typeOfRoom: [
      {
        _id: 1,
        type: 'Phòng Superior Giường Đôi',
      },
      {
        _id: 2,
        type: 'Phòng Superior 2 Giường Đơn',
      },
      {
        _id: 3,
        type: 'Suite Có Sân Hiên',
      },
      {
        _id: 4,
        type: 'Phòng Deluxe Giường Đôi',
      },
      {
        _id: 5,
        type: 'Suite Nhìn Ra Thành Phố',
      },
    ],
    meal: 'Bữa sáng',
    revenue: 100000,
    statusProgress: STATUS_PROGRESS_DATA.PENDING.value,
  },
  {
    id: 123456790,
    name: priceType.NON_REFUNDABLE_PRICE,
    policy: 'Linh động - 2 ngày',
    typeOfRoom: [
      {
        _id: 1,
        type: 'Phòng Superior Giường Đôi',
      },
      {
        _id: 2,
        type: 'Phòng Superior 2 Giường Đơn',
      },
      {
        _id: 3,
        type: 'Suite Có Sân Hiên',
      },
      {
        _id: 4,
        type: 'Phòng Deluxe Giường Đôi',
      },
      {
        _id: 5,
        type: 'Suite Nhìn Ra Thành Phố',
      },
    ],
    meal: 'Bữa sáng',
    revenue: 102200,
  },
  {
    id: 123456791,
    name: priceType.CUSTOM_PRICE,
    policy: 'Linh động - 2 ngày',
    typeOfRoom: [
      {
        _id: 1,
        type: 'Phòng Superior Giường Đôi',
      },
      {
        _id: 2,
        type: 'Phòng Superior 2 Giường Đơn',
      },
      {
        _id: 3,
        type: 'Suite Có Sân Hiên',
      },
      {
        _id: 4,
        type: 'Phòng Deluxe Giường Đôi',
      },
      {
        _id: 5,
        type: 'Suite Nhìn Ra Thành Phố',
      },
    ],
    meal: 'Bữa sáng',
    revenue: 109900,
  },
];

function PriceType() {
  return (
    <div className={`page-content ${styles.Page}`}>
      <AppMeta
        name="Loại giá"
      />

      <Container fluid>
        <AppBreadcrumb
          pageTitle="Loại giá"
          breadcrumbList={breadcrumbList}
        />

        <Card className="Section">
          <div className="Section__Header">
            <p className="Text">
              Tạo và xem lại các loại giá khác nhau dành cho
              khách hàng. Quý vị có thể quản lý giá và tình trạng
              phòng trống trong
              <Link to="/" className="TextLink">
                &nbsp;Lịch
              </Link>
            </p>

            <Link
              className="col-md-2 BtnAdd"
              to={LOCATIONS.UPDATE_PRICE_TYPE}
            >
              <i className="fas fa-plus" />

              Thêm loại giá mới
            </Link>
          </div>

          <DataTableLocationDetailPriceType
            list={fakeDataTableList}
          />
        </Card>
      </Container>
    </div>
  );
}

export default PriceType;
