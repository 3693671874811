import React, { useState, useCallback, useEffect } from 'react';
import {
  Container,
  Col,
  Nav,
  NavLink,
  NavItem,
  Row,
  TabPane,
  TabContent,
  Card,
  CardTitle,
  CardText,
} from 'reactstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { LOCATIONS } from 'constants/index';
import {
  addAlreadyLocationStepOneValidation,
  addAlreadyLocationStepTwoValidation,
  addAlreadyLocationStepThreeValidation,
} from 'utils/validate';

import Meta from 'components/App/Meta';
import Breadcrumb from 'components/App/Breadcrumb';
import StepOne from 'components/AddLocationAlready/StepOne';
import StepTwo from 'components/AddLocationAlready/StepTwo';
import StepThree from 'components/AddLocationAlready/StepThree';

import { ReactComponent as AuthenAccountIconSvg } from 'assets/images/location/authen-account-icon-svg.svg';
import { ReactComponent as ConfirmLocationIconSvg } from 'assets/images/location/confirm-location-icon-svg.svg';
import { ReactComponent as ConnectLocationIconSvg } from 'assets/images/location/link-location-icon-svg.svg';

import styles from './AddAlreadyLocation.module.scss';

const breadcrumbList = [
  {
    name: 'Quản lý khách sạn',
    isActive: false,
    link: '', // TODO: ADD ROUTE WHEN HAVE LINK
  },
  {
    name: 'Danh sách khách sạn',
    isActive: false,
    link: LOCATIONS.LOCATION_LIST,
  },
  {
    name: 'Thêm danh sách khách sạn đã có',
    isActive: true,
    link: '',
  },
];

const TAB = {
  AUTHEN_ACCOUNT: 'AUTHEN_ACCOUNT',
  CONNECT_LOCATION: 'CONNECT_LOCATION',
  CONFIRM_LOCATION: 'CONFIRM_LOCATION',
};

const NAV_TAB = [
  {
    icon: AuthenAccountIconSvg,
    title: 'Xác nhận tài khoản',
    value: TAB.AUTHEN_ACCOUNT,
  },
  {
    icon: ConnectLocationIconSvg,
    title: 'Liên kết chỗ nghỉ',
    value: TAB.CONNECT_LOCATION,
  },
  {
    icon: ConfirmLocationIconSvg,
    title: 'Xác nhận',
    value: TAB.CONFIRM_LOCATION,
  },
];

function AddAlreadyLocation() {
  const [tabActive, setTabActive] = useState(TAB.AUTHEN_ACCOUNT);
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [otp, setOtp] = useState('');

  const validationStepOne = useFormik({
    initialValues: {
      email: '',
      password: '',
    },

    validationSchema: Yup.object({
      email: addAlreadyLocationStepOneValidation.email,
      password: addAlreadyLocationStepOneValidation.password,
    }),

    onSubmit: () => {
      setTabActive(TAB.CONNECT_LOCATION);
    },
  });

  const onSubmitStepOne = useCallback((e) => {
    e.preventDefault();
    validationStepOne.handleSubmit();
  }, [validationStepOne]);

  const validationStepTwo = useFormik({
    initialValues: {
      idLocation: '',
    },

    validationSchema: Yup.object({
      idLocation: addAlreadyLocationStepTwoValidation.idLocation,
    }),

    onSubmit: () => {
      setTabActive(TAB.CONFIRM_LOCATION);
    },
  });

  const onSubmitStepTwo = useCallback((e) => {
    e.preventDefault();
    validationStepTwo.handleSubmit();
  }, [validationStepTwo]);

  const validationStepThree = useFormik({
    initialValues: {
      otpCode: '',
    },

    validationSchema: Yup.object({
      otpCode: addAlreadyLocationStepThreeValidation.otpCode,
    }),

    onSubmit: () => {
    },
  });

  const onChangeTab = useCallback((tab) => () => {
    setTabActive(tab);
  }, []);

  const onToggleShowPassword = useCallback(() => {
    setIsShowPassword((prev) => !prev);
  }, []);

  const onChangeOtpInput = useCallback((value) => {
    setOtp(value);
  }, [otp]);

  useEffect(() => {
    if (otp.length) {
      validationStepThree.setFieldValue('otpCode', otp, false);
    }
  }, [otp]);

  // console.log('🙂  validationStepThree.values.otp   🙂', validationStepThree.values.otpCode);

  return (
    <div className={styles.Page}>
      <div className="page-content">
        <Meta name="Thêm khách sạn đã có" />

        <Container fluid>
          <Breadcrumb
            pageTitle="Thêm khách sạn đã có"
            breadcrumbList={breadcrumbList}
          />
          <Row>
            <Col className="NavMenu" md="2" sm="12">
              <Nav className="flex-column" pills>
                {
                  NAV_TAB.map((tab) => (
                    <NavItem key={tab.value}>
                      <NavLink
                        className={`${(tabActive === tab.value) && ('active')}`}
                        onClick={onChangeTab(tab.value)}
                        key={tab.value}
                      >
                        <tab.icon className="d-block check-nav-icon mt-4 mb-2" />
                        <p className="font-weight-bold mb-4">{tab.title}</p>
                      </NavLink>
                    </NavItem>
                  ))
                }
              </Nav>
            </Col>

            <Col className="NavBody" md="8" sm="12">
              <TabContent activeTab={tabActive}>
                <TabPane tabId={TAB.AUTHEN_ACCOUNT}>
                  <StepOne
                    isShowPassword={isShowPassword}
                    validation={validationStepOne}
                    onToggleShowPassword={onToggleShowPassword}
                    onSubmitStepOne={onSubmitStepOne}
                  />
                </TabPane>

                <TabPane tabId={TAB.CONNECT_LOCATION}>
                  <StepTwo
                    validation={validationStepTwo}
                    onClickPrevTab={onChangeTab}
                    tabPrevious={TAB.AUTHEN_ACCOUNT}
                    onSubmitStepTwo={onSubmitStepTwo}
                  />
                </TabPane>

                <TabPane tabId={TAB.CONFIRM_LOCATION}>
                  <StepThree
                    validation={validationStepThree}
                    tabPrevious={TAB.CONNECT_LOCATION}
                    onClickPrevTab={onChangeTab}
                    otp={otp}
                    onChangeOtpInput={onChangeOtpInput}
                  />
                </TabPane>
              </TabContent>
            </Col>

            <Col className="NavNote" md="2" sm="12">
              <Card className="NavNote__Wrapper">
                <CardTitle className="NavNote__Title">
                  Truy cập tất cả chỗ nghỉ của Quý vị trong cùng một tài khoản
                </CardTitle>

                <CardText className="NavNote__Text">
                  Để quản lý tất cả mọi chỗ nghỉ trên Bookese.com chỉ bằng một đăng nhập,
                  Quý vị có thể thêm chỗ nghỉ vào tài khoản này
                </CardText>

                <CardText className="NavNote__Text">
                  Sau khi thêm, hãy dùng tài khoản này để xem và truy cập vào
                  tất cả các chỗ nghỉ của Quý vị trên cùng một trang tổng quan dành cho nhóm.
                  Thật dễ dàng!
                </CardText>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default AddAlreadyLocation;
