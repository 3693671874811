import {
  GET_ME,
  GET_ME_SUCCESS,
  GET_ME_FAILED,

  UPDATE_ME,
  UPDATE_ME_SUCCESS,
  UPDATE_ME_FAILED,

  UPDATE_MY_AVATAR,
  UPDATE_MY_AVATAR_SUCCESS,
  UPDATE_MY_AVATAR_FAILED,

  DELETE_MY_AVATAR,
  DELETE_MY_AVATAR_SUCCESS,
  DELETE_MY_AVATAR_FAILED,

  UPDATE_MY_PASSWORD,
  UPDATE_MY_PASSWORD_SUCCESS,
  UPDATE_MY_PASSWORD_FAILED,

  LOGOUT,
  REFRESH_TOKEN_SUCCESS,
  REFRESH_TOKEN_FAILED,
} from './actionTypes';

// GET MY PROFILE
export const actionGetMe = (payload) => ({
  type: GET_ME,
  payload,
});

export const actionGetMeSuccess = (payload) => ({
  type: GET_ME_SUCCESS,
  payload,
});

export const actionGetMeFailed = () => ({
  type: GET_ME_FAILED,
});

// UPDATE MY PROFILE
export const actionUpdateMe = (payload) => ({
  type: UPDATE_ME,
  payload,
});

export const actionUpdateMeSuccess = (payload) => ({
  type: UPDATE_ME_SUCCESS,
  payload,
});

export const actionUpdateMeFailed = (payload) => ({
  type: UPDATE_ME_FAILED,
  payload,
});

// UPDATE MY AVATAR
export const actionUpdateMyAvatar = (payload) => ({
  type: UPDATE_MY_AVATAR,
  payload,
});

export const actionUpdateMyAvatarSuccess = (payload) => ({
  type: UPDATE_MY_AVATAR_SUCCESS,
  payload,
});

export const actionUpdateMyAvatarFailed = () => ({
  type: UPDATE_MY_AVATAR_FAILED,
});

// DELETE MY AVATAR
export const actionDeleteMyAvatar = () => ({
  type: DELETE_MY_AVATAR,
});

export const actionDeleteMyAvatarSuccess = () => ({
  type: DELETE_MY_AVATAR_SUCCESS,
});

export const actionDeleteMyAvatarFailed = () => ({
  type: DELETE_MY_AVATAR_FAILED,
});

// UPDATE MY PASSWORD
export const actionUpdateMyPassword = (payload) => ({
  type: UPDATE_MY_PASSWORD,
  payload,
});

export const actionUpdateMyPasswordSuccess = () => ({
  type: UPDATE_MY_PASSWORD_SUCCESS,
});

export const actionUpdateMyPasswordFailed = () => ({
  type: UPDATE_MY_PASSWORD_FAILED,
});

// LOGOUT
export const actionLogout = (payload) => ({
  type: LOGOUT,
  payload,
});

// REFRESH TOKEN
export const actionRefreshTokenSuccess = (payload) => ({
  type: REFRESH_TOKEN_SUCCESS,
  payload,
});

export const actionRefreshTokenFailed = () => ({
  type: REFRESH_TOKEN_FAILED,
});
