import React, { useCallback, useMemo, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  Container,
  Col,
  Card,
  Row,
  CardTitle,
  Table,
  Input,
} from 'reactstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import CurrencyFormat from 'react-currency-format';

import { LOCATIONS } from 'constants/index';
import { createNewRoomValidation } from 'utils/validate';

import AppBreadcrumb from 'components/App/Breadcrumb';
import AppButton from 'components/App/Button';
import AppInputValidation from 'components/App/InputValidation';
import AppMeta from 'components/App/Meta';
import AppSelectValidation from 'components/App/SelectValidation';
import FormField from 'components/AddLocation/FormField';
import SectionWrapper from 'components/AddLocation/SectionWrapper';

import styles from './CreateNewRoom.module.scss';

const tempTypeOfRoomList = [
  {
    label: 'Phòng Standard 1 Giường Đơn',
    value: 'standard_single_room',
  },
  {
    label: 'Phòng Standard 1 Giường Đôi',
    value: 'standard_double_room',
  },
  {
    label: 'Phòng Standard 2 Giường Đơn',
    value: 'standard_twin_room',
  },
  {
    label: 'Phòng Standard 1 Giường Đôi hoặc 2 Giường Đơn',
    value: 'standard_double_or_twin_room',
  },
  {
    label: 'Phòng Standard 1 Giường Queen',
    value: 'standard_queen_room',
  },
  {
    label: 'Phòng Standard 1 Giường King',
    value: 'standard_king_room',
  },
  {
    label: 'Studio',
    value: 'studio',
  },
  {
    label: 'Căn hộ',
    value: 'apartment',
  },
  {
    label: 'Bungalow',
    value: 'bungalow',
  },
  {
    label: 'Biệt thự',
    value: 'villa',
  },
];

const tempValue = [
  { value: 1, label: 1 },
  { value: 2, label: 2 },
  { value: 3, label: 3 },
  { value: 4, label: 4 },
];

const tempTable = [
  {
    id: 1,
    label: 'Giường đơn (1m2 x 2m)',
    amount: 1,
  },
  {
    id: 2,
    label: 'Giường đôi (1m4 x 2m)',
    amount: 1,
  },
  {
    id: 3,
    label: 'Giường King (1m8 x 2m)',
    amount: 1,
  },
  {
    id: 4,
    label: 'Giường Super King (2m x 2m2)',
    amount: 1,
  },
];

function CreateNewRoom() {
  const params = useParams();
  const history = useHistory();

  const [selectedBedOptions, setSelectedBedOptions] = useState([]);
  const [isSelectAllBed, setIsSelectAllBed] = useState(false);
  const [dataTable, setDataTable] = useState(tempTable);

  const roomId = useMemo(() => params.id, [params]);

  const breadcrumbList = [
    {
      name: 'Chỗ nghỉ',
      isActive: false,
      link: LOCATIONS.LOCATION_LIST,
    },
    {
      name: 'Loại phòng',
      isActive: false,
      link: LOCATIONS.ROOM_TYPE,
    },
    {
      name: `${roomId ? 'Chỉnh sửa phòng' : 'Tạo phòng mới'}`,
      isActive: true,
      link: '',
    },
  ];

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      roomNameRequired: '',
      roomNameOptional: '',
      numberOfRoom: 1,
      numberOfBedroom: 1,
      numberOfLivingroom: 1,
      numberOfBathroom: 1,
      typeBed: '',
      numberOfBed: '',
      limitOfAdult: 1,
      limitOfKid: 1,
      limitOfCustomer: 2,
      numberOfBedOptional: 1,
    },

    validationSchema: Yup.object({
      roomNameRequired: createNewRoomValidation.roomNameRequired,
      roomNameOptional: createNewRoomValidation.roomNameOptional,
      numberOfRoom: createNewRoomValidation.numberOfRoom,
      numberOfBedroom: createNewRoomValidation.numberOfBedroom,
      numberOfLivingroom: createNewRoomValidation.numberOfLivingroom,
      numberOfBathroom: createNewRoomValidation.numberOfBathroom,
      typeBed: createNewRoomValidation.typeBed,
      numberOfBed: createNewRoomValidation.numberOfBed,
      limitOfAdult: createNewRoomValidation.limitOfAdult,
      limitOfKid: createNewRoomValidation.limitOfKid,
      limitOfCustomer: createNewRoomValidation.limitOfCustomer,
    }),
  });

  const onSelectAllBed = useCallback(() => {
    setSelectedBedOptions(isSelectAllBed ? [] : dataTable);
    setIsSelectAllBed((prevState) => !prevState);
  }, [selectedBedOptions, isSelectAllBed]);

  const onSelectBed = useCallback((obj) => {
    setSelectedBedOptions((prevState) => {
      if (selectedBedOptions.includes(obj)) {
        return selectedBedOptions.filter((item) => item !== obj);
      }
      return [...prevState, obj];
    });
    setIsSelectAllBed(false);
  }, [selectedBedOptions, isSelectAllBed]);

  const onChangeOption = useCallback((name) => (e) => {
    validation.setFieldValue(name, e.value, false);
  }, []);

  const onClickSaveForm = useCallback(() => {
    history.push(`${LOCATIONS.LOCATION_ROOM_TYPE}?createStatus=success`);
  }, [history]);

  const onChangeAmountRoom = useCallback((id) => (event) => {
    const newList = dataTable.map((item) => {
      const t = { ...item };
      if (t.id === id) {
        t.amount = event.target.value;
      }

      return t;
    });

    setDataTable(newList);
  }, [dataTable]);

  return (
    <div className={`page-content ${styles.Page}`}>
      <AppMeta
        name={roomId ? 'Chỉnh sửa phòng' : 'Tạo phòng mới'}
      />

      <Container fluid>
        <AppBreadcrumb
          pageTitle={roomId ? 'Chỉnh sửa phòng' : 'Tạo phòng mới'}
          breadcrumbList={breadcrumbList}
        />

        <div className="Card__Wrapper">
          <Col md="8">
            <Card className="Card mb-24">
              <SectionWrapper title="Vui lòng chọn" className="m-0">
                <FormField label="Tên phòng" isRequired>
                  <AppSelectValidation
                    placeholder="Vui lòng chọn"
                    name="roomNameRequired"
                    key="roomNameRequired"
                    options={tempTypeOfRoomList}
                    value={validation.values.roomNameRequired}
                    onChange={onChangeOption('roomNameRequired')}
                    validation={validation}
                    isDisabled={!!roomId}
                  />

                  <span className="Subtitle">
                    Đây là tên mà khách sẽ thấy trên trang web Bookese.com
                  </span>
                </FormField>

                <FormField label="Tên tùy chọn (không bắt buộc)">
                  <AppInputValidation
                    isShowCounter
                    // isCounterOnInput
                    name="roomNameOptional"
                    id="roomNameOptional"
                    type="text"
                    placeholder="Nhập tên tuỳ chọn"
                    validation={validation}
                  />

                  <span className="Subtitle">
                    Tạo tên tùy chọn cho riêng Quý vị tham khảo (không bắt buộc).
                  </span>
                </FormField>

                <Row className="mb-24">
                  <Col md="6">
                    <FormField label="Số phòng (loại này)" isRequired>
                      <AppSelectValidation
                        name="numberOfRoom"
                        id="numberOfRoom"
                        options={tempValue}
                        placeholder="Vui lòng nhập số phòng"
                        validation={validation}
                        value={validation.values.numberOfRoom}
                        onChange={onChangeOption('numberOfRoom')}
                      />

                      <span className="Subtitle">
                        Trong số 38 phòng tại chỗ nghỉ của Quý vị
                      </span>
                    </FormField>
                  </Col>

                  <Col md="6">
                    <FormField label="Số phòng ngủ">
                      <AppSelectValidation
                        options={tempValue}
                        name="numberOfBedroom"
                        id="numberOfBedroom"
                        placeholder="Vui lòng nhập số phòng"
                        validation={validation}
                        value={validation.values.numberOfBedroom}
                        onChange={onChangeOption('numberOfBedroom')}
                      />
                    </FormField>
                  </Col>
                </Row>

                <Row>
                  <Col md="6">
                    <FormField label="Số phòng khách">
                      <AppSelectValidation
                        options={tempValue}
                        name="numberOfLivingroom"
                        id="numberOfLivingroom"
                        placeholder="Vui lòng nhập số phòng"
                        validation={validation}
                        value={validation.values.numberOfLivingroom}
                        onChange={onChangeOption('numberOfLivingroom')}
                      />
                    </FormField>
                  </Col>

                  <Col md="6">
                    <FormField label="Số phòng tắm">
                      <AppSelectValidation
                        options={tempValue}
                        name="numberOfBathroom"
                        id="numberOfBathroom"
                        placeholder="Vui lòng nhập số phòng"
                        validation={validation}
                        value={validation.values.numberOfBathroom}
                        onChange={onChangeOption('numberOfBathroom')}
                      />
                    </FormField>
                  </Col>
                </Row>
              </SectionWrapper>
            </Card>

            <Card className="Card">
              <div className="Card__Container">
                <CardTitle className="Card__Title">
                  Tổng sức chứa
                </CardTitle>

                <hr className="Divider" />

                <div className="Card__Section">
                  <div className="Card__Section__Title">
                    Số người lớn tối đa

                    <code>*</code>
                  </div>

                  <Col md="2">
                    <AppSelectValidation
                      options={tempValue}
                      name="limitOfAdult"
                      id="limitOfAdult"
                      selectedClassName="HeighInput selected--small"
                      placeholder="Vui lòng nhập"
                      validation={validation}
                      value={validation.values.limitOfAdult}
                      onChange={onChangeOption('limitOfAdult')}
                    />
                  </Col>
                </div>

                <hr className="Divider" />

                <div className="Card__Section">
                  <div className="Card__Section__Title">
                    Số trẻ em tối đa

                    <code>*</code>
                  </div>

                  <Col md="2">
                    <AppSelectValidation
                      options={tempValue}
                      name="limitOfKid"
                      id="limitOfKid"
                      selectedClassName="HeighInput selected--small"
                      placeholder="Vui lòng nhập"
                      validation={validation}
                      value={validation.values.limitOfKid}
                      onChange={onChangeOption('limitOfKid')}
                    />
                  </Col>
                </div>

                <hr className="Divider" />

                <div className="Card__Section">
                  <div className="Card__Section__Title">
                    Tổng số khách tối đa

                    <code>*</code>

                    <div className="Subtitle">
                      Tối đa 20 khách
                    </div>
                  </div>

                  <Col md="2">
                    <AppSelectValidation
                      isDisabled={!!roomId}
                      options={tempValue}
                      name="limitOfCustomer"
                      id="limitOfCustomer"
                      selectedClassName="HeighInput selected--small"
                      placeholder="Vui lòng nhập"
                      validation={validation}
                      value={validation.values.limitOfCustomer}
                      onChange={onChangeOption('limitOfCustomer')}
                    />
                  </Col>
                </div>
              </div>
            </Card>

            <Card className="Card">
              <div className="Card__Container">
                <CardTitle className="Card__Title">
                  Tùy chọn giường
                </CardTitle>

                <Table
                  hover
                  striped
                  className="mb-0"
                >
                  <thead>
                    <tr>
                      <th className="col-md-2">
                        <Input
                          type="checkbox"
                          checked={isSelectAllBed}
                          onChange={onSelectAllBed}
                        />
                      </th>

                      <th className="Table__Header__Title col-md-6">
                        Loại giường
                        <code>*</code>
                      </th>

                      <th className="Table__Header__Title col-md-4">
                        Số lượng giường
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {dataTable.map((item) => (
                      <tr
                        key={item.id}
                        className="Table__Body__Row"
                      >
                        <th scope="row">
                          <Input
                            type="checkbox"
                            checked={selectedBedOptions.includes(item)}
                            onChange={() => onSelectBed(item)}
                          />
                        </th>

                        <td>
                          {item.label}
                        </td>

                        <td>
                          <CurrencyFormat
                            thousandSeparator="."
                            decimalSeparator=","
                            displayType="input"
                            className="form-control InputCustom"
                            onChange={onChangeAmountRoom(item.id)}
                            placeholder="Số lượng"
                            value={item.amount}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Card>
          </Col>
        </div>

        <Card className="Card__Button">
          <div className="Card__Button__Container">
            {
              roomId && (
                <div className="Button__Delete">
                  <button
                    className="btn-minimal red-100"
                    type="button"
                  >
                    Xóa
                  </button>
                </div>
              )
            }

            <div className="Card__Button__Wrapper">
              <AppButton classes="Button Button__Cancel">
                Hủy
              </AppButton>

              <AppButton classes="Button Button__Save" onClick={onClickSaveForm}>
                Lưu
              </AppButton>
            </div>
          </div>
        </Card>
      </Container>
    </div>
  );
}

export default CreateNewRoom;
