import React, {
  useState,
  useCallback,
} from 'react';
import { Col, Row } from 'reactstrap';
import { useFormik } from 'formik';
import Flatpickr from 'react-flatpickr';

import FormField from 'components/AddLocation/FormField';
import SectionWrapper from 'components/AddLocation/SectionWrapper';
import SelectValidation from 'components/App/SelectValidation';

import 'flatpickr/dist/themes/material_blue.css';
import styles from './StepFive.module.scss';

const optionTimeDenyRoom = [
  { value: '1', label: 'Đến 1 ngày trước khi tới nhận phòng' },
  { value: '2', label: 'Đến 2 ngày trước khi tới' },
  { value: '3', label: 'Đến 3 ngày trước khi tới' },
  { value: '4', label: 'Đến 4 ngày trước khi tới' },
  { value: '5', label: 'Đến 5 ngày trước khi tới' },
  { value: '7', label: 'Đến 7 ngày trước khi tới' },
  { value: '10', label: 'Đến 10 ngày trước khi tới' },
  { value: '14', label: 'Đến 14 ngày trước khi tới' },
];

const optionFeeDenyRoom = [
  { value: '1', label: 'Giá của đêm đầu tiên' },
  { value: '2', label: '50% tổng tiền phòng' },
  { value: '3', label: '100% tổng tiền phòng' },
];

const optionFeeAbsence = [
  { value: '1', label: 'Bằng phí huỷ đặt phòng' },
  { value: '2', label: '100% tổng tiền phòng' },
];

const optionFlatpickr = {
  enableTime: true,
  noCalendar: true,
  dateFormat: 'H:i',
  minuteIncrement: 1,
  time_24hr: true,
};

const defaultDate = new Date();

function StepFive() {
  const [bookingTime] = useState({
    startCheckInDate: defaultDate,
    endCheckInDate: defaultDate,
    startCheckOutDate: defaultDate,
    endCheckOutDate: defaultDate,
  });

  const validation = useFormik({
    initialValues: {
      timeDenyRoom: optionTimeDenyRoom[0].value,
      feeDenyRoom: optionFeeDenyRoom[0].value,
      feeAbsence: optionFeeAbsence[0].value,
    },

    onSubmit: () => {

    },
  });

  const onChangeDenyRoom = useCallback((e) => {
    validation.setFieldValue('timeDenyRoom', e.value, false);
  }, [validation]);

  const onChangeFeeDenyRoom = useCallback((e) => {
    validation.setFieldValue('feeDenyRoom', e.value, false);
  }, [validation]);

  const onChangeFeeAbsence = useCallback((e) => {
    validation.setFieldValue('feeAbsence', e.value, false);
  }, [validation]);

  return (
    <div className={styles.Component}>
      <SectionWrapper title="Hủy đặt phòng" isRequired>
        <FormField label="Khách có thể hủy đặt phòng miễn phí bao lâu trước khi tới nơi?">
          <SelectValidation
            placeholder="Đến 3 ngày trước khi tới nơi"
            options={optionTimeDenyRoom}
            value={validation.values.timeDenyRoom}
            onChange={onChangeDenyRoom}
          />
        </FormField>

        <FormField label={`Khách phải trả bao nhiêu khi hủy đặt phòng trong vòng ${validation.values.timeDenyRoom} ngày trước khi tới nhận phòng?`}>
          <SelectValidation
            placeholder="100% tiền phòng"
            options={optionFeeDenyRoom}
            value={validation.values.feeDenyRoom}
            onChange={onChangeFeeDenyRoom}
          />
        </FormField>

        <FormField label="Quý vị sẽ thu phí bao nhiêu nếu khách vắng mặt?">
          <SelectValidation
            placeholder="100% tiền phòng"
            options={optionFeeAbsence}
            value={validation.values.feeAbsence}
            onChange={onChangeFeeAbsence}
          />
        </FormField>
      </SectionWrapper>

      <SectionWrapper title="Giờ nhận phòng" isRequired>
        <Row>
          <Col>
            <div className="Label">
              Từ
            </div>

            <Flatpickr
              className="Section__Content__TimePicker"
              defaultValue={bookingTime.startCheckInDate}
              options={optionFlatpickr}
            />
          </Col>

          <Col>
            <div className="Label">
              Đến
            </div>

            <Flatpickr
              className="Section__Content__TimePicker"
              defaultValue={bookingTime.endCheckInDate}
              options={optionFlatpickr}
            />
          </Col>
        </Row>
      </SectionWrapper>

      <SectionWrapper title="Giờ trả phòng" isRequired>
        <Row>
          <Col>
            <div className="Label">
              Từ
            </div>

            <Flatpickr
              className="Section__Content__TimePicker"
              defaultValue={bookingTime.startCheckOutDate}
              options={optionFlatpickr}
            />
          </Col>

          <Col>
            <div className="Label">
              Đến
            </div>

            <Flatpickr
              className="Section__Content__TimePicker"
              defaultValue={bookingTime.endCheckOutDate}
              options={optionFlatpickr}
            />
          </Col>
        </Row>
      </SectionWrapper>

      <SectionWrapper title="Hút thuốc và Thú cưng" isRequired>
        <div className="BtnRadioWrapper">
          <div className="Title">Hút thuốc</div>

          <div className="Box">
            <input className="InputRadio" type="radio" id="smoke-allow" name="smoke" checked />
            <label htmlFor="smoke-allow">Cho phép</label>
          </div>
          <div className="Box">
            <input className="InputRadio" type="radio" id="smoke-deny" name="smoke" checked />
            <label htmlFor="smoke-deny">Không cho phép</label>
          </div>
        </div>

        <div className="BtnRadioWrapper">
          <div className="Title">Thú cưng</div>

          <div className="Box">
            <input className="InputRadio" type="radio" id="pets-allow" name="pets" checked />
            <label htmlFor="pets-allow">Cho phép</label>
          </div>
          <div className="Box">
            <input className="InputRadio" type="radio" id="pets-deny" name="pets" checked />
            <label htmlFor="pets-deny">Không cho phép</label>
          </div>
        </div>
      </SectionWrapper>
    </div>
  );
}

export default StepFive;
