import { connect } from 'react-redux';

import {
  actionShowRightSidebar,
  actionChangeSidebarType,
  actionChangeSidebarTheme,
  actionChangeTopBarTheme,
} from 'store/actions';

import Layout from './Layout';

const mapStateToProps = (state) => ({
  leftSideBarType: state.Layout.leftSideBarType,
  topBarTheme: state.Layout.topBarTheme,
  showRightSidebar: state.Layout.showRightSidebar,
  leftSideBarTheme: state.Layout.leftSideBarTheme,
});

const mapDispatchToProps = (dispatch) => ({
  actionShowRightSidebar: (payload) => dispatch(actionShowRightSidebar(payload)),
  actionChangeSidebarType:
  (sidebarType, isMobile) => dispatch(actionChangeSidebarType(sidebarType, isMobile)),
  actionChangeSidebarTheme: (theme) => dispatch(actionChangeSidebarTheme(theme)),
  actionChangeTopBarTheme: (topBarTheme) => dispatch(actionChangeTopBarTheme(topBarTheme)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
