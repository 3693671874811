import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
} from 'react';
import { Link, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from 'reactstrap';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';

import images from 'assets/images';
import {
  COMPANY,
  SITE_NAME,

  LOCATIONS,
  storageKey,
} from 'constants/index';
import { checkHadError, getPlaceholderTrans, validationErrorHandler } from 'utils';
import { accountValidation, userValidation } from 'utils/validate';
import propTypeModels from 'propTypeModels';

import LanguageDropdown from 'Layout/AuthLayout/HeaderDropdown/LanguageDropdown';
import AppButton from 'components/App/Button';
import Carousel from 'components/Carousel';
import Meta from 'components/App/Meta';

const Register = (props) => {
  const {
    me,

    actionRegister,
    registerError,
    registerLoading,
  } = props;

  const history = useHistory();
  const { t } = useTranslation();

  const [statePassWord, setStatePassWord] = useState({
    pass: true,
    confirmPass: true,
  });

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      firstName: '',
      lastName: '',
      middleName: '',
      email: '',
      password: '',
      confirmPassword: '',
      isPrivacyPolicy: false,
    },

    validationSchema: Yup.object({
      firstName: userValidation.firstName,

      middleName: userValidation.middleName,

      lastName: userValidation.lastName,

      email: accountValidation.email,

      password: accountValidation.password,

      confirmPassword: accountValidation.confirmPassword,

      isPrivacyPolicy: accountValidation.isPrivacyPolicy,
    }),

    onSubmit: (values) => {
      actionRegister({ values, history });
    },
  });

  const accessToken = localStorage.getItem(storageKey.AUTHENTICATE_TOKEN);

  const onToggleShowPassWord = useCallback((name) => {
    setStatePassWord((prevState) => ({
      ...prevState,
      [name]: !prevState[name],
    }));
  }, []);

  useEffect(() => {
    if (accessToken) {
      history.push(LOCATIONS.DASHBOARD);
    }
  }, [accessToken]);

  useEffect(() => {
    if (me._id) history.push(LOCATIONS.HOME_PAGE);
  }, [me._id]);

  useEffect(() => {
    validation.setErrors({
      ...registerError,
    });
  }, [registerError]);

  const onSubmit = useCallback((e) => {
    e.preventDefault();
    validation.handleSubmit();
  }, []);

  const onChangePasswordInput = useCallback((e, name) => {
    validation.setValues((prevState) => ({
      ...prevState,
      [name]: e.target.value.trim(),

    }));
  }, []);

  const validationErrors = useMemo(() => validation.errors, [validation.errors]);

  return (
    <>
      <Meta name="register.title" />

      <Container fluid className="p-0">
        <Row className="g-0">
          <Carousel />

          <Col xl={3}>
            <div className="auth-full-page-content p-md-5 p-4">
              <div className="w-100">
                <div className="d-flex flex-column h-100">
                  <div className="mb-4 mb-md-5">
                    <Link to="dashboard" className="d-block auth-logo">
                      <img
                        src={images.logoDark.default}
                        alt=""
                        height="18"
                        className="auth-logo-dark"
                      />
                      <img
                        src={images.logoLightPng.default}
                        alt=""
                        height="18"
                        className="auth-logo-light"
                      />
                    </Link>
                  </div>
                  <div className="my-auto">
                    <div>
                      <h5 className="text-primary">{t('register.title')}</h5>
                      <p className="text-muted">
                        {t('register.create_account')}
                      </p>
                    </div>

                    <div className="mt-4">

                      <Form
                        className="form-horizontal"
                        onSubmit={onSubmit}
                      >
                        <Row>
                          <Col sm="4" xl="12">
                            <div className="mb-3">
                              <Label className="form-label">
                                {t('auth.last_name')}
                              </Label>

                              <Input
                                id="lastName"
                                name="lastName"
                                className="form-control"
                                placeholder={getPlaceholderTrans({
                                  type: 'type.ENTER',
                                  name: 'auth.last_name',
                                })}
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.lastName || ''}
                                invalid={checkHadError(validation, 'lastName')}
                              />

                              {
                                checkHadError(validation, 'lastName') && (
                                  <FormFeedback type="invalid">
                                    {validationErrorHandler(validationErrors.lastName)}
                                  </FormFeedback>
                                )
                              }
                            </div>
                          </Col>

                          <Col sm="4" xl="12">
                            <div className="mb-3">
                              <Label className="form-label">
                                {t('auth.middle_name')}
                              </Label>

                              <Input
                                id="middleName"
                                name="middleName"
                                className="form-control"
                                placeholder={getPlaceholderTrans({
                                  type: 'type.ENTER',
                                  name: 'auth.middle_name',
                                })}
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.middleName || ''}
                                invalid={checkHadError(validation, 'middleName')}
                              />

                              {
                                checkHadError(validation, 'middleName') && (
                                  <FormFeedback type="invalid">
                                    {validationErrorHandler(validationErrors.middleName)}
                                  </FormFeedback>
                                )
                              }
                            </div>
                          </Col>

                          <Col sm="4" xl="12">
                            <div className="mb-3">
                              <Label className="form-label">
                                {t('auth.first_name')}
                                <code>*</code>
                              </Label>

                              <Input
                                id="firstName"
                                name="firstName"
                                className="form-control"
                                placeholder={getPlaceholderTrans({
                                  type: 'type.ENTER',
                                  name: 'auth.first_name',
                                })}
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.firstName || ''}
                                invalid={checkHadError(validation, 'firstName')}
                              />

                              {
                                checkHadError(validation, 'firstName') && (
                                  <FormFeedback type="invalid">
                                    {validationErrorHandler(validationErrors.firstName)}
                                  </FormFeedback>
                                )
                              }
                            </div>
                          </Col>
                        </Row>

                        <div className="mb-3">
                          <Label className="form-label">
                            {t('auth.email')}
                            <code>*</code>
                          </Label>

                          <Input
                            id="email"
                            name="email"
                            className="form-control"
                            placeholder={getPlaceholderTrans({
                              type: 'type.ENTER',
                              name: 'auth.email',
                            })}
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ''}
                            invalid={checkHadError(validation, 'email')}
                          />

                          {
                            checkHadError(validation, 'email') && (
                              <FormFeedback type="invalid">
                                {validationErrorHandler(validationErrors.email)}
                              </FormFeedback>
                            )
                          }
                        </div>

                        <Row>
                          <Col sm="6" xl="12">
                            <div className="mb-3 position-relative">
                              <Label className="form-label">
                                {t('auth.password')}
                                <code>*</code>
                              </Label>

                              <Input
                                className="password_type"
                                name="password"
                                type={statePassWord.pass ? 'password' : 'text'}
                                placeholder={getPlaceholderTrans({
                                  type: 'type.ENTER',
                                  name: 'auth.password',
                                })}
                                onChange={(value) => onChangePasswordInput(value, 'password')}
                                onBlur={validation.handleBlur}
                                value={validation.values.password || ''}
                                invalid={checkHadError(validation, 'password')}
                              />

                              <i
                                className={`font-size-18 show_password fas ${!statePassWord.pass ? 'fa-eye-slash' : 'fa-eye'}`}
                                onClick={() => onToggleShowPassWord('pass')}
                                aria-hidden="true"
                              />

                              {
                                checkHadError(validation, 'password') && (
                                  <FormFeedback type="invalid">
                                    {validationErrorHandler(validationErrors.password)}
                                  </FormFeedback>
                                )
                              }
                            </div>
                          </Col>

                          <Col sm="6" xl="12">
                            <div className="mb-3 position-relative">
                              <Label className="form-label">
                                {t('auth.re_password')}
                                <code>*</code>
                              </Label>

                              <Input
                                className="password_type"
                                name="confirmPassword"
                                type={statePassWord.confirmPass ? 'password' : 'text'}
                                placeholder={getPlaceholderTrans({
                                  type: '',
                                  name: 'auth.re_password',
                                })}
                                onChange={(value) => onChangePasswordInput(value, 'confirmPassword')}
                                onBlur={validation.handleBlur}
                                value={validation.values.confirmPassword || ''}
                                invalid={checkHadError(validation, 'confirmPassword')}
                              />

                              <i
                                className={`font-size-18 show_password fas ${!statePassWord.confirmPass ? 'fa-eye-slash' : 'fa-eye'}`}
                                onClick={() => onToggleShowPassWord('confirmPass')}
                                aria-hidden="true"
                              />

                              {
                                checkHadError(validation, 'confirmPassword') && (
                                  <FormFeedback type="invalid">
                                    {validationErrorHandler(validationErrors.confirmPassword)}
                                  </FormFeedback>
                                )
                              }
                            </div>
                          </Col>
                        </Row>

                        <div className="form-check mb-3">
                          <Label className="form-label">
                            <p className="mb-0">
                              {t('register.accept_terms')}
                              {' '}
                              <Link to="/dieu-khoan" className="text-primary" target="_blank" rel="noreferrer noopener">
                                {t('register.term_of_use')}
                              </Link>
                            </p>
                          </Label>

                          <Input
                            name="isPrivacyPolicy"
                            type="checkbox"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.isPrivacyPolicy || ''}
                            invalid={checkHadError(validation, 'isPrivacyPolicy')}
                          />

                          {
                            checkHadError(validation, 'isPrivacyPolicy') && (
                              <FormFeedback type="invalid">
                                {t(validation.errors.isPrivacyPolicy)}
                              </FormFeedback>
                            )
                          }
                        </div>

                        <div className="mt-4">
                          {registerLoading ? <AppButton loading={registerLoading} /> : (
                            <button
                              disabled={registerLoading}
                              className="btn btn-primary btn-block "
                              type="submit"
                            >
                              {t('register.title')}
                            </button>
                          )}
                        </div>

                      </Form>

                      <div className="mt-5 text-center d-flex justify-content-between align-items-center">
                        <p className="mb-0">
                          {t('register.do_have_account')}
                          {' '}
                          <Link to={LOCATIONS.LOGIN} className="font-weight-medium text-primary">
                            {' '}
                            {t('login.title')}
                          </Link>
                          {' '}
                        </p>
                        <LanguageDropdown />
                      </div>
                    </div>
                  </div>

                  <div className="mt-4 mt-md-5 text-center">
                    <p className="mb-0">
                      {`© ${new Date().getFullYear()} ${SITE_NAME}. ${t('login.developed')} `}

                      <i className="mdi mdi-heart text-danger" />
                      {` ${t('auth.by')} ${COMPANY}`}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

Register.propTypes = {
  me: propTypeModels.user.userPropTypes.isRequired,

  actionRegister: PropTypes.func.isRequired,
  registerError: PropTypes.instanceOf(Object).isRequired,
  registerLoading: PropTypes.bool.isRequired,
};

export default Register;
