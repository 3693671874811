import React, { useCallback, useMemo } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import SectionWrapper from 'components/AddLocation/SectionWrapper';

import { ReactComponent as AddImageIcon } from 'assets/images/location/add_photo.svg';
import { ReactComponent as FullViewIcon } from 'assets/images/location/control-camera.svg';

import styles from './StepFour.module.scss';

const imagesArraySchema = Yup.array()
  .of(Yup.mixed().test('fileSize', 'Hình ảnh không được quá 5Mb', (value) => value && value.file.size <= 52428800))
  .min(1, 'At least 1 file is required');

const validationSchema = Yup.object().shape({
  images: imagesArraySchema,
});

function StepFour() {
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      images: [],
    },

    validationSchema,
  });

  const onClickInputFile = useCallback((e) => {
    e.target.value = null;
  }, []);

  const onChangeImagePreviewList = useCallback((e) => {
    const files = Object.values(e.target.files).map((item) => {
      const itemLink = URL.createObjectURL(item);

      return {
        file: item,
        blob: itemLink,
        selected: false,
        clicked: false,
      };
    });

    formik.setValues((prevState) => ({
      ...prevState,
      images: prevState.images.concat(files),
    }));
  }, [formik]);

  const onMouseOver = useCallback((index) => () => {
    formik.setFieldValue(`images[${index}].clicked`, true || formik.values.images[index].selected);
  }, [formik]);

  const onMouseOut = useCallback((index) => () => {
    formik.setFieldValue(`images[${index}].clicked`, false || formik.values.images[index].selected);
  }, [formik]);

  const onSelectedImagePreview = useCallback((index) => () => {
    formik.setFieldValue(`images[${index}].selected`, !formik.values.images[index].selected);
  }, [formik]);

  const onCheckedAll = useCallback(() => {
    const newImages = formik.values.images.map((image) => (
      { ...image, selected: true, clicked: true }));
    formik.setFieldValue('images', newImages);
  }, [formik]);

  const onUnCheckedAll = useCallback(() => {
    const newImages = formik.values.images.map((image) => (
      { ...image, selected: false, clicked: false }));
    formik.setFieldValue('images', newImages);
  }, [formik]);

  const isCheckedAll = useMemo(() => {
    const filter = formik.values.images.filter((item) => item.selected);
    return filter.length === formik.values.images.length;
  }, [formik]);

  const isHasCheckedItem = useMemo(() => {
    const filter = formik.values.images.filter((item) => item.selected);
    return filter.length > 0;
  }, [formik]);

  const onDeleteItem = useCallback(() => {
    const newImageList = formik.values.images.filter((image) => image.selected === false);

    const deletedImage = formik.values.images.filter((image) => image.selected);

    formik.setFieldValue('images', newImageList);

    deletedImage.forEach((image) => {
      URL.revokeObjectURL(image.blob);
    });
  }, [formik]);

  return (
    <div className={styles.Component}>
      <SectionWrapper
        title="Hình ảnh chỗ nghỉ"
      >
        {formik.values.images.length > 0 && (
        <div className="ActionBar">
          <button
            onClick={onCheckedAll}
            className={`ActionBar__Button ${isCheckedAll ? 'ActionBar__Button--disabled' : ''}`}
            type="button"
          >
            Chọn tất cả

          </button>

          <button
            onClick={onUnCheckedAll}
            className={`ActionBar__Button ${!isHasCheckedItem ? 'ActionBar__Button--disabled' : ''}`}
            type="button"
          >
            Bỏ chọn tất cả
          </button>

          <button
            onClick={onDeleteItem}
            className={`ActionBar__Button ${!isHasCheckedItem ? 'ActionBar__Button--disabled' : ''}`}
            type="button"
          >
            Xoá
          </button>
        </div>
        )}

        <div
          className="InputWrapper"
        >
          {formik.values.images.length > 0
           && formik.values.images.map((item, index) => (
             <div key={crypto.randomUUID()} className="ItemWrapper">
               <img
                 src={item.blob}
                 width={100}
                 height={100}
                 alt="preview"
                 onMouseOver={onMouseOver(index)}
                 onFocus={() => {}}
                 tabIndex={-1}
                 onKeyDown={null}
                 role="presentation"
               />
               {item.clicked && (
               <>
                 <input
                   type="checkbox"
                   onChange={onSelectedImagePreview(index)}
                   checked={item.selected}
                 />

                 <div
                   className="ItemWrapper__Overlay"
                   onMouseLeave={onMouseOut(index)}
                   tabIndex={-1}
                   onKeyDown={null}
                   role="presentation"
                   onFocus={() => {}}
                   onClick={onSelectedImagePreview(index)}
                 >
                   <div className="ItemWrapper__Overlay--Icon"><FullViewIcon /></div>

                   <span className="ItemWrapper__Overlay--Text">Xem</span>
                 </div>
               </>
               )}
             </div>
           ))}

          <label
            htmlFor="images"
            className={`LabelInput ${formik.values.images.length > 0 ? 'LabelInput--HasPreviewImage' : ''}`}
          >
            <div><AddImageIcon /></div>

            <span className="LabelInput__Text">THÊM ẢNH</span>
          </label>

          <input
            type="file"
            multiple
            className="Hidden"
            accept="image/jpeg, image/png, image/gif"
            id="images"
            onChange={onChangeImagePreviewList}
            onClick={onClickInputFile}
          />
        </div>

        <div className="ContentWrapper">
          <span className="ContentWrapper__Title">Tải ít nhất 1 hình lên</span>

          <div className="ContentWrapper__TextWrapper">
            <span className="ContentWrapper__Content">
              Hình ảnh đẹp giống như lời mời gọi hấp dẫn về một
              trải nghiệm trọn vẹn tại chỗ nghỉ, hãy đăng tải nhữn
              g hình có độ phân giải cao và thể hiện chính xác những gì chỗ n
              ghỉ Quý vị có. Chúng tôi sẽ hiển thị những hình này trong trang
              chỗ nghỉ của Quý vị trên trang web Bookese.com.
            </span>

            <span className="ContentWrapper__Content">
              Quý vị có thể sử dụng: Điện thoại thông minh hoặc Máy ảnh kỹ thuật số để chụp ảnh
            </span>

            <span className="ContentWrapper__Content">
              Hình ảnh đẹp giống như lời mời gọi hấp dẫn về một
              trải nghiệm trọn vẹn tại chỗ nghỉ, hãy đăng tải nhữn
              g hình có độ phân giải cao và thể hiện chính xác những gì chỗ n
              ghỉ Quý vị có. Chúng tôi sẽ hiển thị những hình này trong trang
              chỗ nghỉ của Quý vị trên trang web Bookese.com.
            </span>
          </div>
        </div>
      </SectionWrapper>
    </div>
  );
}

export default StepFour;
