/* eslint-disable no-unused-vars */
import React, {
  useState, memo, useCallback, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Input,
  Collapse,
  Card,
  CardBody,
} from 'reactstrap';

import styles from './RadioConvenience.module.scss';

const VALUES_RADIO = {
  ALL: 'ALL',
  SOME: 'SOME',
  NOTHING: 'NOTHING',
};

const COLLAPSE_CONTENTS = [
  {
    label: 'Suite Có Sân Hiên',
    value: 1,
  },
  {
    label: 'Phòng Superior 2 Giường Đơn',
    value: 2,
  },
  {
    label: 'Phòng Superior Giường Đôi',
    value: 3,
  },
  {
    label: 'Phòng Deluxe Giường Đôi',
    value: 4,
  },
  {
    label: 'Suite Nhìn Ra Thành Phố',
    value: 5,
  },
];

function RadioConvenience(props) {
  const {
    title,
    name,
  } = props;

  const [collapse, setCollapse] = useState(false);
  const [valueChecked, setValueChecked] = useState(VALUES_RADIO.ALL);

  const onChangeValueChecked = useCallback((e) => {
    setValueChecked(e.target.value);
  }, [setValueChecked]);

  const onClickCollapse = useCallback(() => {
    setCollapse((prev) => !prev);
  }, [setCollapse]);

  useEffect(() => {
    if (valueChecked === VALUES_RADIO.SOME) {
      setCollapse(true);
    } else {
      setCollapse(false);
    }
  }, [valueChecked]);

  return (
    <div className={styles.Component}>
      <div className="Radio__Container">
        <Col xl="3" xxl="4">
          <div className="Radio__Title">
            {title}
          </div>
        </Col>

        <Col xl="8" xxl="7" className="Radio__Wrapper">
          <div className="Radio__Group">
            <Input
              name={name}
              type="radio"
              className="Radio"
              value={VALUES_RADIO.ALL}
              checked={valueChecked === VALUES_RADIO.ALL}
              onChange={onChangeValueChecked}
            />

            <div className="Radio__Name">
              Tất cả phòng
            </div>
          </div>

          <div className="Radio__Group">
            <Input
              name={name}
              type="radio"
              className="Radio"
              value={VALUES_RADIO.SOME}
              onChange={onChangeValueChecked}
            />

            <div className="Radio__Name">
              Một số phòng
            </div>
          </div>

          <div className="Radio__Group">
            <Input
              name={name}
              type="radio"
              className="Radio"
              value={VALUES_RADIO.NOTHING}
              onChange={onChangeValueChecked}
            />

            <div className="Radio__Name">
              Không có căn nào
            </div>
          </div>
        </Col>

        <Col md="1" className="Chevron__Wrapper">
          <i
            className={`fas fa-angle-${collapse ? 'up' : 'down'} Chevron`}
            onClick={valueChecked === VALUES_RADIO.SOME ? onClickCollapse : null}
            role="presentation"
          />
        </Col>
      </div>

      <Collapse
        isOpen={collapse}
      >
        <Card className="Collapse__Container">
          <CardBody className="Collapse__Content">
            <Row>
              <Col xl="3" xxl="4" />

              <Col xl="9" xxl="8">
                {COLLAPSE_CONTENTS.map((content) => (
                  <div className="Checkbox__Container" key={content.label}>
                    <Input
                      type="checkbox"
                      className="Checkbox"
                      value={content.value}
                    />

                    <div className="Checkbox__Name">{content.label}</div>
                  </div>
                ))}
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Collapse>
    </div>
  );
}

RadioConvenience.propTypes = {
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

RadioConvenience.defaultProps = {

};

export default memo(RadioConvenience);
