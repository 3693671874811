import React, { memo } from 'react';

import { ReactComponent as NoItem } from 'assets/images/NoReview.svg';

import styles from './ReviewList.module.scss';

const FAKE_DATA = [
  { _id: 1 },
  { _id: 2 },
  { _id: 3 },
  { _id: 4 },
  { _id: 5 },
];

function ReviewList() {
  return (
    <div className={styles.ReviewList}>
      <div className="ReviewList">
        <div className="ReviewList__Title"> Các đánh giá gần đây</div>

        <div className="ReviewList__Content">
          {FAKE_DATA.length > 0 ? FAKE_DATA.map((item) => (
            <div key={item._id} className="ReviewListContentItem">
              <div className="ReviewListContentItem__Point">
                <span className="ReviewListContentItem__Point--Number">9.5</span>

                <span className="ReviewListContentItem__Point--Text">Tên account</span>
              </div>

              <span className="ReviewListContentItem__Text">Đánh giá này được gửi mà không có lời bình</span>

              <span className="ReviewListContentItem__Text ReviewListContentItem__Text--Right">20/11/2022</span>
            </div>
          )) : (
            <div className="ReviewListContentNoItem">
              <div className="ReviewListContentNoItem__Icon">
                <NoItem />
              </div>

              <span className="ReviewListContentNoItem__Text">
                Quý vị hiện chưa có đánh giá
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default memo(ReviewList);
