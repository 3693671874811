import React, { memo, useCallback, useState } from 'react';

import { ReactComponent as NoItem } from 'assets/images/NoItem.svg';

import styles from './Overview.module.scss';

const FAKE_DATA = {
  tab1: [
    {
      _id: '1',
      name: 'Hồng Duyên',
      time: ' 1 đêm',
      quantity: 1,
      room: 'Phòng Suite - Giuờng đôi',
      date: {
        date: '11/12/2022 - 12/12/2022',
        timeStart: '12:00 - 13:00',
      },
      payment: {
        price: 4900000,
        timePayment: 'Thanh toán: 3/11/2022',
      },
      code: 1234567890,
    },
    {
      _id: '2',
      name: 'Hồng Duyên',
      time: ' 1 đêm',
      quantity: 1,
      room: 'Phòng Suite - Giuờng đôi',
      date: {
        date: '11/12/2022 - 12/12/2022',
        timeStart: '12:00 - 13:00',
      },
      payment: {
        price: 4900000,
        timePayment: 'Thanh toán: 3/11/2022',
      },
      code: 1234567890,
    },
    {
      _id: '3',
      name: 'Hồng Duyên',
      time: ' 1 đêm',
      quantity: 1,
      room: 'Phòng Suite - Giuờng đôi',
      date: {
        date: '11/12/2022 - 12/12/2022',
        timeStart: '12:00 - 13:00',
      },
      payment: {
        price: 4900000,
        timePayment: 'Thanh toán: 3/11/2022',
      },
      code: 1234567890,
    },
  ],

  tab2: [],

  tab3: [],
};

function Overview() {
  const [tabActive, setTabActive] = useState(1);

  const onClickTab = useCallback((id) => () => {
    setTabActive(id);
  });

  return (
    <div className={styles.Overview}>
      <div className="Overview">
        <span className="Overview__Title">
          Tổng quan đặt phòng hôm nay
        </span>

        <div
          className="Overview__Tabbar"
        >
          <span
            className={`Overview__Tabbar__Item ${tabActive === 1 ? 'Overview__Tabbar__Item--Active' : ''}`}
            onClick={onClickTab(1)}
            tabIndex={-1}
            role="button"
            onKeyPress={null}
          >
            {`Khách đến (${FAKE_DATA.tab1.length})`}
          </span>

          <span
            className={`Overview__Tabbar__Item ${tabActive === 2 ? 'Overview__Tabbar__Item--Active' : ''}`}
            onClick={onClickTab(2)}
            tabIndex={-1}
            role="button"
            onKeyPress={null}
          >
            Khách đi
            <span className={`${FAKE_DATA.tab2.length > 0 ? 'Overview__Tabbar__Item--Number' : ''}`}>{` (${FAKE_DATA.tab2.length})`}</span>
          </span>

          <span
            className={`Overview__Tabbar__Item ${tabActive === 3 ? 'Overview__Tabbar__Item--Active' : ''}`}
            onClick={onClickTab(3)}
            tabIndex={-1}
            role="button"
            onKeyPress={null}
          >
            Đặt phòng mới
            <span className="Overview__Tabbar__Item--Number">{' (10)'}</span>
          </span>
        </div>

        {FAKE_DATA[`tab${tabActive}`].length > 0 ? FAKE_DATA[`tab${tabActive}`].map((item, index) => (
          <div className="Overview__Item" key={item._id}>
            <div className="Overview__Item__Wrapper Overview__Item__Number">
              <span className="Overview__Item__Wrapper--Title">{`0${index + 1}`}</span>
            </div>

            <div className="Overview__Item__Wrapper">
              <span className="Overview__Item__Wrapper--Title">Hồng Duyên</span>

              <span className="Overview__Item__Wrapper--Detail"> 1 đêm - 1 khách</span>
            </div>

            <div className="Overview__Item__Wrapper">
              <span className="Overview__Item__Wrapper--Title">Phòng Suite - Giường đôi</span>
            </div>

            <div className="Overview__Item__Wrapper">
              <span className="Overview__Item__Wrapper--Title">11/12/2022 - 12/12/2022</span>

              <span className="Overview__Item__Wrapper--Detail">Thời điểm khách đến: </span>

              <span className="Overview__Item__Wrapper--Detail">12:00 - 13:00</span>
            </div>

            <div className="Overview__Item__Wrapper">
              <span className="Overview__Item__Wrapper--Title Overview__Item__Wrapper--Right">4.900.000 VNĐ</span>

              <span className="Overview__Item__Wrapper--Detail Overview__Item__Wrapper--Right">Thanh toán: 3/11/2022 </span>
            </div>

            <div className="Overview__Item__Wrapper">
              <span className="Overview__Item__Wrapper--Title Overview__Item__Wrapper--Id">1234567890</span>
            </div>
          </div>
        )) : (
          <div className="Overview__NoItem">
            <div className="Overview__NoItem__Icon">
              <NoItem />
            </div>

            <span className="Overview__NoItem__Text">
              Quý vị không có khách đến trong hôm nay
            </span>

          </div>
        )}
      </div>
    </div>
  );
}

export default memo(Overview);
