import * as Yup from 'yup';

import { VOUCHER_DISCOUNT_TYPES, VOUCHER_MAXIMUM_DISCOUNT_TYPE, VOUCHER_STATUSES } from 'constants/index';
import {
  ACCENTED_REGEX,
  IS_SPECIAL_ACCEPT,
  LETTER_WITH_SPACE_REGEX,
  NUMBER_WITH_PERIOD,
  PHONE_REGEX,
  WORDS_AND_NUMBERS_REGEX,
  EMAIL_REGEX,
  NUMBER_ONLY_REGEX,
  PASSWORD_IS_HAVE_SPECIAL,

  textLengthLimit,
} from 'constants/validationHelper';
import { invalidNumberArea, parseCurrency } from 'utils';

const getRequiredText = (field) => `${field} không được để trống`;
const getMinText = (field, min) => `${field} không được ít hơn ${min} ký tự`;
const getMaxText = (field, max) => `${field} không được vượt quá ${max} ký tự`;
const getTimeLaterThanNowText = (field) => `Vui lòng chọn thời gian ${field} muộn hơn thời gian hiện tại`;
const getInvalidText = (field) => `${field} không hợp lệ`;
const getInvalidLengthPassword = (minLength, maxLength) => `Mật khẩu phải dài từ ${minLength} đến ${maxLength} ký tự, bao gồm chữ và số`;

export const userValidation = {
  firstName: Yup.string().required(({
    type: 'is_required',
    name: 'profile.first_name',
  }))
    .trim()
    .matches(LETTER_WITH_SPACE_REGEX, ({
      message: {
        type: 'letter_only',
        name: 'profile.first_name',
      },
      excludeEmptyString: true,
    }))
    .max(textLengthLimit.VERY_SHORT, ({
      type: 'max_length',
      name: 'profile.first_name',
      number: textLengthLimit.VERY_SHORT,
    })),

  lastName: Yup.string()
    .trim()
    .matches(LETTER_WITH_SPACE_REGEX, ({
      message: {
        type: 'letter_only',
        name: 'profile.last_name',
      },
      excludeEmptyString: true,
    }))
    .max(textLengthLimit.VERY_SHORT, ({
      type: 'max_length',
      name: 'profile.last_name',
      number: textLengthLimit.VERY_SHORT,
    })),

  middleName: Yup.string()
    .trim()
    .matches(LETTER_WITH_SPACE_REGEX, ({
      message: {
        type: 'letter_only',
        name: 'auth.middle_name',
      },
      excludeEmptyString: true,
    }))
    .max(textLengthLimit.VERY_SHORT, ({
      type: 'max_length',
      name: 'auth.middle_name',
      number: textLengthLimit.VERY_SHORT,
    })),

  cardId: Yup.string()
    .max(textLengthLimit.VERY_SHORT, ({
      type: 'max_length',
      name: 'profile.id_number_passport',
      number: textLengthLimit.VERY_SHORT,
    }))
    .matches(ACCENTED_REGEX, {
      message: {
        type: 'wrong_format',
        name: 'profile.id_number_passport',
      },
      excludeEmptyString: true,
    }),

  address: Yup.string()
    .trim()
    .max(textLengthLimit.SHORT, ({
      type: 'max_length',
      name: 'profile.address',
      number: textLengthLimit.SHORT,
    })),

  phoneNumber: Yup.string()
    .trim()
    .matches(PHONE_REGEX, ({
      message: {
        type: 'special_characters',
        name: 'profile.phone_number',
        number: textLengthLimit.SHORT,
      },
      excludeEmptyString: true,
    }))
    .max(textLengthLimit.PHONE_NUMBER, ({
      type: 'max_length',
      name: 'profile.phone_number',
      number: textLengthLimit.PHONE_NUMBER,
    })),

  businessEmail: Yup.string()
    .trim()
    .max(textLengthLimit.SHORT, ({
      type: 'max_length',
      name: 'profile.business_email',
      number: textLengthLimit.SHORT,
    }))
    .email(({
      type: 'wrong_format',
      name: 'profile.email',
    })),

  email: Yup.string()
    .trim()
    .max(textLengthLimit.SHORT, ({
      type: 'max_length',
      name: 'profile.email',
      number: textLengthLimit.SHORT,
    }))
    .email(({
      type: 'wrong_format',
      name: 'profile.email',
    })),
};

export const accountValidation = {
  email: Yup.string()
    .required({
      type: 'is_required',
      name: 'Email',
    })
    .email({
      type: 'wrong_format',
      name: 'Email',
    })
    .max(textLengthLimit.SHORT, {
      type: 'max_length',
      name: 'Email',
      number: textLengthLimit.SHORT,
    }),

  phoneNumber: Yup.string()
    .required(({
      type: 'is_required',
      name: 'auth.username',
    }))
    .trim()
    .max(textLengthLimit.SHORT, {
      type: 'max_length',
      name: 'auth.username',
      number: textLengthLimit.SHORT,
    }),

  password: Yup.string()
    .required(({
      type: 'is_required',
      name: 'auth.password',
    }))
    .trim()
    .min(textLengthLimit.PASSWORD, {
      type: 'min_length',
      name: 'auth.password',
      number: textLengthLimit.PASSWORD,
    })
    .max(textLengthLimit.SHORT, ({
      type: 'max_length',
      name: 'auth.password',
      number: textLengthLimit.SHORT,
    }))
    .matches(IS_SPECIAL_ACCEPT, {
      message: {
        type: 'no_space',
        name: 'auth.password',
      },
      excludeEmptyString: true,
    }),

  confirmPassword: Yup.string().required(({
    type: 'is_required',
    name: 'auth.password',
  }))
    .oneOf([Yup.ref('password'), null], ({
      type: 'confirm_password',
    }))
    .max(textLengthLimit.SHORT, ({
      type: 'max_length',
      name: 'auth.password',
      number: textLengthLimit.SHORT,
    })),

  isPrivacyPolicy: Yup.bool().required(('register.msg_accept_terms'))
    .oneOf([true], ('register.msg_accept_terms')),
};

export const passwordValidation = {
  password: Yup.string().required(({
    type: 'is_required',
    name: 'auth.current_password',
  }))
    .trim()
    .min(textLengthLimit.PASSWORD, ({
      type: 'min_length',
      name: 'auth.current_password',
      number: textLengthLimit.PASSWORD,
    }))
    .max(textLengthLimit.SHORT, ({
      type: 'max_length',
      name: 'current_password',
      number: textLengthLimit.SHORT,
    })),

  newPassword: Yup.string().required(({
    type: 'is_required',
    name: 'auth.new_password',
  }))
    .trim()
    .min(textLengthLimit.PASSWORD, ({
      type: 'min_length',
      name: 'auth.new_password',
      number: textLengthLimit.PASSWORD,
    }))
    .max(textLengthLimit.SHORT, ({
      type: 'max_length',
      name: 'auth.new_password',
      number: textLengthLimit.SHORT,
    }))
    .matches(IS_SPECIAL_ACCEPT, {
      message: {
        type: 'no_space',
        name: 'validation.profile.new_password',
      },
      excludeEmptyString: true,
    }),

  confirmPassword: Yup.string().required(({
    type: 'is_required',
    name: 'auth.re_enter_new_password',
  }))
    .trim()
    .oneOf([Yup.ref('newPassword'), null], ({
      type: 'wrong_password',
      name: 'auth.re_enter_new_password',
    }))
    .min(textLengthLimit.PASSWORD, ({
      type: 'min_length',
      name: 'auth.re_enter_new_password',
      number: textLengthLimit.PASSWORD,
    }))
    .max(textLengthLimit.SHORT, ({
      type: 'max_length',
      name: 'auth.re_enter_new_password',
      number: textLengthLimit.SHORT,
    }))
    .matches(IS_SPECIAL_ACCEPT, {
      message: {
        type: 'no_space',
        name: 'validation.profile.re_enter_new_password',
      },
      excludeEmptyString: true,
    }),
};

export const mediaValidation = {
  name: Yup.string()
    .trim()
    .max(textLengthLimit.SHORT, ({
      type: 'max_length',
      name: 'name',
      number: textLengthLimit.SHORT,
    })),
};

export const productEditValidation = {
  name: Yup.string()
    .required('Tên sản phẩm không được để trống')
    .min(10, 'Tên sản phẩm hợp lệ từ 10-120 ký tự')
    .max(120, 'Tên sản phẩm hợp lệ từ 10-120 ký tự')
    .trim(),

  price: Yup.string()
    .matches(NUMBER_WITH_PERIOD, {
      message: 'Vui lòng nhập vào số nguyên từ 1.000 đến 120.000.000',
      excludeEmptyString: true,
    })
    .test({
      message: 'Giá không được để trống',
      test(value) {
        const { variants } = this.parent;
        if (!value && !variants?.length) return false;

        return true;
      },
    })
    .test({
      message: 'Vui lòng nhập vào số nguyên từ 1.000 đến 120.000.000',
      test(value) {
        const { variants } = this.parent;
        const number = parseCurrency(value);

        if (!variants?.length && invalidNumberArea(number, 1000, 120000000)) return false;
        return true;
      },
    }),

  quantity: Yup.string()
    .matches(NUMBER_WITH_PERIOD, {
      message: 'Vui lòng nhập vào số nguyên từ 0 đến 10.000.000',
      excludeEmptyString: true,
    })
    .test({
      message: 'Kho hàng không được để trống',
      test(value) {
        const { variants } = this.parent;
        if (!value && !variants?.length) return false;

        return true;
      },
    })
    .test({
      message: 'Vui lòng nhập vào số nguyên từ 0 đến 10.000.000',
      test(value) {
        const number = parseCurrency(value);

        if (value && invalidNumberArea(number, 0, 10000000)) return false;
        return true;
      },
    }),

  isVariantImageErr: Yup.bool()
    .test({
      message: 'Bạn phải thêm tất cả các hình ảnh phân loại hàng hoặc để trống tất cả chúng',
      test() {
        const { variants } = this.from[0].value;
        const variantWithoutImage = variants[0]?.values.filter(
          (item) => item.variantName && !item.image,
        ).length;
        const validVariant = variants[0]?.values.filter((item) => item.variantName).length;

        if (variantWithoutImage === 0
        || variantWithoutImage === validVariant) return true;

        return false;
      },
    }),

  isShippingUnitErr: Yup.bool()
    .test({
      message: 'Sản phẩm không thể lưu vì chưa có kênh vận chuyển nào được bật',
      test() {
        const { logisticsChannels } = this.from[0].value;

        const selectedChannel = logisticsChannels.find((item) => !item.disabled);

        if (selectedChannel) return true;

        return false;
      },
    }),

  isDuplicateGroupVariantName: Yup.bool()
    .test({
      message: 'Tên phân loại hàng phải khác nhau',
      test() {
        const { variants } = this.from[0].value;

        if (variants?.length > 1
          && variants[1].name
          && (variants[0].name.toLowerCase() === variants[1].name.toLowerCase())) return false;

        return true;
      },
    }),

  variants: Yup.array()
    .of(Yup.object()
      .shape({
        name: Yup.string()
          .required('Tên nhóm phân loại không được để trống')
          .max(14, 'Tên nhóm phân loại không được vượt quá 14 ký tự')
          .trim(),
        values: Yup.array()
          .of(
            Yup.object().shape({
              variantName: Yup.string()
                .required('Phân loại hàng không được để trống')
                .max(20, 'Phân loại hàng không được quá 20 ký tự')
                .trim(),
            }),
          )
          .max(50, 'Phân loại hàng không được vượt quá 50'),
      }))
    .test({
      message: 'Số lượng nhóm phân loại không được vượt quá 2',
      test(value = []) {
        if (value.length > 2) return false;
        return true;
      },
    }),

  productVariants: Yup.array()
    .of(Yup.object().shape({
      sku: Yup.string()
        .max(100, 'SKU sản phẩm không vượt quá 100 ký tự')
        .trim(),

      name: Yup.string()
        .trim(),

      quantity: Yup.string()
        .matches(NUMBER_WITH_PERIOD, {
          message: 'Vui lòng nhập vào số nguyên từ 0 đến 10.000.000',
          excludeEmptyString: true,
        })
        .test({
          message: 'Kho hàng không được để trống',
          test(value) {
            if (!value) return false;

            return true;
          },
        })
        .test({
          message: 'Vui lòng nhập vào số nguyên từ 0 đến 10.000.000',
          test(value) {
            const number = parseCurrency(value);

            if (value && invalidNumberArea(number, 0, 10000000)) return false;
            return true;
          },
        }),

      price: Yup.string()
        .matches(NUMBER_WITH_PERIOD, {
          message: 'Vui lòng nhập vào số nguyên từ 1.000 đến 120.000.000',
          excludeEmptyString: true,
        })
        .test({
          message: 'Giá không được để trống',
          test(value) {
            if (!value) return false;

            return true;
          },
        })
        .test({
          message: 'Vui lòng nhập vào số nguyên từ 1.000 đến 120.000.000',
          test(value) {
            const number = parseCurrency(value);

            if (value && invalidNumberArea(number, 1000, 120000000)) return false;
            return true;
          },
        }),

      tier: Yup.array().of(Yup.string()),
    })),

  description: Yup.string()
    .required('Mô tả không được để trống')
    .min(100, 'Mô tả sản phẩm hợp lệ từ 100-3000 ký tự')
    .max(3000, 'Mô tả sản phẩm hợp lệ từ 100-3000 ký tự')
    .trim(),

  imagePreview: Yup.object()
    .required('Ảnh bìa không được để trống'),

  brand: Yup.string()
    .required('Thương hiệu không được để trống'),

  variantGroupName: Yup.string()
    .required('Tên nhóm phân loại không được để trống')
    .max(14, 'Tên nhóm phân loại không được vượt quá 14 ký tự'),

  variantName: Yup.string()
    .required('Phân loại hàng không được để trống')
    .max(20, 'Phân loại hàng không được vượt quá 20 ký tự'),

  sku: Yup.string()
    .max(100, 'SKU phân loại không vượt quá 100 ký tự'),

  weight: Yup.string()
    .required('Cân nặng không được để trống')
    .matches(NUMBER_WITH_PERIOD, {
      message: 'Vui lòng nhập vào số nguyên từ 1 đến 1.000.000 ',
      excludeEmptyString: true,
    })
    .test({
      message: 'Vui lòng nhập vào số nguyên từ 1 đến 1.000.000 ',
      test(value) {
        const number = parseCurrency(value);

        if (value && invalidNumberArea(number, 1, 1000000)) return false;
        return true;
      },
    }),

  length: Yup.string()
    .required('Chiều dài không được để trống')
    .matches(NUMBER_WITH_PERIOD, {
      message: 'Vui lòng nhập vào số nguyên từ 1 đến 1.000.000 ',
      excludeEmptyString: true,
    })
    .test({
      message: 'Vui lòng nhập vào số nguyên từ 1 đến 1.000.000 ',
      test(value) {
        const number = parseCurrency(value);

        if (value && invalidNumberArea(number, 1, 1000000)) return false;
        return true;
      },
    }),

  width: Yup.string()
    .required('Chiều rộng không được để trống')
    .matches(NUMBER_WITH_PERIOD, {
      message: 'Vui lòng nhập vào số nguyên từ 1 đến 1.000.000 ',
      excludeEmptyString: true,
    })
    .test({
      message: 'Vui lòng nhập vào số nguyên từ 1 đến 1.000.000 ',
      test(value) {
        const number = parseCurrency(value);

        if (value && invalidNumberArea(number, 1, 1000000)) return false;
        return true;
      },
    }),

  height: Yup.string()
    .required('Chiều cao không được để trống')
    .matches(NUMBER_WITH_PERIOD, {
      message: 'Vui lòng nhập vào số nguyên từ 1 đến 1.000.000 ',
      excludeEmptyString: true,
    })
    .test({
      message: 'Vui lòng nhập vào số nguyên từ 1 đến 1.000.000 ',
      test(value) {
        const number = parseCurrency(value);

        if (value && invalidNumberArea(number, 1, 1000000)) return false;
        return true;
      },
    }),

  priceAll: Yup.string()
    .matches(NUMBER_WITH_PERIOD, {
      message: 'Vui lòng nhập vào số nguyên từ 1.000 đến 120.000.000',
      excludeEmptyString: true,
    })
    .test({
      message: 'Vui lòng nhập vào số nguyên từ 1.000 đến 120.000.000',
      test(value) {
        const number = parseCurrency(value);

        if (value && invalidNumberArea(number, 1000, 120000000)) return false;
        return true;
      },
    }),

  quantityAll: Yup.string()
    .matches(NUMBER_WITH_PERIOD, {
      message: 'Vui lòng nhập vào số nguyên từ 0 đến 10.000.000',
      excludeEmptyString: true,
    })
    .test({
      message: 'Vui lòng nhập vào số nguyên từ 0 đến 10.000.000',
      test(value) {
        const number = parseCurrency(value);

        if (value && invalidNumberArea(number, 0, 10000000)) return false;
        return true;
      },
    }),
};

export const voucherCreateValidation = {
  name: Yup.string().trim()
    .required(getRequiredText('Tên'))
    .max(100, getMaxText('Tên', 100)),
  code: Yup.string().trim()
    .required(getRequiredText('Mã Voucher'))
    .min(5, 'Mã Voucher hợp lệ từ 5-9 ký tự, chỉ bao gồm chữ cái (A-Z), số (0-9)')
    .max(9, 'Mã Voucher hợp lệ từ 5-9 ký tự, chỉ bao gồm chữ cái (A-Z), số (0-9)')
    .matches(WORDS_AND_NUMBERS_REGEX, 'Mã Voucher hợp lệ từ 5-9 ký tự, chỉ bao gồm chữ cái (A-Z), số (0-9)'),
  startAt: Yup.date()
    .test(
      'startAt',
      getRequiredText('Thời gian bắt đầu'),
      (value, context) => {
        const { isEdit, voucherStatusCode } = context.parent;
        const date = new Date();

        if (!value?.getTime?.()) {
          return false;
        }

        if (date.getTime() > value.getTime()
        && (!isEdit || (isEdit && voucherStatusCode === VOUCHER_STATUSES.UPCOMING.code))) {
          return context.createError({ message: getTimeLaterThanNowText('bắt đầu') });
        }

        return value && value?.getTime?.();
      },
    ),
  expiredAt: Yup.date()
    .test(
      'end_after_start_1_hour',
      'Chương trình phải kéo dài ít nhất là 1h kể từ khi bắt đầu',
      (value, context) => {
        const { startAt, voucherStatusCode, isEdit } = context.parent;
        const date = new Date();

        if (!value || !value?.getTime?.()) {
          return context.createError({ message: getRequiredText('Thời gian kết thúc') });
        }

        if (!startAt?.getTime?.()) {
          return false;
        }

        if (date.getTime() > value.getTime()
        && (!isEdit || (isEdit && voucherStatusCode >= VOUCHER_STATUSES.UPCOMING.code))) {
          return context.createError({ message: getTimeLaterThanNowText('kết thúc') });
        }

        return value.getTime() - startAt.getTime() >= 3600000;
      },
    ),
  // discountType: Yup.string().required(getRequiredText('Mức giảm')),
  discount: Yup.string()
    .required(getRequiredText('Mức giảm'))
    .test(
      'reduction_number',
      'Vui lòng nhập vào giá trị giữa 1 và 99 ',
      (value, context) => {
        const { discountType } = context.parent;
        const discount = parseCurrency(value);

        if (discountType === VOUCHER_DISCOUNT_TYPES.PERCENT) {
          return (discount >= 1 && discount <= 99);
        }

        if (discount < 1000 || discount > 120000000) {
          return context.createError({ message: 'Vui lòng nhập vào số nguyên từ 1.000 đến 120.000.000' });
        }

        return true;
      },
    ),
  maximumDiscount: Yup.string()
    .test(
      'maximum_reduction_number',
      getRequiredText('Mức giảm tối đa'),
      (value, context) => {
        const { discountType, maximumDiscountType } = context.parent;
        const maximumDiscount = parseCurrency(value);

        if (discountType === VOUCHER_DISCOUNT_TYPES.PERCENT
          && maximumDiscountType === VOUCHER_MAXIMUM_DISCOUNT_TYPE.LIMITED) {
          if (!maximumDiscount) {
            return false;
          }

          if (maximumDiscount < 1000 || maximumDiscount > 120000000) {
            return context.createError({ message: 'Vui lòng nhập vào số nguyên từ 1.000 đến 120.000.000' });
          }

          return !!maximumDiscount;
        }

        return true;
      },
    ),
  orderMinimalAmount: Yup.string()
    .required(getRequiredText('Giá trị đơn hàng tối thiểu'))
    .test(
      'minimum_order_value',
      'Giá trị voucher không thể vượt quá giá trị tối thiểu của đơn hàng',
      (value, context) => {
        const { discountType } = context.parent;
        const discount = parseCurrency(context.parent.discount);
        const orderMinimalAmount = parseCurrency(value);

        if (orderMinimalAmount < 0 || orderMinimalAmount > 120000000) {
          return context.createError({ message: 'Vui lòng nhập vào số nguyên từ 0 đến 120.000.000' });
        }

        if (discountType === VOUCHER_DISCOUNT_TYPES.PRICE) {
          return orderMinimalAmount >= discount;
        }

        return true;
      },
    ),
  quantity: Yup.string()
    .required(getRequiredText('Tổng số lượng mã giảm giá'))
    .test(
      'number_of_vouchers',
      'Vui lòng nhập vào số nguyên từ 1 đến 200.000',
      (value) => {
        const quantity = parseCurrency(value);

        return quantity >= 1 && quantity <= 200000;
      },
    ),
  cost: Yup.string()
    .required(getRequiredText('Giá mua (V-Token)'))
    .test(
      'cost_format',
      'Ký tự đầu và ký tự cuối phải là ký tự số',
      (value) => (value?.[value?.length - 1] !== ',' && value?.[0] !== ','),
    ),
  condition: Yup.string().required(getRequiredText('Điều kiện sử dụng')),
};

export const widthDrawValidation = {
  price: Yup.string().trim()
    .required(getRequiredText('Giá trị yêu cầu thanh toán'))
    .test({
      message: 'Vui lòng nhập vào số nguyên lớn hơn 0',
      test(value) {
        const number = parseCurrency(value);

        return number > 0;
      },
    })
    .test({
      message: 'Giá trị giao dịch tối thiểu là 100.000 đ',
      test(value) {
        const number = parseCurrency(value);

        return number >= 100000;
      },
    })
    .test({
      message: 'Giá trị giao dịch tối đa là 300.000.000 đ',
      test(value) {
        const number = parseCurrency(value);

        return number <= 300000000;
      },
    }),
};

export const loginAdminValidation = {
  email: Yup.string()
    .required(getRequiredText('Email'))
    .max(textLengthLimit.SHORT, getMaxText('Email', textLengthLimit.SHORT))
    .matches(EMAIL_REGEX, getInvalidText('Email')),

  password: Yup.string()
    .trim()
    .required(getRequiredText('Mật khẩu'))
    .min(
      textLengthLimit.PASSWORD,
      getInvalidLengthPassword(textLengthLimit.PASSWORD, textLengthLimit.PASSWORD_MAX),
    )
    .max(
      textLengthLimit.PASSWORD_MAX,
      getInvalidLengthPassword(textLengthLimit.PASSWORD, textLengthLimit.PASSWORD_MAX),
    )
    .matches(PASSWORD_IS_HAVE_SPECIAL, getInvalidText('Mật khẩu')),
};

export const loginAdminStep2Validation = {
  code: Yup.string()
    .trim()
    .required(getRequiredText('Mã OTP'))
    .min(textLengthLimit.CODE, getMinText('Mã OTP', textLengthLimit.CODE))
    .max(textLengthLimit.CODE, getMaxText('Mã OTP', textLengthLimit.CODE))
    .matches(NUMBER_ONLY_REGEX, getInvalidText('Mã OTP')),
};

export const stepOneValidation = {
  type: Yup.string().required('Vui lòng nhập thông tin này!'),
  nameLocation: Yup.string().required('Vui lòng nhập thông tin này!'),
  star: Yup.string().required('Vui lòng nhập thông tin này!'),
  userName: Yup.string().required('Vui lòng nhập thông tin này!'),
  phoneNumber: Yup.string()
    .required('Vui lòng nhập thông tin này!')
    .trim()
    .matches(PHONE_REGEX, 'Số điện thoại không hợp lệ'),
  otherPhoneNumber: Yup.string()
    .trim()
    .matches(PHONE_REGEX, 'Số điện thoại không hợp lệ'),
  city: Yup.string().required('Vui lòng nhập thông tin này!'),
  district: Yup.string().required('Vui lòng nhập thông tin này!'),
  village: Yup.string().required('Vui lòng nhập thông tin này!'),
  arena: Yup.string(),
  locate: Yup.string().required('Vui lòng nhập thông tin này!'),
};

export const stepThreeValidation = {
  language: Yup.array(),
};

export const editInformationLocateValidation = {
  name: Yup.string(),
  type: Yup.string(),
  rankStar: Yup.string(),
  city: Yup.string(),
  district: Yup.string(),
  ward: Yup.string(),
  address: Yup.string(),
  gps: Yup.string(),
  description: Yup.string(),
};

export const addAlreadyLocationStepOneValidation = {
  email: Yup.string()
    .required(getRequiredText('Email'))
    .max(textLengthLimit.SHORT, getMaxText('Email', textLengthLimit.SHORT))
    .matches(EMAIL_REGEX, getInvalidText('Email')),

  password: Yup.string()
    .trim()
    .required(getRequiredText('Mật khẩu'))
    .min(
      textLengthLimit.PASSWORD,
      getInvalidLengthPassword(textLengthLimit.PASSWORD, textLengthLimit.PASSWORD_MAX),
    )
    .max(
      textLengthLimit.PASSWORD_MAX,
      getInvalidLengthPassword(textLengthLimit.PASSWORD, textLengthLimit.PASSWORD_MAX),
    )
    .matches(PASSWORD_IS_HAVE_SPECIAL, getInvalidText('Mật khẩu')),
};

export const addAlreadyLocationStepTwoValidation = {
  idLocation: Yup.string()
    .required(getRequiredText('Id chỗ nghỉ')),
};

export const addAlreadyLocationStepThreeValidation = {
  otpCode: Yup.string()
    .trim()
    .required(getRequiredText('Mã OTP'))
    .min(textLengthLimit.CODE, getMinText('Mã OTP', textLengthLimit.CODE))
    .max(textLengthLimit.CODE, getMaxText('Mã OTP', textLengthLimit.CODE))
    .matches(NUMBER_ONLY_REGEX, getInvalidText('Mã OTP')),
};

export const createNewRoomValidation = {
  roomNameRequired: Yup.string()
    .required(getRequiredText('Tên phòng')),
  roomNameOptional: Yup.string(),
  numberOfRoom: Yup.string()
    .required(getRequiredText('Số phòng')),
  numberOfBedroom: Yup.string(),
  numberOfLivingroom: Yup.string(),
  numberOfBathroom: Yup.string(),
  typeBed: Yup.string()
    .required(getRequiredText('Loại giường')),
  numberOfBed: Yup.string()
    .required(getRequiredText('Loại giường')),
  limitOfAdult: Yup.string()
    .required(getRequiredText('Số người lớn tối đa')),
  limitOfKid: Yup.string()
    .required(getRequiredText('Số trẻ em tối đa')),
  limitOfCustomer: Yup.string()
    .required(getRequiredText('Số người tối đa')),
};
