import React, { useState, useCallback } from 'react';
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
} from 'reactstrap';

import { LOCATIONS } from 'constants/index';

import { ReactComponent as FlexibleOrangeIconSvg } from 'assets/images/location/flexible-orange-icon-svg.svg';
import { ReactComponent as NoRefundIconSvg } from 'assets/images/location/no-refund-icon-svg.svg';
import { ReactComponent as PolicyCheckoutAndDepositIconSvg } from 'assets/images/location/policy-checkout-deposit-icon-svg.svg';
import { ReactComponent as PolicyKidAndExtraBedIconSvg } from 'assets/images/location/policy-kid-extra-bed-icon-svg.svg';
import { ReactComponent as PolicyReceiveRoomIconSvg } from 'assets/images/location/policy-receive-room-icon-svg.svg';
import { ReactComponent as PolicySmokingAndPetIconSvg } from 'assets/images/location/policy-smoking-pet-icon-svg.svg';

import AppBreadcrumb from 'components/App/Breadcrumb';
import AppButton from 'components/App/Button';
import AppMeta from 'components/App/Meta';

import ModalEditPolicyReceiveRoom from 'components/Modals/ModalEditPolicyReceiveRoom';
import ModalEditPolicyDeposit from 'components/Modals/ModalEditPolicyDeposit';
import ModalEditPolicySmoke from 'components/Modals/ModalEditPolicySmoke';
import ModalEditPolicyCancelRoomFlex from 'components/Modals/ModalEditPolicyCancelRoomFlex';

import styles from './Policy.module.scss';

const breadcrumbList = [
  {
    name: 'Chỗ nghỉ',
    isActive: false,
    link: LOCATIONS.LOCATION_LIST,
  },
  {
    name: 'Chính sách',
    isActive: true,
    link: '',
  },
];

const defaultDate = new Date();

function Policy() {
  const [isOpenModalCancelRoomFlex, setIsOpenModalCancelRoomFlex] = useState(false);
  const [isOpenModalReceiveRoom, setIsOpenModalReceiveRoom] = useState(false);
  const [isOpenModalDeposit, setIsOpenModalDeposit] = useState(false);
  const [isOpenModalSmoke, setIsOpenModalSmoke] = useState(false);

  const [bookingTime] = useState({
    startCheckInDate: defaultDate,
    endCheckInDate: defaultDate,
    startCheckOutDate: defaultDate,
    endCheckOutDate: defaultDate,
  });

  const onToggleModalCancelRoomFlex = useCallback(() => {
    setIsOpenModalCancelRoomFlex((prev) => !prev);
  }, []);

  const onToggleModalReceiveRoom = useCallback(() => {
    setIsOpenModalReceiveRoom((prev) => !prev);
  }, []);

  const onToggleModalDeposit = useCallback(() => {
    setIsOpenModalDeposit((prev) => !prev);
  }, []);

  const onToggleModalSmoke = useCallback(() => {
    setIsOpenModalSmoke((prev) => !prev);
  }, []);

  return (
    <div className={`page-content ${styles.Page}`}>
      <AppMeta
        name="Chính sách chỗ nghỉ"
      />

      <Container fluid>
        <AppBreadcrumb
          pageTitle="Chính sách"
          breadcrumbList={breadcrumbList}
        />

        <Card className="Section">
          <CardTitle className="Section__Title">
            Chính sách hủy phòng
          </CardTitle>

          <Row className="Section__Card">
            <Col md="6">
              <div className="Section__Card__Information">
                <div className="Section__Card__Information__Header">
                  <FlexibleOrangeIconSvg className="Section__Card__Information__Header__Icon" />

                  <div className="Section__Card__Information__Header__Title">
                    Linh động - 3 ngày
                  </div>
                </div>

                <div className="Section__Card__Information__Body">
                  <div className="Section__Card__Information__Body__Wrapper">
                    <div className="Section__Card__Information__Body__Point">•</div>

                    <div className="Section__Card__Information__Body__Content">
                      Khách có thể hủy miễn phí đến 3 ngày trước khi tới nhận phòng.
                      Khách sẽ phải trả toàn bộ tiền phòng nếu hủy phòng
                      trong vòng 3 ngày trước khi tới nhận phòng.
                      Nếu khách không đến, họ sẽ phải trả toàn bộ tiền phòng.
                    </div>
                  </div>
                </div>

                <div className="Section__Card__Information__Footer">
                  <AppButton
                    classes="Section__Card__Information__Footer__Edit"
                    onClick={onToggleModalCancelRoomFlex}
                  >
                    Chỉnh sửa
                  </AppButton>

                  <AppButton
                    classes="Section__Card__Information__Footer__Apply"
                    outline
                  >
                    Áp dụng cho các chỗ nghỉ còn lại
                  </AppButton>
                </div>
              </div>
            </Col>

            <Col md="6">
              <div className="Section__Card__Information">
                <div className="Section__Card__Information__Header">
                  <NoRefundIconSvg className="Section__Card__Information__Header__Icon" />

                  <div className="Section__Card__Information__Header__Title">
                    Không hoàn tiền
                  </div>
                </div>

                <div className="Section__Card__Information__Body">
                  <div className="Section__Card__Information__Body__Wrapper">
                    <div className="Section__Card__Information__Body__Point">•</div>

                    <div className="Section__Card__Information__Body__Content">
                      Khách sẽ phải trả toàn bộ tiền phòng nếu hủy phòng bất kỳ lúc nào.
                    </div>
                  </div>

                  <div className="Section__Card__Information__Body__Wrapper">
                    <div className="Section__Card__Information__Body__Point">•</div>

                    <div className="Section__Card__Information__Body__Content">
                      Khách sẽ phải thanh toán trước toàn bộ tiền phòng bất kỳ lúc nào.
                    </div>
                  </div>
                </div>

                <div className="Section__Card__Information__Footer">
                  <AppButton
                    classes="Section__Card__Information__Footer__Edit btn-danger"
                  >
                    Xóa
                  </AppButton>

                  <AppButton
                    classes="Section__Card__Information__Footer__Apply"
                    outline
                  >
                    Áp dụng cho các chỗ nghỉ còn lại
                  </AppButton>
                </div>
              </div>
            </Col>
          </Row>
        </Card>

        <Row>
          <Col md="6">
            <Card className="Section">
              <div className="Section__Card__Policy__Title__Wrapper">
                <PolicyReceiveRoomIconSvg className="Section__Card__Policy__Title__Image" />

                <CardTitle className="Section__Card__Policy__Title">
                  Chính sách nhận phòng
                </CardTitle>
              </div>

              <CardBody md="6" className="Section__Card__Policy">
                <hr className="Section__Card__Line" />

                <Row>
                  <Col md="6" className="Section__Card__Policy__Category">
                    Giờ nhận phòng
                  </Col>

                  <Col md="6" className="Section__Card__Policy__Category__Information">
                    14:00
                  </Col>
                </Row>

                <hr className="Section__Card__Line" />

                <Row>
                  <Col md="6" className="Section__Card__Policy__Category">
                    Giờ trả phòng
                  </Col>

                  <Col md="6" className="Section__Card__Policy__Category__Information">
                    12:00
                  </Col>
                </Row>

                <hr className="Section__Card__Line" />

                <Col md="12" className="Section__Card__Policy__Notice">
                  Quý khách sẽ được yêu cầu xuất trình chứng minh thư
                  hoặc hộ chiếu khi làm thủ tục nhận phòng
                </Col>

                <hr className="Section__Card__Line" />

                <AppButton
                  classes="Section__Card__Policy__Button"
                  onClick={onToggleModalReceiveRoom}
                >
                  Chỉnh sửa
                </AppButton>
              </CardBody>
            </Card>
          </Col>

          <Col md="6">
            <Card className="Section">
              <div className="Section__Card__Policy__Title__Wrapper">
                <PolicyCheckoutAndDepositIconSvg className="Section__Card__Policy__Title__Image" />

                <CardTitle className="Section__Card__Policy__Title">
                  Chính sách thanh toán và đặt cọc
                </CardTitle>
              </div>

              <CardBody md="6" className="Section__Card__Policy">
                <hr className="Section__Card__Line" />

                <Row>
                  <Col md="6" className="Section__Card__Policy__Category">
                    Thẻ quốc tế chấp nhận thanh toán
                  </Col>

                  <Col md="6" className="Section__Card__Policy__Category__Information">
                    Mastercard, JCB, Visa, Dinners Club
                  </Col>
                </Row>

                <hr className="Section__Card__Line" />

                <Row>
                  <Col md="6" className="Section__Card__Policy__Category">
                    Thẻ nội địa chấp nhận thanh toán
                  </Col>

                  <Col md="6" className="Section__Card__Policy__Category__Information">
                    Napas
                  </Col>
                </Row>

                <hr className="Section__Card__Line" />

                <Col md="12" className="Section__Card__Policy__Notice">
                  Quý khách sẽ được yêu cầu đặt cọc 1.000.000 VNĐ cho
                  những dịch vụ phát sinh và hoàn trả khi làm thủ tục trả phòng
                  sau khi đã cần trừ hoàn tất
                </Col>

                <hr className="Section__Card__Line" />

                <AppButton
                  classes="Section__Card__Policy__AppButton"
                  onClick={onToggleModalDeposit}
                >
                  Chỉnh sửa
                </AppButton>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col md="6">
            <Card className="Section">
              <div className="Section__Card__Policy__Title__Wrapper">
                <PolicyKidAndExtraBedIconSvg className="Section__Card__Policy__Title__Image" />

                <CardTitle className="Section__Card__Policy__Title">
                  Chính sách trẻ em và giường phụ
                </CardTitle>
              </div>

              <CardBody md="6" className="Section__Card__Policy">
                <hr className="Section__Card__Line" />

                <Col md="12" className="Section__Card__Policy__Notice">
                  Trẻ̉ em dưới 6 tuổi được miễn phí phụ thu khi dùng giường có sẵn
                </Col>

                <hr className="Section__Card__Line" />

                <Col md="12" className="Section__Card__Policy__Notice">
                  Trẻ̉ em từ 6 - 11 tuổi sẽ phụ thu 200.000 VNĐ/trẻ/phòng/ đêm
                </Col>

                <hr className="Section__Card__Line" />

                <Col md="12" className="Section__Card__Policy__Notice">
                  Khách từ 12 tuổi trở lên sẽ phụ thu giường phụ 300.000 VNĐ/khách/ phòng/ đêm
                </Col>

                <hr className="Section__Card__Line" />

                <AppButton
                  classes="Section__Card__Policy__Button"
                >
                  Chỉnh sửa
                </AppButton>
              </CardBody>
            </Card>
          </Col>

          <Col md="6">
            <Card className="Section">
              <div className="Section__Card__Policy__Title__Wrapper">
                <PolicySmokingAndPetIconSvg className="Section__Card__Policy__Title__Image" />

                <CardTitle className="Section__Card__Policy__Title">
                  Chính sách hút thuốc và thú cưng
                </CardTitle>
              </div>

              <CardBody md="6" className="Section__Card__Policy">
                <hr className="Section__Card__Line" />

                <Col md="12" className="Section__Card__Policy__Notice">
                  Khách không được hút thuốc trong chỗ nghỉ
                </Col>

                <hr className="Section__Card__Line" />

                <Col md="12" className="Section__Card__Policy__Notice">
                  Khách không được mang thú cưng
                </Col>

                <hr className="Section__Card__Line" />

                <AppButton
                  classes="Section__Card__Policy__AppButton"
                  onClick={onToggleModalSmoke}
                >
                  Chỉnh sửa
                </AppButton>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

      <ModalEditPolicyCancelRoomFlex
        isOpen={isOpenModalCancelRoomFlex}
        onToggleModal={onToggleModalCancelRoomFlex}
      />

      <ModalEditPolicyReceiveRoom
        isOpen={isOpenModalReceiveRoom}
        startCheckInDate={bookingTime.startCheckInDate}
        endCheckInDate={bookingTime.endCheckInDate}
        startCheckOutDate={bookingTime.startCheckOutDate}
        endCheckOutDate={bookingTime.endCheckOutDate}
        onToggleModal={onToggleModalReceiveRoom}
      />

      <ModalEditPolicyDeposit
        isOpen={isOpenModalDeposit}
        onToggleModal={onToggleModalDeposit}
      />

      <ModalEditPolicySmoke
        isOpen={isOpenModalSmoke}
        onToggleModal={onToggleModalSmoke}
      />
    </div>
  );
}

export default Policy;
