import React, { useEffect } from 'react';
import {
  Switch, BrowserRouter as Router, Route, useHistory,
} from 'react-router-dom';
import PropTypes from 'prop-types';
// import jwtDecode from 'jwt-decode';
// import { toast } from 'react-toastify';
// import { useTranslation } from 'react-i18next';

import { authProtectedRoutes } from 'routes';
import { LOCATIONS, storageKey } from 'constants/index';
import AuthMiddleware from 'routes/route';
import axiosClient from 'utils/axios';
import propTypeModels from 'propTypeModels';

// layouts Format
import AuthLayout from 'Layout/AuthLayout';
import ForgotPassword from 'pages/Authentication/ForgotPassword';
import Login from 'pages/Authentication/Login';
import Pages404 from 'pages/PageErrors/Page404';
import Register from 'pages/Authentication/Register';
import ResetPassword from 'pages/Authentication/ResetPassword';
import ScrollToTop from 'components/ScrollToTop';

// Import scss
import 'assets/scss/theme.scss';
import 'App/styles.scss';
import 'App/theme-override.scss';

const App = (props) => {
  const {
    me,

    actionGetMe,
  } = props;

  const history = useHistory();
  // const { t } = useTranslation();

  // const refreshAccessToken = localStorage.getItem(storageKey.AUTHENTICATE_REFRESH_TOKEN);
  const accessToken = localStorage.getItem(storageKey.AUTHENTICATE_TOKEN);

  if (accessToken) {
    axiosClient.defaults.headers.Authorization = `Bearer ${accessToken}`;

    // phần này cần làm việc với back-end về việc bảo mật token khi axios request

    // axiosClient.interceptors.request.use((config) => {
    //   const { origin } = new URL(config.baseURL);
    //   const allowedOrigins = [process.env.REACT_APP_BASE_URL];

    //   if (allowedOrigins.includes[origin]) {
    //     // eslint-disable-next-line no-param-reassign
    //     config.headers.authorization = accessToken;
    //   }

    //   return config;
    // }, (error) => Promise.reject(error));
  }

  // check refresh Token
  // useEffect(() => {
  //   if (me._id) {
  //     // Check refresh token
  //     try {
  //       const currentDate = (Date.now() / 1000);
  //       const checkToken = jwtDecode(accessToken).exp;
  //       const checkRefreshToken = jwtDecode(refreshAccessToken).exp;

  //       if ((checkToken < currentDate) && (checkRefreshToken < currentDate)) {
  //         localStorage.removeItem(storageKey.AUTHENTICATE_TOKEN);
  //         localStorage.removeItem(storageKey.AUTHENTICATE_REFRESH_TOKEN);
  //         return null;
  //       }
  //       if (checkToken < currentDate) {
  //         if (checkRefreshToken > currentDate) {
  //           actionRefreshTokenSuccess({ refreshAccessToken });
  //         }
  //       }
  //     } catch (error) {
  //       localStorage.removeItem(storageKey.AUTHENTICATE_TOKEN);
  //       localStorage.removeItem(storageKey.AUTHENTICATE_REFRESH_TOKEN);
  //       actionRefreshTokenFailed();
  //       toast.error(t('error.wrong_format'));
  //     }
  //   }
  //   return undefined;
  // }, [me._id]);

  // Check token failed
  useEffect(() => {
    if ((!accessToken && (Object.keys(me).length === 0)) || !accessToken) {
      return null;
    }

    actionGetMe({ history });
    return undefined;
  }, [accessToken]);

  return (
    <>
      <ScrollToTop />

      <Router>
        <Switch>
          <Route path={LOCATIONS.LOGIN} component={Login} />
          <Route path={LOCATIONS.REGISTER} component={Register} />
          <Route path={LOCATIONS.FORGOT_PASSWORD} component={ForgotPassword} />
          <Route path={LOCATIONS.RESET_PASSWORD} component={ResetPassword} />

          {authProtectedRoutes.map((route) => (
            <AuthMiddleware
              path={route.path}
              layout={AuthLayout}
              component={route.component}
              key={route.path}
              exact={route.exact}
            />
          ))}

          <Route component={Pages404} key="not-found" />
        </Switch>
      </Router>
    </>
  );
};

App.propTypes = {
  // actionGetMe: PropTypes.func.isRequired,
  me: propTypeModels.user.userPropTypes.isRequired,

  actionGetMe: PropTypes.func,
  // actionRefreshTokenSuccess: PropTypes.func,
  // actionRefreshTokenFailed: PropTypes.func.isRequired,
};

App.defaultProps = {
  actionGetMe: () => {},
  // actionRefreshTokenSuccess: () => {},
};

export default App;
