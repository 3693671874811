export const UPDATE_ME = 'UPDATE_ME';
export const UPDATE_ME_SUCCESS = 'UPDATE_ME_SUCCESS';
export const UPDATE_ME_FAILED = 'UPDATE_ME_FAILED';

export const UPDATE_MY_AVATAR = 'UPDATE_MY_AVATAR';
export const UPDATE_MY_AVATAR_SUCCESS = 'UPDATE_MY_AVATAR_SUCCESS';
export const UPDATE_MY_AVATAR_FAILED = 'UPDATE_MY_AVATAR_FAILED';

export const DELETE_MY_AVATAR = 'DELETE_MY_AVATAR';
export const DELETE_MY_AVATAR_SUCCESS = 'DELETE_MY_AVATAR_SUCCESS';
export const DELETE_MY_AVATAR_FAILED = 'DELETE_MY_AVATAR_FAILED';

export const UPDATE_MY_PASSWORD = 'UPDATE_MY_PASSWORD';
export const UPDATE_MY_PASSWORD_SUCCESS = 'UPDATE_MY_PASSWORD_SUCCESS';
export const UPDATE_MY_PASSWORD_FAILED = 'UPDATE_MY_PASSWORD_FAILED';

export const GET_ME = 'GET_ME';
export const GET_ME_SUCCESS = 'GET_ME_SUCCESS';
export const GET_ME_FAILED = 'GET_ME_FAILED';

export const LOGOUT = 'LOGOUT';

export const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS';
export const REFRESH_TOKEN_FAILED = 'REFRESH_TOKEN_FAILED';
