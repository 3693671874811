import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

import { themeColor } from 'constants/index';

import styles from './AppButton.module.scss';

const AppButton = (props) => {
  const {
    disabled,
    loading,
    outline,

    classes,
    color,
    fontSize,
    name,
    type,

    children,

    onClick,
  } = props;

  return (
    <Button
      type={type}
      color={color}
      className={`${styles.Component} ${classes}`}
      disabled={loading || disabled}
      style={{ fontSize }}
      outline={outline}
      onClick={onClick}
    >
      {
      !loading ? children
        : (
          <>
            <i className="bx bx-loader bx-spin align-middle me-1" />
            {`${name}...`}
          </>
        )
      }
    </Button>
  );
};

AppButton.propTypes = {
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  outline: PropTypes.bool,

  name: PropTypes.string,
  classes: PropTypes.string,
  fontSize: PropTypes.string,
  color: PropTypes.oneOf([
    themeColor.PRIMARY,
    themeColor.SECONDARY,
    themeColor.DANGER,
    themeColor.INFO,
    themeColor.LIGHT,
    themeColor.LINK,
    themeColor.SUCCESS,
    themeColor.WARNING,
  ]),
  type: PropTypes.string,

  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Object),
  ]),

  onClick: PropTypes.func,
};

AppButton.defaultProps = {
  disabled: false,
  loading: false,
  outline: false,

  children: '',
  classes: '',
  color: themeColor.PRIMARY,
  fontSize: '',
  name: 'Đang gửi',
  type: 'button',

  onClick: () => { },
};

export default memo(AppButton);
