import React, {
  useMemo,
  memo,
} from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  ModalBody,
  ModalHeader,
  Label,
  Col,
  Row,
  Input,
} from 'reactstrap';

import { ReactComponent as CloseButtonSvg } from 'assets/images/location/close-button-svg.svg';

import Button from 'components/App/Button';

import styles from './ModalEditPolicySmoke.module.scss';

function ModalEditPolicySmoke(props) {
  const {
    isOpen,

    onToggleModal,
  } = props;

  const CloseButton = useMemo(() => (
    <button className={styles.ButtonClose} onClick={onToggleModal} type="button">
      <CloseButtonSvg />
    </button>
  ));

  return (
    <div className={styles.Component}>
      <Modal
        fade
        size="lg"
        isOpen={isOpen}
        toggle={onToggleModal}
        className={styles.Modal}
      >
        <ModalHeader
          toggle={onToggleModal}
          className={styles.ModalHeader}
          close={CloseButton}
        />

        <ModalBody
          className={styles.ModalBody}
        >
          <Label className="Label">
            Chính sách hút thuốc và thú cưng
          </Label>

          <hr className="Divider" />

          <Row className="Block__Container mb-30">
            <Col md="5" className="Block__Title">
              Hút thuốc
            </Col>

            <Col md="7" className="Block__Content__Wrapper">
              <div className="Block__Content__Deposit">
                <div className="Block__Checkbox__Wrapper">
                  <Input
                    type="radio"
                    className="Block__Checkbox"
                    name="smoke"
                    value="true"
                  />

                  <div className="Block__Checkbox__Name">
                    Cho phép
                  </div>
                </div>

                <div className="Block__Checkbox__Wrapper">
                  <Input
                    type="radio"
                    className="Block__Checkbox"
                    name="smoke"
                    value="false"
                  />

                  <div className="Block__Checkbox__Name">
                    Không được phép
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          <hr className="Divider" />

          <Row className="Block__Container mb-30">
            <Col md="5" className="Block__Title">
              Thú cưng
            </Col>

            <Col md="7" className="Block__Content__Wrapper">
              <div className="Block__Content__Deposit">
                <div className="Block__Checkbox__Wrapper">
                  <Input
                    type="radio"
                    className="Block__Checkbox"
                    name="pet"
                    value="true"
                  />

                  <div className="Block__Checkbox__Name">
                    Cho phép
                  </div>
                </div>

                <div className="Block__Checkbox__Wrapper">
                  <Input
                    type="radio"
                    className="Block__Checkbox"
                    name="pet"
                    value="false"
                  />

                  <div className="Block__Checkbox__Name">
                    Không được phép
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          <hr className="Divider" />

          <div className="Block__Policy__Wrapper">
            <Col md="5" className="Block__Title mb-8">
              Tóm tắt chính sách
            </Col>

            <div className="Block__Policy__Information__Wrapper">
              <div className="Block__Policy__Information__Point">•</div>

              <div className="Block__Policy__Information__Content">
                Khách không được hút thuốc trong chỗ nghỉ
              </div>
            </div>

            <div className="Block__Policy__Information__Wrapper">
              <div className="Block__Policy__Information__Point">•</div>

              <div className="Block__Policy__Information__Content">
                Khách không được mang thú cưng
              </div>
            </div>
          </div>

          <hr className="Divider" />

          <div className="Button__Wrapper">
            <Button
              classes="Button Button__Cancel"
              onClick={onToggleModal}
            >
              Hủy
            </Button>

            <Button classes="Button Button__Save">
              Lưu
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}

ModalEditPolicySmoke.propTypes = {
  isOpen: PropTypes.bool.isRequired,

  onToggleModal: PropTypes.func.isRequired,
};

ModalEditPolicySmoke.defaultProps = {

};

export default memo(ModalEditPolicySmoke);
