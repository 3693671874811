import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

import styles from './Selection.module.scss';

function Selection(props) {
  const {
    isHiddenSearchable,
    isHiddenClearable,

    selectedClassName,
    placeholder,
    value,

    options,

    onChange,
  } = props;
  return (
    <div className={styles.Component}>
      <Select
        isSearchable={isHiddenSearchable}
        isClearable={isHiddenClearable}
        className={`basic-multi-select ${selectedClassName}`}
        classNamePrefix="select2-selection"
        placeholder={placeholder}
        options={options}
        onChange={onChange}
        noOptionsMessage={({ option }) => (!option && 'Không có lựa chọn')}
        value={options.filter((item) => item.value === value)}
      />
    </div>
  );
}

Selection.propTypes = {
  isHiddenSearchable: PropTypes.bool,
  isHiddenClearable: PropTypes.bool,

  selectedClassName: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.number,
    PropTypes.instanceOf(Object),
  ]),

  options: PropTypes.instanceOf(Array).isRequired,

  onChange: PropTypes.func,
};

Selection.defaultProps = {
  isHiddenSearchable: false,
  isHiddenClearable: false,

  selectedClassName: '',
  placeholder: '',
  value: '',

  onChange: () => null,
};

export default memo(Selection);
