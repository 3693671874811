import {
  GET_LIST_MEDIA,
  GET_LIST_MEDIA_FAILED,
  GET_LIST_MEDIA_SUCCESS,

  GET_MEDIA_DETAIL,
  GET_MEDIA_DETAIL_FAILED,
  GET_MEDIA_DETAIL_SUCCESS,

  UPLOAD_MEDIA,
  UPLOAD_MEDIA_SUCCESS,
  UPLOAD_MEDIA_FAILED,

  UPDATE_MEDIA,
  UPDATE_MEDIA_FAILED,
  UPDATE_MEDIA_SUCCESS,

  DELETE_MEDIA,
  DELETE_MEDIA_FAILED,
  DELETE_MEDIA_SUCCESS,

  CLEAR_MEDIA_DETAIL,
} from './actionTypes';

// GET LIST MEDIA
export const actionGetListMedia = (payload) => ({
  type: GET_LIST_MEDIA,
  payload,
});

export const actionGetListMediaSuccess = (payload) => ({
  type: GET_LIST_MEDIA_SUCCESS,
  payload,
});

export const actionGetListMediaFailed = () => ({
  type: GET_LIST_MEDIA_FAILED,
});

// GET MEDIA DETAIL
export const actionGetMediaDetail = (payload) => ({
  type: GET_MEDIA_DETAIL,
  payload,
});

export const actionGetMediaDetailSuccess = (payload) => ({
  type: GET_MEDIA_DETAIL_SUCCESS,
  payload,
});

export const actionGetMediaDetailFailed = () => ({
  type: GET_MEDIA_DETAIL_FAILED,
});

// UPLOAD MEDIA
export const actionUploadMedia = (payload) => ({
  type: UPLOAD_MEDIA,
  payload,
});

export const actionUploadMediaSuccess = (payload) => ({
  type: UPLOAD_MEDIA_SUCCESS,
  payload,
});

export const actionUploadMediaFailed = () => ({
  type: UPLOAD_MEDIA_FAILED,
});

// UPDATE MEDIA
export const actionUpdateMedia = (payload) => ({
  type: UPDATE_MEDIA,
  payload,
});

export const actionUpdateMediaSuccess = (payload) => ({
  type: UPDATE_MEDIA_SUCCESS,
  payload,
});

export const actionUpdateMediaFailed = () => ({
  type: UPDATE_MEDIA_FAILED,
});

// DELETE MEDIA
export const actionDeleteMedia = (payload) => ({
  type: DELETE_MEDIA,
  payload,
});

export const actionDeleteMediaSuccess = (payload) => ({
  type: DELETE_MEDIA_SUCCESS,
  payload,
});

export const actionDeleteMediaFailed = () => ({
  type: DELETE_MEDIA_FAILED,
});

export const actionClearMediaDetail = () => ({
  type: CLEAR_MEDIA_DETAIL,
});
