// import { getParamsFormObject } from 'utils';
import axiosClient from 'utils/axios';

export default {
  async getLocationList() {
    const path = '/places/me';

    const response = await axiosClient.get(path);

    return response.data;
  },

  async getRoomStatusList({ startDate, endDate, place }) {
    const path = `/room-status?place=${place}&start=${startDate}&end=${endDate}`;

    const response = await axiosClient.get(path);

    return response.data;
  },

  async updateRoomStatus(payload) {
    const path = '/room-status';

    const response = await axiosClient.post(path, payload);

    return response.data;
  },
};
