/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React, {
  useState, useCallback, useEffect, useMemo,
} from 'react';
import { Link, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Label,
} from 'reactstrap';
import * as Yup from 'yup';
import { useFormik } from 'formik';

import AppButton from 'components/App/Button';
import AppInputValidation from 'components/App/InputValidation';
import Authentication from 'components/App/Authentication';
import Meta from 'components/App/Meta';

import { checkHadError } from 'utils';
import { loginAdminStep2Validation, loginAdminValidation } from 'utils/validate';

import styles from './Login.module.scss';

const ARRAY_OF_PHONE_LINES = [1, 2, 3, 4, 5, 6];

const LOGIN_STEP = {
  FILL_DATA_STEP: 0,
  VERIFICATION_CODE_STEP: 1,
};

const COUNT_DOWN_TIMER = 10;

function Login(props) {
  const {
    loadingStep1,
    loadingStep2,

    loginError,

    actionLoginStep1,
    actionLoginStep2,
  } = props;

  const [isShowPassword, setIsShowPassword] = useState(true);
  const [step, setStep] = useState(LOGIN_STEP.FILL_DATA_STEP);
  const [seconds, setSeconds] = useState(COUNT_DOWN_TIMER);

  const history = useHistory();

  const onCallBackNextStep = useCallback((value) => {
    // setSeconds(COUNT_DOWN_TIMER);
    setStep(value);
  }, []);

  const validationStep1 = useFormik({
    initialValues: {
      email: '',
      password: '',
    },

    validationSchema: Yup.object({
      email: loginAdminValidation.email,
      password: loginAdminValidation.password,
    }),

    onSubmit: (values) => {
      // setSeconds(COUNT_DOWN_TIMER);
      // setStep(LOGIN_STEP.VERIFICATION_CODE_STEP);

      actionLoginStep1(
        {
          ...values,
          history,
          callback: onCallBackNextStep,
        },
      );
    },
  });

  const validationStep2 = useFormik({
    initialValues: {
      phoneNumber: '',
      code: '',
    },

    validationSchema: Yup.object({
      code: loginAdminStep2Validation.code,
    }),

    onSubmit: (values) => {
      actionLoginStep2({
        email: validationStep1.values.email,
        password: validationStep1.values.password,
        // code: values.code,
        history,
      });
    },
  });

  const onChangeEmail = useCallback((e) => {
    validationStep1.setValues((prev) => ({
      ...prev,
      email: e.target.value.trim(),
    }), [validationStep1]);
  });

  const onChangePassword = useCallback((e) => {
    if (e.currentTarget.value.includes(' ')) {
      e.currentTarget.value = e.currentTarget.value.replace(/\s/g, '');
    }
    validationStep1.setValues((prev) => ({
      ...prev,
      password: e.target.value,
    }));
  }, [validationStep1]);

  const onToggleShow = useCallback(() => {
    setIsShowPassword((prevState) => !prevState);
  }, []);

  const onSubmitStep1 = useCallback((e) => {
    e.preventDefault();

    validationStep1.submitForm();
  }, [validationStep1]);

  const onSubmitStep2 = useCallback((e) => {
    e.preventDefault();
    validationStep2.handleSubmit();
  }, [validationStep2]);

  const validationErrorsStep1 = useMemo(() => validationStep1.errors, [validationStep1.errors]);

  const validationErrorsStep2 = useMemo(() => validationStep2.errors, [validationStep2.errors]);

  const onRecode = useCallback(() => {
    setSeconds(COUNT_DOWN_TIMER);

    // TODO: _____Đoạn code cần sử dụng ở phiên bản tiếp theo_____
    // actionLoginStep1(
    //   {
    //     email: validationStep1.values.email,
    //     callback: onCallBackNextStep,
    //   },
    // );
  }, [
    validationStep1,
    onCallBackNextStep,
    // TODO: _____Đoạn code cần sử dụng ở phiên bản tiếp theo_____
    // actionLoginStep1,
  ]);

  const renderStep = useCallback(() => {
    switch (step) {
      case LOGIN_STEP.VERIFICATION_CODE_STEP: {
        return (
          <div className="tab-content">
            <form
              onSubmit={onSubmitStep2}
              className="tab-pane active"
              id="sign-in"
            >
              <div className="d-flex justify-content-start">
                <div className="form-input__phone-code position-relative">
                  <input
                    type="text"
                    className={`phone-input ${validationStep2.values.code.toString().length > 5 && styles.HiddenCaret}`}
                    name="code"
                    maxLength={6}
                    value={validationStep2.values.code}
                    onChange={validationStep2.handleChange}
                    onBlur={validationStep2.handleBlur}
                    disabled={loadingStep2}
                  />

                  <div className="phone-line">
                    {ARRAY_OF_PHONE_LINES.map((index) => <div className="phone-line__item" key={index} />)}
                  </div>

                  {checkHadError(validationStep2, 'code') && (
                  <span className="form-input--error">
                    {validationErrorsStep2.code}
                  </span>
                  )}
                </div>
              </div>

              <p className="mb-5 mt-4">
                { seconds === 0
                  ? (
                    <>
                      <span className="me-2">
                        Chưa nhận được mã OTP?
                      </span>

                      <AppButton
                        type="button"
                        classes="btn_recode text-underline"
                        onClick={onRecode}
                      >
                        GỬI LẠI
                      </AppButton>
                    </>
                  )
                  : `Mã xác thực có hiệu lực trong ${seconds}s`}
              </p>

              <AppButton
                type="submit"
                classes="btn w-100"
                loading={loadingStep2}
                disabled={checkHadError(validationStep2, 'code')}
              >
                Tiếp theo
              </AppButton>
            </form>
          </div>
        );
      }

      default: {
        return (
          <form onSubmit={validationStep1.submitForm}>
            <div className="Login__Email">
              <Label className="Login__Label">
                Email đăng nhập
                <code>*</code>
              </Label>

              <AppInputValidation
                inputClassName="Login__App-Input"
                placeholder="Nhập tên đăng nhập"
                name="email"
                validation={validationStep1}
                isCustomOnChange
                onChange={onChangeEmail}
              />
            </div>

            <div className="Login__Password">
              <Label className="Login__Label">
                Mật khẩu
                <code>*</code>
              </Label>

              <AppInputValidation
                inputClassName="Login__App-Input"
                placeholder="Nhập mật khẩu"
                name="password"
                validation={validationStep1}
                type={isShowPassword ? 'password' : 'text'}
                isCustomOnChange
                onChange={onChangePassword}
              />

              <i
                className={`font-size-18 fas
                  ${checkHadError(validationErrorsStep1, 'password') ? 'eye-error' : 'eye-default'}
                  ${!isShowPassword ? 'fa-eye-slash' : 'fa-eye'} cursor-pointer Login__Show-Pass-Icon
                  `}
                aria-hidden="true"
                onClick={onToggleShow}
              />
            </div>

            <Link
              to="/login"
              className="Login__Forgot-Password"
            >
              Quên mật khẩu?
            </Link>

            <AppButton
              classes="Login__Btn-Login"
              loading={loadingStep1}
              onClick={onSubmitStep1}
              type="submit"
            >
              KẾ TIẾP
            </AppButton>
          </form>
        );
      }
    }
  }, [
    seconds,
    step,
    validationErrorsStep2,
    validationStep1,
    validationStep2,
    onRecode,
    onSubmitStep1,
    onSubmitStep2,
  ]);

  useEffect(() => {
    if (step === LOGIN_STEP.VERIFICATION_CODE_STEP) {
      const myInterval = setInterval(() => {
        if (seconds <= 0) {
          clearInterval(myInterval);
        } else {
          setSeconds((prevState) => prevState - 1);
        }
      }, 1000);
      return () => {
        clearInterval(myInterval);
      };
    }

    return setSeconds(COUNT_DOWN_TIMER);
  }, [step, seconds]);

  useEffect(() => {
    if (Object.keys(loginError).length) {
      validationStep1.setErrors({
        ...loginError,
      });
    }
  }, [loginError]);

  return (
    <>
      <Meta name="" />

      <Authentication
        title="Xin chào!"
        description={step === LOGIN_STEP.FILL_DATA_STEP ? 'Đăng nhập để đến quản lý chỗ nghỉ' : 'Chúng tôi đã gửi mã OTP đến Email Quý vị'}
      >
        <div className={styles.Page}>
          {renderStep()}
        </div>
      </Authentication>
    </>
  );
}

Login.propTypes = {
  loadingStep1: PropTypes.bool.isRequired,
  loadingStep2: PropTypes.bool.isRequired,

  loginError: PropTypes.instanceOf(Object),

  actionLoginStep1: PropTypes.func.isRequired,
  actionLoginStep2: PropTypes.func.isRequired,
};

Login.defaultProps = {
  loginError: {},
};

export default Login;
