import axiosClient from 'utils/axios';

const endPoint = '/auth';

const TYPE_LOGIN = 'EXTRANET';

export default {
  async login(payload) {
    const { email, password } = payload;

    const path = `${endPoint}/login`;

    const response = await axiosClient.post(`${path}`, { email, password, type: TYPE_LOGIN });

    return response.data;
  },

  async refreshToken(payload) {
    const { refreshToken } = payload;

    const path = `${endPoint}/token`;

    const response = await axiosClient.post(`${path}`, { refreshToken });

    return response.data;
  },

  async register(payload) {
    const { registerData } = payload;

    const path = `${endPoint}/register`;

    const response = await axiosClient.post(path, registerData);

    return response.data;
  },

  async forgotPassword(payload) {
    const { passwordData } = payload;

    const path = `${endPoint}/forgot-password`;

    const response = await axiosClient.post(path, passwordData);

    return response.data;
  },
};
