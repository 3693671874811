import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

function ScrollToTop(props) {
  const { history } = props;

  useEffect(() => {
    const unListen = history.listen(() => {
      window.scrollTo(0, 0);
    });

    return () => { unListen(); };
  }, []);

  return null;
}

ScrollToTop.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
};

export default withRouter(ScrollToTop);
