import React from 'react';
import {
  Container,
  Col,
  Row,
  Card,
} from 'reactstrap';
import { useFormik } from 'formik';

import {
  LOCATIONS,
  BEST_CONVENIENCE,
  OTHER_CONVENIENCE,
  VIEW_ROOM,
  BATHROOM_CONVENIENCE,
  themeColor,
} from 'constants/index';

import AppBreadcrumb from 'components/App/Breadcrumb';
import AppButton from 'components/App/Button';
import AppInputNumberValidation from 'components/App/InputNumberValidation';
import AppMeta from 'components/App/Meta';
import RadioConvenience from 'components/RadioConvenience';
import SectionWrapper from 'components/AddLocation/SectionWrapper';

import styles from './ConvenienceRoom.module.scss';

const breadcrumbList = [
  {
    name: 'Chỗ nghỉ',
    isActive: false,
    link: LOCATIONS.LOCATION_LIST,
  },
  {
    name: 'Loại phòng',
    isActive: false,
    link: LOCATIONS.LOCATION_ROOM_TYPE,
  },
  {
    name: 'Chỉnh sửa phòng',
    isActive: true,
    link: '',
  },
];

function ConvenienceRoom() {
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {},
  });

  return (
    <div className="page-content">
      <div className={styles.Page}>
        <AppMeta
          name="Tiện nghi phòng"
        />

        <Container fluid>
          <AppBreadcrumb
            pageTitle="Tiện nghi phòng"
            breadcrumbList={breadcrumbList}
          />

          <div className="mb-24">
            <SectionWrapper
              title="Diện tích phòng"
            >
              <Row>
                <Col md="4">
                  <div className="Input__Wrapper">
                    <div className="Input__Title">
                      Suite Có Sân Hiên
                    </div>

                    <AppInputNumberValidation
                      name="room1"
                      type="text"
                      placeholder="Nhập diện tích"
                      validation={formik}
                      showCurrency="m²"
                    />
                  </div>

                  <div className="Input__Wrapper">
                    <div className="Input__Title">
                      Phòng Deluxe Giường Đôi
                    </div>

                    <AppInputNumberValidation
                      name="room2"
                      type="text"
                      placeholder="Nhập diện tích"
                      validation={formik}
                      showCurrency="m²"
                    />
                  </div>
                </Col>

                <Col md="4">
                  <div className="Input__Wrapper">
                    <div className="Input__Title">
                      Phòng Superior 2 Giường Đơn
                    </div>

                    <AppInputNumberValidation
                      name="room3"
                      type="text"
                      placeholder="Nhập diện tích"
                      validation={formik}
                      showCurrency="m²"
                    />
                  </div>

                  <div className="Input__Wrapper">
                    <div className="Input__Title">
                      Suite Nhìn Ra Thành Phố
                    </div>

                    <AppInputNumberValidation
                      name="room4"
                      type="text"
                      placeholder="Nhập diện tích"
                      validation={formik}
                      showCurrency="m²"
                    />
                  </div>
                </Col>

                <Col md="4">
                  <div className="Input__Wrapper">
                    <div className="Input__Title">
                      Phòng Superior Giường Đôi
                    </div>

                    <AppInputNumberValidation
                      name="room5"
                      type="text"
                      placeholder="Nhập diện tích"
                      validation={formik}
                      showCurrency="m²"
                    />
                  </div>
                </Col>
              </Row>
            </SectionWrapper>
          </div>

          <Row>
            <Col md="12" lg="6">
              <div className="mb-24">
                <SectionWrapper
                  title="Những tiện nghi tốt nhất"
                >
                  {BEST_CONVENIENCE.map((item) => (
                    <div className="Section" key={item.label}>
                      <RadioConvenience
                        title={item.label}
                        name={item.name}
                      />
                    </div>
                  ))}
                </SectionWrapper>
              </div>

              <SectionWrapper
                title="Tiện nghi phòng"
              >
                {OTHER_CONVENIENCE.map((item) => (
                  <div className="Section" key={item.label}>
                    <RadioConvenience
                      title={item.label}
                      name={item.name}
                    />
                  </div>
                ))}
              </SectionWrapper>
            </Col>

            <Col md="12" lg="6">
              <div className="mb-24">
                <SectionWrapper
                  title="Tầm nhìn phòng"
                >
                  {VIEW_ROOM.map((item) => (
                    <div className="Section" key={item.label}>
                      <RadioConvenience
                        title={item.label}
                        name={item.name}
                      />
                    </div>
                  ))}
                </SectionWrapper>
              </div>

              <SectionWrapper
                title="Tiện nghi phòng tắm"
              >
                {BATHROOM_CONVENIENCE.map((item) => (
                  <div className="Section" key={item.label}>
                    <RadioConvenience
                      title={item.label}
                      name={item.name}
                    />
                  </div>
                ))}
              </SectionWrapper>
            </Col>
          </Row>

          <Card className={styles.SectionFooter}>
            <div className="d-flex justify-content-end">
              <AppButton color={themeColor.SECONDARY} classes="ButtonAction mr-24">
                Hủy
              </AppButton>

              <AppButton classes="ButtonAction">
                Lưu
              </AppButton>
            </div>
          </Card>
        </Container>
      </div>
    </div>
  );
}

export default ConvenienceRoom;
