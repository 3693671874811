import React, { memo } from 'react';

import AvataImg from 'assets/images/avatarHotel.png';
import { ReactComponent as StarSVG } from 'assets/images/star_purple500.svg';

import styles from './Information.module.scss';

function Information() {
  return (
    <div className={styles.Information}>
      <div className="Information">
        <div className="Information__Avatar">
          <img src={AvataImg} alt="avatar" />
        </div>

        <div className="Information__Content InformationContent">
          <span className="InformationContent__Name">
            White Lotus Saigon Hotel
          </span>

          <div className="InformationContent__Star">
            <StarSVG />

            <StarSVG />

            <StarSVG />
          </div>

          <span className="InformationContent__Address">
            6A Thi Sách, Bến Nghé, Quận 1, TP. Hồ Chí Minh
          </span>
        </div>
      </div>

    </div>
  );
}

export default memo(Information);
