import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  Row,
} from 'reactstrap';

import Button from 'components/App/Button';
import FormField from 'components/AddLocation/FormField';
import InputValidation from 'components/App/InputValidation';
import SectionWrapper from 'components/AddLocation/SectionWrapper';

import styles from './StepTwo.module.scss';

function StepTwo(props) {
  const {
    tabPrevious,

    validation,

    onClickPrevTab,
    onSubmitStepTwo,
  } = props;
  return (
    <div className={styles.Component}>
      <form onSubmit={validation.handleSubmit}>
        <SectionWrapper title="Liên kết chỗ nghỉ">
          <Row className="Section">
            <Col md="6">
              <FormField
                label="Id chỗ nghỉ"
                isRequired
              >
                <InputValidation
                  inputClassName="Section__Input Section__Input"
                  placeholder="Vui lòng nhập ID chỗ nghỉ của Quý vị"
                  name="idLocation"
                  keyName="idLocation"
                  validation={validation}
                />
              </FormField>
            </Col>
          </Row>
        </SectionWrapper>

        <div className="Button__Wrapper">
          <Button
            classes="Button Button__Back"
            onClick={onClickPrevTab(tabPrevious)}
          >
            <i className="fas fa-arrow-left" />

            Quay về trước
          </Button>

          <Button
            classes="Button Button__Next"
            type="submit"
            onClick={onSubmitStepTwo}
          >
            Bước tiếp theo

            <i className="fas fa-arrow-right" />
          </Button>
        </div>
      </form>
    </div>
  );
}

StepTwo.propTypes = {
  tabPrevious: PropTypes.string.isRequired,

  validation: PropTypes.instanceOf(Object).isRequired,

  onClickPrevTab: PropTypes.func.isRequired,
  onSubmitStepTwo: PropTypes.func.isRequired,
};

StepTwo.defaultProps = {

};

export default memo(StepTwo);
