import { connect } from 'react-redux';

import {
  actionRefreshTokenSuccess, actionRefreshTokenFailed,
} from 'store/actions';
import { GET_ME } from 'store/auth/profile/actionTypes';

import App from './App';

const mapStateToProps = (state) => ({
  me: state.Profile.me,
});

const mapDispatchToProps = (dispatch) => ({
  actionGetMe: (payload) => dispatch({
    type: GET_ME,
    payload,
  }),

  actionRefreshTokenSuccess: (payload) => dispatch(actionRefreshTokenSuccess(payload)),
  actionRefreshTokenFailed: () => dispatch(actionRefreshTokenFailed()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
