import React, {
  useCallback,
  useMemo,
  memo,
} from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import {
  Modal,
  ModalBody,
  ModalHeader,
  Label,
  Col,
} from 'reactstrap';

import { ReactComponent as CloseButtonSvg } from 'assets/images/location/close-button-svg.svg';

import Button from 'components/App/Button';

// import SectionWrapper from 'components/AddLocation/SectionWrapper';
import FormField from 'components/AddLocation/FormField';
import SelectValidation from 'components/App/SelectValidation';
import styles from './ModalEditPolicyCancelRoomFlex.module.scss';

const optionTimeDenyRoom = [
  { value: '0', label: 'Không thể hủy đặt phòng miễn phí' },
  { value: '1', label: 'Đến 1 ngày trước khi tới nhận phòng' },
  { value: '2', label: 'Đến 2 ngày trước khi tới' },
  { value: '3', label: 'Đến 3 ngày trước khi tới' },
  { value: '4', label: 'Đến 4 ngày trước khi tới' },
  { value: '5', label: 'Đến 5 ngày trước khi tới' },
  { value: '7', label: 'Đến 7 ngày trước khi tới' },
  { value: '10', label: 'Đến 10 ngày trước khi tới' },
  { value: '14', label: 'Đến 14 ngày trước khi tới' },
];

const optionFeeDenyRoom = [
  { value: '1', label: 'Giá của đêm đầu tiên' },
  { value: '2', label: '50% tổng tiền phòng' },
  { value: '3', label: '100% tổng tiền phòng' },
];

const optionFeeAbsence = [
  { value: '1', label: 'Bằng phí huỷ đặt phòng' },
  { value: '2', label: '100% tổng tiền phòng' },
];

function ModalEditPolicyCancelRoomFlex(props) {
  const {
    isOpen,

    onToggleModal,
  } = props;

  const CloseButton = useMemo(() => (
    <button className={styles.ButtonClose} onClick={onToggleModal} type="button">
      <CloseButtonSvg />
    </button>
  ));

  const validation = useFormik({
    initialValues: {
      timeDenyRoom: optionTimeDenyRoom[0].value,
      feeDenyRoom: optionFeeDenyRoom[0].value,
      feeAbsence: optionFeeAbsence[0].value,
    },

    onSubmit: () => {

    },
  });

  const onChangeDenyRoom = useCallback((e) => {
    validation.setFieldValue('timeDenyRoom', e.value, false);
  }, [validation]);

  const onChangeFeeDenyRoom = useCallback((e) => {
    validation.setFieldValue('feeDenyRoom', e.value, false);
  }, [validation]);

  const onChangeFeeAbsence = useCallback((e) => {
    validation.setFieldValue('feeAbsence', e.value, false);
  }, [validation]);

  return (
    <div className={styles.Component}>
      <Modal
        fade
        size="lg"
        isOpen={isOpen}
        toggle={onToggleModal}
        className={styles.Modal}
      >
        <ModalHeader
          toggle={onToggleModal}
          className={styles.ModalHeader}
          close={CloseButton}
        />

        <ModalBody
          className={styles.ModalBody}
        >
          <Label className="Label">
            {validation.values.timeDenyRoom !== '0'
              ? `Linh động - ${validation.values.timeDenyRoom} ngày`
              : 'Không thể huỷ đặt phòng miễn phí'}
          </Label>

          <hr className="Divider" />

          {/* <SectionWrapper title="Hủy đặt phòng" isRequired> */}
          <FormField label="Khách có thể hủy đặt phòng miễn phí bao lâu trước khi tới nơi?">
            <SelectValidation
              placeholder="Đến 3 ngày trước khi tới nơi"
              options={optionTimeDenyRoom}
              value={validation.values.timeDenyRoom}
              onChange={onChangeDenyRoom}
            />
          </FormField>

          <FormField
            label={validation.values.timeDenyRoom !== '0'
              ? `Khách phải trả bao nhiêu khi hủy đặt phòng trong vòng ${validation.values.timeDenyRoom} ngày trước khi tới nhận phòng?`
              : 'Khách phải trả bao nhiêu khi hủy đặt phòng trước khi tới nhận phòng?'}
          >
            <SelectValidation
              placeholder="100% tiền phòng"
              options={optionFeeDenyRoom}
              value={validation.values.timeDenyRoom !== '0' ? validation.values.feeDenyRoom : validation.values.feeDenyRoom[2]}
              onChange={onChangeFeeDenyRoom}
              isDisabled={validation.values.timeDenyRoom === '0'}
            />
          </FormField>

          <FormField label="Quý vị sẽ thu phí bao nhiêu nếu khách vắng mặt?">
            <SelectValidation
              placeholder="100% tiền phòng"
              options={optionFeeAbsence}
              value={validation.values.timeDenyRoom !== '0' ? validation.values.feeDenyRoom : validation.values.feeDenyRoom[1]}
              onChange={onChangeFeeAbsence}
              isDisabled={validation.values.timeDenyRoom === '0'}
            />
          </FormField>
          {/* </SectionWrapper> */}

          <hr className="Divider" />

          <div className="Block__Policy__Wrapper">
            <Col md="5" className="Block__Title mb-8">
              Tóm tắt chính sách
            </Col>

            <div className="Block__Policy__Information__Wrapper">
              <div className="Block__Policy__Information__Content">
                Khách có thể hủy miễn phí đến 3 ngày trước khi tới nhận phòng.
                Khách sẽ phải trả toàn bộ tiền phòng nếu hủy phòng trong vòng 3
                ngày trước khi tới nhận phòng. Nếu khách không đến, họ sẽ phải
                trả toàn bộ tiền phòng.
              </div>
            </div>
          </div>

          <hr className="Divider" />

          <div className="Button__Wrapper">
            <Button
              classes="Button Button__Cancel"
              onClick={onToggleModal}
            >
              Hủy
            </Button>

            <Button classes="Button Button__Save">
              Lưu
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}

ModalEditPolicyCancelRoomFlex.propTypes = {
  isOpen: PropTypes.bool.isRequired,

  onToggleModal: PropTypes.func.isRequired,
};

ModalEditPolicyCancelRoomFlex.defaultProps = {

};

export default memo(ModalEditPolicyCancelRoomFlex);
