import {
  CHANGE_SIDEBAR_THEME,
  CHANGE_SIDEBAR_TYPE,
  CHANGE_TOPBAR_THEME,
  SHOW_RIGHT_SIDEBAR,
  SHOW_SIDEBAR,
  TOGGLE_LEFT_MENU,
} from './actionTypes';

export const actionChangeSidebarTheme = (theme) => ({
  type: CHANGE_SIDEBAR_THEME,
  payload: theme,
});

export const actionChangeSidebarType = (sidebarType, isMobile) => ({
  type: CHANGE_SIDEBAR_TYPE,
  payload: { sidebarType, isMobile },
});

export const actionChangeTopBarTheme = (topBarTheme) => ({
  type: CHANGE_TOPBAR_THEME,
  payload: topBarTheme,
});

export const actionShowRightSidebar = (isopen) => ({
  type: SHOW_RIGHT_SIDEBAR,
  payload: isopen,
});

export const actionShowSidebar = (isopen) => ({
  type: SHOW_SIDEBAR,
  payload: isopen,
});

export const actionToggleLeftMenu = (isopen) => ({
  type: TOGGLE_LEFT_MENU,
  payload: isopen,
});
