/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {
  memo, useCallback, useEffect, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  Input,
  Row,
} from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import Select from 'react-select';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import debounce from 'lodash/debounce';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import { useTranslation } from 'react-i18next';

import {
  DEBOUNCE_SEARCH_DELAY,

  searchType,
} from 'constants/index';
import {
  translateOptions,
  translateOptionsWithCondition,
} from 'utils';

import AppButton from 'components/App/Button';
import AppLoading from 'components/App/Loading';
import Pagination from 'components/Pagination';
import SelectedPageSize from 'components/App/SelectedPageSize';

import './styles.scss';

const DEFAULT_LIMIT = 20;

const limitList = [20, 30, 50];

const DataTables = (props) => {
  const {
    isHiddenCheckbox,
    loading,
    visibleLimit,
    visiblePagination,

    btnAdd: {
      visibleBtn,
      btnName,
      onClick,
      colorBtn,
      classes,
    },

    data: {
      list,
      total,
    },

    dataFolderMedia: {
      visibleMedia,
      selectMedia,
    },

    dataTypeMedia: {
      visibleTypeMedia,
      selectTypeMedia,
    },

    search: {
      visibleSearch,
      onFilter,
    },

    columns,

    onClearData,
    getDataProjectPersonalClient,
  } = props;

  const { t } = useTranslation();

  const initialState = {
    q: '',
    limit: DEFAULT_LIMIT,
    page: 1,
    type: '',
    status: '',
    folderType: '',
    statusSlide: '',
    mimeType: null,
  };

  const [state, setState] = useState(initialState);
  const [isShowDropDown, setIsShowDropDown] = useState(false);
  const [filter, setFilter] = useState(initialState);
  const [pageCount, setPageCount] = useState(0);

  const pageOptions = {
    sizePerPage: state.limit,
    custom: true,
  };

  const emptyDataMessage = useMemo(() => <p className="w-100 mb-0">{t('search_not_found')}</p>, []);

  const totalPage = useMemo(
    () => (Math.ceil(total / state.limit) || 1), [total, state.limit],
  );

  const onChangeOptionLimit = useCallback((e) => {
    setIsShowDropDown(!isShowDropDown);

    if (e.target.value) {
      setState((prevState) => ({
        ...prevState,
        limit: +e.target.value || prevState.limit,
      }));
    }
  }, [isShowDropDown]);

  const searchDebounced = useCallback(
    debounce((params) => setState(params), DEBOUNCE_SEARCH_DELAY), [],
  );

  const onSearch = useCallback((e) => {
    setFilter((prevState) => ({
      ...prevState,
      q: e.target.value,
      page: 1,
      limit: !visibleLimit ? total : prevState.limit,
    }));

    searchDebounced({
      ...state,
      q: e.target.value,
      page: 1,
      limit: !visibleLimit ? total : state.limit,
    });
  }, [visibleLimit, total]);

  const onClickBtn = useCallback(() => {
    onClick();

    if (state.q || state.page !== 1) {
      setState(initialState);
    }
  }, []);

  const onSelect = useCallback((e, option) => {
    if (e?.value || e?.value === 0) {
      if (option.name === searchType.MEDIA_FOLDER) {
        setState((prevState) => ({
          ...prevState,
          folderType: e?.value,
        }));
      }

      if (option.name === searchType.MEDIA_TYPE) {
        setState((prevState) => ({
          ...prevState,
          mimeType: e?.value,
        }));
      }

      if (option.name === searchType.STATUS_SLIDE) {
        setState((prevState) => ({
          ...prevState,
          statusSlide: e?.value,
        }));
      }

      return;
    }

    if (option.name === searchType.MEDIA_FOLDER) {
      setState((prevState) => ({
        ...prevState,
        folderType: '',
      }));
    }

    if (option.name === searchType.MEDIA_TYPE) {
      setState((prevState) => ({
        ...prevState,
        mimeType: '',
      }));
    }

    if (option.name === searchType.STATUS_SLIDE) {
      setState((prevState) => ({
        ...prevState,
        statusSlide: '',
      }));
    }
  }, []);

  const onChangePage = useCallback((event) => {
    setState((prevState) => ({
      ...prevState,
      page: event.selected + 1,
    }));

    window.scrollTo(0, 0);
  }, []);

  useEffect(() => () => {
    if (onClearData) onClearData();
  }, []);

  useEffect(() => {
    if (onFilter) {
      onFilter(state);
    }
  }, [state]);

  useEffect(() => {
    if (!visibleLimit && total) {
      setState((prevState) => ({
        ...prevState,
        limit: total,
      }));
    }
  }, [visibleLimit, total]);

  useEffect(() => {
    getDataProjectPersonalClient(state);
  }, [state]);

  useEffect(() => {
    setPageCount(totalPage);
  }, [totalPage]);

  return (
    <Row className="position-relative">
      <AppLoading loading={loading} loaderOnPage />

      <Col className="col-12">
        <Row className="mb-2">
          {visibleSearch && (
            <Col xl="3" sm="6" md="4">
              <div className="search-box me-2 mb-2 d-inline-block">
                <div className="position-relative">
                  <Input
                    onChange={onSearch}
                    placeholder={`${t('search')}...`}
                    name="search"
                    value={filter.q || ''}
                  />
                  <i className="bx bx-search-alt search-icon" />
                </div>
              </div>
            </Col>
          )}

          {
            visibleMedia && (
              <Col xl="4" sm="4" md="4">
                <div className="search-box mb-2">
                  <Select
                    options={translateOptionsWithCondition(
                      'constant.media_folder',
                      selectMedia,
                      'value',
                      selectMedia.AVATAR,
                      1,
                    )}
                    value={
                      translateOptions('constant.media_folder', selectMedia).filter(
                        (option) => option.value === state.folderType,
                      )
                    }
                    name="media-folder"
                    classNamePrefix="select2-selection"
                    className="basic-multi-select"
                    isSearchable
                    isClearable
                    placeholder={`${t('media.search_by_folder')}...`}
                    onChange={onSelect}
                  />
                </div>
              </Col>
            )
          }

          {
            visibleTypeMedia && (
              <Col xl="4" sm="4" md="4">
                <div className="search-box mb-2">
                  <Select
                    options={translateOptionsWithCondition(
                      'constant.media_types',
                      selectTypeMedia,
                      'value',
                      visibleTypeMedia,
                      1,
                      1,
                    )}
                    value={
                      translateOptions('constant.media_types', selectTypeMedia, 1).filter(
                        (option) => option.value === state.mimeType,
                      )
                    }
                    name="media-type"
                    classNamePrefix="select2-selection"
                    className="basic-multi-select"
                    isSearchable
                    isClearable
                    placeholder={`${t('media.search_by_media_type')}...`}
                    onChange={onSelect}
                  />
                </div>
              </Col>
            )
          }

          {
            visibleBtn && (
              <Col xl={visibleSearch ? '3' : '12'} sm="6" md="4" className="btn_all_page">
                <div className="text-sm-end">
                  <AppButton
                    onClick={onClickBtn}
                    classes={classes}
                    color={colorBtn}
                  >
                    <i className="mdi mdi-plus me-1" />
                    {' '}
                    {t(btnName)}
                  </AppButton>
                </div>
              </Col>
            )
          }
        </Row>

        <PaginationProvider
          keyField="_id"
          pagination={paginationFactory(pageOptions)}
        >
          {({ paginationTableProps }) => (
            <ToolkitProvider
              keyField="_id"
              columns={columns}
              data={list || []}
            >
              {(toolkitProps) => (
                <>
                  <Row>
                    <Col xl="12">
                      <div className="table-responsive">
                        <BootstrapTable
                          keyField="_id"
                          responsive
                          bordered={false}
                          striped={false}
                          noDataIndication={emptyDataMessage}
                          selectRow={{
                            mode: 'checkbox',
                            hideSelectColumn: isHiddenCheckbox,
                          }}
                          classes="table table-striped table-bordered"
                          headerWrapperClasses="thead-light"
                          {...toolkitProps.baseProps}
                          {...paginationTableProps}
                        />
                      </div>
                    </Col>
                  </Row>

                  <Row className="align-items-md-center mt-30">
                    <Col className="inner-custom-pagination d-flex">
                      <div className="d-flex align-items-center">
                        {visibleLimit ? (
                          <>
                            <p className="mb-0 font-size-13">
                              {t('show')}
                            </p>
                            <div className="d-inline mx-2">
                              <SelectedPageSize
                                isShowDropDown={isShowDropDown}
                                limit={state.limit}
                                limitList={limitList}
                                onChangeOptionLimit={onChangeOptionLimit}
                              />
                            </div>
                            <p className="mb-0 font-size-13">
                              {t('of')}
                              {' '}
                              <span className="font-weight-semibold">
                                {total}
                              </span>
                              {' '}
                              {t('entries')}
                            </p>
                          </>
                        ) : (
                          <p className="mb-0 font-size-13">
                            <span className="font-weight-semibold">
                              {total}
                            </span>
                            {' '}
                            {t('entries')}
                          </p>
                        )}
                      </div>

                      {
                        visiblePagination && (
                          <Pagination
                            className="text-md-right ms-auto"
                            pageCount={pageCount}
                            onChangePage={onChangePage}
                          />
                        )
                      }
                    </Col>
                  </Row>
                </>
              )}
            </ToolkitProvider>
          )}
        </PaginationProvider>

      </Col>
    </Row>
  );
};

DataTables.propTypes = {
  isHiddenCheckbox: PropTypes.bool,
  loading: PropTypes.bool,
  visibleLimit: PropTypes.bool,
  visiblePagination: PropTypes.bool,

  btnAdd: PropTypes.shape({
    btnName: PropTypes.string,
    colorBtn: PropTypes.string,
    classes: PropTypes.string,

    visibleBtn: PropTypes.bool,

    onClick: PropTypes.func,
  }),
  data: PropTypes.shape({
    list: PropTypes.instanceOf(Object),
    total: PropTypes.number,
  }),
  dataFolderMedia: PropTypes.shape({
    visibleMedia: PropTypes.bool,
    selectMedia: PropTypes.instanceOf(Object),
  }),
  dataTypeMedia: PropTypes.shape({
    visibleTypeMedia: PropTypes.bool,
    selectTypeMedia: PropTypes.instanceOf(Object),
  }),

  dataStatusSlide: PropTypes.shape({
    visibleStatusSlide: PropTypes.bool,
    selectStatusSlide: PropTypes.instanceOf(Object),
  }),

  search: PropTypes.shape({
    visibleSearch: PropTypes.bool,

    onFilter: PropTypes.func,
  }),

  columns: PropTypes.instanceOf(Object),

  onClearData: PropTypes.func,
  getDataProjectPersonalClient: PropTypes.func,
};

DataTables.defaultProps = {
  isHiddenCheckbox: true,
  loading: false,
  visibleLimit: true,
  visiblePagination: true,

  btnAdd: {
    btnName: 'Button name',
    colorBtn: 'primary',
    classes: '',
    visibleBtn: false,
    onClick: () => { },
  },
  data: {
    list: [],
    total: 1,
  },
  dataFolderMedia: {
    visibleMedia: false,
    selectMedia: {},
  },
  dataTypeMedia: {
    visibleTypeMedia: false,
    selectTypeMedia: {},
  },

  dataStatusSlide: {
    visibleStatusSlide: false,
    selectStatusSlide: {},
  },
  search: {
    visibleSearch: true,
    onFilter: () => { },
  },

  columns: [],

  onClearData: () => { },
  getDataProjectPersonalClient: () => { },
};

export default memo(DataTables);
