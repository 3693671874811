// GET LIST MEDIA

export const GET_LIST_MEDIA = 'GET_LIST_MEDIA';
export const GET_LIST_MEDIA_SUCCESS = 'GET_LIST_MEDIA_SUCCESS';
export const GET_LIST_MEDIA_FAILED = 'GET_LIST_MEDIA_FAILED';

// GET MEDIA DETAIL
export const GET_MEDIA_DETAIL = 'GET_MEDIA_DETAIL';
export const GET_MEDIA_DETAIL_SUCCESS = 'GET_MEDIA_DETAIL_SUCCESS';
export const GET_MEDIA_DETAIL_FAILED = 'GET_MEDIA_DETAIL_FAILED';

// UPLOAD MEDIA
export const UPLOAD_MEDIA = 'UPLOAD_MEDIA';
export const UPLOAD_MEDIA_SUCCESS = 'UPLOAD_MEDIA_SUCCESS';
export const UPLOAD_MEDIA_FAILED = 'UPLOAD_MEDIA_FAILED';

// UPDATE DETAIL
export const UPDATE_MEDIA = 'UPDATE_MEDIA';
export const UPDATE_MEDIA_SUCCESS = 'UPDATE_MEDIA_SUCCESS';
export const UPDATE_MEDIA_FAILED = 'UPDATE_MEDIA_FAILED';

// DELETE DETAIL
export const DELETE_MEDIA = 'DELETE_MEDIA';
export const DELETE_MEDIA_SUCCESS = 'DELETE_MEDIA_SUCCESS';
export const DELETE_MEDIA_FAILED = 'DELETE_MEDIA_FAILED';

export const CLEAR_MEDIA_DETAIL = 'CLEAR_MEDIA_DETAIL';
