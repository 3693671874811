import { connect } from 'react-redux';

import { actionForgotPassword } from 'store/actions';

import ForgotPassword from './ForgotPassword';

const mapStateToProps = (state) => ({
  forgotPasswordLoading: state.ForgotPassword.loading,
});

const mapDispatchToProps = (dispatch) => ({
  actionForgotPassword: (email, history) => dispatch(actionForgotPassword(email, history)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
