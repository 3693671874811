import React from 'react';
import PropTypes from 'prop-types';
import ReactPagination from 'react-paginate';

import styles from './Pagination.module.scss';

const Pagination = (props) => {
  const {
    page,
    pageCount,

    onChangePage,
  } = props;

  return (
    <div className={styles.Component}>
      <ReactPagination
        breakLabel="..."
        onPageChange={onChangePage}
        pageRangeDisplayed={3}
        pageCount={pageCount}
        containerClassName="pagination"
        activeClassName="active"
        pageLinkClassName="page-link"
        breakLinkClassName="page-link"
        nextLinkClassName="page-link"
        previousLinkClassName="page-link"
        pageClassName="page-item"
        breakClassName="page-item"
        nextClassName="page-item"
        previousClassName="page-item"
        previousLabel={<>&laquo;</>}
        nextLabel={<>&raquo;</>}
        forcePage={page - 1}
      />
    </div>
  );
};

Pagination.propTypes = {
  page: PropTypes.number,

  pageCount: PropTypes.number.isRequired,

  onChangePage: PropTypes.func.isRequired,
};

Pagination.defaultProps = {
  page: 1,
};

export default Pagination;
