import React, { useCallback, useState } from 'react';
import {
  Container,
  Col,
  Row,
  Card,
  CardTitle,
} from 'reactstrap';

import { LOCATIONS } from 'constants/index';

import AppBreadcrumb from 'components/App/Breadcrumb';
import AppMeta from 'components/App/Meta';
import AppButton from 'components/App/Button';
import CardLocationContactInfo from 'components/CardLocationContactInfo';
import ModalAddContactInfo from 'components/Modals/ModalAddContactInfo';

import { ReactComponent as Add } from 'assets/images/Add.svg';

import styles from './ContactInfo.module.scss';

const breadcrumbList = [
  {
    name: 'Chỗ nghỉ',
    isActive: false,
    link: LOCATIONS.LOCATION_LIST,
  },
  {
    name: 'Thông tin liên hệ',
    isActive: true,
    link: '',
  },
];

const infoCheckIn = [{
  id: '1',
  name: 'Nguyễn Văn A',
  position: 'Chức vụ',
  mail: 'nguyenvana@gmail.com',
  phoneNumber: '0800312454',
  language: 'Tiếng Việt',
},
{
  id: '2',
  name: 'Nguyễn Văn A',
  position: 'Chức vụ',
  mail: 'nguyenvana@gmail.com',
  phoneNumber: '0800312454',
  language: 'Tiếng Việt',
},
];

function ContactInfo() {
  const [isShowModalAddContactInfo, setIsShowModalAddContactInfo] = useState(false);

  const onToggleModalAddContactInfo = useCallback(() => {
    setIsShowModalAddContactInfo((prevState) => !prevState);
  }, []);

  return (
    <div className={`page-content ${styles.Page}`}>
      <AppMeta
        name="Thông tin liên hệ"
      />

      <Container fluid>
        <AppBreadcrumb
          pageTitle="Thông tin liên hệ"
          breadcrumbList={breadcrumbList}
        />

        <Row>
          <Col md="6" xxl="3">
            <Card className="Section">
              <CardTitle className="Section__Title">
                Đại diện liên hệ chính
              </CardTitle>

              <p className="Section__Subtitle">
                Đây là chi tiết liên hệ được gửi cho khách trong xác nhận đặt phòng
              </p>

              {infoCheckIn.map((item) => (
                <CardLocationContactInfo
                  list={item}
                />
              ))}

              <AppButton
                color="secondary"
                classes="BtnAddRepresentative"
                onClick={onToggleModalAddContactInfo}
              >
                <Add />

                <p className="TextButton">Thêm người đại diện liên hệ</p>
              </AppButton>
            </Card>
          </Col>

          <Col md="6" xxl="3">
            <Card className="Section">
              <CardTitle className="Section__Title">
                Đặt phòng
              </CardTitle>

              <p className="Section__Subtitle">
                Đây là đại điện liên hệ và quản lý các đặt phòng của Quý vị
              </p>

              {infoCheckIn.map((item) => (
                <CardLocationContactInfo
                  list={item}
                />
              ))}

              <AppButton
                color="secondary"
                classes="BtnAddRepresentative"
                onClick={onToggleModalAddContactInfo}
              >
                <Add />

                <p className="TextButton">Thêm người đại diện liên hệ</p>
              </AppButton>
            </Card>
          </Col>

          <Col md="6" xxl="3">
            <Card className="Section">
              <CardTitle className="Section__Title">
                Thông tin & hỗ trợ
              </CardTitle>

              <p className="Section__Subtitle">
                Đây là chi tiết liên hệ hỗ trợ khách hàng
              </p>

              <AppButton
                color="secondary"
                classes="BtnAddRepresentative"
                onClick={onToggleModalAddContactInfo}
              >
                <Add />

                <p className="TextButton">Thêm người đại diện liên hệ</p>
              </AppButton>
            </Card>
          </Col>

          <Col md="6" xxl="3">
            <Card className="Section">
              <CardTitle className="Section__Title">
                Thanh toán & hóa đơn
              </CardTitle>

              <p className="Section__Subtitle">
                Đây là chi tiết liên hệ hỗ trợ khách thanh toán
                và gửi hoá đơn thanh toán về Email này
              </p>

              {infoCheckIn.map((item) => (
                <CardLocationContactInfo
                  list={item}
                />
              ))}

              <AppButton
                color="secondary"
                classes="BtnAddRepresentative"
                onClick={onToggleModalAddContactInfo}
              >
                <Add />

                <p className="TextButton">Thêm người đại diện liên hệ</p>
              </AppButton>
            </Card>
          </Col>
        </Row>

        <ModalAddContactInfo
          isOpen={isShowModalAddContactInfo}
          onToggleModal={onToggleModalAddContactInfo}
        />

      </Container>
    </div>
  );
}

export default ContactInfo;
