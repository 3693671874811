import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

import { LOCATIONS, storageKey } from 'constants/index';

import AppLoading from 'components/App/Loading';

const AuthMiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  // eslint-disable-next-line react/prop-types
  path,
  roles,
  permission,
  ...rest
}) => {
  const accessToken = localStorage.getItem(storageKey.AUTHENTICATE_TOKEN);

  const [rolesState, setRolesState] = useState([]);

  useEffect(() => {
    if (roles) {
      setRolesState(roles);
    }
  }, [roles]);

  const renderLayout = (props) => {
    const checkPermission = rolesState?.permissions?.filter((role) => role === permission);

    if (rolesState.isRoot || checkPermission || permission === '') {
      return (
        <Layout>
          <Component {...props} />
        </Layout>
      );
    }

    // if (rolesState) {
    //   if (!rolesState.isRoot
    //   && !checkPermission
    //   && permission !== ''
    //   ) {
    //     return <Page403 />;
    //   }
    // }

    return <AppLoading />;
  };

  return (
    accessToken ? (
      <Route
        {...rest}
        render={renderLayout}
      />
    ) : (
      <Redirect
        to={LOCATIONS.LOGIN}
        from={path}
      />
    )
  );
};

AuthMiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,

  permission: PropTypes.string,
  roles: PropTypes.instanceOf(Object),
  location: PropTypes.instanceOf(Object),
  component: PropTypes.instanceOf(Object),

  layout: PropTypes.instanceOf(Object),
};

AuthMiddleware.defaultProps = {
  isAuthProtected: false,

  permission: '',
  roles: {},
  component: {},
  location: {},
  layout: {},
};

export default AuthMiddleware;
