/* eslint-disable no-unused-vars */
import React, { useMemo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Label,
} from 'reactstrap';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';

import AppButton from 'components/App/Button';
import Authentication from 'components/App/Authentication';
import Meta from 'components/App/Meta';

import { loginAdminValidation } from 'utils/validate';

import styles from './ForgotPassword.module.scss';

const ForgotPasswordPage = (props) => {
  const {
    // loadingStep1,
    // loadingStep2,

    actionForgotPassword,
    forgotPasswordLoading,
  } = props;

  const history = useHistory();

  const validationStep1 = useFormik({
    enableReinitialize: true,

    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: loginAdminValidation.email,
    }),
    onSubmit: (values) => {
      actionForgotPassword({ user: values, history });
    },
  });

  const validationStep2 = useFormik({
    initialValues: {
      code: '',
    },

    validationSchema: Yup.object({
      // code: signUpStep2Validation.code,
    }),

    onSubmit: (values) => {
      console.log('««««« values »»»»»', values);

      // actionRegisterStep2({
      //   phoneNumber: newPhoneNumber,
      //   code: values.code,
      //   callback: onCallBackNextStep,
      // });
    },
  });

  const onSubmit = useCallback((e) => {
    e.preventDefault();
    validationStep1.handleSubmit();
  }, []);

  const validationErrors = useMemo(() => validationStep1.errors, [validationStep1.errors]);

  return (
    <>
      <Meta name="" />

      <Authentication
        title="Xin chào!"
        description="Đăng nhập để đến quản lý chỗ nghỉ"
      >
        <div className={styles.Page}>
          <div className="Login__Email">
            <Label className="Login__Label">
              Nhập mã OTP
            </Label>

            {/* <AppInputValidation
              inputClassName="Login__App-Input"
              placeholder="Nhập tên đăng nhập"
              name="email"
              validation={validationStep1}
              isCustomOnChange
              onChange={onChangeEmail}
            /> */}
            <input
              type="text"
              className={`phone-input ${validationStep2.values.code.length > 5 && styles.hiddenCaret}`}
              name="code"
              id="code"
              maxLength="6"
              value={validationStep2.values.code}
              onChange={validationStep2.handleChange}
              onBlur={validationStep2.handleBlur}
              // disabled={loadingStep2}
            />
          </div>

          <div
            className="Login__Forgot-Password"
          >
            Chưa nhận được mã OTP? 12s
          </div>

          <AppButton
            classes="Login__Btn-Login"
            loading={forgotPasswordLoading}
            // onClick={onLoginAdmin}
            type="submit"
          >
            KẾ TIẾP
          </AppButton>

        </div>
      </Authentication>
    </>
  );
};

ForgotPasswordPage.propTypes = {
  actionForgotPassword: PropTypes.func.isRequired,
  forgotPasswordLoading: PropTypes.bool.isRequired,
};

export default ForgotPasswordPage;
