import { Redirect } from 'react-router-dom';

import { allowPermission, LOCATIONS } from 'constants/index';

import Profile from 'pages/Authentication/Profile';

import ForgotPwd from 'pages/Authentication/ForgotPassword';
import Login from 'pages/Authentication/Login';
import Register from 'pages/Authentication/Register';

import Dashboard from 'pages/Dashboard/index';

import Media from 'pages/Media';
import MediaDetail from 'pages/Media/MediaDetail';

import EditLocation from 'pages/Location/EditLocation';
import LocationList from 'pages/Location/LocationList';
import LocationDetail from 'pages/Location/LocationDetail';
import AddAlreadyLocation from 'pages/Location/EditLocation/AddAlreadyLocation';
// import LocationPolicy from 'pages/Location/Policy';
import LocationContactInfo from 'pages/Location/ContactInfo';
import LocationPolicy from 'pages/Location/LocationDetail/Policy';
import LocationConveniencesServices from 'pages/Location/LocationDetail/ConveniencesServices';

import OpenCloseRoom from 'pages/Location/LocationDetail/OpenCloseRoom';
import AmountRoom from 'pages/Location/LocationDetail/AmountRoom';
import Price from 'pages/Location/LocationDetail/Price';
import PriceType from 'pages/Location/LocationDetail/PriceType';
import EditPriceType from 'pages/Location/LocationDetail/EditPriceType';

import CreateNewRoom from 'pages/Location/LocationDetail/Policy/TypeRoom/CreateNewRoom/CreateNewRoom';
import TypeRoom from 'pages/Location/LocationDetail/Policy/TypeRoom';

import ConvenienceRoom from 'pages/Location/ConvenienceRoom';
import Calendar from 'pages/Location/Calendar';

const authProtectedRoutes = [
  { path: LOCATIONS.DASHBOARD, exact: true, component: Dashboard },

  // profile
  { path: LOCATIONS.PROFILE, exact: true, component: Profile },

  // Media
  {
    path: LOCATIONS.MEDIA,
    component: Media,
    exact: true,
    permission: allowPermission.VIEW_ALL_MEDIA,
  },
  {
    path: LOCATIONS.MEDIA_VIEW,
    component: MediaDetail,
    permission: allowPermission.VIEW_DETAIL_MEDIA,
  },

  // Location
  {
    path: LOCATIONS.LOCATION_ADD,
    component: EditLocation,
    exact: true,
  },
  {
    path: LOCATIONS.ADD_ALREADY_LOCATION,
    component: AddAlreadyLocation,
    exact: true,
  },
  {
    path: LOCATIONS.LOCATION_LIST,
    component: LocationList,
    exact: true,
  },
  {
    path: LOCATIONS.LOCATION_DETAIL,
    component: LocationDetail,
    exact: true,
  },
  {
    path: LOCATIONS.LOCATION_POLICY,
    component: LocationPolicy,
    exact: true,
  },

  // CREATE AND DETAIL ROOM
  {
    path: LOCATIONS.LOCATION_CONVENIENCE_SERVICE,
    component: LocationConveniencesServices,
    exact: true,
  },
  {
    path: LOCATIONS.CALENDAR,
    component: Calendar,
    exact: true,
  },
  {
    path: LOCATIONS.OPEN_CLOSE_ROOM,
    component: OpenCloseRoom,
    exact: true,
  },
  {
    path: LOCATIONS.UPDATE_AMOUNT_ROOM,
    component: AmountRoom,
    exact: true,
  },
  {
    path: LOCATIONS.UPDATE_PRICE,
    component: Price,
    exact: true,
  },
  {
    path: LOCATIONS.LIST_PRICE_TYPE,
    component: PriceType,
    exact: true,
  },
  {
    path: LOCATIONS.UPDATE_PRICE_TYPE,
    component: EditPriceType,
    exact: true,
  },
  {
    path: LOCATIONS.LOCATION_CREATE_ROOM,
    component: CreateNewRoom,
    exact: true,
  },
  {
    path: LOCATIONS.LOCATION_EDIT_ROOM,
    component: CreateNewRoom,
    exact: true,
  },

  // EDIT CONVENIENCE ROOM
  {
    path: LOCATIONS.LOCATION_CONVENIENCE_ROOM,
    component: ConvenienceRoom,
    exact: true,
  },
  {
    path: LOCATIONS.LOCATION_CONVENIENCE_SERVICE,
    component: LocationConveniencesServices,
    exact: true,
  },
  {
    path: LOCATIONS.LOCATION_ROOM_TYPE,
    component: TypeRoom,
    exact: true,
  },
  {
    path: LOCATIONS.LOCATION_CONTACT_INFO,
    component: LocationContactInfo,
    exact: true,
  },

  // this route should be at the end of all other routes
  { path: '/', exact: true, component: () => <Redirect to={LOCATIONS.LOCATION_LIST} /> },
];

const publicRoutes = [
  { path: LOCATIONS.LOGIN, exact: true, component: Login },
  { path: LOCATIONS.FORGOT_PASSWORD, exact: true, component: ForgotPwd },
  { path: LOCATIONS.REGISTER, exact: true, component: Register },
];

export { publicRoutes, authProtectedRoutes };
