import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { MetaTags } from 'react-meta-tags';
import { useTranslation } from 'react-i18next';

import { SITE_NAME } from 'constants/index';

const Meta = (props) => {
  const { name } = props;

  const { t } = useTranslation();

  return (
    <MetaTags>
      <meta charSet="utf-8" />

      <title>
        {`${t(name)} | ${SITE_NAME}`}
      </title>
    </MetaTags>
  );
};

Meta.propTypes = {
  name: PropTypes.string,
};

Meta.defaultProps = {
  name: '',
};

export default memo(Meta);
