import { connect } from 'react-redux';

import { actionShowRightSidebar } from 'store/actions';

import Header from './Header';

const mapStateToProps = (state) => ({
  showRightSidebar: state.Layout.showRightSidebar,
});

const mapDispatchToProps = (dispatch) => ({
  actionShowRightSidebar: (payload) => dispatch(actionShowRightSidebar(payload)),

});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
